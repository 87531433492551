import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface ModalContentsProps {
  image: string;
  targetEmployeeData: any;
}
// Element  | null JXS.Element
// (alias) const PersonalInfoSection: () => JSX.Element | undefined
export const ModalContents = ({
  image,
  targetEmployeeData,
}: ModalContentsProps): any => {
  const navigate = useNavigate();

  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');

  /**
   * permitCode 권한 코드
   * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
   * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
   * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
   */

  const isHeadOfficeManagerCode =
    permitCode === process.env.HEAD_OFFICE_MANAGER_CODE;
  const isNormalEmployeeCode = permitCode === process.env.NORMAL_EMPLOYEE_CODE;

  if (!isHeadOfficeManagerCode && !token) {
    alert('로그인부터 해주세요.');
    navigate('/login');
    return;
  } else if (!isHeadOfficeManagerCode) {
    alert('접근 권한이 없습니다.');
    return;
  }

  console.log(image, '😫😫😫image');

  return (
    <Wrap>
      {/* <UserImg src={`${image}`} alt="증명사진" /> */}
      {image ? (
        <UserImg src={`${image}`} />
      ) : (
        <NoneApartmentImg>데이터 없음</NoneApartmentImg>
      )}

      <Box>
        <ItemBox>
          <ItemName>이름</ItemName>
          <TextBox>
            {targetEmployeeData.UserNameKR
              ? targetEmployeeData.UserNameKR
              : '-'}
          </TextBox>
        </ItemBox>

        <ItemBox>
          <ItemName>사번</ItemName>
          <TextBox>
            {targetEmployeeData.EmployeeNo
              ? targetEmployeeData.EmployeeNo
              : '-'}
          </TextBox>
        </ItemBox>

        <ItemBox>
          <ItemName>아이디</ItemName>
          <TextBox>
            {targetEmployeeData.UserId ? targetEmployeeData.UserId : '-'}
          </TextBox>
        </ItemBox>
      </Box>

      <Box>
        <ItemBox>
          <ItemName>주민등록번호</ItemName>
          <TextBox>
            {targetEmployeeData.RegNumber ? targetEmployeeData.RegNumber : '-'}
          </TextBox>
        </ItemBox>

        <ItemBox>
          <ItemName>전화번호</ItemName>
          <TextBox>
            {targetEmployeeData.NumberMobile
              ? targetEmployeeData.NumberMobile
              : '-'}
          </TextBox>
        </ItemBox>

        <ItemBox>
          <ItemName>직무</ItemName>
          <TextBox>
            {targetEmployeeData.JobName ? targetEmployeeData.JobName : '-'}
          </TextBox>
        </ItemBox>
      </Box>

      <Box>
        <ItemBox>
          <ItemName>입사일</ItemName>
          <TextBox>
            {targetEmployeeData.EnterDate ? targetEmployeeData.EnterDate : '-'}
          </TextBox>
        </ItemBox>

        <ItemBox>
          <ItemName>퇴사일</ItemName>
          <TextBox>
            {targetEmployeeData.ExitDate ? targetEmployeeData.ExitDate : '-'}
          </TextBox>
        </ItemBox>
      </Box>

      <Box>
        <ItemBox>
          <ItemName>주소1</ItemName>
          <TextBox className="long">
            {targetEmployeeData.Address1 ? targetEmployeeData.Address1 : '-'}
          </TextBox>
        </ItemBox>
      </Box>

      <Box>
        <ItemBox>
          <ItemName>주소2</ItemName>
          <TextBox>
            {targetEmployeeData.Address2 ? targetEmployeeData.Address2 : '-'}
          </TextBox>
        </ItemBox>
      </Box>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Box = styled.ul`
  list-style: none;
`;

const ItemBox = styled.li`
  float: left;
  margin: 5px;
`;

const ImageBoxContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 220px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  font-size: 12px;
  color: ${({ theme }) => theme.fontColor.lightGray};
`;

const ItemName = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.fontColor.gray};
`;

const UserImg = styled.img`
  width: 138px;
  height: 184px;
  margin: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  font-size: 12px;
  color: ${({ theme }) => theme.fontColor.lightGray};
`;

const NoneApartmentImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 138px;
  height: 202px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  color: ${({ theme }) => theme.fontColor.lightGray};
  font-size: 13px;
`;

const TextBox = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: 120px;
  font-size: 13px;

  &.medium {
    width: 200px;
  }

  &.long {
    width: 350px;
  }
`;
