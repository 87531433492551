// [MEMO] 근태 이상 현황 조회(출근=근무)
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface loadWorkStatusCheckToWorkDataProps {
  setToWorkStatusCheckData: Dispatch<SetStateAction<never[]>>;
  startCheckToWorkYearPicker: string;
  startCheckToWorkMonthPicker: string;
  startCheckToWorkDatePicker: string;
  endCheckToWorkYearPicker: string;
  endCheckToWorkMonthPicker: string;
  endCheckToWorkDatePicker: string;
}

export const loadWorkStatusCheckToWorkData = async ({
  setToWorkStatusCheckData,
  startCheckToWorkYearPicker,
  startCheckToWorkMonthPicker,
  startCheckToWorkDatePicker,
  endCheckToWorkYearPicker,
  endCheckToWorkMonthPicker,
  endCheckToWorkDatePicker,
}: loadWorkStatusCheckToWorkDataProps) => {
  const token = sessionStorage.getItem('token');

  const calStartYear = String(startCheckToWorkYearPicker).padStart(2, '0');
  const calStartMonth = String(startCheckToWorkMonthPicker).padStart(2, '0');
  const calStartDate = String(startCheckToWorkDatePicker).padStart(2, '0');

  const calEndYear = String(endCheckToWorkYearPicker).padStart(2, '0');
  const calEndMonth = String(endCheckToWorkMonthPicker).padStart(2, '0');
  const calEndDate = String(endCheckToWorkDatePicker).padStart(2, '0');

  const startEndDate = `StartDate=${calStartYear}${calStartMonth}${calStartDate}&EndDate=${calEndYear}${calEndMonth}${calEndDate}&`;

  // ContractIdx=000053

  await axios
    .get(
      // https://apihr.mmigroup.co.kr:9020/dashboard/da10010/
      // attendancecheck?StartDate=20230501&EndDate=20230530&Action=A
      `${process.env.COMMON_URL}/dashboard/da10010/attendancecheck?${startEndDate}Action=A`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '메인화면 / 근태 이상 현황 조회(출근, 근무) API: ',
      //   `${process.env.COMMON_URL}/dashboard/da10010/attendancecheck?${startEndDate}Action=A`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          // console.log(
          //   '메인화면 / 근태 이상 현황 조회(출근, 근무) res.data.Data: ',
          //   res.data.Data
          // );
          setToWorkStatusCheckData(res.data.Data);
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
