// [MEMO] 근태 이상 현황 조회(퇴근)
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface loadWorkStatusCheckToHomeDataProps {
  setToHomeStatusCheckData: Dispatch<SetStateAction<never[]>>;
  startCheckToHomeYearPicker: string;
  startCheckToHomeMonthPicker: string;
  startCheckToHomeDatePicker: string;
  endCheckToHomeYearPicker: string;
  endCheckToHomeMonthPicker: string;
  endCheckToHomeDatePicker: string;
}

export const loadWorkStatusCheckToHomeData = async ({
  setToHomeStatusCheckData,
  startCheckToHomeYearPicker,
  startCheckToHomeMonthPicker,
  startCheckToHomeDatePicker,
  endCheckToHomeYearPicker,
  endCheckToHomeMonthPicker,
  endCheckToHomeDatePicker,
}: loadWorkStatusCheckToHomeDataProps) => {
  const token = sessionStorage.getItem('token');

  const calStartYear = String(startCheckToHomeYearPicker).padStart(2, '0');
  const calStartMonth = String(startCheckToHomeMonthPicker).padStart(2, '0');
  const calStartDate = String(startCheckToHomeDatePicker).padStart(2, '0');

  const calEndYear = String(endCheckToHomeYearPicker).padStart(2, '0');
  const calEndMonth = String(endCheckToHomeMonthPicker).padStart(2, '0');
  const calEndDate = String(endCheckToHomeDatePicker).padStart(2, '0');

  const startEndDate = `StartDate=${calStartYear}${calStartMonth}${calStartDate}&EndDate=${calEndYear}${calEndMonth}${calEndDate}&`;

  await axios
    .get(
      // https://apihr.mmigroup.co.kr:9020/dashboard/da10010/attendancecheck?Action=L
      `${process.env.COMMON_URL}/dashboard/da10010/attendancecheck?${startEndDate}Action=L`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '메인화면 / 근태 이상 현황 조회(퇴) API: ',
      //   `${process.env.COMMON_URL}/dashboard/da10010/attendancecheck?${startEndDate}Action=L`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          // console.log(
          //   '메인화면 / 근태 이상 현황 조회(퇴) res.data.Data: ',
          //   res.data.Data
          // );
          setToHomeStatusCheckData(res.data.Data);
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
