import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { ModalContents } from './ModalContents';

interface BeforeResistGuideModalProps {
  apartmentIdx: number;
  setOpenBeforeResistGuideModal: Dispatch<SetStateAction<boolean>>;
  filteredNewAddedData: [];
  modifiedData: [];
  onlyExistentServerData: [];
  resultData: [];
  resultData2: [];

  completelyExistentData: [];

  setLoadedData: Dispatch<SetStateAction<[]>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setDetectChangingData: Dispatch<SetStateAction<boolean>>;
}

export const BeforeResistGuideModal = ({
  apartmentIdx,
  setOpenBeforeResistGuideModal,
  filteredNewAddedData,
  modifiedData,
  onlyExistentServerData,
  resultData,
  resultData2,

  completelyExistentData,
  setLoadedData,
  setLoading,
  setDetectChangingData,
}: BeforeResistGuideModalProps) => {
  const closeModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setOpenBeforeResistGuideModal(false);
    e.stopPropagation();
  };

  return (
    <Background onClick={e => closeModal(e)}>
      <Container
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ModalContents
          closeModal={closeModal}
          apartmentIdx={apartmentIdx}
          filteredNewAddedData={filteredNewAddedData}
          modifiedData={modifiedData}
          onlyExistentServerData={onlyExistentServerData}
          resultData={resultData}
          resultData2={resultData2}
          setLoadedData={setLoadedData}
          setLoading={setLoading}
          setOpenBeforeResistGuideModal={setOpenBeforeResistGuideModal}
          setDetectChangingData={setDetectChangingData}
        />
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: white;
`;
