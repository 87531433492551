import React, { useState, useEffect } from 'react';
import * as S from './PersonalInfo.style';

import axios from 'axios';

import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/store';

import { useQuery } from 'react-query'; // React Query

import { Input } from '../../UI/Input/Short';
import { Button } from '../../UI/Button/Long';

// Element  | null JXS.Element
// (alias) const PersonalInfoSection: () => JSX.Element | undefined
export const PersonalInfo = (): any => {
  const token = sessionStorage.getItem('token');
  const sessionId = sessionStorage.getItem('sessionId');
  const employeeNum = sessionStorage.getItem('employeeNum');

  const [userData, setUserData] = useState({});

  useEffect(() => {
    refetch();
  }, [employeeNum]);

  const { refetch } = useQuery({
    queryKey: ['userLogin'],
    queryFn: async () =>
      axios
        .get(
          `${process.env.COMMON_URL}/common/refuser/${employeeNum}`
          // {
          //   sessionId: 'string',
          //   tokenId: 'string',
          // }
        )
        .then(res => {
          setUserData(res.data.Data[0]);
        }),
  });

  const requestKRName = useSelector(
    (state: RootState) => state.targetEmployeeInfo.nameKRValue
  ); // 이름(한글)
  const requestEmployeeNum = useSelector(
    (state: RootState) => state.targetEmployeeInfo.employeeNumValue
  ); // 사번

  const requestUserId = useSelector(
    (state: RootState) => state.targetEmployeeInfo.userIDValue
  );

  const requestJobName = useSelector(
    (state: RootState) => state.targetEmployeeInfo.jobNameValue
  ); // 직무

  const requestRegistrationNum = useSelector(
    (state: RootState) => state.targetEmployeeInfo.registrationNumValue
  ); // 주민등록번호
  const requestPhoneNum = useSelector(
    (state: RootState) => state.targetEmployeeInfo.phoneNumValue
  ); // 전화번호
  const requestAddress1 = useSelector(
    (state: RootState) => state.targetEmployeeInfo.address1Value
  ); // 주소1
  const requestAddress2 = useSelector(
    (state: RootState) => state.targetEmployeeInfo.address2Value
  ); // 주소2

  const requestEnterDate = useSelector(
    (state: RootState) => state.targetEmployeeInfo.enterDateValue
  ); // 입사일

  return (
    <S.Section>
      <S.Wrap>
        <S.Box>
          <S.ItemBox>
            <S.ItemName>이름</S.ItemName>
            <Input defaultValue={requestKRName ? requestKRName : '-'} />
          </S.ItemBox>
          <S.ItemBox>
            <S.ItemName>사번</S.ItemName>
            <Input
              defaultValue={requestEmployeeNum ? requestEmployeeNum : '-'}
            />
          </S.ItemBox>
        </S.Box>

        <S.Box>
          <S.ItemBox>
            <S.ItemName>아이디</S.ItemName>
            <Input defaultValue={requestUserId ? requestUserId : '-'} />
          </S.ItemBox>
          <S.ItemBox>
            <S.ItemName>비밀번호</S.ItemName>
            <Button label="비밀번호 변경" />
          </S.ItemBox>
        </S.Box>

        <S.Box>
          <S.ItemBox>
            <S.ItemName>주민등록번호</S.ItemName>
            <Input
              defaultValue={
                requestRegistrationNum ? requestRegistrationNum : '-'
              }
            />
          </S.ItemBox>
          <S.ItemBox>
            <S.ItemName>전화번호</S.ItemName>
            <Input defaultValue={requestPhoneNum ? requestPhoneNum : '-'} />
          </S.ItemBox>
        </S.Box>

        <S.Box>
          <S.ItemBox>
            <S.ItemName>직무</S.ItemName>
            <Input defaultValue={requestJobName ? requestJobName : '-'} />
          </S.ItemBox>
          <S.ItemBox>
            <S.ItemName>입사일</S.ItemName>
            <Input defaultValue={requestEnterDate ? requestEnterDate : '-'} />
          </S.ItemBox>
        </S.Box>

        <S.Box>
          <S.ItemBox>
            <S.ItemName>주소1</S.ItemName>
            <Input
              defaultValue={requestAddress1 ? requestAddress1 : '-'}
              size="medium"
            />
          </S.ItemBox>
        </S.Box>

        <S.Box>
          <S.ItemBox>
            <S.ItemName>주소2</S.ItemName>
            <Input defaultValue={requestAddress2 ? requestAddress2 : '-'} />
          </S.ItemBox>
        </S.Box>
      </S.Wrap>
    </S.Section>
  );
};
