// [MEMO] 차량 주차 정보 인쇄
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as S from './ParkingInfoPrinting.style';

import { loadExistingData } from '../utils/loadExistingData';

import { ApprovalList } from '../../SP10-020-approval-table-setting/ApprovalList/ApprovalList'; // 결재 박스

import { read, utils, writeFileXLSX, WorkSheet } from 'xlsx'; //🎅🎅 SheetJS

/**
 * permitCode 권한 코드
 * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
 * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
 * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
 */

const ParkingInfoPrinting = () => {
  /* 👇 🥺🥺🥺🥺🥺 */
  const queryParams = new URLSearchParams(window.location.search);
  const receivedObject = JSON.parse(queryParams.get('dataObject'));

  console.log('🍕receivedObject: ', receivedObject);
  /* 👆 🥺🥺🥺🥺🥺 */

  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentNameAtSessionStorage = sessionStorage.getItem('apartmentName');

  const [isListData, setIsListData] = useState(false);

  /* 👇 🥺🥺🥺🥺🥺 */
  const [loadedData, setLoadedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataReceived = loadedData;

  useEffect(() => {
    loadExistingData({
      setLoadedData,
      setLoading,
      apartmentIdx: Number(receivedObject.apartmentIdx),
      selectedContractStart: receivedObject.selectedContractStart,
      selectedContractEnd: receivedObject.selectedContractEnd,
      significantFlagSign: receivedObject.significantFlagSign,
      unregisteredCarSign: receivedObject.unregisteredCarSign,
      enteredCarNumber: receivedObject.enteredCarNumber, // 🍑🍑🍑
      selectedCheckPeriod: receivedObject.selectedCheckPeriod,
    });
  }, []);
  /* 👆 🥺🥺🥺🥺🥺 */

  // 👇 [TAG] 데이터 받기(useLocation)
  // const dataReceived = location.state.loadedData;
  // const calStartYear = location.state.calStartYear;
  // const calStartMonth = location.state.calStartMonth;
  // const calStartDate = location.state.calStartDate;
  // const calEndYear = location.state.calEndYear;
  // const calEndMonth = location.state.calEndMonth;
  // const calEndDate = location.state.calEndDate;
  // const checkPeriod = location.state.checkPeriod;

  // const startDate = `${calStartYear}-${calStartMonth}-${calStartDate}`;
  // const endDate = `${calEndYear}-${calEndMonth}-${calEndDate}`;

  const startDate = `${receivedObject.selectedContractStart.slice(
    0,
    4
  )}-${receivedObject.selectedContractStart.slice(
    4,
    6
  )}-${receivedObject.selectedContractStart.slice(6, 8)}`;
  const endDate = `${receivedObject.selectedContractEnd.slice(
    0,
    4
  )}-${receivedObject.selectedContractEnd.slice(
    4,
    6
  )}-${receivedObject.selectedContractEnd.slice(6, 8)}`;

  const dataArray: {
    idx: string; //
    addr1: string; // 동/Addr1
    addr2: string; // 호수/Addr2
    carNumber: string; // 차량 번호/CarNo

    repeatCount: string; // 외부 주차 횟수/RepeatCnt
    flag: string; // 위반 여부(M: 위반O, 이외: 위반X)/Flag
    flagCount: number; // 위반 횟수/FlagCnt

    carFlag: string; // 차량 등록 유형/CarFlag
    carRegDate: string; // 차량 등록일/CarRegDate
    checkDateTime1: string; // 주차 확인일(날짜)
    checkDateTime2: string; // 주차 확인일(시간)

    ownerName: string; // 소유주 이름/OwnerName
    ownerPhoneNum: string; // 소유주 전화번호/OwnerPhone

    employeeName: string; // 근무자(등록인)/EmployeName
    employeeNum: string; // 근무자(등록인) 사번/RegEmployeeNo
  }[] = [];

  function loadReportListData() {
    // [MEMO] onCarType: 차량 유형(번호판 구분)
    function onCarType(data: string) {
      if (data === 'N') {
        return '일반';
      } else if (data === 'E') {
        return '전기차';
      } else if (data === 'C') {
        return '영업';
      } else if (data === 'G') {
        return '녹색';
      } else if (data === 'T') {
        return '건설기계';
      } else if (data === 'F') {
        return '미분류';
      }
      return data;
    }

    // [MEMO] onCarFlag: 등록 유형
    function onCarFlag(data: string) {
      if (data === 'R') {
        return '등록';
      } else if (data === 'T') {
        return '임시';
      } else if (data === 'A') {
        return '방문';
      } else if (data === 'N') {
        return '미등록';
      }
      return data;
    }

    // [MEMO] onFlag: 위반 여부
    function onFlag(data: string) {
      if (data === 'N') {
        return '';
      } else if (data === 'M') {
        return '위반';
      }
      return data;
    }

    function onOwnerPhone(data: string) {
      /**
       * String.prototype.startsWith()
       * -https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
       * -startsWith() 메서드는 어떤 문자열이 특정 문자로 시작하는지를 확인하여,
       * 그 결과를 true 혹은 false로 반환한다.
       */
      if (data.length === 11) {
        if (data.startsWith('010')) {
          return `${data.slice(0, 3)}-${data.slice(3, 7)}-${data.slice(7, 11)}`;
        }
      }
      return data;
    }

    dataReceived &&
      dataReceived.map(data => {
        let resultData = {};
        if (permitCode === headOfficerManagerCode) {
          resultData = {
            idx: data.idx, //
            addr1: data.Addr1, // 동/Addr1
            addr2: data.Addr2, //  호수/Addr2
            carNumber: data.CarNo, // 차량 번호/CarNo
            CarType: onCarType(data.CarType), // 차량 유형(번호 유형, 번호판 구분)

            repeatCount: data.RepeatCnt, // 외부 주차 횟수/RepeatCnt
            flag: onFlag(data.Flag), // 위반 여부(M: 위반O, 이외: 위반X)/Flag
            flagCount: data.FlagCnt, // 위반 횟수/FlagCnt

            DetArea: data.DetArea, // 인식 크기(관리자만 확인 가능)
            confidenceCheck: `${data.Conf}/${data.RConf}`, // 신뢰도(관리자만 확인 가능)

            carFlag: onCarFlag(data.CarFlag), // 차량 등록 유형/CarFlag
            carRegDate: data.CarRegDate.slice(0, 10), // 차량 등록일/CarRegDate
            checkDateTime1: `${data.CheckingDateTime.slice(0, 10)}`, // 주차 확인일(날짜)
            checkDateTime2: `${data.CheckingDateTime.slice(11, 19)}`, // 주차 확인일(시간)

            ownerName: data.OwnerName, // 소유주 이름/OwnerName
            ownerPhoneNum: onOwnerPhone(data.OwnerPhone), // 소유주 전화번호/OwnerPhone

            employeeName: data.EmployeName, // 근무자(등록인)/EmployeName
            employeeNum: data.RegEmployeeNo, // 근무자(등록인) 사번/RegEmployeeNo
          };
        } else if (permitCode === apartmentManagerCode) {
          resultData = {
            idx: data.idx, //
            addr1: data.Addr1, // 동/Addr1
            addr2: data.Addr2, //  호수/Addr2
            carNumber: data.CarNo, // 차량 번호/CarNo
            CarType: onCarType(data.CarType), // 차량 유형(번호 유형, 번호판 구분)

            repeatCount: data.RepeatCnt, // 외부 주차 횟수/RepeatCnt
            flag: onFlag(data.Flag), // 위반 여부(M: 위반O, 이외: 위반X)/Flag
            flagCount: data.FlagCnt, // 위반 횟수/FlagCnt

            carFlag: onCarFlag(data.CarFlag), // 차량 등록 유형/CarFlag
            carRegDate: data.CarRegDate.slice(0, 10), // 차량 등록일/CarRegDate
            checkDateTime1: `${data.CheckingDateTime.slice(0, 10)}`, // 주차 확인일(날짜)
            checkDateTime2: `${data.CheckingDateTime.slice(11, 19)}`, // 주차 확인일(시간)

            ownerName: data.OwnerName, // 소유주 이름/OwnerName
            ownerPhoneNum: onOwnerPhone(data.OwnerPhone), // 소유주 전화번호/OwnerPhone

            employeeName: data.EmployeName, // 근무자(등록인)/EmployeName
            employeeNum: data.RegEmployeeNo, // 근무자(등록인) 사번/RegEmployeeNo
          };
        }

        dataArray.push(resultData);
      });
  }
  loadReportListData();

  // 👇 오늘 날짜
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const today = `${year}-${month}-${day}`;

  /* 👇 [TAG] 배열 자르기 -----start */
  // const itemsPerFirstPage = 30; // 첫 페이지에 출력할 항목 수
  // isListData === true -> 결재자가 아무도 없는 상태(결재박스 없는 상태)
  const itemsPerFirstPage = isListData === true ? 35 : 31; // 첫 페이지에 출력할 항목 수
  const itemsPerPage = 44; // (첫 페이지 이후) 한 페이지에 출력할 항목 수

  // 데이터를 페이지당 항목 수로 분할
  const pages = [];
  let pageIndex = 0;

  for (let i = 0; i < dataArray.length; i++) {
    if (!pages[pageIndex]) {
      pages[pageIndex] = [];
    }

    pages[pageIndex].push(dataArray[i]);

    // 👇 미사용 -----start
    // if (pages[pageIndex].length === itemsPerPage) {
    //   pageIndex++;
    // }
    // 👆 미사용 -----end

    if (
      pages[pageIndex].length ===
      (pageIndex === 0 ? itemsPerFirstPage : itemsPerPage)
    ) {
      pageIndex++;
    }
  }
  /* 👆 [TAG] 배열 자르기 -----end */

  /* 👇 excel export 1 -----start 🎅🎅 */
  /* the component state is an HTML string */
  const [__html, setHtml] = useState('');
  /* the ref is used in export */
  const tableRef = useRef(null);

  /* Fetch and update the state once */
  useEffect(() => {
    (async () => {
      const f = await (
        await fetch('https://sheetjs.com/pres.xlsx')
      ).arrayBuffer();
      const wb = read(f); // parse the array buffer
      const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
      const data = utils.sheet_to_html(ws); // generate HTML
      setHtml(data); // update state
    })();
  }, []);

  /* get live table and export to XLSX */
  const exportExcelFile = useCallback(() => {
    const columns = [
      {
        name: '차량 번호',
      },
      {
        name: '동',
      },
      {
        name: '호',
      },
      {
        name: '등록 여부',
      },
      {
        name: '외부 횟수',
      },
      {
        name: '위반 여부',
      },
      {
        name: '위반 횟수',
      },
      {
        name: '주차 확인 날짜',
      },
      {
        name: '주차 확인 시간',
      },
      {
        name: '확인',
      },
    ];

    const $table = tableRef.current.getElementsByTagName('table')[0];
    console.log('🥗1🥗tableRef: ', tableRef);
    console.log('🥗2🥗tableRef.current: ', tableRef.current);
    const $tr = $table.querySelectorAll('tr');
    const columnData = columns.map(column => column.name);
    const rowData = Array.from($tr).map(row =>
      Array.from(row.children).map(cell => cell.textContent || cell.innerText)
    );
    console.log('🍖1🍖rowData: ', rowData);
    console.log(
      '🍖2🍖Array.from...: ',
      Array.from($tr).map(row => Array.from(row.children))
    );

    const header = columnData; //
    const body = rowData;

    const ws: WorkSheet = utils.aoa_to_sheet([header, ...body]);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');

    writeFileXLSX(wb, '주차현황보고서.xlsx');
  }, [tableRef]);

  const dataForExcel = () => {
    return (
      <div style={{ display: 'none' }}>
        <div ref={tableRef}>
          <Tables>
            <tbody>
              {dataArray.map((row, index) => (
                <tr key={index}>
                  <td>{row.carNumber}</td>
                  <td>{row.addr1}</td>
                  <td>{row.addr2}</td>
                  <td>{row.carFlag}</td>
                  <td>{row.repeatCount}</td>
                  <td>{row.flag}</td>
                  <td>{row.flagCount}</td>
                  <td>{row.checkDateTime1}</td>
                  <td>{row.checkDateTime2}</td>
                  <td>{row.employeeName}</td>
                </tr>
              ))}
            </tbody>
          </Tables>
        </div>
      </div>
    );
  };
  /* 👆 excel export 1 -----end 🎅🎅 */

  return (
    <>
      <ViewSection>
        <Button className="print" onClick={() => window.print()}>
          인쇄하기
        </Button>
        <Button className="close" onClick={() => window.close()}>
          닫기
        </Button>
        <Button
          style={{ marginLeft: '5px', cursor: 'pointer' }}
          onClick={exportExcelFile}
        >
          보고서 내보내기(엑셀)
        </Button>
      </ViewSection>

      {dataForExcel()}

      <PrintSection>
        <TitleSection>
          <TitleBox>주차 현황 보고서</TitleBox>
          <ApprovalListSection>
            <ApprovalList setIsListData={setIsListData} />
          </ApprovalListSection>

          <InfoBox>
            <div>
              단지명:
              {permitCode === apartmentManagerCode &&
                (apartmentNameAtSessionStorage
                  ? apartmentNameAtSessionStorage
                  : '')}
              {permitCode === headOfficerManagerCode &&
                (receivedObject.apartmentName
                  ? receivedObject.apartmentName
                  : '')}
            </div>
            <div>주식회사 앰앰아이</div>
          </InfoBox>
        </TitleSection>

        <ReportHeaderLineWrap>
          <ReportHeaderLineBox>
            <ReportHeaderTitle>조회 기간</ReportHeaderTitle>
            <ReportHeaderContents>
              {startDate} ~ {endDate}
            </ReportHeaderContents>
          </ReportHeaderLineBox>

          <ReportHeaderLineBox>
            <ReportHeaderTitle>작성일</ReportHeaderTitle>
            <ReportHeaderContents className="right-section">
              {today}
            </ReportHeaderContents>
          </ReportHeaderLineBox>
        </ReportHeaderLineWrap>

        <ReportHeaderLineWrap>
          <ReportHeaderLineBox className="bottom">
            <ReportHeaderTitle>산출 기간</ReportHeaderTitle>
            <ReportHeaderContents>
              {receivedObject.selectedCheckPeriod
                ? `${receivedObject.selectedCheckPeriod}`
                : '30'}
              일
            </ReportHeaderContents>
          </ReportHeaderLineBox>

          <ReportHeaderLineBox>
            <ReportHeaderTitle>건수</ReportHeaderTitle>
            <ReportHeaderContents className="right-section">
              {dataArray.length}
            </ReportHeaderContents>
          </ReportHeaderLineBox>
        </ReportHeaderLineWrap>

        {pages.map((page, pageIndex) => {
          console.log('인쇄 페이지:', page);
          const lastPageIndex = pages.length - 1;

          return (
            <>
              <RecordListTitle>주차기록표</RecordListTitle>
              <RecordListHeaderSection>
                <RecordListHeaderBox className="car-number">
                  <RecordListCategoryTitle>차량 번호</RecordListCategoryTitle>
                </RecordListHeaderBox>

                <RecordListHeaderBox className="addr1">
                  <RecordListCategoryTitle>동</RecordListCategoryTitle>
                </RecordListHeaderBox>

                <RecordListHeaderBox className="addr2">
                  <RecordListCategoryTitle>호</RecordListCategoryTitle>
                </RecordListHeaderBox>

                <RecordListHeaderBox className="car-flag">
                  <RecordListCategoryTitle>등록 여부</RecordListCategoryTitle>
                </RecordListHeaderBox>

                <RecordListHeaderBox className="repeat-count">
                  <RecordListCategoryTitle>외부 횟수</RecordListCategoryTitle>
                </RecordListHeaderBox>

                <RecordListHeaderBox className="flag">
                  <RecordListCategoryTitle>위반 여부</RecordListCategoryTitle>
                </RecordListHeaderBox>

                <RecordListHeaderBox className="flag-count">
                  <RecordListCategoryTitle>위반 횟수</RecordListCategoryTitle>
                </RecordListHeaderBox>

                <RecordListHeaderBox className="check-date-time1">
                  <RecordListCategoryTitle>
                    주차 확인 날짜
                  </RecordListCategoryTitle>
                </RecordListHeaderBox>

                <RecordListHeaderBox className="check-date-time2">
                  <RecordListCategoryTitle>
                    주차 확인 시간
                  </RecordListCategoryTitle>
                </RecordListHeaderBox>

                <RecordListHeaderBox className="employee-name">
                  <RecordListCategoryTitle>확인</RecordListCategoryTitle>
                </RecordListHeaderBox>
              </RecordListHeaderSection>
              <Tables>
                <tbody>
                  <tr key={[pageIndex]}>
                    <td>
                      {page.map((row, index) => (
                        <div key={index}>
                          <RecordListResultWrap>
                            <RecordListResultBox className="car-number">
                              <RecordListResultContents>
                                {row.carNumber}
                              </RecordListResultContents>
                            </RecordListResultBox>

                            <RecordListResultBox className="addr1">
                              <RecordListResultContents>
                                {row.addr1}
                              </RecordListResultContents>
                            </RecordListResultBox>

                            <RecordListResultBox className="addr2">
                              <RecordListResultContents>
                                {row.addr2}
                              </RecordListResultContents>
                            </RecordListResultBox>

                            <RecordListResultBox className="car-flag">
                              <RecordListResultContents>
                                {row.carFlag}
                              </RecordListResultContents>
                            </RecordListResultBox>

                            <RecordListResultBox className="repeat-count">
                              <RecordListResultContents>
                                {row.repeatCount}
                              </RecordListResultContents>
                            </RecordListResultBox>

                            <RecordListResultBox className="flag">
                              <RecordListResultContents>
                                {row.flag}
                              </RecordListResultContents>
                            </RecordListResultBox>

                            <RecordListResultBox className="flag-count">
                              <RecordListResultContents>
                                {row.flagCount}
                              </RecordListResultContents>
                            </RecordListResultBox>

                            <RecordListResultBox className="check-date-time1">
                              <RecordListResultContents>
                                {row.checkDateTime1}
                              </RecordListResultContents>
                            </RecordListResultBox>

                            <RecordListResultBox className="check-date-time2">
                              <RecordListResultContents>
                                {row.checkDateTime2}
                              </RecordListResultContents>
                            </RecordListResultBox>

                            <RecordListResultBox className="employee-name">
                              <RecordListResultContents>
                                {row.employeeName}
                              </RecordListResultContents>
                            </RecordListResultBox>
                          </RecordListResultWrap>
                        </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </Tables>
              {pageIndex + 1} / {pages.length}
              <p
                style={
                  pageIndex === lastPageIndex
                    ? { display: 'none', marginTop: '3mm' }
                    : { pageBreakBefore: 'always', marginTop: '3mm' }
                }
              />
            </>
          );
        })}
      </PrintSection>
    </>
  );
};

export default ParkingInfoPrinting;

const ViewSection = styled.div`
  position: fixed;
  top: 10px;
  left: 10px;

  /* 인쇄 시에만 출력할 스타일 지정 */
  @media print {
    display: none;
  }
`;

const PrintSection = styled.div`
  /* 웹 페이지 상에서 보이는 스타일 */
  /* display: none; -> 웹 페이지에서는 보이지 않도록 숨김 처리 */
  width: 210mm;
  height: 297mm;
  margin: 0;
  padding: 5mm;
  background-color: white;

  /* 인쇄 시에만 유효한 스타일(출력할 스타일 지정) */
  @media print {
    display: block;
    size: A4;
    width: 210mm;
    height: 297mm;
    /* margin: 0; */
    /* margin: 10mm; */
  }
`;

const Button = styled.button`
  ${({ theme }) => theme.printBtnStyle};

  &.print {
    margin-right: 5px;
    width: 60px;
    height: 30px;
  }

  &.close {
    width: 60px;
    height: 30px;
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5mm;
  margin-bottom: 5.8mm;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
`;

const ApprovalListSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  width: 100%;
`;

const InfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`;

const ReportHeaderLineWrap = styled.div`
  display: flex;
  font-size: 14px;
`;

const ReportHeaderLineBox = styled.div`
  display: flex;
  width: 50%;
  border-top: solid 1px black;
`;

const ReportHeaderTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  width: 30%;
  border-right: solid 1px black;
  border-left: solid 1px black;
  font-weight: 600;
`;

const ReportHeaderContents = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  padding: 5px 0;
  width: 70%;

  &.right-section {
    border-right: solid 1px black;
  }
`;

const RecordListTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  border: solid 1px black;
  background-color: lightGray;
  font-size: 15px;
  font-weight: 600;
`;

const RecordListHeaderSection = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 600;
`;

const RecordListHeaderBox = styled.div`
  padding: 5px 0;
  border-bottom: solid 1px black;

  &.car-number {
    width: 10%;
    border-right: solid 1px black;
    border-left: solid 1px black; /* 필수 - 바깥 테두리 */
  }

  &.addr2 {
    width: 10%;
    border-right: solid 1px black;
  }

  &.addr1 {
    width: 10%;
    border-right: solid 1px black;
  }

  &.car-flag {
    width: 10%;
    border-right: solid 1px black;
  }

  &.repeat-count {
    width: 10%;
    border-right: solid 1px black;
  }

  &.flag {
    width: 10%;
    border-right: solid 1px black;
  }

  &.flag-count {
    width: 10%;
    border-right: solid 1px black;
  }

  &.check-date-time1 {
    width: 10%;
    border-right: solid 1px black;
  }

  &.check-date-time2 {
    width: 10%;
    border-right: solid 1px black;
  }

  &.employee-name {
    width: 10%;
    border-right: solid 1px black; /* 필수 - 바깥 테두리 */
  }
`;

const RecordListCategoryTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Tables = styled.table`
  width: 100%;
`;

const RecordListResultWrap = styled.div`
  display: flex;
  /* height: 25px; */ /* 결재 박스 관련(기존) */
  height: 23px; /* 결재 박스 관련(수정 후) */
`;

const RecordListResultBox = styled.div`
  display: flex;
  flex-direction: column;

  &.car-number {
    width: 10%;
    border-right: solid 1px black;
    border-left: solid 1px black; /* 필수 - 바깥 테두리 */
  }

  &.addr1 {
    width: 10%;
    border-right: solid 1px black;
  }

  &.addr2 {
    width: 10%;
    border-right: solid 1px black;
  }

  &.car-flag {
    width: 10%;
    border-right: solid 1px black;
  }

  &.repeat-count {
    width: 10%;
    border-right: solid 1px black;
  }

  &.flag {
    width: 10%;
    border-right: solid 1px black;
  }

  &.flag-count {
    width: 10%;
    border-right: solid 1px black;
  }

  &.check-date-time1 {
    width: 10%;
    border-right: solid 1px black;
  }

  &.check-date-time2 {
    width: 10%;
    border-right: solid 1px black;
  }

  &.employee-name {
    width: 10%;
    border-right: solid 1px black; /* 필수 - 바깥 테두리 */
  }
`;

const RecordListResultContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25px;
  border-bottom: solid 1px black;
  font-size: 10px;
  font-weight: 600;

  p {
    margin-right: 5px;
  }
`;
