import styled from 'styled-components';

export const Bar = styled.div`
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

export const GuideSection = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 30px;
  height: 60px;
  background-color: white;
  color: ${({ theme }) => theme.fontColor.darkGray};
  font-size: 13px;
  font-weight: 500;
`;

export const GuideBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;

  p {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
  }
`;

export const StatusBarSection = styled.div`
  width: 100%;
  height: 60px; /* [MEMO] 색상바를 포함하고 있는 셀 높이 */
  display: flex; /* [MEMO] 색상바 중앙정렬 */
  flex-direction: column; /* [MEMO] 색상바 중앙정렬 */
  justify-content: center; /* [MEMO] 색상바 중앙정렬 */
`;

export const StatusBarWrap = styled.div`
  position: relative;
  width: 100%;

  div {
    height: 32px; /* [MEMO] 색상바 높이 */
    font-size: 12px;
    font-weight: 400;
    color: white;

    div {
      /* [MEMO] 색상바 상태 텍스트 스타일 */
      display: table-cell;
      vertical-align: middle;
      text-align: start;
    }
  }

  /* [MEMO] status-bar-modal: 상태바 호버시 나타나는 작은 말풍선 */
  .status-bar-modal {
    position: absolute;
    display: none;
    margin-bottom: 5px;

    text-align: center;

    width: 100px;
    padding: 10px 5px;
    bottom: 100%;
    border-radius: 0.5em;
    background-color: ${({ theme }) => theme.backgroundColor.darkGray};
    box-shadow: 5px 5px 10px -3px ${({ theme }) => theme.backgroundColor.shadowGray};
    /* box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.3); */
    /* MEMO: 좌우 위아래 블러 (크기) 색상 */
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    /* z-index: 100; */

    left: -50px; /* width: 100px;의 반 만큼 */

    :after {
      content: '';
      position: absolute;
      bottom: -1.6em;
      left: 50%;

      width: 0px;
      height: 0px;
      border-top: 16px solid ${({ theme }) => theme.backgroundColor.darkGray};
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      transform: translate(-50%, -50%);
    }
  }

  div:hover + p.status-bar-modal {
    display: block;
  }
`;

export const TableWrap = styled.div`
  ${({ theme }) => theme.stopDrag};
  ${({ theme }) => theme.scrollBarStyle};

  width: 100%;
  overflow-x: auto;
`;

export const Tables = styled.table`
  background-color: white;
  width: 100%;
  table-layout: fixed;
  font-size: 13px;
`;

export const THead = styled.thead`
  background-color: white;
`;

export const TrForHeader = styled.tr`
  th.hour-header {
    display: table-cell;
    vertical-align: middle;
    text-align: start;
    width: 50px;
    height: 60px;
    padding-left: 5px;
    border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
    border-left: solid 1px ${({ theme }) => theme.backgroundColor.gray};
    color: ${({ theme }) => theme.fontColor.lightGray};
    font-weight: 400;
  }

  th.time-header {
    display: table-cell;
    vertical-align: middle;
    text-align: start;
    width: calc(50px / 6);
    height: 60px;
    padding-left: 5px;
    border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
    border-left: solid 1px ${({ theme }) => theme.backgroundColor.gray};
    color: ${({ theme }) => theme.fontColor.lightGray};
    font-weight: 400;
  }
`;

export const ThEmployeeNameHeader = styled.th`
  width: 170px; /* [MEMO] 이름 너비 */
  color: ${({ theme }) => theme.fontColor.darkGray};

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 0; /* sticky 적용을 위한 코드 */
  background-color: white; /* sticky 적용을 위한 코드 */

  &.hours-array-section {
    border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  }
`;

export const ThTotalWorkingTimeHeader = styled.th`
  width: 80px; /* [MEMO] 총 근무시간 너비 */
  /**
   * sticky 적용을 위한 코드 - border-right 주석 처리:
   * sticky 적용을 위해 ThTotalWorkingTimeHeader에 div 요소를 추가하고 div 요소 내에 border-right를 적용했기에,
   * 이곳의 border-right는 주석 처리함.
   * sticky 적용이 필요 없다면 div 요소 자체를 제거한 후, 이곳의 border-right를 활성화 할 것.
   */
  /* border-right: solid 1px ${({ theme }) =>
    theme.backgroundColor.shadowGray}; */

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 170px; /* sticky 적용을 위한 코드, ThEmployeeNameHeader의 너비가 170px */
  background-color: white; /* sticky 적용을 위한 코드 */

  div {
    /**
      * sticky 적용을 위한 코드
      * 1-1. ThTotalWorkingTimeHeader 내 div 요소:
      * 오직 sticky 적용을 위해 필요한 요소. sticky 적용이 필요 없다면 div 요소 자체를 제거할 것.
      * 1-2. div 요소 내 border-right, height:
      * border-right 스타일을 가시화하기 위해 넣은 것
     */
    height: 60px;
    border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  }

  &.hours-array-section {
    border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  }
`;

export const ThHourHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: start;
  width: 50px; /* [MEMO] 시간 헤더(0~23) 너비 */
  height: 60px;
  padding-left: 5px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /**
   * sticky 적용을 위한 코드 - border-right:
   * 기존의 border-left를 삭제한 후 border-right 스타일을 적용함.
   * sticky 적용 필요 없다면 (border-right 삭제 후) border-left를 적용해도 됨.
   * + border-right 삭제할 경우 last-date 내 border-right 스타일도 제거할 것.
   */
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  color: ${({ theme }) => theme.fontColor.lightGray};
  font-weight: 400;

  &.last-date {
    border-right: none;
  }
`;

export const ThTimeHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: start;
  width: calc(50px / 6); /* [MEMO] 분 헤더(시간당 6칸) 너비 */
  height: 10px;
  padding-left: 5px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-left: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  color: ${({ theme }) => theme.fontColor.lightGray};
  font-weight: 400;
`;

export const TrForBody = styled.tr`
  /* 바디에 있는 td 요소(바디에 있는 셀들) 높이 설정 */
  height: 60px;
`;

export const TdEmployeeName = styled.td`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  /* text-align: center; */ /* 테이블 셀 중앙정렬 */
  text-align: start;
  padding-left: 30px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};
  font-weight: 500;

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 0; /* sticky 적용을 위한 코드 */
  background-color: white; /* sticky 적용을 위한 코드 */
  /* sticky 적용을 위한 코드, z-index 값을 주어야 스크롤을 움직일 때 상태바 위에 나타날 수 있음 */
  z-index: 1;
`;

export const TdTotalWorkingTime = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /* sticky 적용을 위한 코드 -  border-right 주석 처리:
   * sticky 적용을 위해 주석처리 함.
   * sticky 미적용을 원할 경우, div 요소 내 border-right를 지운 후, 이곳에 border-right를 활성화 시키면 됨
   */
  /* border-right: solid 1px ${({ theme }) =>
    theme.backgroundColor.shadowGray}; */
  color: white;
  font-weight: 500;

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 170px; /* sticky 적용을 위한 코드, TdEmployeeName의 너비가 170px */
  background-color: white; /* sticky 적용을 위한 코드 */
  /* sticky 적용을 위한 코드, z-index 값을 주어야 스크롤을 움직일 때 상태바 위에 나타날 수 있음 */
  z-index: 1;

  div {
    display: flex;
    justify-content: start;
    align-items: center;

    /**
      * sticky 적용을 위한 코드- div 요소 내 height, border-right:
      * border-right 스타일을 가시화하기 위해 넣은 것.
      * sticky 적용 필요 없다면 불필요.
     */
    height: 60px;
    border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};

    p {
      padding: 4px 6px;
      border-radius: 0.5em;
      background-color: ${({ theme }) => theme.statusColor.working};
    }
  }
`;

export const TdTimeInfo = styled.td`
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;
