import styled from 'styled-components';

export const Button = styled.button`
  &.button-long {
    display: inline-block;
    border: 0;
    border-radius: 0.5em;
    font-weight: 500;
    line-height: 1;
    cursor: pointer;
  }

  &.button-long--primary {
    background-color: ${({ theme }) => theme.mainColor.main};
    color: white;

    :hover {
      background-color: ${({ theme }) => theme.mainColor.dark};
    }
  }

  &.button-long--secondary {
    color: ${({ theme }) => theme.fontColor.black};
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
    background-color: white;
  }

  &.button-long--small {
    font-size: 13px;
    padding: 8px 10px;
  }

  &.button-long--medium {
    font-size: 14px;
    padding: 10px 12px;
  }

  &.button-long--large {
    font-size: 16px;
    padding: 12px 14px;
  }
`;
