import React from 'react';
import styled from 'styled-components';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/agGridStyle/ag-theme-custom.css';
import { gridOptions } from './gridOptions'; // 그리드 항목

export const Grid = () => {
  //
  return (
    <>
      <div id="myGrid" className="ag-theme-alpine ag-theme-custom">
        {/* <AgGridReact
          headerHeight={45} // 타이틀 행 높이
          rowHeight={35} // 본문 행 높이
          gridOptions={gridOptions}
          rowData={data.map((data, idx) => {
            return {
              idx: data.idx, // 동
              Addr1: data.Addr1, // 동
              Addr2: data.Addr2, // 호
              CarNo: data.CarNo, // 차량 번호
              // CarFlag: data.CarFlag, // 등록 유형?
              CarFlag: onCarFlag(data.CarFlag), // 등록 유형?
              CreateDateTime: data.CreateDateTime.slice(0, 10), // 등록일
              CarExpireDate: data.CarExpireDate.slice(0, 10), // 만료일

              OwnerName: data.OwnerName, // 차량 소유자
              OwnerPhone: onOwnerPhone(data.OwnerPhone), // 차량 소유자 전화번호

              // EmployeName: data.EmployeName, // 관리자
              // RegEmployeeNo: data.RegEmployeeNo, // 관리자 사번
              modify: data.idx ? '수정' : '', // 수정
              delete: data.idx ? '삭제' : '', // 삭제
            };
          })}
          animateRows={true}
          rowDragManaged={true}
          suppressMoveWhenRowDragging={true}
          pagination={true}
          paginationPageSize={15}
          onCellClicked={e => {
            controlModifyDataModal(e);
            deleteSingleServerData({ e, apartmentIdx, setDetectChangingData });
          }}
          // defaultColDef={{
          //   cellStyle: { textAlign: 'center' }, // 중앙 정렬 스타일 추가
          // }}
        /> */}
      </div>
    </>
  );
};
