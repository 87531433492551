// ref가 없는 인덱스 API (=계약 번호(고유 번호 아님))

import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

interface loadApartmentNameBtnProps {
  apartmentListContractData: any[];
  setApartmentListContractData: Dispatch<SetStateAction<any[]>>;
  enteredNameValue: string;
  setOpenResult: Dispatch<SetStateAction<boolean>>;
  setSearchResult: Dispatch<SetStateAction<any>>;
}

export const searchApartmentName = async ({
  apartmentListContractData,
  setApartmentListContractData,
  enteredNameValue, // 단지 이름
  setOpenResult,
  setSearchResult,
}: loadApartmentNameBtnProps) => {
  const token = sessionStorage.getItem('token');
  console.log(
    'searchApartmentName.tsx-고유 인덱스(ref있음, 계약번호아님)/enteredNameValue: ',
    enteredNameValue
  );

  await axios
    .get(
      // https://dev.mmigroup.co.kr:8020/common/contractinfo?ItemPerPage=0&ItemStartPage=0&Name=%EC%9D%B4%EB%A6%84&nowdata=1
      `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${enteredNameValue}&nowdata=1`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   'searchApartmentName.tsx-고유 인덱스(ref있음, 계약번호아님)/API: ',
      //   `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${enteredNameValue}&nowdata=1`
      // );
      try {
        setSearchResult(res.data.Data);
        setOpenResult(true);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
