import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as S from './Login.style';

import axios from 'axios';
import { useQuery } from 'react-query'; // React Query

import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/store';
import { useDispatch } from 'react-redux';
import { menuData } from '../../redux/slice/loggedInMenuDataSlice';
import {
  userInfo,
  nameKR,
  employeeNum,
  userID,
  permitCode,
  jobName,
} from '../../redux/slice/loggedInUserBasicSlice';

import { Input } from '../../components/UI/Input/Short';
import { Button } from '../../components/UI/Button/Short';

import RouterTrackerForAnalytics from '../../lib/utils/RouterTrackerForAnalytics';

import { onChangeURL } from './utils/onChangeURL';

// ----- 👇 🎁버전 관련 -----
import appVersionJson from '../../../version.json';
const { name, buildDate, version } = appVersionJson;
const CompliedDate = new Intl.DateTimeFormat('ko-KR', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
}).format(buildDate);
// ----- 👆 🎁버전 관련 -----

const Login = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  //console.log('window.location: ', window.location);
  //console.log('window.location.origin: ', window.location.origin);
  //console.log('location: ', location);

  const [companyId, setCompanyId] = useState('');
  //console.log('🍖companyId: ', companyId);

  const windowLocation = window.location;
  onChangeURL({ windowLocation, setCompanyId });

  const URL_TEST = process.env.SITE_URL_TEST;
  const MMI_URL_DEV = process.env.SITE_URL_MMI_DEV;
  const MMI_URL_PRO = process.env.SITE_URL_MMI_PRO;
  const WELLTECH_URL_DEV = process.env.SITE_URL_WELLTECH_DEV;
  const WELLTECH_URL_PRO = process.env.SITE_URL_WELLTECH_PRO;
  const WELLTECH_URL_DEMO_URL = process.env.SITE_URL_WELLTECH_DEMO;

  const $title = document.head.querySelector('title');
  $title!.innerText = '로그인';
  RouterTrackerForAnalytics();

  const selectedLanguage = 'ko';
  const MMI_ID = 'MM';
  //console.log('process.env.COMMON_URL', process.env.COMMON_URL);
  //console.log(document.referrer, 'document.referrer');

  const navigate = useNavigate();
  const dispatch = useDispatch(); // redux toolkit

  const [loginId, setLoginId] = useState('');
  const [loginPw, setLoginPw] = useState('');

  const idValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginId(e.target.value);
  };

  const pwValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginPw(e.target.value);
  };

  /**
   * [MEMO] 정규표현식 (조건: 최소 6자, 최대 15자 하나의 문자 및 하나의 숫자 포함)
   * 정규 표현식 문제 있는 듯... 조건에 맞지 않음 (수정할 필요 있음)
   * https://mmigroup.atlassian.net/wiki/spaces/MMI/pages/61702231?focusedCommentId=144637953
   */
  const isValidPw = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,15}$/.test(
    loginPw.trim()
  );
  const onLoginBtn = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault(); // 기본 동작 중단
    if (loginId.length <= 0) {
      return alert('아이디를 입력해주세요');
    }
    if (loginPw.length <= 0) {
      return alert('비밀번호를 입력해주세요');
    }
    refetch();
  };
  // const pressEnter = (e: { key: string }) => {
  const pressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // 기본 동작 중단
      console.log('Enter key pressed'); // 디버깅을 위한 로그
      return onLoginBtn(e);
    }
  };

  // 카테고리 목데이터 -----start
  // fetch(`/sample/category.json`)
  //   .then(response => response.json())
  //   .then(result => {
  //     // dispatch(menuData(result.Data.InfoMenu));
  //   });
  // 카테고리 목데이터 -----end

  const { refetch } = useQuery({
    queryKey: ['userLogin'],
    queryFn: async () =>
      axios
        .post(
          // 'https://apihr.mmigroup.co.kr:9020/common/login?lang=ko&corpId=MM&logintype=B&logindevice=W'
          `${process.env.COMMON_URL}/common/login?lang=${selectedLanguage}&corpId=${companyId}&logintype=I&logindevice=W`,
          {
            userId: loginId,
            password: loginPw,
            sid1: '',
            sid2: '',
            sid3: '',
          }
        )
        .then(res => {
          // console.log(
          //   '로그인: ',
          //   `${process.env.COMMON_URL}/common/login?lang=${selectedLanguage}&corpId=${companyId}&logintype=I&logindevice=W`
          // );

          try {
            console.log(res.data, '로그인 페이지 - res.data');

            if (res.data.Data.InfoUser[0].TokenID) {
              if (res.data.Data.InfoUser[0].ContractIdx) {
                sessionStorage.setItem(
                  'contractIdx',
                  res.data.Data.InfoUser[0].ContractIdx
                );
              }

              sessionStorage.setItem(
                'token',
                res.data.Data.InfoUser[0].TokenID
              );
              sessionStorage.setItem(
                'sessionId',
                res.data.Data.InfoUser[0].SessionID
              );
              sessionStorage.setItem(
                'employeeNum',
                res.data.Data.InfoUser[0].EmployeeNo
              );
              sessionStorage.setItem(
                'userId',
                res.data.Data.InfoUser[0].UserId
              );
              sessionStorage.setItem(
                'permitCode',
                res.data.Data.InfoUser[0].PermitCode
              );
              sessionStorage.setItem(
                'userNameKR',
                res.data.Data.InfoUser[0].UserNameKR
              );
              // [MEMO] apartmentContractIdx: 단지 '계약' 인덱스 (ref 없는 인덱스)
              sessionStorage.setItem(
                'apartmentContractIdx',
                res.data.Data.InfoUser[0].ContractIdx
              );
              // [MEMO] apartmentContractIdx: 단지 인덱스 (ref 있는, 일반 인덱스)
              sessionStorage.setItem(
                'apartmentRefIdx',
                res.data.Data.InfoUser[0].RefContractIdx
              );
              sessionStorage.setItem(
                'apartmentName',
                res.data.Data.InfoUser[0].ContractName
              );
              dispatch(menuData(res.data.Data.InfoMenu)); // 🚨 목데이터 사용 동안 임시로 주석 처리할 것
              dispatch(userInfo(res.data.Data.InfoUser[0]));
              dispatch(nameKR(res.data.Data.InfoUser[0].UserNameKR)); // 이름(한글)
              dispatch(employeeNum(res.data.Data.InfoUser[0].EmployeeNo)); // 사번
              dispatch(userID(res.data.Data.InfoUser[0].UserId)); // ID
              dispatch(permitCode(res.data.Data.InfoUser[0].PermitCode)); // 권한 코드
              dispatch(jobName(res.data.Data.InfoUser[0].JobName)); // 직무
              console.log('로그인 페이지 - 총 데이터: ', res.data.Data);
              console.log(
                '로그인 페이지 - 유저 정보: ',
                res.data.Data.InfoUser[0]
              );
              console.log(
                '로그인 페이지 - 메뉴 데이터: ',
                res.data.Data.InfoMenu
              );

              navigate('/');
              // MEMO: 아래 조건문 = 외부 링크를 통해 접속시... 뒤로가기 유지?
              /*
              if (
                // MEMO: `${process.env.SITE_URL}` = 'http://localhost:3005'
                // process.env.NODE_ENV === 'development' &&
                document.referrer &&
                document.referrer.indexOf(`${process.env.SITE_URL}`) !== -1
              ) {
                history.back(); // 뒤로가기
              }
              // 아래 else: 히스토리가 없는 경우 (URL을 직접 입력하여 유입된 경우)
              else {
                location.href = `${process.env.SITE_URL}`; // 메인페이지로
              }
              */
            }
          } catch (e) {
            console.error(e);
            if (res.data.ErrorCode === 128) {
              return alert('비밀번호가 일치하지 않습니다');
            }

            alert(
              `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
            );
          }
        }),
    enabled: false,
  });

  const versionDate = () => {
    if (process.env.NODE_ENV === 'production') {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const date = today.getDate();

      return (
        <div>
          v.{year}.{month}.{date}
        </div>
      );
    } else {
      return 'dev';
    }
  };

  versionDate();

  return (
    <S.Section>
      <S.Wrap>
        <S.WrapForm>
          {/* <div style={{ display: 'flex' }}>
            <div>기업코드</div>
            <input style={{ border: 'solid 1px red' }} />
          </div> */}
          <S.Title>
            {window.location.origin === MMI_URL_DEV && (<>앰앰아이 스마트 근태 (DEV)</>)}
            {window.location.origin === MMI_URL_PRO && (<>앰앰아이 스마트 근태</> )}
            {window.location.origin === WELLTECH_URL_DEV && (<>웰텍 스마트 근태 (DEV)</>) }
            {window.location.origin === WELLTECH_URL_PRO && (<>웰텍 스마트 근태</>) }
            {window.location.origin === WELLTECH_URL_DEMO_URL && (<>웰텍 스마트 근태 (DEMO)</> )}
            {window.location.origin === URL_TEST && (<>개발용 스마트 근태</> )}

          </S.Title>
          <S.LoginForm>
            <S.ItemBox>
              <S.ItemName>
                <label htmlFor="input-id">아이디 혹은 단지번호(6자리)</label>
              </S.ItemName>
              <Input
                placeholder="아이디 혹은 단지번호(6자리)를 입력해주세요"
                size="medium"
                type="text"
                onChange={idValue}
                onKeyPress={pressEnter}
              />
            </S.ItemBox>

            <S.ItemBox>
              <S.ItemName>
                <label htmlFor="input-pw">비밀번호</label>
              </S.ItemName>
              <Input
                placeholder="비밀번호를 입력해주세요"
                size="medium"
                type="password"
                onChange={pwValue}
                onKeyPress={pressEnter}
              />
            </S.ItemBox>

            <div className="login-button">
              {/* <Button onClick={() => refetch()} label="로그인" /> */}
              <Button onClick={e => onLoginBtn(e)} label="로그인" />
            </div>

            <S.Bar />
            {/* <S.FindInfoText onClick={() => controlFindPasswordPage()}> */}
            {/* <S.FindInfoText
            // onClick={() => window.open(`${data.html_url}`, '_blank')}
            onClick={() => {
              window.open(`/login/findPassword`, '_blank');
            }}
          >
            비밀번호 찾기
          </S.FindInfoText> */}

            <S.ButtonWrap>
              <S.ChangeBtn>
                {/* <S.FindInfoText> */}
                <p>
                  <Link to="/changePassword" target="_blank">
                    비밀번호 변경
                  </Link>
                </p>
                {/* </S.FindInfoText> */}
              </S.ChangeBtn>
              <S.ChangeBtn className="right-button">
                {/* <S.FindInfoText style={{ marginTop: '5px' }}> */}
                <p>
                  <Link to="/changePhoneNumber" target="_blank">
                    전화번호 변경
                  </Link>
                </p>
                {/* </S.FindInfoText> */}
              </S.ChangeBtn>
              <S.ChangeBtn className="right-button">
                {/* <S.FindInfoText> */}
                <p>
                  <a href="https://pf.kakao.com/_xhVgUG/chat" target="_blank">
                    카카오톡 문의하기
                  </a>
                </p>
                {/* </S.FindInfoText> */}
              </S.ChangeBtn>
            </S.ButtonWrap>
          </S.LoginForm>
        </S.WrapForm>
        {/* <S.UpdateInfo>{versionDate()}</S.UpdateInfo> */}
        {/* ----- 👇 🎁버전 관련 ----- */}
        <S.UpdateInfo>v.{CompliedDate.slice(0, 12)}</S.UpdateInfo>
        {/* ----- 👆 🎁버전 관련 ----- */}
      </S.Wrap>
    </S.Section>
  );
};

export default Login;
