import { Buffer } from 'buffer'; // 문자열을 base64로 변환

interface onEncodedStringProps {
  e: React.ChangeEvent<HTMLInputElement>;
}

// { e: React.ChangeEvent<HTMLInputElement>;
// setEnteredName: React.Dispatch<React.SetStateAction<string>>; }
// 형식의 인수는 'onEncodedStringProps' 형식의 매개 변수에 할당될 수 없습니다.

export const onEncodedString = ({ e }: onEncodedStringProps) => {
  const encodedString = Buffer.from(e.target.value)
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');

  return { encodedString };
};
