import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

interface WorkRecordModalProps {
  closeMapModal: () => void;
  cellData: any;
}

export const MapModal = ({ closeMapModal, cellData }: WorkRecordModalProps) => {
  const GOOGLE_MAP_API = process.env.GOOGLE_MAP_API;
  const [mapCenter, setMapCenter] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  useEffect(() => {
    const GPSLatitude = parseFloat(cellData.GPSLatitude);
    const GPSLongitude = parseFloat(cellData.GPSLongitude);
    if (!isNaN(GPSLatitude) && !isNaN(GPSLongitude)) {
      setMapCenter({ lat: GPSLatitude, lng: GPSLongitude });
    }
  }, [cellData]);

  return (
    <Background>
      <Container>
        <ModalWrap>
          {mapCenter ? (
            <iframe
              width="600"
              height="450"
              loading="lazy"
              allowFullScreen
              src={`https://www.google.com/maps/embed/v1/view?key=${GOOGLE_MAP_API}&center=${mapCenter.lat},${mapCenter.lng}&zoom=19`}
            />
          ) : (
            <div>위치 정보 없음</div>
          )}
          <ButtonList>
            <ButtonStyle onClick={closeMapModal}>닫기</ButtonStyle>
          </ButtonList>
        </ModalWrap>
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: white;
`;

const ModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const ButtonList = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.printBtnStyle};
  width: 80px;

  &.close {
    margin-left: 10px;
  }
`;
