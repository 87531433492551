// CV10-020 차량 등록 관리 - 서버 데이터 전부 삭제하기(=초기화)
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface deleteAllServerDataProps {
  setOpenBeforeInitialAlertModal: Dispatch<SetStateAction<boolean>>;
  apartmentIdx: number;
  setDetectChangingData: Dispatch<SetStateAction<boolean>>;
}

export const deleteAllServerData = async ({
  setOpenBeforeInitialAlertModal,
  apartmentIdx,
  setDetectChangingData,
}: deleteAllServerDataProps) => {
  const token = sessionStorage.getItem('token');
  const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx');

  let currentApartmentRefIdx = '';
  if (Number(apartmentRefIdx) !== 0) {
    currentApartmentRefIdx = `${apartmentRefIdx}`;
  } else {
    currentApartmentRefIdx = `${apartmentIdx}`;
  }

  console.log('초기화-currentApartmentRefIdx: ', currentApartmentRefIdx);

  await axios({
    // https://apihr.mmigroup.co.kr:9020/vehicle/cv10020/regcarreset/1
    url: `${process.env.COMMON_URL}/vehicle/cv10020/regcarreset/${currentApartmentRefIdx}`,
    method: 'delete',
    headers: { tokenId: token },
  }).then(res => {
    console.log(
      '📢url: ',
      `${process.env.COMMON_URL}/vehicle/cv10020/regcarreset/${currentApartmentRefIdx}`
    );
    console.log('👍res.data: ', res.data);

    try {
      if (res.data.ErrorCode === 0) {
        alert('기존 데이터가 전부 삭제되었습니다');
        setOpenBeforeInitialAlertModal(false);
        setDetectChangingData(true);
      }
      incorrectToken(res);
    } catch (e) {
      console.error(e);
    }
  });
};
