import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Background>
      <Wrap>
        <TextBox>
          <h1>404 - Not Found</h1>
          <h3>
            존재하지 않는 페이지 입니다.
            {/* <code>{location.pathname}</code> */}
          </h3>
          {/* <p>The page you are looking for does not exist.</p> */}
          {/* <Link to="/">홈으로 돌아가기(클릭)</Link> */}
          <GoToHome
            onClick={() => {
              navigate('/');
            }}
          >
            홈으로 돌아가기(클릭)
          </GoToHome>
        </TextBox>
      </Wrap>
    </Background>
  );
};

export default PageNotFound;

const Background = styled.div`
  position: fixed;
  display: flex;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  /* background: rgba(0, 0, 0, 0.7); */
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  color: white;
  font-size: 16px;
  line-height: 1.5;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const GoToHome = styled.div`
  cursor: pointer;
`;
