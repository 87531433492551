// [MEMO] 스마트폰 상태 조회(오류)
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface loadUserDeviceCheckErrorDataProps {
  setUserDeviceErrorCheckData: Dispatch<SetStateAction<never[]>>;
  startCheckErrorYearPicker: string;
  startCheckErrorMonthPicker: string;
  startCheckErrorDatePicker: string;
  endCheckErrorYearPicker: string;
  endCheckErrorMonthPicker: string;
  endCheckErrorDatePicker: string;
}

export const loadUserDeviceCheckErrorData = async ({
  setUserDeviceErrorCheckData,
  startCheckErrorYearPicker,
  startCheckErrorMonthPicker,
  startCheckErrorDatePicker,
  endCheckErrorYearPicker,
  endCheckErrorMonthPicker,
  endCheckErrorDatePicker,
}: loadUserDeviceCheckErrorDataProps) => {
  const token = sessionStorage.getItem('token');

  const calStartYear = String(startCheckErrorYearPicker).padStart(2, '0');
  const calStartMonth = String(startCheckErrorMonthPicker).padStart(2, '0');
  const calStartDate = String(startCheckErrorDatePicker).padStart(2, '0');

  const calEndYear = String(endCheckErrorYearPicker).padStart(2, '0');
  const calEndMonth = String(endCheckErrorMonthPicker).padStart(2, '0');
  const calEndDate = String(endCheckErrorDatePicker).padStart(2, '0');

  const startEndDate = `StartDate=${calStartYear}${calStartMonth}${calStartDate}&EndDate=${calEndYear}${calEndMonth}${calEndDate}&`;

  await axios
    .get(
      // https://apihr.mmigroup.co.kr:9020/dashboard/da10010/userdevicecheck?StartDate=20230501&EndDate=20230530&Action=E
      `${process.env.COMMON_URL}/dashboard/da10010/userdevicecheck?${startEndDate}Action=E`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '메인화면 / 스마트폰 상태 조회(오류) API: ',
      //   `${process.env.COMMON_URL}/dashboard/da10010/userdevicecheck?${startEndDate}Action=E`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          setUserDeviceErrorCheckData(res.data.Data);
          // console.log(
          //   '메인화면 / 스마트폰 상태 조회(오류) res.data.Data: ',
          //   res.data.Data
          // );
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
