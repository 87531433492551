// 앱 상태 현황 = 스마트폰 상태 조회 UI
import React from 'react';
import styled from 'styled-components';

interface UserDeviceCheckSectionProps {
  userDeviceInstallCheckData?: any[];
  userDeviceErrorCheckData?: any[];
}

export const UserDeviceCheckSection = ({
  userDeviceInstallCheckData,
  userDeviceErrorCheckData,
}: UserDeviceCheckSectionProps) => {
  return (
    <Tables>
      <thead>
        {userDeviceInstallCheckData && (
          <tr>
            <ThHeader className="start-th">근무지</ThHeader>
            <ThHeader>이름</ThHeader>
            <ThHeader>사번</ThHeader>
            <ThHeader>직무</ThHeader>
            <ThHeader className="end-th">직위</ThHeader>
          </tr>
        )}

        {userDeviceErrorCheckData && (
          <tr>
            <ThHeader className="start-th">근무지</ThHeader>
            <ThHeader>이름</ThHeader>
            <ThHeader>사번</ThHeader>
            <ThHeader>직무</ThHeader>
            <ThHeader>직위</ThHeader>
            <ThHeader>최종접속</ThHeader>
          </tr>
        )}
      </thead>

      <tbody>
        {userDeviceInstallCheckData &&
          userDeviceInstallCheckData.map(data => (
            <tr key={data.idx}>
              <TdHeader>{data.ContractName}</TdHeader>
              <TdHeader>{data.EmployeName}</TdHeader>
              <TdHeader>{data.EmployeeNo}</TdHeader>
              <TdHeader>{data.JobGroupName}</TdHeader>
              <TdHeader>{data.CategoryName}</TdHeader>
            </tr>
          ))}

        {userDeviceErrorCheckData &&
          userDeviceErrorCheckData.map(data => (
            <tr key={data.idx}>
              <TdHeader>{data.ContractName}</TdHeader>
              <TdHeader>{data.EmployeName}</TdHeader>
              <TdHeader>{data.EmployeeNo}</TdHeader>
              <TdHeader>{data.JobGroupName}</TdHeader>
              <TdHeader>{data.CategoryName}</TdHeader>
              <TdHeader>{data.LatestDate}</TdHeader>
            </tr>
          ))}
      </tbody>
    </Tables>
  );
};

const Tables = styled.table`
  width: 100%;
  font-size: 13px;
`;

const ThHeader = styled.th`
  padding: 5px 0;
  background-color: ${({ theme }) => theme.backgroundColor.gray};
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};

  &.end-th {
    border-right: none;
  }
`;

const TdHeader = styled.td`
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
`;
