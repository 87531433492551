export const gridOptionsOfficeManager = {
  columnDefs: [
    {
      field: 'Addr1',
      headerName: '동',
      resizable: true,
      filter: true,
      sortable: true,
      width: 80,
      cellClass: 'cell-class-contents',
      // cellStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'Addr2',
      headerName: '호',
      resizable: true,
      filter: true,
      sortable: true,
      width: 80,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'CarNo',
      headerName: '차량 번호',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'CarType',
      headerName: '번호 유형',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'DetArea',
      headerName: '인식 크기',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'confidenceCheck',
      headerName: '신뢰도',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'RepeatCnt',
      headerName: '외부주차 횟수', // 특이사항 횟수
      resizable: true,
      filter: true,
      sortable: true,
      width: 100,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'Flag',
      headerName: '위반 여부',
      resizable: true,
      filter: true,
      sortable: true,
      width: 100,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'FlagCnt',
      headerName: '위반 횟수',
      resizable: true,
      filter: true,
      sortable: true,
      width: 100,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'CarFlag',
      headerName: '등록 유형',
      resizable: true,
      filter: true,
      sortable: true,
      width: 100,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'CarRegDate',
      headerName: '차량 등록일',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'CheckingDateTime',
      headerName: '주차 확인일', // 조회일
      resizable: true,
      filter: true,
      sortable: true,
      width: 140,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'OwnerName',
      headerName: '소유주',
      resizable: true,
      filter: true,
      sortable: true,
      width: 150,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'OwnerPhone',
      headerName: '소유주 연락처',
      resizable: true,
      filter: true,
      sortable: true,
      width: 150,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'EmployeName',
      headerName: '등록인',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'attachedFile',
      headerName: '첨부파일',
      resizable: true,
      filter: true,
      sortable: true,
      width: 80,
      cellStyle: { textAlign: 'center' },
      cellClass: 'cell-class-contents',
      headerClass: 'header-class-center', // 헤더에 클래스 적용
    },
  ],

  // custom loading template. the class ag-overlay-loading-center is part of the grid,
  // it gives a white background and rounded border
  overlayLoadingTemplate:
    '<span class="ag-overlay-loading-center" style="padding: 10px; border: 1px solid #444; background: lightgoldenrodyellow;">데이터를 불러오는 중입니다</span>',

  overlayNoRowsTemplate:
    '<span style="padding: 10px; border: 3px solid #ededed; border-radius: 0.5em; background-color: white; box-shadow: 5px 5px 15px -3px #ededed; color: #717171; font-weight: 600;">불러온 데이터가 없습니다</span>',
};
