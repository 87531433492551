import React, { Dispatch, SetStateAction } from 'react';
import * as S from './Short.style';

interface ContentsListProps {
  nameKR: string;
  code: string | number;
}

interface DropBoxProps {
  setCategoryName: Dispatch<SetStateAction<string>>;
  setCategoryValue: Dispatch<SetStateAction<string | number>>;
  title: string;
  selectedCategoryName: string;
  contentsList: ContentsListProps[];
  // contentsList: () => void; // contentsList 함수일 때
  size?: 'small' | 'medium' | 'large';
}

export const Dropbox = ({
  setCategoryName,
  setCategoryValue,
  title,
  selectedCategoryName,
  contentsList,
  size = 'small',
}: DropBoxProps) => {
  const onSelectedCode = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { name, value },
    } = e;

    if (name === selectedCategoryName) {
      setCategoryName(name);
      setCategoryValue(value);
    }
  };

  return (
    <div>
      <S.TitleSection>{title}</S.TitleSection>
      <S.List
        name={selectedCategoryName}
        onChange={e => {
          onSelectedCode(e);
        }}
        className={['dropbox-short', `dropbox-short--${size}`].join(' ')}
      >
        <option></option>
        {contentsList
          ? contentsList.map((item, idx: number) => {
              return (
                <option key={idx} className="option-list" value={item.code}>
                  {item.nameKR}
                </option>
              );
            })
          : '데이터 없음'}
      </S.List>
    </div>
  );
};
