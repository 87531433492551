import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ko } from 'date-fns/esm/locale';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import './DatePicker.css';

import { onApartmentContractInfo } from '../utils/onApartmentContractInfo';
import { onWorkStatusLoadBtnDaily } from '../utils/onWorkStatusLoadBtnDaily';
import { onWorkStatusLoadBtnWeekly } from '../utils/onWorkStatusLoadBtnWeekly';
import { onWorkStatusLoadBtnMonthly } from '../utils/onWorkStatusLoadBtnMonthly';

import { Modal } from '../../apartmentContractInfoSelectModal/Modal';
import DailySection from '../DailySection/DailySection';
import WeeklySection from '../WeeklySection/WeeklySection';
import MonthlySection from '../MonthlySection/MonthlySection';
import MonthlyPrinting from '../MonthlyPrinting/MonthlyPrinting'; // [MEMO] 인쇄 관련: 월 근무현황 인쇄
import PersonalWorkCardPrinting from '../PersonalWorkCardPrinting/PersonalWorkCardPrinting'; // [MEMO] 인쇄 관련: 출퇴근 카드 인쇄

import { Button } from '../../UI/Button/Long';

/**
 * permitCode 권한 코드
 * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
 * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
 * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
 */

export const Contents = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const permitCode = sessionStorage.getItem('permitCode');
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련

  useEffect(() => {
    setDailySection(true); // [MEMO] 첫화면은 일간 근무 현황이 보이도록
  }, []);

  const [employeeDataDaily, setEmployeeDataDaily] = useState<any[]>([]);
  const [employeeDataWeekly, setEmployeeDataWeekly] = useState<any[]>([]);
  const [employeeDataMonthly, setEmployeeDataMonthly] = useState<any[]>([]);

  const [onModal, setOnModal] = useState(false); // MEMO: 아파트 계약 인덱스 리스트가 있는 모달
  const [apartmentListContractData, setApartmentListContractData] = useState<
    any[]
  >([]); // MEMO: 아파트 '계약' 정보 API
  const [apartmentContractIdx, setApartmentContractIdx] = useState(''); // 아파트 계약 인덱스
  const [apartmentName, setApartmentName] = useState('');

  const [startDate, setStartDate] = useState(new Date()); // MEMO: Date Picker 라이브러리

  const [dailySection, setDailySection] = useState(false);
  const [weeklySection, setWeeklySection] = useState(false);
  const [monthlySection, setMonthlySection] = useState(false);

  const onSwitchDailySection = () => {
    setDailySection(true);
    setWeeklySection(false);
    setMonthlySection(false);

    if (apartmentContractIdx) {
      onWorkStatusLoadBtnDaily({
        setEmployeeDataDaily,
        apartmentContractIdx, // 아파트 계약 인덱스
        calYear,
        calMonth,
        calDate,
        setDailySchedulerLoading, // [MEMO] 일간 데이터 로딩
      });
    }

    navigate('/AC103/dailySection');
  };

  const onSwitchWeeklySection = () => {
    setDailySection(false);
    setWeeklySection(true);
    setMonthlySection(false);

    if (apartmentContractIdx) {
      onWorkStatusLoadBtnWeekly({
        setEmployeeDataWeekly,
        apartmentContractIdx, // 아파트 계약 인덱스
        calYear,
        calMonth,
        calDate,
        setWeeklySchedulerLoading,
      });
    }

    navigate('/AC103/weeklySection');
  };

  const onSwitchMonthlySection = () => {
    setDailySection(false);
    setWeeklySection(false);
    setMonthlySection(true);

    if (apartmentContractIdx) {
      onWorkStatusLoadBtnMonthly({
        setEmployeeDataMonthly,
        apartmentContractIdx, // 아파트 계약 인덱스
        calYear,
        calMonth,
        setMonthlySchedulerLoading,
        setMonthlyPrintingLoading, // [MEMO] 인쇄 관련
        setPersonalWorkCardLoading, // [MEMO] 인쇄 관련
      });
    }

    navigate('/AC103/monthlySection');
  };

  const closeModal = () => {
    setOnModal(false);
  };

  // 👇 데이터 피커에서 선택한 날짜
  const year = startDate.getFullYear().toString();
  const month = (startDate.getMonth() + 1).toString();
  const date = startDate.getDate().toString();

  const calYear = String(year).padStart(2, '0');
  const calMonth = String(month).padStart(2, '0');
  const calDate = String(date).padStart(2, '0');

  let sundayDateOfTargetWeekString;
  let saturdayDateOfTargetWeekString;

  function targetWeeklyDate() {
    // 👇 목표하는 날짜(사용자가 달력에서 선택하는 날짜) / 입력 예: '2023-05-04'
    const targetDate = new Date(`${calYear}-${calMonth}-${calDate}`);

    // 👇 목표하는 날짜가 포함된 주의 일요일 날짜 (Date객체)
    const sundayDateOfTargetWeekObject = new Date(
      targetDate.getFullYear(),
      targetDate.getMonth(),
      targetDate.getDate() - targetDate.getDay() + 1
    );

    // 👇 목표하는 날짜가 포함된 주의 일요일 날짜 (문자열로 변환) / 결과 예: '2023-04-30'
    sundayDateOfTargetWeekString = sundayDateOfTargetWeekObject
      .toISOString()
      .substring(0, 10);
    // console.log('[AC10-030]조직원 근무/Contents - 일요일 정보: ', sundayDateOfTargetWeekString);

    // 👇 목표하는 날짜가 포함된 주의 토요일 날짜 (Date객체)
    const saturdayDateOfTargetWeekObject = new Date(
      targetDate.getFullYear(),
      targetDate.getMonth(),
      targetDate.getDate() - targetDate.getDay() + 7
    );

    // 👇 목표하는 날짜가 포함된 주의 토요일 날짜 (문자열로 변환) / 결과 예: '2023-08-05'
    saturdayDateOfTargetWeekString = saturdayDateOfTargetWeekObject
      .toISOString()
      .substring(0, 10);
    // console.log('[AC10-030]조직원 근무/Contents - 토요일 정보: ', saturdayDateOfTargetWeekString);
  }
  targetWeeklyDate();

  // [TAG] 월 근무현황 인쇄 ----- start
  const [monthlyPrintingLoading, setMonthlyPrintingLoading] = useState(true);

  // 👇👇👇👇👇 프린트 페이지 새 창으로 전환 - 월 근무현황 인쇄 🥺🥺🥺🥺🥺
  const onMoveToMonthlyPrintPage = () => {
    if (employeeDataMonthly.length <= 0) {
      return alert('불러온 데이터가 없습니다');
    }

    const dataToSend = 'monthly-printing';
    const dataObject = {
      apartmentContractIdx: apartmentContractIdx, // 아파트 계약 인덱스
      calYear: calYear,
      calMonth: calMonth,
      // setMonthlySchedulerLoading: setMonthlySchedulerLoading,
      // setMonthlyPrintingLoading: setMonthlyPrintingLoading,
      // setPersonalWorkCardLoading: setPersonalWorkCardLoading,
    };

    const url = `/${dataToSend}?dataObject=${encodeURIComponent(
      JSON.stringify(dataObject)
    )}`;

    const childWindow = window.open(
      url,
      '_blank',
      'width=820px, height=1000px, toolbar=no, location=no, status=no'
    );

    if (childWindow) {
      childWindow.postMessage({ dataObject }, window.origin);
    }
  };
  // 👆👆👆👆👆 프린트 페이지 새 창으로 전환 - 월 근무현황 인쇄 🥺🥺🥺🥺🥺

  // [TAG] 출퇴근 카드 인쇄 ----- start
  const [personalWorkCardLoading, setPersonalWorkCardLoading] = useState(true);

  // 👇👇👇👇👇 프린트 페이지 새 창으로 전환 - 출퇴근 카드 인쇄 🥺🥺🥺🥺🥺
  const onMoveToPersonalWorkCardPrintPage = () => {
    if (employeeDataMonthly.length <= 0) {
      return alert('불러온 데이터가 없습니다');
    }

    const dataToSend = 'personal-work-card-printing';
    const dataObject = {
      apartmentContractIdx: apartmentContractIdx, // 아파트 계약 인덱스
      calYear: calYear,
      calMonth: calMonth,
      // setMonthlySchedulerLoading: setMonthlySchedulerLoading,
      // setMonthlyPrintingLoading: setMonthlyPrintingLoading,
      // setPersonalWorkCardLoading: setPersonalWorkCardLoading,
    };

    const url = `/${dataToSend}?dataObject=${encodeURIComponent(
      JSON.stringify(dataObject)
    )}`;

    const childWindow = window.open(
      url,
      '_blank',
      'width=820px, height=1000px, toolbar=no, location=no, status=no'
    );

    if (childWindow) {
      childWindow.postMessage({ dataObject }, window.origin);
    }
  };
  // 👆👆👆👆👆 프린트 페이지 새 창으로 전환 - 출퇴근 카드 인쇄 🥺🥺🥺🥺🥺

  const [dailySchedulerLoading, setDailySchedulerLoading] = useState(false); // [MEMO] 일간 데이터 로딩
  const [weeklySchedulerLoading, setWeeklySchedulerLoading] = useState(false); // [MEMO] 주간 데이터 로딩
  const [monthlySchedulerLoading, setMonthlySchedulerLoading] = useState(false); // [MEMO] 월간 데이터 로딩

  // [TAG] 👇 일간 근무현황 상태바 수정시, 페이지 업데이트 -----start
  const [isUpdateStatusBar, setIsUpdateStatusBar] = useState(false);

  if (isUpdateStatusBar === true) {
    onWorkStatusLoadBtnDaily({
      setEmployeeDataDaily,
      apartmentContractIdx, // 아파트 계약 인덱스
      calYear,
      calMonth,
      calDate,
      setDailySchedulerLoading,
    });

    setIsUpdateStatusBar(false);
  }
  // [TAG] 👆 일간 근무현황 상태바 수정시, 페이지 업데이트 -----end

  // [TAG] 🚨수정 구현중-임의로 추가: 👇 일간 근무현황 상태바 수정시, 페이지 업데이트 -----start
  const [isUpdateWeeklyStatus, setIsUpdateWeeklyStatus] = useState(false);

  if (isUpdateWeeklyStatus === true) {
    onWorkStatusLoadBtnWeekly({
      setEmployeeDataWeekly,
      apartmentContractIdx, // 아파트 계약 인덱스
      calYear,
      calMonth,
      calDate,
      setWeeklySchedulerLoading,
    });

    setIsUpdateWeeklyStatus(false);
  }
  // [TAG] 🚨수정 구현중-임의로 추가: 👆 일간 근무현황 상태바 수정시, 페이지 업데이트 -----end

  // [TAG] 👇 일간 근무현황 상태바 수정시, 페이지 업데이트 -----start
  const [isUpdateMonthlyStatus, setIsUpdateMonthlyStatus] = useState(false);

  if (isUpdateMonthlyStatus === true) {
    onWorkStatusLoadBtnMonthly({
      setEmployeeDataMonthly,
      apartmentContractIdx, // 아파트 계약 인덱스
      calYear,
      calMonth,
      // calDate,
      setMonthlySchedulerLoading,
      setMonthlyPrintingLoading,
      setPersonalWorkCardLoading,
    });

    setIsUpdateMonthlyStatus(false);
  }
  // [TAG] 👆 일간 근무현황 상태바 수정시, 페이지 업데이트 -----end

  // 💨💨💨💨💨💨💨💨💨💨💨💨💨💨💨
  const selectedApartmentIdx = sessionStorage.getItem('selectedApartmentIdx');
  const selectedCalYear = sessionStorage.getItem('selectedCalYear');
  const selectedCalMonth = sessionStorage.getItem('selectedCalMonth');
  const selectedCalDate = sessionStorage.getItem('selectedCalDate');

  if (apartmentContractIdx) {
    sessionStorage.setItem('selectedApartmentIdx', apartmentContractIdx);
  }

  if (calYear) {
    sessionStorage.setItem(
      'selectedCalYear',
      selectedCalYear ? selectedCalYear : calYear
    );
  }

  if (calMonth) {
    sessionStorage.setItem(
      'selectedCalMonth',
      selectedCalMonth ? selectedCalMonth : calMonth
    );
  }

  if (calDate) {
    sessionStorage.setItem(
      'selectedCalDate',
      selectedCalDate ? selectedCalDate : calDate
    );
  }

  if (dailySection && location.pathname === '/AC103/monthlySection') {
    setDailySection(false);
    setWeeklySection(false);
    setMonthlySection(true);

    // 👇 날짜 업데이트 (돌아가기로 나타난 페이지에서도 날짜 반영하게끔)
    // const newStartDate = new Date(`${selectedCalYear}-${selectedCalMonth}-01`);
    // setStartDate(newStartDate);
    const newStartDate = new Date(
      `${selectedCalYear}-${selectedCalMonth}-${
        selectedCalDate ? selectedCalDate : '01'
      }`
    );
    setStartDate(newStartDate);
    // 👆 날짜 업데이트 (돌아가기로 나타난 페이지에서도 날짜 반영하게끔)

    if (selectedApartmentIdx) {
      onWorkStatusLoadBtnMonthly({
        setEmployeeDataMonthly,
        apartmentContractIdx: selectedApartmentIdx, // 아파트 계약 인덱스
        calYear: selectedCalYear,
        calMonth: selectedCalMonth,
        setMonthlySchedulerLoading,
        setMonthlyPrintingLoading, // [MEMO] 인쇄 관련
        setPersonalWorkCardLoading, // [MEMO] 인쇄 관련
      });
    }
  }

  // 👇👇👇👇👇 일간 임시 👽👽👽
  function onPermitCodeView2() {
    if (permitCode === apartmentManagerCode) {
      // 관리소장 권한시: 계약 단지 선택
      useEffect(() => {
        onApartmentContractInfo({
          apartmentListContractData,
          setApartmentListContractData,
          setOnModal,
        });
      }, []);
    }

    if (calDate) {
      useEffect(() => {
        // 근무 내역 불러오기
        if (dailySection && apartmentContractIdx) {
          onWorkStatusLoadBtnDaily({
            setEmployeeDataDaily,
            apartmentContractIdx, // 아파트 계약 인덱스
            calYear,
            calMonth,
            calDate,
            setDailySchedulerLoading,
          });
        }
      }, [calYear, calMonth, calDate, apartmentContractIdx]);
    }
  }
  onPermitCodeView2();
  // 👆👆👆👆👆 일간 임시 👽👽👽

  // 👇👇👇👇👇 주간 임시 🌞🌞🌞
  //   <Button
  //   label="근무 현황 불러오기"
  //   onClick={() =>
  //     onWorkStatusLoadBtnWeekly({
  //       setEmployeeDataWeekly,
  //       apartmentContractIdx, // 아파트 계약 인덱스
  //       calYear,
  //       calMonth,
  //       calDate,
  //       setWeeklySchedulerLoading,
  //     })
  //   }
  // />

  function onPermitCodeView3() {
    if (permitCode === apartmentManagerCode) {
      // 관리소장 권한시: 계약 단지 선택
      useEffect(() => {
        onApartmentContractInfo({
          apartmentListContractData,
          setApartmentListContractData,
          setOnModal,
        });
      }, []);
    }

    if (calDate) {
      useEffect(() => {
        // 근무 내역 불러오기
        if (weeklySection && apartmentContractIdx) {
          onWorkStatusLoadBtnWeekly({
            setEmployeeDataWeekly,
            apartmentContractIdx, // 아파트 계약 인덱스
            calYear,
            calMonth,
            calDate,
            setWeeklySchedulerLoading,
          });
        }
      }, [calYear, calMonth, calDate, apartmentContractIdx]);
    }
  }
  onPermitCodeView3();

  // 👆👆👆👆👆 주간 임시 🌞🌞🌞

  // 👇 [TAG] 권한에 따른 UI 변경 관련
  function onPermitCodeView() {
    if (permitCode === apartmentManagerCode) {
      // 관리소장 권한시: 계약 단지 선택
      useEffect(() => {
        onApartmentContractInfo({
          apartmentListContractData,
          setApartmentListContractData,
          setOnModal,
        });
      }, []);
    }

    useEffect(() => {
      // 근무 내역 불러오기
      if (monthlySection && apartmentContractIdx) {
        onWorkStatusLoadBtnMonthly({
          setEmployeeDataMonthly,
          apartmentContractIdx, // 아파트 계약 인덱스
          calYear,
          calMonth,
          setMonthlySchedulerLoading,
          setMonthlyPrintingLoading, // [MEMO] 인쇄 관련
          setPersonalWorkCardLoading, // [MEMO] 인쇄 관련
        });
      }
    }, [calYear, calMonth, apartmentContractIdx]);
  }
  onPermitCodeView();

  function onClickedButton(item) {
    setApartmentContractIdx(item.idx);
    setApartmentName(item.Name);
  }
  // [TAG] 👆 권한에 따른 UI 변경 관련

  const dataColorTagList = {
    working: '#57d49e',
    breaking: '#ffc936',
    notWorking: '#ff6038',
    timeToWork: 'rgba(168, 242, 167, 0.3)', // 출근
    timeToHome: 'rgba(252, 164, 164, 0.3)', // 퇴근
  };

  function dataColorTagSection() {
    if (
      location.pathname === '/AC103' ||
      location.pathname === '/AC103/dailySection'
    ) {
      return (
        <>
          <GuideSection>
            <GuideBox>
              <p style={{ backgroundColor: `${dataColorTagList.working}` }} />
              근무
            </GuideBox>
            <GuideBox>
              <p style={{ backgroundColor: `${dataColorTagList.breaking}` }} />
              휴식
            </GuideBox>
            <GuideBox>
              <p
                style={{ backgroundColor: `${dataColorTagList.notWorking}` }}
              />
              미근무
            </GuideBox>
          </GuideSection>
        </>
      );
    }

    // if (
    //   location.pathname === '/AC103/weeklySection' ||
    //   location.pathname === '/AC103/monthlySection'
    // ) {
    //   return (
    //     <GuideSection>
    //       <GuideBox>
    //         <p style={{ backgroundColor: `${dataColorTagList.working}` }} />
    //         근무
    //       </GuideBox>
    //       <GuideBox>
    //         <p style={{ backgroundColor: `${dataColorTagList.timeToWork}` }} />
    //         출근
    //       </GuideBox>
    //       <GuideBox>
    //         <p style={{ backgroundColor: `${dataColorTagList.timeToHome}` }} />
    //         퇴근
    //       </GuideBox>
    //     </GuideSection>
    //   );
    // }
  }

  return (
    <>
      {/* 관리자 권한시: 계약 단지 선택 */}
      {permitCode === headOfficerManagerCode && onModal && (
        <Modal
          closeModal={closeModal}
          apartmentListContractData={apartmentListContractData}
          setApartmentListContractData={setApartmentListContractData}
          apartmentContractIdx={apartmentContractIdx}
          setApartmentContractIdx={setApartmentContractIdx}
          apartmentName={apartmentName}
          setApartmentName={setApartmentName}
        />
      )}

      <Title>조직원 근무</Title>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <SubTitle>
          <SubTitleCategory
            className={`${dailySection ? 'on-button' : 'off-button'}`}
            onClick={() => onSwitchDailySection()}
          >
            일간
          </SubTitleCategory>

          <SubTitleCategory
            className={`${weeklySection ? 'on-button' : 'off-button'}`}
            onClick={() => onSwitchWeeklySection()}
          >
            주간
          </SubTitleCategory>
          <SubTitleCategory
            className={`${monthlySection ? 'on-button' : 'off-button'}`}
            onClick={() => onSwitchMonthlySection()}
          >
            월간
          </SubTitleCategory>
        </SubTitle>

        {dataColorTagSection()}
      </div>
      <Bar />

      <Section>
        <DatePickerSection>
          <DateInfoBox>
            <div style={{ zIndex: 100 }}>
              {/* 👇 일간 근무 현황 */}
              {dailySection && (
                <div>
                  <DatePicker
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    selected={startDate}
                    onChange={(date: React.SetStateAction<any>) =>
                      setStartDate(date)
                    }
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              )}

              {/* 👇 주간 근무 현황 */}
              {weeklySection && (
                <WeeklyPeriodBox>
                  <div>
                    <DatePicker
                      locale={ko}
                      dateFormat="yyyy-MM-dd"
                      selected={startDate}
                      onChange={(date: React.SetStateAction<any>) =>
                        setStartDate(date)
                      }
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  <p>
                    {sundayDateOfTargetWeekString
                      ? `${sundayDateOfTargetWeekString}(일) ~ ${saturdayDateOfTargetWeekString}(토)`
                      : '날짜 정보 없음'}
                  </p>
                </WeeklyPeriodBox>
              )}

              {/* 👇 월간 근무 현황 */}
              {monthlySection && (
                <div>
                  <DatePicker
                    locale={ko}
                    dateFormat="yyyy-MM"
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    showMonthYearPicker
                  />
                </div>
              )}
            </div>

            {permitCode === headOfficerManagerCode && (
              <div
                style={{
                  display: 'flex',
                  marginRight: '5px',
                  marginLeft: '5px',
                }}
              >
                <Button
                  label="계약 단지 선택"
                  onClick={() =>
                    onApartmentContractInfo({
                      apartmentListContractData,
                      setApartmentListContractData,
                      setOnModal,
                    })
                  }
                />

                <ApartmentNameBox>
                  <ApartmentIdx style={{ marginRight: '5px' }}>
                    {apartmentContractIdx}
                  </ApartmentIdx>
                  <div>{apartmentName}</div>
                </ApartmentNameBox>
              </div>
            )}

            {permitCode === apartmentManagerCode &&
              apartmentListContractData.map((item, key) => {
                return (
                  <div
                    key={key}
                    onClick={() => {
                      // setApartmentContractIdx(item.idx);
                      onClickedButton(item);
                    }}
                  >
                    <PrintBtn
                      style={{
                        fontSize: '13px',
                        marginLeft: '5px',
                      }}
                    >
                      {item.JobGroupEtc}
                    </PrintBtn>
                  </div>
                );
              })}
          </DateInfoBox>

          {monthlySection && (
            <PrintBtnSection>
              <PrintBtn
                className="monthly-printing"
                onClick={() => onMoveToMonthlyPrintPage()}
              >
                월 근무현황 인쇄
              </PrintBtn>

              <PrintBtn onClick={() => onMoveToPersonalWorkCardPrintPage()}>
                출퇴근 카드 인쇄
              </PrintBtn>
            </PrintBtnSection>
          )}
        </DatePickerSection>

        {/* <Bar /> */}
        {/* <LoadInfoSection> */}
        {/* <LoadBtnSection> */}
        {/* {permitCode === headOfficerManagerCode && (
              <div style={{ marginRight: '5px' }}>
                <Button
                  label="계약 단지 선택"
                  onClick={() =>
                    onApartmentContractInfo({
                      apartmentListContractData,
                      setApartmentListContractData,
                      setOnModal,
                    })
                  }
                />
              </div>
            )} */}

        {/* 👇 [TAg] '불러오기' 버튼 주석처리(자동 불러오기로 변경했기 때문에) -----start */}
        {/* <ButtonSection> */}
        {/* {dailySection && (
                <Button
                  label="근무 현황 불러오기"
                  onClick={() =>
                    onWorkStatusLoadBtnDaily({
                      setEmployeeDataDaily,
                      apartmentContractIdx, // 아파트 계약 인덱스
                      calYear,
                      calMonth,
                      calDate,
                      setDailySchedulerLoading,
                    })
                  }
                />
              )} */}

        {/* {weeklySection && (
                <Button
                  label="근무 현황 불러오기"
                  onClick={() =>
                    onWorkStatusLoadBtnWeekly({
                      setEmployeeDataWeekly,
                      apartmentContractIdx, // 아파트 계약 인덱스
                      calYear,
                      calMonth,
                      calDate,
                      setWeeklySchedulerLoading,
                    })
                  }
                />
              )} */}

        {/* {monthlySection && (
                <Button
                  label="근무 현황 불러오기"
                  onClick={() =>
                    onWorkStatusLoadBtnMonthly({
                      setEmployeeDataMonthly,
                      apartmentContractIdx, // 아파트 계약 인덱스
                      calYear,
                      calMonth,
                      setMonthlySchedulerLoading,
                      setMonthlyPrintingLoading, // [MEMO] 인쇄 관련
                      setPersonalWorkCardLoading, // [MEMO] 인쇄 관련
                    })
                  }
                />
              )} */}
        {/* </ButtonSection> */}
        {/* 👆 [TAg] '불러오기' 버튼 주석처리(자동 불러오기로 변경했기 때문에) -----end */}
        {/* </LoadBtnSection> */}

        {/* [MEMO] 선택한 계약 단지 인덱스, 이름 */}
        {/* <ApartmentNameBox>
            <ApartmentIdx style={{ marginRight: '5px' }}>
              {apartmentContractIdx}
            </ApartmentIdx>
            <ApartmentName>{apartmentName}</ApartmentName>
          </ApartmentNameBox> */}
        {/* </LoadInfoSection> */}

        <LoadedDataSection>
          {dailySection && (
            <DailySection
              employeeData={employeeDataDaily}
              apartmentContractIdx={apartmentContractIdx} // 아파트 계약 인덱스
              calYear={calYear}
              calMonth={calMonth}
              calDate={calDate}
              // calYear={selectedCalYear} 수정전 적용한 것
              // calMonth={selectedCalMonth} 수정전 적용한 것
              // calDate={selectedCalDate} 수정전 적용한 것
              schedulerLoading={dailySchedulerLoading}
              setIsUpdateStatusBar={setIsUpdateStatusBar}
            />
          )}

          {weeklySection && (
            <WeeklySection
              employeeData={employeeDataWeekly}
              calYear={calYear}
              calMonth={calMonth}
              calDate={calDate}
              // calYear={selectedCalYear}
              // calMonth={selectedCalMonth}
              // calDate={selectedCalDate}
              schedulerLoading={weeklySchedulerLoading}
              setIsUpdateStatus={setIsUpdateWeeklyStatus} // 🚨수정 구현중-임의로 추가
              apartmentContractIdx={apartmentContractIdx} // 🚨수정 구현중-임의로 추가
            />
          )}

          {monthlySection && (
            <MonthlySection
              employeeData={employeeDataMonthly}
              calYear={calYear}
              calMonth={calMonth}
              // calYear={selectedCalYear} // 🚨수정 구현중-임시주석
              // calMonth={selectedCalMonth} // 🚨수정 구현중-임시주석
              // calDate={calDate}
              schedulerLoading={monthlySchedulerLoading}
              setIsUpdateStatus={setIsUpdateMonthlyStatus} // 🚨수정 구현중-임의로 추가
              apartmentContractIdx={apartmentContractIdx} // 🚨수정 구현중-임의로 추가
            />
          )}
        </LoadedDataSection>
      </Section>
    </>
  );
};

const Bar = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const Title = styled.div`
  ${({ theme }) => theme.contentsMainTitle};
  ${({ theme }) => theme.stopDrag};
`;

const SubTitle = styled.div`
  ${({ theme }) => theme.contentsSubTitle};
  ${({ theme }) => theme.stopDrag};

  display: flex;
`;

const SubTitleCategory = styled.div`
  ${({ theme }) => theme.contentsSubTitleCategory};

  &.on-button {
    color: ${({ theme }) => theme.fontColor.black};
    position: relative;

    ::before {
      content: '';
      position: absolute;
      bottom: -20px;
      width: 80%;
      height: 6px;
      background-color: ${({ theme }) => theme.fontColor.black};
      left: 50%; /* MEMO: 가상 요소를 가운데로 이동시키기 */
      transform: translateX(-50%);
    }
  }

  &.off-button {
    color: ${({ theme }) => theme.fontColor.lightGray};
  }

  &:active {
    color: #fc894d;
  }

  &:hover {
    color: ${({ theme }) => theme.mainColor.main};
  }
`;

const Section = styled.div`
  /* */
`;

const DatePickerSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: 40px;
  font-size: 13px;
`;

const DateInfoBox = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-right: 5px;
    color: ${({ theme }) => theme.fontColor.darkGray};
    font-weight: 500;
  }
`;

const WeeklyPeriodBox = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 5px;
    font-size: 13px;
    color: ${({ theme }) => theme.fontColor.darkGray};
  }
`;

const PrintBtnSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrintBtn = styled.button`
  ${({ theme }) => theme.printBtnStyle};

  &.monthly-printing {
    margin-right: 5px;
  }
`;

const LoadInfoSection = styled.div`
  display: flex;
  padding: 0 30px;
  height: 40px;
`;

const LoadBtnSection = styled.div`
  display: flex;
  align-items: center;
`;

const ApartmentNameBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;

  p {
    color: ${({ theme }) => theme.fontColor.darkGray};
  }
`;

const ApartmentIdx = styled.div`
  color: ${({ theme }) => theme.mainColor.main};
`;

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
`;

const LoadedDataSection = styled.div`
  /* */
`;

const GuideSection = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  padding: 0 30px;
  /* height: 60px; */
  background-color: white;
  color: ${({ theme }) => theme.fontColor.darkGray};
  font-size: 13px;
  font-weight: 500;
`;

const GuideBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;

  p {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
  }
`;
