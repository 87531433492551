// CV10-020 차량 등록 관리 - 서버 데이터 개별 삭제
import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { incorrectToken } from '../../../../lib/utils/incorrectToken';

interface deleteSingleServerDataProps {
  e: {};
  apartmentIdx: number;
  setDetectChangingData: Dispatch<SetStateAction<boolean>>; // 개별 데이터 삭제시, 데이터 불러오기 트리거
}

export const deleteSingleServerData = async ({
  e,
  apartmentIdx,
  setDetectChangingData,
}: deleteSingleServerDataProps) => {
  const token = sessionStorage.getItem('token');
  const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx');

  const dataIdx = e.data.idx;

  if (!apartmentIdx && Number(apartmentRefIdx) === 0) {
    return alert('단지를 선택해주세요');
  }

  if (e.value !== '삭제') {
    return;
  }

  if (e.value === '삭제') {
    await axios({
      // https://apihr.mmigroup.co.kr:9020/vehicle/cv10020/regcarmanage/1?idx=999
      // /vehicle/cv10020/regcarmanage/${1}?idx=${dataIdx}
      url: `${process.env.COMMON_URL}/vehicle/cv10020/regcarmanage/${1}`,
      method: 'delete',
      headers: { tokenId: token },
      data: {
        Data: [
          {
            idx: dataIdx,
          },
        ],
      },
    }).then(res => {
      console.log(
        '📢url: ',
        `${process.env.COMMON_URL}/vehicle/cv10020/regcarmanage/${1}`
      );
      console.log('deleteSingleServerData.tsx-res.data: ', res.data);

      try {
        if (res.data.ErrorCode === 0) {
          alert('데이터가 삭제되었습니다.');
          setDetectChangingData(true);
        }
        incorrectToken(res);
      } catch (e) {
        console.error(e);
      }
    });
  }
};
