import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

interface onLoadBtnProps {
  employeeData: any[];
  setEmployeeData: Dispatch<SetStateAction<any[]>>;
  enteredEmployeeNumValue: string;
  enteredNameValue: string;
  enteredPhoneNumValue: string;
  enteredInsuranceNumValue: string;
  requestSelectedDepartmentCode: string;
  requestSelectedJobCodeValue: string;
  requestSelectedJobGroupCode: string;
  requestSelectedPositionCode: string;
  enteredBirthValue: string;
  enteredEnterDateValue: string;
  enteredExitDateValue: string;
  enteredCategoryCodeValue: string;
  enteredContractIdxValue: string;
  enteredContractStartDateValue: string;
  enteredContractEndDateValue: string;
  requestSelectedAreaCode: string;
  requestSelectedStatusCode: string;
}

export const onLoadBtn = async ({
  employeeData,
  setEmployeeData,
  enteredEmployeeNumValue,
  enteredNameValue,
  enteredPhoneNumValue,
  enteredInsuranceNumValue,
  requestSelectedDepartmentCode,
  requestSelectedJobCodeValue,
  requestSelectedJobGroupCode,
  requestSelectedPositionCode,
  enteredBirthValue,
  enteredEnterDateValue,
  enteredExitDateValue,
  enteredCategoryCodeValue,
  enteredContractIdxValue, // 단지 계약 정보 인덱스
  enteredContractStartDateValue,
  enteredContractEndDateValue,
  requestSelectedAreaCode,
  requestSelectedStatusCode,
}: onLoadBtnProps) => {
  // -------------------------------------------------------------start
  const permitCode = sessionStorage.getItem('permitCode');
  const apartmentContractIdx = sessionStorage.getItem('apartmentContractIdx');

  const contractIdxForApartmentManager = `&ContractIdx=${apartmentContractIdx}`;

  /**
   * permitCode 권한 코드
   * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
   * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
   * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
   */

  const isHeadOfficeManagerCode =
    permitCode === process.env.HEAD_OFFICE_MANAGER_CODE;

  const isApartmentManagerCode =
    permitCode === process.env.APARTMENT_MANAGER_CODE;
  // -------------------------------------------------------------end

  const token = sessionStorage.getItem('token');

  if (isHeadOfficeManagerCode) {
    await axios
      .get(
        // http://dev.mmigroup.co.kr:8020/common/refuser?ItemPerPage=0&ItemStartPage=0
        // `${process.env.COMMON_URL}/common/refuser?ItemPerPage=0&ItemStartPage=0`
        // `/sample/SE10-030-employee-info.json` // ✅ 목데이터 (근로자 정보 = 직원 관리 카테고리)
        `${process.env.COMMON_URL}/common/refuser?ItemPerPage=0&ItemStartPage=0${enteredEmployeeNumValue}${enteredNameValue}${enteredPhoneNumValue}${enteredBirthValue}${enteredEnterDateValue}${enteredExitDateValue}${requestSelectedDepartmentCode}${requestSelectedJobCodeValue}${requestSelectedJobGroupCode}${requestSelectedPositionCode}${enteredCategoryCodeValue}${enteredContractIdxValue}${enteredContractStartDateValue}${enteredContractEndDateValue}${enteredInsuranceNumValue}${requestSelectedAreaCode}${requestSelectedStatusCode}`,
        {
          headers: { tokenId: token },
        }
      )
      .then(res => {
        console.log(
          'API확인',
          `${process.env.COMMON_URL}/common/refuser?ItemPerPage=0&ItemStartPage=0${enteredEmployeeNumValue}${enteredNameValue}${enteredPhoneNumValue}${enteredBirthValue}${enteredEnterDateValue}${enteredExitDateValue}${requestSelectedDepartmentCode}${requestSelectedJobCodeValue}${requestSelectedJobGroupCode}${requestSelectedPositionCode}${enteredCategoryCodeValue}${enteredContractIdxValue}${enteredContractStartDateValue}${enteredContractEndDateValue}${enteredInsuranceNumValue}${requestSelectedAreaCode}${requestSelectedStatusCode}`
        );
        try {
          setEmployeeData(res.data.Data);
          console.log(employeeData, 'res.data.Data');
        } catch (e) {
          console.error(e);
          console.log(
            `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
          );
        }
      });
  }

  if (isApartmentManagerCode) {
    await axios
      .get(
        // http://dev.mmigroup.co.kr:8020/common/refuser?ItemPerPage=0&ItemStartPage=0
        // `${process.env.COMMON_URL}/common/refuser?ItemPerPage=0&ItemStartPage=0`
        // `/sample/SE10-030-employee-info.json` // ✅ 목데이터 (근로자 정보 = 직원 관리 카테고리)
        `${process.env.COMMON_URL}/common/refuser?ItemPerPage=0&ItemStartPage=0${enteredEmployeeNumValue}${enteredNameValue}${enteredPhoneNumValue}${enteredBirthValue}${enteredEnterDateValue}${enteredExitDateValue}${requestSelectedDepartmentCode}${requestSelectedJobCodeValue}${requestSelectedJobGroupCode}${requestSelectedPositionCode}${enteredCategoryCodeValue}${contractIdxForApartmentManager}${enteredContractStartDateValue}${enteredContractEndDateValue}${enteredInsuranceNumValue}${requestSelectedAreaCode}${requestSelectedStatusCode}`,
        {
          headers: { tokenId: token },
        }
      )
      .then(res => {
        console.log(
          'API확인',
          `${process.env.COMMON_URL}/common/refuser?ItemPerPage=0&ItemStartPage=0${enteredEmployeeNumValue}${enteredNameValue}${enteredPhoneNumValue}${enteredBirthValue}${enteredEnterDateValue}${enteredExitDateValue}${requestSelectedDepartmentCode}${requestSelectedJobCodeValue}${requestSelectedJobGroupCode}${requestSelectedPositionCode}${enteredCategoryCodeValue}${contractIdxForApartmentManager}${enteredContractStartDateValue}${enteredContractEndDateValue}${enteredInsuranceNumValue}${requestSelectedAreaCode}${requestSelectedStatusCode}`
        );
        try {
          setEmployeeData(res.data.Data);
          console.log(employeeData, 'res.data.Data');
        } catch (e) {
          console.error(e);
          console.log(
            `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
          );
        }
      });
  }
};
