import React from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/store';

// MEMO: DEPARTMENT_CODE2: 서버에서 가져온 department 카테고리 데이터
// export const DEPARTMENT_CODE2 = () => {
//   const requestDepartmentName = useSelector(
//     (state: RootState) => state.codeValue.departmentNameValue
//   );

//   requestDepartmentName.map((item, idx: number) => {
//     console.log(item.DeptName, '! 부서이름');
//     console.log(item.DeptCode, '! 부서코드');

//     const nameKR = `${item.DeptName}`;
//     const code = `${item.DeptName}`;

//     // return { nameKR, code };
//   });
// };

// (소속) 부서 코드
export const TEMP_DEPARTMENT_CODE = [
  {
    nameKR: '현장관리부',
    code: '20000',
  },
  {
    nameKR: '경리부',
    code: '30000',
  },
  {
    nameKR: '입찰부',
    code: '40000',
  },
  {
    nameKR: '장비팀',
    code: '50000',
  },
  {
    nameKR: '부설연구소',
    code: '60000',
  },
  {
    nameKR: '개발팀',
    code: '61000',
  },
  {
    nameKR: '(주)앰앰아이',
    code: '10000',
  },
  {
    nameKR: '외부사용자',
    code: '90000',
  },
  {
    nameKR: '일반사용자',
    code: '99000',
  },
];

// 직무 코드 (JobCode)
export const TEMP_JOB_CODE = [
  {
    nameKR: '대표',
    code: '10000',
  },
  {
    nameKR: '운영',
    code: '20000',
  },
  {
    nameKR: '기획',
    code: '21000',
  },
  {
    nameKR: '경리',
    code: '22000',
  },
  {
    nameKR: '영업',
    code: '30000',
  },
  {
    nameKR: '입찰',
    code: '31000',
  },
  {
    nameKR: '연구소',
    code: '40000',
  },
  {
    nameKR: '현장',
    code: '50000',
  },
  {
    nameKR: '장비',
    code: '51000',
  },
  {
    nameKR: '생산',
    code: '52000',
  },
  {
    nameKR: '관리(파견)',
    code: '60000',
  },
  {
    nameKR: '경비(파견)',
    code: '61000',
  },
  {
    nameKR: '미화(파견)',
    code: '62000',
  },
];

// 직무 그룹 코드 (JobGroupCode)
export const TEMP_JOB_GROUP_CODE = [
  {
    nameKR: '미분류',
    code: '0',
  },
  {
    nameKR: '본사/지사',
    code: '1',
  },
  {
    nameKR: '연구소',
    code: '2',
  },
  {
    nameKR: '관리(파견)',
    code: '5',
  },
  {
    nameKR: '경비(파견)',
    code: '6',
  },
  {
    nameKR: '미화(파견)',
    code: '7',
  },
  {
    nameKR: '관리소장(외부)',
    code: '8',
  },
];

// 직급 코드 (PositionCode)
export const TEMP_POSITION_CODE = [
  {
    nameKR: '대표이사',
    code: '10000',
  },
  {
    nameKR: '전무',
    code: '15100',
  },
  {
    nameKR: '상무',
    code: '15200',
  },
  {
    nameKR: '이사',
    code: '15300',
  },
  {
    nameKR: '소장',
    code: '20000',
  },
  {
    nameKR: '부장',
    code: '30000',
  },
  {
    nameKR: '팀장',
    code: '31000',
  },
  {
    nameKR: '과장',
    code: '40000',
  },
  {
    nameKR: '반장',
    code: '41000',
  },
  {
    nameKR: '대리',
    code: '50000',
  },
  {
    nameKR: '팀원',
    code: '51000',
  },
  {
    nameKR: '사원',
    code: '60000',
  },
];

// 근무 지역 코드 (AreaCode)
export const TEMP_AREA_CODE = [
  {
    nameKR: '본사/지사/연구소',
    code: '9',
  },
  {
    nameKR: '서울',
    code: '0',
  },
  {
    nameKR: '인천',
    code: '1',
  },
  {
    nameKR: '부산',
    code: '2',
  },
  {
    nameKR: '경기도',
    code: '3',
  },
  {
    nameKR: '강원도',
    code: '4',
  },
  {
    nameKR: '충청도/대전/세종',
    code: '5',
  },
  {
    nameKR: '전라도/광주',
    code: '6',
  },
  {
    nameKR: '경상도/대구/울산',
    code: '7',
  },
  {
    nameKR: '제주도',
    code: '8',
  },
];

// 사원 상태 (Status)
export const TEMP_STATUS_CODE = [
  {
    nameKR: '정상(근무중)',
    code: 'N',
  },
  {
    nameKR: '휴직(무급)',
    code: 'H',
  },
  {
    nameKR: '휴직(유급)',
    code: 'P',
  },
  {
    nameKR: '퇴사(정상)',
    code: 'Q',
  },
  {
    nameKR: '퇴사(블랙리스트)',
    code: 'B',
  },
  {
    nameKR: '정상+휴직',
    code: 'A',
  },
  {
    nameKR: '퇴사 모두',
    code: 'E',
  },
];
