// [MEMO] 단지 계약 정보 불러오기
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface loadApartmentContractListProps {
  setApartmentData: Dispatch<SetStateAction<any[]>>;
}

export const loadApartmentContractList = async ({
  setApartmentData,
}: loadApartmentContractListProps) => {
  const token = sessionStorage.getItem('token');
  const apartmentContractIdx = sessionStorage.getItem('apartmentContractIdx');

  const requestedApartmentContractIdx =
    apartmentContractIdx && `&Aptidx=${apartmentContractIdx}`;

  await axios
    .get(
      // https://apihr.mmigroup.co.kr:9020/common/contractinfo?ItemPerPage=0&ItemStartPage=0&Aptidx=192&Valid=1
      `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${requestedApartmentContractIdx}&Valid=1`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '메인화면 / 단지 계약 정보 불러오기 API: ',
      //   `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${requestedApartmentContractIdx}&Valid=1`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          setApartmentData(res.data.Data);
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
