import styled from 'styled-components';

export const EssentialCategorySection = styled.div`
  ${({ theme }) => theme.stopDrag};
  /* padding-right: 30px; */
  padding-left: 30px;
  padding-bottom: 15px;

  &.on-option {
    padding-bottom: 0;
  }
`;

export const EssentialCategoryWrap = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  padding-left: 10px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-radius: 0.5em;
  color: ${({ theme }) => theme.fontColor.black};
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;

  &.on-option {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  :hover {
    color: ${({ theme }) => theme.mainColor.main};
  }
`;

export const CategorySection = styled.div`
  ${({ theme }) => theme.stopDrag};
  display: flex;
  flex-direction: column;

  /* padding-right: 30px; */
  padding-left: 30px;
  padding-bottom: 15px;
`;

export const CategoryWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-top: none;
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  background-color: ${({ theme }) => theme.backgroundColor.gray};

  height: 140px; /* 템플릿 사용안내 높이와 일치해야 함 */
`;

export const CategoryGroup = styled.div`
  float: left;
`;

export const CategoryBox = styled.div`
  float: left;
  margin: 5px 10px;
`;

export const CategoryName = styled.div`
  font-size: 13px;
  margin-bottom: 5px;
  padding-left: 2px;
`;

export const InputStyle = styled.input`
  background-color: white;
  width: 150px;
  height: 35px;
  padding: 5px;

  background-color: white;
  border-radius: 0.5em;
  border: solid 1px #c1c1c1;
  color: #343a40;
  font-size: 13px;
`;

export const SelectStyle = styled.select`
  width: 60px;
  height: 35px;
  padding: 5px;

  background-color: white;
  border-radius: 0.5em;
  border: solid 1px #c1c1c1;
  color: #343a40;
  font-size: 13px;
`;

export const checkboxLabelStyle = styled.label`
  //
`;
