import React from 'react';
import styled from 'styled-components';

import { comma } from '../../../lib/utils/comma';

interface ModalContentsProps {
  apartmentDetailData: any;
  image: string;
}

export const ModalContents = ({
  apartmentDetailData,
  image,
}: ModalContentsProps) => {
  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');

  /**
   * permitCode 권한 코드
   * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
   * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
   * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
   */

  const isHeadOfficeManagerCode =
    permitCode === process.env.HEAD_OFFICE_MANAGER_CODE;
  const isNormalEmployeeCode = permitCode === process.env.NORMAL_EMPLOYEE_CODE;

  // if (!isHeadOfficeManagerCode && !token) {
  //   alert('로그인부터 해주세요.');
  //   navigate('/login');
  //   return;
  // } else if (!isHeadOfficeManagerCode) {
  //   alert('접근 권한이 없습니다.');
  //   return;
  // }

  return (
    <>
      <Wrap>
        <ImageWrap>
          <ImageBox>
            {image ? (
              <ApartmentImg src={`${image}`} />
            ) : (
              <NoneImg>데이터 없음</NoneImg>
            )}
          </ImageBox>
        </ImageWrap>

        <ApartmentInfoWrap>
          <ApartmentInfoSection>
            <CategoryListWrap>
              <CategoryWrap>
                <CategoryBox>
                  <CategoryName>단지이름</CategoryName>
                  <TextBox className="long">
                    {apartmentDetailData.Name ? apartmentDetailData.Name : ''}
                  </TextBox>
                </CategoryBox>
              </CategoryWrap>

              <CategoryWrap>
                <CategoryBox>
                  <CategoryName>주소</CategoryName>
                  <TextBox className="long">
                    {apartmentDetailData.Address1
                      ? apartmentDetailData.Address1
                      : ''}
                  </TextBox>
                </CategoryBox>
              </CategoryWrap>

              <CategoryWrap>
                <CategoryBox>
                  <CategoryName>지역</CategoryName>
                  <TextBox>
                    {apartmentDetailData.AreaName
                      ? apartmentDetailData.AreaName
                      : ''}
                  </TextBox>
                </CategoryBox>
                <CategoryBox>
                  <CategoryName>우편번호</CategoryName>
                  <TextBox>
                    {apartmentDetailData.POSTCode
                      ? apartmentDetailData.POSTCode
                      : ''}
                  </TextBox>
                </CategoryBox>

                <CategoryBox>
                  <CategoryName>단지 연락처</CategoryName>
                  <TextBox>
                    {apartmentDetailData.InfoPhone
                      ? apartmentDetailData.InfoPhone
                      : ''}
                  </TextBox>
                </CategoryBox>

                <CategoryBox>
                  <CategoryName>단지 이메일</CategoryName>
                  <TextBox>
                    {apartmentDetailData.InfoMail
                      ? apartmentDetailData.InfoMail
                      : ''}
                  </TextBox>
                </CategoryBox>
              </CategoryWrap>

              <CategoryWrap>
                <CategoryBox>
                  <CategoryName>담당 관리자</CategoryName>
                  <TextBox>
                    {apartmentDetailData.EmployeeNameManager
                      ? apartmentDetailData.EmployeeNameManager
                      : ''}
                  </TextBox>
                </CategoryBox>
                <CategoryBox>
                  <CategoryName>관리자 이메일</CategoryName>
                  <TextBox>
                    {apartmentDetailData.ManageEMail
                      ? apartmentDetailData.ManageEMail
                      : ''}
                  </TextBox>
                </CategoryBox>
                <CategoryBox>
                  <CategoryName>관리자 연락처</CategoryName>
                  <TextBox>
                    {apartmentDetailData.ManagePhone
                      ? apartmentDetailData.ManagePhone
                      : ''}
                  </TextBox>
                </CategoryBox>
              </CategoryWrap>
            </CategoryListWrap>
          </ApartmentInfoSection>

          <ApartmentInfoSection>
            <CategoryListWrap>
              <CategoryWrap>
                <CategoryBox>
                  <CategoryName>계약직군</CategoryName>
                  <TextBox>
                    {apartmentDetailData.JobGroupEtc
                      ? apartmentDetailData.JobGroupEtc
                      : ''}
                  </TextBox>
                </CategoryBox>
                <CategoryBox>
                  <CategoryName>계약인원(남)</CategoryName>
                  <TextBox>
                    {apartmentDetailData.StartEmployeeMale
                      ? apartmentDetailData.StartEmployeeMale
                      : ''}
                  </TextBox>
                </CategoryBox>
                <CategoryBox>
                  <CategoryName>계약인원(여)</CategoryName>
                  <TextBox>
                    {apartmentDetailData.StartEmployeeFemale
                      ? apartmentDetailData.StartEmployeeFemale
                      : ''}
                  </TextBox>
                </CategoryBox>
                <CategoryBox>
                  <CategoryName>계약 시작일</CategoryName>
                  <TextBox>
                    {apartmentDetailData.StartDate
                      ? apartmentDetailData.StartDate
                      : ''}
                  </TextBox>
                </CategoryBox>
                <CategoryBox>
                  <CategoryName>계약 종료일</CategoryName>
                  <TextBox>
                    {apartmentDetailData.EndDate
                      ? apartmentDetailData.EndDate
                      : ''}
                  </TextBox>
                </CategoryBox>
              </CategoryWrap>
              <CategoryWrap>
                <CategoryBox>
                  <CategoryName>건물수</CategoryName>
                  <TextBox>
                    {apartmentDetailData.NoBuilding
                      ? comma(apartmentDetailData.NoBuilding)
                      : ''}
                  </TextBox>
                </CategoryBox>
                <CategoryBox>
                  <CategoryName>세대수</CategoryName>
                  <TextBox>
                    {apartmentDetailData.NoHouse
                      ? comma(apartmentDetailData.NoHouse)
                      : ''}
                  </TextBox>
                </CategoryBox>
                <CategoryBox>
                  <CategoryName>주차대수</CategoryName>
                  <TextBox>
                    {apartmentDetailData.NoParking
                      ? comma(apartmentDetailData.NoParking)
                      : ''}
                  </TextBox>
                </CategoryBox>
                <CategoryBox>
                  <CategoryName>전체면적</CategoryName>
                  <TextBox>
                    {apartmentDetailData.ManageArea
                      ? comma(apartmentDetailData.ManageArea)
                      : ''}
                  </TextBox>
                </CategoryBox>
                <CategoryBox>
                  <CategoryName>과세면적</CategoryName>
                  <TextBox>
                    {apartmentDetailData.TaxationArea
                      ? comma(apartmentDetailData.TaxationArea)
                      : ''}
                  </TextBox>
                </CategoryBox>
                <CategoryBox>
                  <CategoryName>면세면적</CategoryName>
                  <TextBox>
                    {apartmentDetailData.TaxExemptionArea
                      ? comma(apartmentDetailData.TaxExemptionArea)
                      : ''}
                  </TextBox>
                </CategoryBox>
              </CategoryWrap>

              <CategoryWrap></CategoryWrap>

              <CategoryWrap>
                <CategoryBox>
                  <CategoryName>총 용역비용</CategoryName>
                  <TextBox>
                    {apartmentDetailData.StartTotalCost
                      ? comma(apartmentDetailData.StartTotalCost)
                      : ''}
                  </TextBox>
                </CategoryBox>
                <CategoryBox>
                  <CategoryName>월 용역비용</CategoryName>
                  <TextBox>
                    {apartmentDetailData.StartMonthCost
                      ? comma(apartmentDetailData.StartMonthCost)
                      : ''}
                  </TextBox>
                </CategoryBox>
                <CategoryBox>
                  <CategoryName>산출내역서 용역비용</CategoryName>
                  <TextBox>
                    {apartmentDetailData.StartCalculatiuonCost
                      ? comma(apartmentDetailData.StartCalculatiuonCost)
                      : ''}
                  </TextBox>
                </CategoryBox>
              </CategoryWrap>
            </CategoryListWrap>
          </ApartmentInfoSection>
        </ApartmentInfoWrap>
      </Wrap>
    </>
  );
};

const Wrap = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
`;

const ImageWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const ApartmentInfoWrap = styled.div`
  padding: 5px;
  border-radius: 0.5em;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
`;

const ApartmentInfoSection = styled.div`
  display: flex;
`;

const ImageBox = styled.div`
  /* margin: 5px; */
`;

const CategoryListWrap = styled.div`
  /* */
`;

const CategoryWrap = styled.div`
  display: flex;
`;

const CategoryBox = styled.div`
  margin: 5px;
`;

const CategoryName = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.fontColor.gray};
`;

const ApartmentImg = styled.img`
  width: 138px;
  height: 202px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
`;

const NoneImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 138px;
  height: 202px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  color: ${({ theme }) => theme.fontColor.lightGray};
  font-size: 13px;
`;

const TextBox = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: 120px;
  font-size: 13px;

  &.medium {
    width: 200px;
  }

  &.long {
    width: 600px;
  }
`;
