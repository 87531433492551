import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { incorrectToken } from '../../../../lib/utils/incorrectToken';

interface onModifyDataProps {
  detailWorkData: any[];
  employeeNum: string;
  targetWorkStatusIdx: string | null;
  isNextDay: boolean;
  selectedStartHours: string;
  selectedStartMinutes: string;
  selectedEndHours: string;
  selectedEndMinutes: string;
  closeModal: () => void;
  setIsUpdateStatusBar: Dispatch<SetStateAction<boolean>>;
}

export const onModifyData = async ({
  detailWorkData,
  employeeNum,
  targetWorkStatusIdx,
  isNextDay,
  selectedStartHours,
  selectedStartMinutes,
  selectedEndHours,
  selectedEndMinutes,
  closeModal,
  setIsUpdateStatusBar,
}: onModifyDataProps) => {
  if (detailWorkData.length <= 0) {
    return;
  }

  function addString(str: string) {
    if (str.length === 1) {
      return '0' + str;
    } else return str;
  }

  const token = sessionStorage.getItem('token');
  const contractIdx = sessionStorage.getItem('contractIdx'); // 아파트 계약 인덱스

  await axios({
    // https://dev.mmigroup.co.kr:8020/attendance/ac10030/attendancereport/630037?idx=2805
    url: `${process.env.COMMON_URL}/attendance/ac10030/attendancereport/${employeeNum}?idx=${targetWorkStatusIdx}`,
    method: 'put',
    headers: { tokenId: token },
    data: {
      idx: targetWorkStatusIdx,
      AttendStatusCode: detailWorkData[0].AttendStatusCode,
      AttendStatusName: detailWorkData[0].AttendStatusName,
      Working: detailWorkData[0].Working,
      StatusTardy: detailWorkData[0].StatusTardy,
      StatusLeave: detailWorkData[0].StatusLeave,
      ChangedRecord: detailWorkData[0].ChangedRecord,
      DeleteRecord: detailWorkData[0].DeleteRecord,
      TriggerDate: detailWorkData[0].TriggerDate,
      // TriggerTimeStart: '02:00',
      TriggerTimeStart: `${addString(selectedStartHours)}:${addString(
        selectedStartMinutes
      )}`,
      // TriggerTimeEnd: '12:48',
      TriggerTimeEnd: !detailWorkData[0].TriggerTimeEnd
        ? ''
        : `${addString(selectedEndHours)}:${addString(selectedEndMinutes)}`,
      // EndTimeDay: detailWorkData[0].EndTimeDay,
      EndTimeDay: isNextDay,
      ModifyAvailableTimeStart: detailWorkData[0].ModifyAvailableTimeStart,
      ModifyAvailableTimeEnd: detailWorkData[0].ModifyAvailableTimeEnd,
      ModifyEndTimeDay: detailWorkData[0].ModifyEndTimeDay,
      AttedNote: detailWorkData[0].AttedNote,
    },
  }).then(res => {
    console.log(
      '[AC10-030-REPORT] 근무 현황(일간) 수정 API: ',
      `${process.env.COMMON_URL}/attendance/ac10030/attendancereport/${employeeNum}?idx=${targetWorkStatusIdx}`
    );

    console.log(
      '시작시간: ',
      `${addString(selectedStartHours)}:${addString(selectedStartMinutes)}`
    );
    console.log(
      '종료시간: ',
      !detailWorkData[0].TriggerTimeEnd
        ? ''
        : `${addString(selectedEndHours)}:${addString(selectedEndMinutes)}`
    );
    console.log('다음날 퇴근?: ', isNextDay);
    console.log('시간 변경 완료 - res.data.ErrorCode: ', res.data);

    try {
      if (res.data.ErrorCode === 0) {
        return (
          closeModal(),
          alert('변경이 완료되었습니다'),
          setIsUpdateStatusBar(true)
        );
      }

      incorrectToken(res);
    } catch (e) {
      console.error(e);
      console.log(
        `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
      );
    }
  });
};
