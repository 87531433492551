// [MEMO] 일간 근무 현황 컴포넌트
import React, { useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { Scheduler } from './Scheduler/Scheduler';

interface DailySectionProps {
  employeeData: any[];
  apartmentContractIdx: string;
  calYear: string;
  calMonth: string;
  calDate: string;
  schedulerLoading: boolean;
  setIsUpdateStatusBar: Dispatch<SetStateAction<boolean>>;
}

const DailySection = ({
  employeeData,
  apartmentContractIdx,
  calYear,
  calMonth,
  calDate,
  schedulerLoading,
  setIsUpdateStatusBar,
}: DailySectionProps) => {
  if (schedulerLoading) {
    return (
      <>
        <Bar />
        <LoadingWrap>
          <LoadingGIF src="/loading.gif" />
        </LoadingWrap>
      </>
    );
  }

  return (
    <>
      <Scheduler
        employeeData={employeeData}
        apartmentContractIdx={apartmentContractIdx}
        calYear={calYear}
        calMonth={calMonth}
        calDate={calDate}
        setIsUpdateStatusBar={setIsUpdateStatusBar}
      />
    </>
  );
};

export default DailySection;

const Bar = styled.div`
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const LoadingGIF = styled.img`
  ${({ theme }) => theme.loadingGIF}
`;
