import styled from 'styled-components';

export const Section = styled.div`
  ${({ theme }) => theme.stopDrag};

  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100%;
  color: ${({ theme }) => theme.fontColor.black};
  z-index: 50;
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 55px; /* [MEMO] 네브바 height */
  padding: 0 25px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  background-color: ${({ theme }) => theme.backgroundColor.white};
  font-size: 14px; /* [MEMO] 네브바 폰트 크기 */
  font-weight: 400;

  @media (max-width: 900px) {
    display: flex;
    align-items: center;
  }
`;

export const ToggleSection = styled.div`
  display: none;
  justify-content: center;

  @media (max-width: 900px) {
    display: block;
    cursor: pointer;
  }
`;

export const TitleSection = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const CompanyInfoSection = styled.div`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

export const Logo = styled.img`
  height: 32px;
  margin-right: 5px;
`;

export const UserSection = styled.div`
  display: flex;
  align-items: center;

  right: 0;

  div {
    display: flex;
    align-items: center;
    text-align: center;
    margin-right: 10px;

    p {
      margin-right: 2px;
      font-weight: 500;
    }
  }
`;

export const ManualSection = styled.div`
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.fontColor.lightGray};
  }
`;

export const KakaoQuestionImg = styled.img`
  height: 32px;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }
`;

export const UserInfoSection = styled.div`
  p {
    &.user-name {
      color: ${({ theme }) => theme.mainColor.main};
      font-weight: 500;
    }
    &.apartment-name {
      color: ${({ theme }) => theme.mainColor.main};
      font-weight: 500;
    }
  }
`;
