// ref가 있는 인덱스 API (=단지 고유 번호(계약 아님))

import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

interface searchApartmentNameProps {
  apartmentListData: any[];
  setApartmentListData: Dispatch<SetStateAction<any[]>>;
  enteredNameValue: string;
  setOpenResult: Dispatch<SetStateAction<boolean>>;
  setSearchResult: Dispatch<SetStateAction<any>>;
}

export const searchApartmentName = async ({
  apartmentListData,
  setApartmentListData,
  enteredNameValue, // 단지 이름
  setOpenResult,
  setSearchResult,
}: searchApartmentNameProps) => {
  const token = sessionStorage.getItem('token');
  // console.log(
  //   'searchApartmentName.tsx-계약 인덱스(ref없음, 고유번호아님)/enteredNameValue: ',
  //   enteredNameValue
  // );

  await axios
    .get(
      // https://dev.mmigroup.co.kr:8020/common/refcontractinfo?ItemPerPage=0&ItemStartPage=0&Name=%EC%9D%B4%EB%A6%84
      `${process.env.COMMON_URL}/common/refcontractinfo?ItemPerPage=0&ItemStartPage=0${enteredNameValue}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   'searchApartmentName.tsx-계약 인덱스(ref없음, 고유번호아님)/API: ',
      //   `${process.env.COMMON_URL}/common/refcontractinfo?ItemPerPage=0&ItemStartPage=0${enteredNameValue}`
      // );
      try {
        setSearchResult(res.data.Data);
        setOpenResult(true);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
