import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// import * as S from './PersonalInfoPage.style';
import styled from 'styled-components';

// import { Mainbar } from '../../components/Mainbar/Mainbar';
import { Navbar } from '../../components/Navbar/Navbar';
import { Sidebar } from '../../components/Sidebar/Sidebar';

import { PersonalInfo } from '../../components/SE10-030-personal-info/PersonalInfo/PersonalInfo';

const PersonalInfoPage = () => {
  const location = useLocation();

  // history.replaceState;
  useEffect(() => {
    //
  }, [location]);
  console.log(window.location, 'window.l~');

  return (
    <>
      {/* <Mainbar /> */}

      {/* 👇 1. 네브바 */}
      {/* <Navbar /> */}
      {/* 👆 1. 네브바 */}

      {/* <Body> */}
      {/* 👇 2. 사이드바 */}
      {/* <Sidebar /> */}
      {/* 👆 2. 사이드바 */}
      {/* <Contents> */}
      {/* <Wrap> */}
      {/* 👇 3. 페이지 */}
      <PersonalInfo />
      {/* 👆 3. 페이지 */}
      {/* </Wrap>
        </Contents>
      </Body> */}
    </>
  );
};

export default PersonalInfoPage;

const Body = styled.div`
  display: flex;
  margin-top: 55px; /* 네브바의 height 감안 */
  /* height: 100vh; */
`;

const Contents = styled.div`
  margin-left: 200px; /* 사이드바의 width 감안 */
  width: 100vw;
  height: calc(100vh - 55px); /* 네브바의 height 감안 */
  /* background-color: orange; */
  background-color: ${({ theme }) => theme.backgroundColor.gray};
`;

const Wrap = styled.div`
  padding: 15px;
  /* background-color: pink; */
  background-color: ${({ theme }) => theme.backgroundColor.gray};
`;
