export const gridOptions = {
  columnDefs: [
    // {
    //   field: 'idx',
    //   headerName: '번호',
    //   resizable: true,
    //   filter: true,
    //   sortable: true,
    //   width: 80,
    //   cellClass: 'cell-class',
    // },
    {
      field: 'Addr1',
      headerName: '동',
      resizable: true,
      filter: true,
      sortable: true,
      width: 80,
      cellStyle: { textAlign: 'start' },
      cellClass: 'cell-class-contents',
    },
    {
      field: 'Addr2',
      headerName: '호',
      resizable: true,
      filter: true,
      sortable: true,
      width: 80,
      cellStyle: { textAlign: 'start' },
      cellClass: 'cell-class-contents',
    },
    {
      field: 'CarNo',
      headerName: '차량 번호',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'CarFlag',
      headerName: '등록 유형',
      resizable: true,
      filter: true,
      sortable: true,
      width: 100,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'CreateDateTime',
      headerName: '등록일',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'CarExpireDate',
      headerName: '만료일',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'OwnerName',
      headerName: '소유주',
      resizable: true,
      filter: true,
      sortable: true,
      width: 150,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'OwnerPhone',
      headerName: '소유주 연락처',
      resizable: true,
      filter: true,
      sortable: true,
      width: 150,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    // {
    //   field: 'EmployeName',
    //   headerName: '관리자',
    //   resizable: true,
    //   filter: true,
    //   sortable: true,
    //   width: 120,
    //   cellClass: 'cell-class-contents',
    // },
    // {
    //   field: 'RegEmployeeNo',
    //   headerName: '관리자 사번',
    //   resizable: true,
    //   filter: true,
    //   sortable: true,
    //   width: 120,
    //   cellClass: 'cell-class-contents',
    // },
    {
      field: 'modify',
      headerName: '',
      resizable: true,
      width: 80,
      cellClass: 'cell-class-button',
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'delete',
      headerName: '',
      resizable: true,
      width: 80,
      cellClass: 'cell-class-button',
      cellStyle: { textAlign: 'center' },
    },
  ],

  // custom loading template. the class ag-overlay-loading-center is part of the grid,
  // it gives a white background and rounded border
  overlayLoadingTemplate:
    '<span class="ag-overlay-loading-center" style="padding: 10px; border: 1px solid #444; background: lightgoldenrodyellow;">데이터를 불러오는 중입니다</span>',

  overlayNoRowsTemplate:
    '<span style="padding: 10px; border: 3px solid #ededed; border-radius: 0.5em; background-color: white; box-shadow: 5px 5px 15px -3px #ededed; color: #717171; font-weight: 600;">불러온 데이터가 없습니다</span>',
};
