import React from 'react';
import * as S from './WorkStatusPersonPage.style';

import { Mainbar } from '../../components/Mainbar/Mainbar';

const WorkStatusPersonPage = () => {
  return (
    <>
      {/* <Mainbar />
      <S.ContentsSection>
      <S.ContentsWrap> */}
      <div>WorkStatusPerson</div>
      {/* </S.ContentsWrap>
      </S.ContentsSection> */}
    </>
  );
};

export default WorkStatusPersonPage;
