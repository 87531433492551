import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { AddContents } from './AddContents';

interface AddModalProps {
  // closeModal: React.MouseEventHandler<HTMLDivElement> | undefined;
  detailWorkData: any;
  employeeNum: string;
  targetWorkStatusIdx: string | null;
  setRightClickModalVisible: Dispatch<SetStateAction<boolean>>;
  closeModal: () => void;
  setIsUpdateStatusBar: Dispatch<SetStateAction<boolean>>;
  calYear: string;
  calMonth: string;
  calDate: string;

  cellData: any; // 🔰
}

export const AddModal = ({
  // closeModal,
  detailWorkData,
  employeeNum,
  targetWorkStatusIdx,
  setRightClickModalVisible,
  closeModal,
  setIsUpdateStatusBar,
  calYear,
  calMonth,
  calDate,

  cellData, // 🔰
}: AddModalProps) => {
  setRightClickModalVisible(false);

  return (
    <Background onClick={closeModal}>
      <Container
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <AddContents
          employeeNum={employeeNum}
          targetWorkStatusIdx={targetWorkStatusIdx}
          closeModal={closeModal}
          setIsUpdateStatusBar={setIsUpdateStatusBar}
          calYear={calYear}
          calMonth={calMonth}
          calDate={calDate}
          cellData={cellData}
        />
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: white;
`;
