import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Contents } from '../Contents/Contents';

export const ApprovalTableSetting = () => {
  const navigate = useNavigate();

  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');

  /**
   * permitCode 권한 코드
   * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
   * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
   * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
   */

  const isHeadOfficeManagerCode =
    permitCode === process.env.HEAD_OFFICE_MANAGER_CODE;

  const isApartmentManagerCode =
    permitCode === process.env.APARTMENT_MANAGER_CODE;

  if (
    (!isHeadOfficeManagerCode && !token) ||
    (!isApartmentManagerCode && !token)
  ) {
    alert('로그인부터 해주세요.');
    navigate('/login');
    return null;
  } else if (!isHeadOfficeManagerCode && !isApartmentManagerCode) {
    alert('접근 권한이 없습니다.');
    navigate('/');
    return null;
  }

  return (
    <>
      <Contents />
    </>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: white;
`;
