// 근무 지역 코드 (AreaCode)
export const TEMP_AREA_CODE = [
  {
    nameKR: '서울',
    code: '0',
  },
  {
    nameKR: '인천',
    code: '1',
  },
  {
    nameKR: '부산',
    code: '2',
  },
  {
    nameKR: '경기도',
    code: '3',
  },
  {
    nameKR: '강원도',
    code: '4',
  },
  {
    nameKR: '충청도/대전/세종',
    code: '5',
  },
  {
    nameKR: '전라도/광주',
    code: '6',
  },
  {
    nameKR: '경상도/대구/울산',
    code: '7',
  },
  {
    nameKR: '제주도',
    code: '8',
  },
];
