import { Dispatch, SetStateAction } from 'react';

interface onMinusHalfYearProps {
  calStartYear: string;
  calStartMonth: string;
  calStartDate: string;
  setStartDate: Dispatch<SetStateAction<any>>;
}

// MEMO: 6개월씩 감소
export const onMinusHalfYear = ({
  calStartYear,
  calStartMonth,
  calStartDate,
  setStartDate,
}: onMinusHalfYearProps) => {
  let targetMonth = Number(calStartMonth);
  let targetYear = Number(calStartYear);
  // const targetDate = Number(calStartDate);
  const targetDate = 1;

  if (targetMonth > 6) {
    targetMonth = targetMonth - 6;
  } else if (targetMonth === 6) {
    targetMonth = 12;
    targetYear = targetYear - 1;
  } else {
    targetMonth = 12 - (6 - targetMonth);
    targetYear = targetYear - 1;
  }

  setStartDate(new Date(`${targetYear}-${targetMonth}-${targetDate}`));
};
