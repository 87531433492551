// [MEMO] todo리스트(해야 할 일)
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface loadToDodoDataProps {
  setToDoData: Dispatch<SetStateAction<any[]>>;
}

export const loadToDodoData = async ({ setToDoData }: loadToDodoDataProps) => {
  const token = sessionStorage.getItem('token');

  await axios
    .get(
      // https://apihr.mmigroup.co.kr:9020/dashboard/da10010/todolist?ItemPerPage=0&ItemStartPage=0
      `${process.env.COMMON_URL}/dashboard/da10010/todolist?ItemPerPage=0&ItemStartPage=0`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '메인화면 / todo리스트(해야 할 일) API: ',
      //   `${process.env.COMMON_URL}/dashboard/da10010/todolist?ItemPerPage=0&ItemStartPage=0`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          // console.log(
          //   '메인화면 / todo리스트(해야 할 일) res.data.Data: ',
          //   res.data.Data
          // );
          setToDoData(res.data.Data);
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
