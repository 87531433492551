// [MEMO] 공지사항
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { loadDetailedNoticeData } from '../utils/loadDetailedNoticeData';

interface NoticeBoardSectionProps {
  noticeData: any;
  setDetailedNoticeData: Dispatch<SetStateAction<any[]>>;
  setDetailedNoticeModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const NoticeBoardSection = ({
  noticeData,
  setDetailedNoticeData,
  setDetailedNoticeModalVisible,
}: NoticeBoardSectionProps) => {
  // console.log(
  //   '[DA10-010]공지사항/NoticeBoardSection - noticeData: ',
  //   noticeData
  // );

  return (
    <>
      {/* <div>공지사항</div> */}
      <div>
        {noticeData.map((data: { idx: number; PostTitle: string }) => (
          <PostWrap key={data.idx}>
            <PostTitle
              style={{ marginBottom: '10px' }}
              onClick={() =>
                loadDetailedNoticeData({
                  setDetailedNoticeData,
                  noticeIdx: data.idx,
                  setDetailedNoticeModalVisible,
                })
              }
            >
              {data.PostTitle}
            </PostTitle>
          </PostWrap>
        ))}
      </div>
    </>
  );
};

const PostWrap = styled.div`
  font-size: 13px;
`;

const PostTitle = styled.div`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.fontColor.lightGray};
  }
`;
