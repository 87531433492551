import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { onApartmentContractInfo } from '../utils/onApartmentContractInfo'; // ref가 없는 인덱스 API (=단지 계약 번호(고유 번호 아님))
import { Modal } from '../../apartmentContractInfoSelectModal/Modal'; // ref가 없는 인덱스 모달 (=단지 계약 번호(고유 번호 아님))

import { onLoadBtn } from '../utils/onLoadBtn';
//import { onChangeLocationList } from '../utils/onChangeLocationList';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

import { Grid } from '../Grid/Grid';
import { Button } from '../../UI/Button/Long';

/**
 * permitCode 권한 코드
 * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
 * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
 * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
 */

export const Contents = () => {
  const token = sessionStorage.getItem('token');

  const [areaListData, setAreaListData] = useState([]);
  // 삭제 예정 시작
  //const [apartmentIdx, setApartmentIdx] = useState(''); // 아파트 리스트 인덱스
  //const [apartmentName, setApartmentName] = useState('');
  // 삭제 예정 종료

  const permitCode = sessionStorage.getItem('permitCode');
  const contractIdx = sessionStorage.getItem('contractIdx'); // 관리소장 로그인시 아파트 계약 인덱스
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련

  const [onModal, setOnModal] = useState(false); // MEMO: 아파트 계약 정보 리스트가 있는 모달
  // const [apartmentListData, setApartmentListData] = useState<any[]>([]); // MEMO: 아파트 계약 정보 API
  const [apartmentListContractData, setApartmentListContractData] = useState<
    any[]
  >([]); // MEMO: 아파트 계약 정보 API
  // const [apartmentIdx, setApartmentIdx] = useState(''); // 아파트 리스트 인덱스
  const [apartmentContractIdx, setApartmentContractIdx] = useState(''); // 아파트 계약 인덱스
  const [apartmentName, setApartmentName] = useState('');

  //삭제 예정
  const [apartmentListData, setApartmentListData] = useState<any[]>([]); // MEMO: 아파트 계약 정보 API
// 삭제 예정 종료

  const closeModal = () => {
    setOnModal(false);
  };

  const [dataArray, setDataArray] = useState<any[]>([]);
  const [extractedData, setExtractedData] = useState<any[]>([]);

  const handleSave = async () => {
    const extractedData = dataArray.map((data, idx) => ({
      idx: Number(data.dataIdx),
      NewOrderNo: Number(idx),
    }));

    setExtractedData(extractedData);

    if (extractedData.length <= 0) {
      return;
      // return alert('불러온 목록이 없습니다');
    }

    console.log('extractedData: ', extractedData);

    await axios({
      url: `${process.env.COMMON_URL}/setting/sa20010/workingareaorder/${apartmentContractIdx}?TrackingGroup=W`,
      method: 'put',
      headers: { tokenId: token },
      data: { NewOrderIdx: extractedData },
    }).then(res => {
      // console.log(
      //   '[SA20-010] 근무 구역 설정 순서 수정 API: ',
      //   `${process.env.COMMON_URL}/setting/sa20010/workingareaorder/${apartmentContractIdx}?TrackingGroup=W`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          alert('변경이 완료되었습니다');
          onLoadBtn({ setAreaListData, apartmentContractIdx });
          return;
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
  };

  // 👇 [TAG] 권한에 따른 UI 변경 관련
  function onPermitCodeView() {
    if (permitCode === apartmentManagerCode) {
      // 관리소장 권한시: 계약 단지 선택
      useEffect(() => {
        onApartmentContractInfo({
          apartmentListContractData,
          setApartmentListContractData,
          setOnModal,
        });
      }, []);
    }
    useEffect(() => {
      if (apartmentContractIdx) {
        onLoadBtn({
          setAreaListData,
          apartmentContractIdx
        })
      }
    }, [apartmentContractIdx]);
    //console.log('apartmentContractIdx: ', apartmentContractIdx);
  }
  onPermitCodeView();

  function onClickedButton(item) {
    setApartmentContractIdx(item.idx);
    setApartmentName(item.Name);
  }

  return (
    <>
      {/* 관리자 권한시: 계약 단지 선택 */}
      {permitCode === headOfficerManagerCode && onModal && (
        <Modal
          closeModal={closeModal}
          apartmentListContractData={apartmentListContractData}
          setApartmentListContractData={setApartmentListContractData}
          apartmentContractIdx={apartmentContractIdx}
          setApartmentContractIdx={setApartmentContractIdx}
          apartmentName={apartmentName}
          setApartmentName={setApartmentName}
        />
      )}

      <Title>근무 구역 설정</Title>

      <Section>
        <InfoFunctionSection>
          <LoadInfoSection>
            <LoadBtnSection>
              {permitCode === headOfficerManagerCode && (
                <div
                  style={{
                    display: 'flex',
                    marginRight: '5px'
                    // marginLeft: '5px',
                  }}
                >
                  <Button
                    label="계약 단지 선택"
                    onClick={() =>
                      onApartmentContractInfo({
                        apartmentListContractData,
                        setApartmentListContractData,
                        setOnModal,
                      })
                    }
                  />

                  <ApartmentNameBox>
                    <ApartmentIdx style={{ marginRight: '5px' }}>
                      {apartmentContractIdx}
                    </ApartmentIdx>
                    <ApartmentName>{apartmentName}</ApartmentName>
                  </ApartmentNameBox>
                </div>
              )}
              {permitCode === apartmentManagerCode && apartmentListContractData.map((item, key) => {
                  return (
                    <div
                      key={key}
                      onClick={() => {
                        onClickedButton(item);
                      }}
                      style={{ fontSize: '13px' }}
                    >
                      <PrintBtn
                        style={{
                          fontSize: '13px',
                          marginRight: '5px',
                        }}
                      >
                        {item.JobGroupEtc}
                      </PrintBtn>
                    </div>
                  );
              })}
            </LoadBtnSection>
            <ApartmentNameBox>
              {/* <p>단지 이름</p> */}
              <ApartmentIdx style={{ marginRight: '5px' }}>
                {/* {apartmentIdx} */}
              </ApartmentIdx>
              <ApartmentName>{/* {apartmentName} */}</ApartmentName>
            </ApartmentNameBox>

          </LoadInfoSection>
          <SaveAreaBtnSection>
            <SaveBtn
              className={`${extractedData.length <= 0 ? 'off' : 'on'}`}
              onClick={() => handleSave()}
            >
              순서 변경 저장하기
            </SaveBtn>
          </SaveAreaBtnSection>
        </InfoFunctionSection>
        <LoadedDataSection>
          <Grid
            areaListData={areaListData}
            apartmentName={apartmentName}
            apartmentIdx={apartmentContractIdx}
            dataArray={dataArray}
            setDataArray={setDataArray}
            extractedData={extractedData}
            setExtractedData={setExtractedData}
          />
        </LoadedDataSection>
      </Section>
    </>
  );
};

const Bar = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const Title = styled.div`
  ${({ theme }) => theme.contentsMainTitle};
  ${({ theme }) => theme.stopDrag};
`;

const Section = styled.div`
  ${({ theme }) => theme.stopDrag};
`;

const InfoFunctionSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30 0 30px;
  height: 40px;
  font-size: 13px;
`;

const PrintBtn = styled.button`
  ${({ theme }) => theme.printBtnStyle};
`;

const SaveAreaBtnSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadInfoSection = styled.div`
  display: flex;
  /* justify-content: space-between; */
  height: 40px;
`;

const LoadBtnSection = styled.div`
  display: flex;
  align-items: center;
`;

const ApartmentNameBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;

  p {
    color: ${({ theme }) => theme.fontColor.darkGray};
  }
`;

const ApartmentIdx = styled.div`
  color: ${({ theme }) => theme.mainColor.main};
`;

const ApartmentName = styled.div`
  /* font-weight: 600; */
`;

const LoadedDataSection = styled.div`
  /* padding: 20px; */
  /* background-color: gray; */
`;

const SaveBtn = styled.div`
  ${({ theme }) => theme.printBtnStyle};
  padding: 8px 10px;

  &.on {
    /*  */
  }

  &.off {
    cursor: default;

    :hover {
      background-color: #ededed;
    }
  }
`;
