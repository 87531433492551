// [MEMO] 주간 근무 현황 컴포넌트
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { Scheduler } from './Scheduler/Scheduler';

interface WeeklySectionProps {
  employeeData: any[];
  apartmentContractIdx: string; // 🚨수정 구현중-임의로 추가
  calYear: string;
  calMonth: string;
  calDate: string;
  schedulerLoading: boolean;
  setIsUpdateStatus: Dispatch<SetStateAction<boolean>>;
}

const WeeklySection = ({
  employeeData,
  apartmentContractIdx, // 🚨수정 구현중-임의로 추가
  calYear,
  calMonth,
  calDate,
  schedulerLoading,
  setIsUpdateStatus,
}: WeeklySectionProps) => {
  if (schedulerLoading) {
    return (
      <>
        <Bar />
        <LoadingWrap>
          <LoadingGIF src="/loading.gif" />
        </LoadingWrap>
      </>
    );
  }

  return (
    <>
      <Scheduler
        employeeData={employeeData}
        apartmentContractIdx={apartmentContractIdx}
        calYear={calYear}
        calMonth={calMonth}
        calDate={calDate}
        setIsUpdateStatus={setIsUpdateStatus}
      />
    </>
  );
};

export default WeeklySection;

const Bar = styled.div`
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const LoadingGIF = styled.img`
  ${({ theme }) => theme.loadingGIF}
`;
