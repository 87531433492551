/* eslint-disable prefer-const */
// 월간 스케줄러
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { minutesToHours } from '../../../../lib/utils/minutesToHours';
import { onLoadWorkRecordInfo } from '../utils/onLoadWorkRecordInfo'; // 태그 기록 불러오기
import { onLoadDetailWorkInfo } from '../utils/onLoadDetailWorkInfo'; // 근무 상세 기록 불러오기

import { RightClickModalAtExistent } from '../RightClickModal/RightClickModalAtExistent'; // 데이터 있는 셀 우클릭시 모달 - 수정 관련
import { RightClickModalAtEmpty } from '../RightClickModal/RightClickModalAtEmpty'; // 빈 셀 우클릭시 모달 - 신규 등록 관련
import { ModifyModal } from '../ModifyModal/ModifyModal'; // 근무 시간 수정 관련 모달
import { AddModal } from '../AddModal/AddModal'; // 근무 시간 추가 관련 모달

interface SchedulerProps {
  employeeData: any[];
  apartmentContractIdx: string;
  calYear: string;
  calMonth: string;
  setIsUpdateStatus: Dispatch<SetStateAction<boolean>>;
}

interface statusInfoType {
  idx?: string; // =statusIdx
  AttendStatusCode?: string; // =statusCode
  Working?: string; // =statusActionType
  AttendStatusName?: string; // =statusName
  TriggerTimeStart?: string; // =startTime
  TriggerTimeEnd?: string; // =endTime
}

/*
Property 'statusInfo' does not exist on type
'{
  date?: number | undefined;
  timeToWork?: string | undefined;
  timeToHome?: string | undefined;
  workingTime?: string | undefined;
  isNextDateTimeToHome?: boolean | undefined;
  statusCode?: string | undefined;
}'.ts(2339)
*/

interface statusInfoViewType {
  date?: number;
  timeToWork?: string;
  timeToHome?: string;
  workingTime?: string;
  isNextDateTimeToHome?: boolean;
  statusCode?: string;

  // startTime?: boolean;
  // endTime?: boolean;
  // statusActionType?: string;
}

export const Scheduler = ({
  employeeData,
  apartmentContractIdx,
  calYear,
  calMonth,
  setIsUpdateStatus,
}: SchedulerProps) => {
  // console.log('[AC10-030]조직원 근무-월간 employeeData: ', employeeData);

  const MONTHS = [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ];
  const DAYS = ['일', '월', '화', '수', '목', '금', '토'];

  /**
   * [MEMO] targetMonth:
   * Date객체의 월 인덱스는 0부터 시작하기 때문에, 데이터 피커에서 가져 온 달에서 1을 빼야 함
   */
  const targetYear = Number(calYear);
  const targetMonth = Number(calMonth) - 1;

  const date = new Date(targetYear, targetMonth);

  const firstDayOfMonth = new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  ).getDay();
  // const firstDayOfMonth = new Date(date).getDay(); // [MEMO] const firstDayOfMonth = new Date(...) 혹은 해당 firstDayOfMonth로도 요일 표현 가능

  const lastDateOfMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();

  // [MEMO] headerArray: 헤더 & 화면에 나타나는 달 정보(날짜, 요일)
  let headerArray: {
    date: number;
    day: string;
  }[] = [];

  for (let i = 1; i <= lastDateOfMonth; i++) {
    const dayOfMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      i
    ).getDay();

    headerArray.push({
      date: i,
      // day: DAYS[(firstDayOfMonth + i - 1) % 7], // [MEMO] firstDayOfMonth로도 요일 표현 가능
      day: DAYS[dayOfMonth],
    });
  }

  const dataColorTagList = {
    working: '#57d49e',
    breaking: '#ffc936',
    notWorking: '#ff6038',
    timeToWork: 'rgba(168, 242, 167, 0.3)', // 출근
    timeToHome: 'rgba(252, 164, 164, 0.3)', // 퇴근
  };

  /**
   * 변수명 변경
   * dataArray -> processedEmployeeData
   * (dataArray에 속한) dayArray -> dayData
   */
  let processedEmployeeData: {
    dayData: {
      // date?: number | undefined;
      // timeToWork?: string | undefined;
      // timeToHome?: string | undefined;
      // workingTime?: string | undefined;
      // isNextDateTimeToHome?: boolean | undefined;
      // statusCode?: string | undefined;

      date?: number | undefined;
      employeeName?: string | undefined;
      employeeNum?: string | undefined;
      timeToWork?: string | undefined;
      timeToHome?: string | undefined;
      workingTime?: string | undefined;
      isNextDateTimeToHome?: boolean | undefined;
      statusInfo?: {
        statusIdx?: number | undefined;
        statusCode?: string | undefined;
        statusActionType?: string | undefined;
        statusName?: string | undefined;
        startTime?: string | undefined;
        endTime?: string | undefined;
      };
      statusTardy?: boolean | undefined;
      statusLeave?: boolean | undefined;
      changedRecord?: string | undefined;
      deleteRecord?: boolean | undefined;

      // date?: number;
      // timeToWork?: string;
      // timeToHome?: string;
      // workingTime?: string;
      // isNextDateTimeToHome?: boolean; // 퇴근 날이 다음 날짜인지 체크 (true: 다음날 퇴근)
      // statusCode?: string; // 상태(근무, 연차 등) 코드
      // statusInfo: {};
    }[];
    employeeName?: string | undefined;
    employeeNum?: string | undefined;
    totalWorkingTime?: number | undefined; // [MEMO] 총 근무시간
  }[] = [];

  function loadEmployeeData() {
    employeeData.map(item => {
      let employeeDayArray: {
        date?: number;
        employeeName?: string;
        employeeNum?: string;
        timeToWork?: string;
        timeToHome?: string;
        workingTime?: string;
        isNextDateTimeToHome?: boolean;
        statusInfo?: {
          statusIdx?: number;
          statusCode?: string;
          statusActionType?: string;
          statusName?: string;
          startTime?: string;
          endTime?: string;
        };
        statusTardy?: boolean;
        statusLeave?: boolean;
        changedRecord?: string;
        deleteRecord?: boolean;
      }[] = [];

      let totalWorkingTime = 0; // [MEMO] 총 근무시간

      if (item.WorkingDate.length > 0) {
        let idx = 0;

        for (
          let processDate = 0;
          processDate < lastDateOfMonth;
          processDate++
        ) {
          if (
            processDate + 1 ===
            Number(item.WorkingDate[idx].WorkingDate.slice(-2))
          ) {
            // [MEMO] 👇 총 근무 시간
            totalWorkingTime =
              totalWorkingTime + Number(item.WorkingDate[idx].WorkingTime);

            // [TAG] 👇 현재 시간 체크를 위한 조건 -----start
            if (item.WorkingDate[idx].WorkingData[0].EndTimeDay) {
              //  조건: 다음날 퇴근

              let resultDate;

              if (item.WorkingDate[idx].WorkingData.length > 1) {
                resultDate = {
                  date: processDate + 1,

                  employeeName: item.UserNameKR, // 🍟
                  employeeNum: item.EmployeeNo, // 🍟

                  timeToWork:
                    item.WorkingDate[idx].WorkingData[0].TriggerTimeStart, //출근
                  timeToHome:
                    item.WorkingDate[idx].WorkingData[0].TriggerTimeEnd, // 퇴근
                  workingTime: item.WorkingDate[idx].WorkingTime, // 근무시간
                  isNextDateTimeToHome: true, // 퇴근 날이 다음 날짜인지 체크 (true: 다음날 퇴근)

                  statusInfo: (function () {
                    return item.WorkingDate[idx].WorkingData.map(
                      (d: statusInfoType) => ({
                        statusIdx: d.idx,
                        statusCode: d.AttendStatusCode,
                        statusActionType: d.Working,
                        statusName: d.AttendStatusName,
                        startTime: d.TriggerTimeStart,
                        endTime: d.TriggerTimeEnd,
                      })
                    );
                  })(),

                  statusTardy: item.WorkingDate[idx].WorkingData[0].StatusTardy,
                  statusLeave: item.WorkingDate[idx].WorkingData[0].StatusLeave,
                  changedRecord:
                    item.WorkingDate[idx].WorkingData[0].ChangedRecord,
                  deleteRecord:
                    item.WorkingDate[idx].WorkingData[0].DeleteRecord,
                };
              } else {
                resultDate = {
                  date: processDate + 1,
                  employeeName: item.UserNameKR, // 🍟
                  employeeNum: item.EmployeeNo, // 🍟

                  timeToWork:
                    item.WorkingDate[idx].WorkingData[0].TriggerTimeStart, //출근
                  timeToHome:
                    item.WorkingDate[idx].WorkingData[0].TriggerTimeEnd, // 퇴근
                  workingTime: item.WorkingDate[idx].WorkingTime, // 근무시간
                  isNextDateTimeToHome: true, // 퇴근 날이 다음 날짜인지 체크 (true: 다음날 퇴근)

                  statusInfo: [
                    {
                      statusIdx: item.WorkingDate[idx].WorkingData[0].idx,
                      statusCode:
                        item.WorkingDate[idx].WorkingData[0].AttendStatusCode,
                      statusActionType:
                        item.WorkingDate[idx].WorkingData[0].Working,
                      statusName:
                        item.WorkingDate[idx].WorkingData[0].AttendStatusName,
                      startTime:
                        item.WorkingDate[idx].WorkingData[0].TriggerTimeStart,
                      endTime:
                        item.WorkingDate[idx].WorkingData[0].TriggerTimeEnd,
                    },
                  ],

                  statusTardy: item.WorkingDate[idx].WorkingData[0].StatusTardy, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
                  statusLeave: item.WorkingDate[idx].WorkingData[0].StatusLeave, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
                  changedRecord:
                    item.WorkingDate[idx].WorkingData[0].ChangedRecord, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
                  deleteRecord:
                    item.WorkingDate[idx].WorkingData[0].DeleteRecord, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
                };
              }

              employeeDayArray.push(resultDate);
            }
            // [TAG] 👆 현재 시간 체크를 위한 조건 -----end
            else {
              let resultDate;

              if (item.WorkingDate[idx].WorkingData.length > 1) {
                // console.log(
                //   '! 근무, 휴식 혼합 !-item.WorkingDate[idx].WorkingData: ',
                //   item.WorkingDate[idx].WorkingData
                // );

                resultDate = {
                  date: processDate + 1,
                  employeeName: item.UserNameKR, // 🍟 근무자 이름
                  employeeNum: item.EmployeeNo, // 🍟 근무자 사번

                  timeToWork:
                    item.WorkingDate[idx].WorkingData[0].TriggerTimeStart, //출근
                  timeToHome:
                    item.WorkingDate[idx].WorkingData[0].TriggerTimeEnd, // 퇴근
                  workingTime: item.WorkingDate[idx].WorkingTime, // 근무시간
                  isNextDateTimeToHome: true, // 퇴근 날이 다음 날짜인지 체크 (true: 다음날 퇴근)

                  statusInfo: (function () {
                    return item.WorkingDate[idx].WorkingData.map(d => ({
                      statusIdx: d.idx,
                      statusCode: d.AttendStatusCode,
                      statusActionType: d.Working,
                      statusName: d.AttendStatusName,
                      startTime: d.TriggerTimeStart,
                      endTime: d.TriggerTimeEnd,
                    }));
                  })(),

                  statusTardy: item.WorkingDate[idx].WorkingData[0].StatusTardy, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
                  statusLeave: item.WorkingDate[idx].WorkingData[0].StatusLeave, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
                  changedRecord:
                    item.WorkingDate[idx].WorkingData[0].ChangedRecord, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
                  deleteRecord:
                    item.WorkingDate[idx].WorkingData[0].DeleteRecord, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
                };
              } else {
                resultDate = {
                  date: processDate + 1,
                  employeeName: item.UserNameKR, // 🍟 근무자 이름
                  employeeNum: item.EmployeeNo, // 🍟 근무자 사번

                  timeToWork:
                    item.WorkingDate[idx].WorkingData[0].TriggerTimeStart, //출근
                  timeToHome:
                    item.WorkingDate[idx].WorkingData[0].TriggerTimeEnd, // 퇴근
                  workingTime: item.WorkingDate[idx].WorkingTime, // 근무시간

                  statusInfo: [
                    {
                      statusIdx: item.WorkingDate[idx].WorkingData[0].idx,
                      statusCode:
                        item.WorkingDate[idx].WorkingData[0].AttendStatusCode,
                      statusName:
                        item.WorkingDate[idx].WorkingData[0].AttendStatusName,
                      statusActionType:
                        item.WorkingDate[idx].WorkingData[0].Working,
                      startTime:
                        item.WorkingDate[idx].WorkingData[0].TriggerTimeStart,
                      endTime:
                        item.WorkingDate[idx].WorkingData[0].TriggerTimeEnd,
                    },
                  ],

                  statusTardy: item.WorkingDate[idx].WorkingData[0].StatusTardy, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
                  statusLeave: item.WorkingDate[idx].WorkingData[0].StatusLeave, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
                  changedRecord:
                    item.WorkingDate[idx].WorkingData[0].ChangedRecord, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
                  deleteRecord:
                    item.WorkingDate[idx].WorkingData[0].DeleteRecord, // 근무 수정 관련 속성🚨수정 구현중-임의로 추가
                };
              }
              employeeDayArray.push(resultDate);
            }

            if (idx < item.WorkingDate.length - 1) {
              idx++;
            }
          } else {
            const resultDate = {
              date: processDate + 1,
              employeeName: item.UserNameKR, // 🍟 근무자 이름
              employeeNum: item.EmployeeNo, // 🍟 근무자 사번

              statusName: '근무없음',
              timeToWork: '',
              timeToHome: '',
              workingTime: '',
            };

            employeeDayArray.push(resultDate);
          }
        }

        idx = 0;
      } else {
        for (
          let processDate = 0;
          processDate < lastDateOfMonth;
          processDate++
        ) {
          let resultDate = {
            date: processDate + 1,
            employeeName: item.UserNameKR, // 🍟 근무자 이름
            employeeNum: item.EmployeeNo, // 🍟 근무자 사번

            timeToWork: '',
            timeToHome: '',
            statusName: '근무없음',
            workingTime: '',
          };

          employeeDayArray.push(resultDate);
        }
      }

      let result = {
        dayData: employeeDayArray,
        employeeName: item.UserNameKR,
        employeeNum: item.EmployeeNo,
        totalWorkingTime: totalWorkingTime, // [MEMO] 총 근무시간
      };

      processedEmployeeData.push(result);
    });
  }

  loadEmployeeData();

  console.log('🍖월간🍖processedEmployeeData: ', processedEmployeeData);

  // [TAG] 👇 근무 시간 추가 -----start
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [rightClickModalVisibleAtEmpty, setRightClickModalVisibleAtEmpty] =
    useState(false);

  const closeAddModal = () => {
    setAddModalVisible(false);
  };

  const onRightClickAtEmpty = (
    e: React.MouseEvent,
    employeeNum?: string | undefined,
    row: any,
    data: any // 😈임시
  ) => {
    setRowInfo(row);
    setCellData(data);

    setTargetEmployeeNum(employeeNum);

    // onLoadDetailWorkInfo({
    //   employeeNum,
    //   setDetailWorkData,
    // });

    const position = { x: e.clientX, y: e.clientY };
    setRightClickModalPosition(position);
    setRightClickModalVisibleAtEmpty(true);
  };
  // [TAG] 👆 근무 시간 추가 -----end

  // [TAG] 👇 근무 시간 수정 -----start
  const [modifyModalVisible, setModifyModalVisible] = useState(false);
  const [
    rightClickModalVisibleAtExistent,
    setRightClickModalVisibleAtExistent,
  ] = useState(false);

  const closeModifyModal = () => {
    setModifyModalVisible(false);
  };

  const onRightClickAtExisting = (
    e: React.MouseEvent,
    statusIdx: string,
    employeeNum: string,
    row: any,
    data: any // 😈임시
  ) => {
    setRowInfo(row);
    setCellData(data);

    setTargetEmployeeNum(employeeNum);
    onLoadWorkRecordInfo({
      row,
      apartmentContractIdx,
      calYear,
      calMonth,
      calDate: data.date,
      workRecordData,
      setWorkRecordData,
      setOnWorkRecordModal,
    });

    // onLoadDetailWorkInfo({
    //   employeeNum,
    //   statusIdx,
    //   setDetailWorkData,
    // });

    const position = { x: e.clientX, y: e.clientY };
    setRightClickModalPosition(position);
    setRightClickModalVisibleAtExistent(true);
  };
  // [TAG] 👆 근무 시간 수정 -----end

  // [TAG] 👇 근태 기록 확인 -----start
  const [onWorkRecordModal, setOnWorkRecordModal] = useState(false);
  const [workRecordData, setWorkRecordData] = useState([]);

  const closeModal = () => {
    setOnWorkRecordModal(false);
  };
  // [TAG] 👆 근태 기록 확인-----end

  const [detailWorkData, setDetailWorkData] = useState([]);
  const [rowInfo, setRowInfo] = useState([]);
  const [cellData, setCellData] = useState({});
  const [rightClickModalPosition, setRightClickModalPosition] = useState({
    x: 0,
    y: 0,
  });
  const [targetEmployeeNum, setTargetEmployeeNum] = useState('');
  // const [targetWorkStatusIdx, setTargetWorkStatusIdx] = useState<string | null>(
  //   null
  // );

  const handleRightClickModal = () => {
    setRightClickModalVisibleAtExistent(false);
    setRightClickModalVisibleAtEmpty(false);
  };

  const handleStatusBarClick = (employeeNum: string) => {
    setTargetEmployeeNum(employeeNum);

    // onLoadDetailWorkInfo({
    //   employeeNum,
    //   idx,
    //   setDetailWorkData,
    // });
  };

  // -----
  const [selectedStatusIdx, setSelectedStatusIdx] = useState('');
  // const [selectedStatusCode, setSelectedStatusCode] = useState('');
  // console.log('💚월간1💚selectedStatusIdx::: ', selectedStatusIdx);
  // console.log('💚월간2💚selectedStatusCode::: ', selectedStatusCode);

  return (
    <>
      {rightClickModalVisibleAtExistent && (
        <RightClickModalAtExistent
          closeModal={handleRightClickModal}
          setOnWorkRecordModal={setOnWorkRecordModal}
          setModifyModalVisible={setModifyModalVisible}
          position={rightClickModalPosition}
          workRecordData={workRecordData}
          detailWorkData={detailWorkData}
          rowInfo={rowInfo}
          setAddModalVisible={setAddModalVisible}
          cellData={cellData} // 💥
        />
      )}

      {rightClickModalVisibleAtEmpty && (
        <RightClickModalAtEmpty
          closeModal={handleRightClickModal}
          setAddModalVisible={setAddModalVisible}
          position={rightClickModalPosition}
          detailWorkData={detailWorkData}
          rowInfo={rowInfo}
          cellData={cellData} // 💥
        />
      )}

      {modifyModalVisible && (
        <ModifyModal
          closeModal={closeModifyModal}
          setRightClickModalVisible={setRightClickModalVisibleAtExistent}
          setIsUpdateStatus={setIsUpdateStatus}
          detailWorkData={cellData}
          cellData={cellData}
          employeeNum={targetEmployeeNum}
          calYear={calYear}
          calMonth={calMonth}
          selectedStatusIdx={selectedStatusIdx} // 🔰
          setSelectedStatusIdx={setSelectedStatusIdx} // 🔰
        />
      )}

      {addModalVisible && (
        <AddModal
          closeModal={closeAddModal}
          setRightClickModalVisible={setRightClickModalVisibleAtEmpty}
          setIsUpdateStatus={setIsUpdateStatus}
          cellData={cellData}
          employeeNum={targetEmployeeNum}
          calYear={calYear}
          calMonth={calMonth}
        />
      )}

      {/* 👇 [TAG] 본문 태그 색상 안내(어떤 상태인지) -----start */}
      {/* <Bar />
      <GuideSection>
        <GuideBox>
          <p style={{ backgroundColor: `${dataColorTagList.working}` }} />
          근무
        </GuideBox>
        <GuideBox>
          <p style={{ backgroundColor: `${dataColorTagList.timeToWork}` }} />
          출근
        </GuideBox>
        <GuideBox>
          <p style={{ backgroundColor: `${dataColorTagList.timeToHome}` }} />
          퇴근
        </GuideBox>
      </GuideSection> */}
      {/* 👆 [TAG] 본문 태그 색상 안내(어떤 상태인지) -----end */}

      <Bar />
      <TableWrap>
        <Tables>
          <THead>
            <tr>
              <ThEmployeeNameHeader>{/* 근무자 */}</ThEmployeeNameHeader>
              <ThTotalWorkingTimeHeader>
                {/* 총 근무시간 */}
                <div />
              </ThTotalWorkingTimeHeader>

              {headerArray.map((data, index) => (
                <ThDateHeader
                  key={index}
                  className={`${
                    index === lastDateOfMonth - 1 ? 'last-date' : ''
                  }`}
                >
                  {data.day === '일' ? (
                    <>
                      <div className="sunday">{data.date}</div>
                      <p className="sunday">{data.day}</p>
                    </>
                  ) : (
                    <>
                      <div>{data.date}</div>
                      <p>{data.day}</p>
                    </>
                  )}
                </ThDateHeader>
              ))}
            </tr>
          </THead>

          <tbody>
            {processedEmployeeData.map((row, index) => (
              <TrForBody key={index}>
                <TdEmployeeName>{row.employeeName}</TdEmployeeName>

                {/* 👇 총 근무시간 */}
                <TdTotalWorkingTime>
                  {row.totalWorkingTime > 0 ? (
                    <div>
                      <p>{minutesToHours(row.totalWorkingTime)} h</p>
                    </div>
                  ) : (
                    <div />
                  )}
                </TdTotalWorkingTime>
                {/* 👆 총 근무시간 */}

                {row.dayData.map((data, dayDataIndex) => {
                  console.log('🎏🎏🎏data.idx::: ', data.idx);
                  return (
                    <>
                      {/* [MEMO] 분기 처리 이전(데이터 있고/없고 나누기 이전)으로 되돌리려면:
                       * 데이터 없는 영역에 해당되는 코드를 삭제 후,
                       * 데이터 있는 영역의 코드 중, 조건부 랜더링(data.statusInfo &&)을 삭제하면 된다.
                       */}
                      {/* 👇 데이터 있는 영역 -----start */}
                      {data.statusInfo && (
                        <TdTimeInfo
                          className={`${
                            dayDataIndex === lastDateOfMonth - 1
                              ? 'last-date'
                              : ''
                          }`}
                          onClick={() => handleStatusBarClick(row.employeeNum)}
                          onContextMenu={e => {
                            e.preventDefault(),
                              onRightClickAtExisting(
                                e,
                                data.statusIdx,
                                row.employeeNum,
                                row,
                                data // 😈임시
                              );
                          }}
                        >
                          {data.statusInfo.map((d, idx: number) => {
                            let elements = [];

                            if (d.statusCode === 'W1000' && d.startTime) {
                              elements.push(
                                <div key={0 + idx} className="time-to-work">
                                  <p>출근</p>
                                  {d.startTime}
                                </div>
                              );
                            }
                            if (d.statusCode === 'W1000' && d.endTime) {
                              elements.push(
                                <div key={1 + idx} className="time-to-home">
                                  <p>퇴근</p>
                                  {d.endTime}
                                </div>
                              );
                            }
                            if (d.statusCode === 'H1000') {
                              return (
                                <div
                                  key={2 + idx}
                                  className="day-off"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p>연차</p>
                                  <p style={{ fontWeight: '500' }}>
                                    {d.statusActionType === 'A' && '종일'}
                                    {d.statusActionType === 'H' && '반차'}
                                  </p>
                                  {/* <p style={{ fontSize: '8px' }}>
                                  {d.startTime}-{d.endTime}
                                </p> */}
                                </div>
                              );
                            }
                            if (d.statusCode === 'H2000') {
                              return (
                                <div
                                  key={3 + idx}
                                  className="day-off"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p>월차</p>
                                  <p style={{ fontWeight: '500' }}>
                                    {d.statusActionType === 'A' && '종일'}
                                    {d.statusActionType === 'H' && '반차'}
                                  </p>
                                  {/* <p style={{ fontSize: '8px' }}>
                                  {d.startTime}-{d.endTime}
                                </p> */}
                                </div>
                              );
                            }
                            if (d.statusCode === 'H3000') {
                              return (
                                <div
                                  key={4 + idx}
                                  className="day-off"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <p>대휴</p>
                                  <p style={{ fontWeight: '500' }}>
                                    {d.statusActionType === 'A' && '종일'}
                                    {d.statusActionType === 'H' && '반차'}
                                  </p>
                                  {/* <p style={{ fontSize: '8px' }}>
                                  {d.startTime}-{d.endTime}
                                </p> */}
                                </div>
                              );
                            }

                            return elements;
                          })}
                        </TdTimeInfo>
                      )}
                      {/* 👆 데이터 있는 영역 -----end */}

                      {/* 👇 데이터 없는 영역 -----start */}
                      {!data.statusInfo && (
                        <TdTimeInfo
                          className={`${
                            dayDataIndex === lastDateOfMonth - 1
                              ? 'last-date'
                              : ''
                          }`}
                          onClick={() =>
                            handleStatusBarClick(data.idx, row.employeeNum)
                          }
                          onContextMenu={e => {
                            e.preventDefault(),
                              onRightClickAtEmpty(
                                e,
                                row.employeeNum,
                                // 👇 [MEMO] row: onLoadWorkRecordInfo를 위한 (태그 정보 목록 모달을 위한)
                                row,
                                data
                              );
                          }}
                        ></TdTimeInfo>
                      )}
                      {/* 👆 데이터 없는 영역 -----end */}
                    </>
                  );
                })}
              </TrForBody>
            ))}
          </tbody>
        </Tables>
      </TableWrap>
    </>
  );
};

const Bar = styled.div`
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const TableWrap = styled.div`
  ${({ theme }) => theme.stopDrag};
  ${({ theme }) => theme.scrollBarStyle};

  width: 100%;
  overflow-x: auto;
`;

const Tables = styled.table`
  background-color: white;
  width: 100%;
  table-layout: fixed;
  font-size: 13px;
`;

const THead = styled.thead`
  background-color: white;
  height: 60px;
  font-weight: 500;
`;

export const ThEmployeeNameHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 170px; /* [MEMO] 이름 너비 */
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 0; /* sticky 적용을 위한 코드 */
  background-color: white; /* sticky 적용을 위한 코드 */
`;

export const ThTotalWorkingTimeHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 80px; /* [MEMO] 총 근무시간 너비 */
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /**
   * sticky 적용을 위한 코드 - border-right 주석 처리:
   * sticky 적용을 위해 ThTotalWorkingTimeHeader에 div 요소를 추가하고 div 요소 내에 border-right를 적용했기에,
   * 이곳의 border-right는 주석 처리함.
   * sticky 적용이 필요 없다면 div 요소 자체를 제거한 후, 이곳의 border-right를 활성화 할 것.
   */
  /* border-right: solid 1px ${({ theme }) =>
    theme.backgroundColor.shadowGray}; */
  color: ${({ theme }) => theme.fontColor.darkGray};

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 170px; /* sticky 적용을 위한 코드, ThEmployeeNameHeader의 너비가 170px */
  background-color: white; /* sticky 적용을 위한 코드 */

  div {
    /**
      * sticky 적용을 위한 코드
      * 1-1. ThTotalWorkingTimeHeader 내 div 요소:
      * 오직 sticky 적용을 위해 필요한 요소. sticky 적용이 필요 없다면 div 요소 자체를 제거할 것.
      * 1-2. div 요소 내 border-right, height:
      * border-right 스타일을 가시화하기 위해 넣은 것
     */
    height: 60px;
    border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  }
`;

const ThDateHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 93px; /* [MEMO] 날짜 너비 */
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /**
   * sticky 적용을 위한 코드 - border-right:
   * 기존의 border-left를 삭제한 후 border-right 스타일을 적용함.
   * sticky 적용 필요 없다면 (border-right 삭제 후) border-left를 적용해도 됨.
   * + border-right 삭제할 경우 last-date 내 border-right 스타일도 제거할 것.
   */
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  color: ${({ theme }) => theme.fontColor.lightGray};
  font-size: 11px;
  font-weight: 400;

  &.last-date {
    border-right: none;
  }

  div {
    margin-bottom: 5px;
    color: ${({ theme }) => theme.fontColor.darkGray};
    font-size: 18px;
    font-weight: 500;

    &.sunday {
      color: ${({ theme }) => theme.pointColor.red};
    }
  }

  p {
    &.sunday {
      color: ${({ theme }) => theme.pointColor.red};
    }
  }
`;

const TrForBody = styled.tr`
  /* 바디에 있는 td 요소(바디에 있는 셀들) 높이 설정 */
  height: 75px;
`;

const TdEmployeeName = styled.td`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  /* text-align: center; */ /* 테이블 셀 중앙정렬 */
  text-align: start;
  padding-left: 30px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};
  font-weight: 500;

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 0; /* sticky 적용을 위한 코드 */
  background-color: white; /* sticky 적용을 위한 코드 */
`;

const TdTotalWorkingTime = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /* sticky 적용을 위한 코드 -  border-right 주석 처리:
   * sticky 적용을 위해 주석처리 함.
   * sticky 미적용을 원할 경우, div 요소 내 border-right를 지운 후, 이곳에 border-right를 활성화 시키면 됨
   */
  /* border-right: solid 1px ${({ theme }) =>
    theme.backgroundColor.shadowGray}; */
  color: white;
  font-weight: 500;

  position: sticky; /* sticky 적용을 위한 코드 */
  left: 170px; /* sticky 적용을 위한 코드, TdEmployeeName의 너비가 170px */
  background-color: white; /* sticky 적용을 위한 코드 */

  div {
    display: flex;
    justify-content: start;
    align-items: center;

    /**
      * sticky 적용을 위한 코드- div 요소 내 height, border-right:
      * border-right 스타일을 가시화하기 위해 넣은 것.
      * sticky 적용 필요 없다면 불필요.
     */
    height: 75px;
    border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};

    p {
      padding: 4px 6px;
      border-radius: 0.5em;
      background-color: ${({ theme }) => theme.statusColor.working};
    }
  }
`;

export const TdTimeInfo = styled.td`
  padding: 5px;
  padding-top: 0;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /**
   * sticky 적용을 위한 코드 - border-right:
   * border-left대신 border-right에 스타일을 적용함.
   * sticky 적용 필요 없다면 (border-right 삭제 후) border-left를 적용해도 됨.
   * + border-right 삭제할 경우 &.last-date 내 border-right 스타일도 제거할 것.
   */
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  font-size: 12px;
  line-height: 1.5;

  &.last-date {
    border-right: none;
  }

  div {
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
    font-weight: 500;

    &.time-to-work {
      /* [MEMO] 출근 태그 */
      display: flex;
      margin-top: 5px;
      background: ${({ theme }) => theme.statusColor.startWorkBackground};
      color: ${({ theme }) => theme.fontColor.darkGray};

      p {
        margin-right: 5px;
        font-weight: 600;
      }
    }

    &.time-to-home {
      /* [MEMO] 퇴근 태그 */
      display: flex;
      margin-top: 5px;
      background: ${({ theme }) => theme.statusColor.endWorkBackground};
      color: ${({ theme }) => theme.fontColor.darkGray};

      p {
        margin-right: 5px;
        font-weight: 600;
      }
    }

    &.working {
      /* [MEMO] 근무 태그 */
      display: flex;
      margin-top: 5px;
      background-color: ${({ theme }) => theme.statusColor.working};
      color: white;

      p {
        margin-right: 5px;
        font-weight: 600;
      }
    }

    &.day-off {
      /* [MEMO] 각종 연차 태그(연차, 월차, 반차 등) */
      display: flex;
      margin-top: 5px;
      background-color: ${({ theme }) => theme.statusColor.dayOffBackground};
      color: ${({ theme }) => theme.fontColor.darkGray};

      p {
        margin-right: 5px;
        font-weight: 600;
      }
    }
  }
`;
