import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface onApartmentSerialNumberListProps {
  apartmentListData: any[];
  setApartmentListData: Dispatch<SetStateAction<any[]>>;
  setOnModal: Dispatch<SetStateAction<boolean>>;
}
/**
 * ref 있으면 -> 일반 단지 인덱스, 단지 고유 인덱스(계약 아님)
 * 등록차량관리, 차량주차관리는 ref 있는 API로 해야 함.
 */

export const onApartmentSerialNumberList = async ({
  apartmentListData,
  setApartmentListData,
  setOnModal,
}: onApartmentSerialNumberListProps) => {
  const token = sessionStorage.getItem('token');

  await axios
    .get(
      // 'https://apihr.mmigroup.co.kr:9020/common/refcontractinfo?ItemPerPage=0&ItemStartPage=0'
      // `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${apartmentNameForm}&Valid=1${managerNumForm}`,
      `${process.env.COMMON_URL}/common/refcontractinfo?ItemPerPage=0&ItemStartPage=0`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      try {
        if (res.data.ErrorCode === 0) {
          setApartmentListData(res.data.Data);
          setOnModal(true);
        }
        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
