import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface loadDataProps {
  setDataList: Dispatch<SetStateAction<any[]>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  apartmentIdx: number;
  selectedDate: string;
}

export const loadData = async ({
  setDataList,
  setLoading,
  apartmentIdx,
  selectedDate,
}: loadDataProps) => {
  const token = sessionStorage.getItem('token');

  if (!apartmentIdx) {
    return alert('단지를 선택해주세요');
  }

  setLoading(true);

  // const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx');
  // if (!apartmentIdx && Number(apartmentRefIdx) === 0) {
  //   return alert('단지를 선택해주세요');
  // }

  // const currentApartmentRefIdx = '';
  // if (Number(apartmentRefIdx) !== 0) {
  //   currentApartmentRefIdx = `${apartmentRefIdx}`;
  // } else {
  //   currentApartmentRefIdx = `${apartmentIdx}`;
  // }

  await axios
    .get(
      // `${
      //   process.env.COMMON_URL
      // }/cost/cs30020/paysliplist/${'202311'}?ContractIdx=${'1'}`,
      // `${process.env.COMMON_URL}/cost/cs30020/paysliplist/${selectedDate}?ContractIdx=${currentApartmentRefIdx}`,
      `${process.env.COMMON_URL}/cost/cs30020/paysliplist/${selectedDate}?ContractIdx=${apartmentIdx}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      try {
        if (res.data.ErrorCode === 0) {
          console.log('😊😊😊res.data.Data: ', res.data.Data);
          console.log(
            'CV30-020 데이터 불러오기 API: ',
            `/${selectedDate}?ContractIdx=${apartmentIdx}`
          );

          setLoading(false);
          setDataList(res.data.Data);
        }
        incorrectToken(res);
      } catch (e) {
        console.error(e);
      }
    });
};
