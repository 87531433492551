import styled from 'styled-components';

export const Tables = styled.table`
  width: 100%;
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px; /* 🚨🚨🚨🚨🚨 결재 박스 관련(수정 후) */
`;

export const InfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`;

export const TdEmployeeInfoCategory = styled.td`
  display: flex;
  height: 2.5%;
  font-size: 14px;
`;

export const EmployeeInfoBox = styled.div`
  display: flex;
  width: 50%;
  border-top: solid 1px black;

  &.bottom {
    border-bottom: solid 1px black;
  }
`;

export const EmployeeInfoTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 20px; /* 😊 */
  border-right: solid 1px black;
  border-left: solid 1px black;
  font-weight: 600;
`;

export const EmployeeInfoContents = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  width: 70%;

  &.right-section {
    border-right: solid 1px black;
  }
`;

/* [TAG] (이하) 출근, 결근, 휴가, 출장 박스 스타일 - start */
export const TdResultStatus = styled.td`
  display: flex;
  height: 5%;
`;

export const DateCountBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 10%;
  border-right: solid 1px black;

  &.first-element {
    border-left: solid 1px black;
  }
`;

export const DateCountTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 30%; */ /* 😊 */
  height: 16px;
  border-bottom: solid 1px black;
  font-size: 10px;
  font-weight: 600;
`;

export const DateCountResultNum = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 70%; */ /* 😊 */
  height: 32px;
  font-size: 14px;
`;
/* [TAG] (이하) 출근, 결근, 휴가, 출장 박스 스타일 - end */

/* [TAG] (이하) 연장, 지각, 조회 박스 스타일 - start */
export const ExtraWorkBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
`;

export const ExtraWorkTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 30%; */ /* 😊 */
  height: 16px;
  border-right: solid 1px black;
  border-bottom: solid 1px black;
  font-size: 10px;
  font-weight: 600;
`;

export const ExtraWorkResultSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 70%; */ /* 😊 */
  height: 32px;
  border-right: solid 1px black;
`;

export const ExtraWorkResultWrap = styled.div`
  width: 50%;
  height: 100%;
`;

export const ExtraWorkResultLeftTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50%; */ /* 😊 */
  height: 16px;
  border-right: solid 1px black;
  border-bottom: solid 1px black;
  font-size: 10px;
  font-weight: 600;
`;

export const ExtraWorkResultLeftNum = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50%; */ /* 😊 */
  height: 16px;
  border-right: solid 1px black;
  font-size: 14px;
`;

export const ExtraWorkResultRightTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50%; */ /* 😊 */
  height: 16px;
  border-bottom: solid 1px black;
  font-size: 10px;
  font-weight: 600;
`;

export const ExtraWorkResultRightNum = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50%; */ /* 😊 */
  height: 16px;
  font-size: 14px;
`;
/* [TAG] (이하) 연장, 지각, 조회 박스 스타일 - end */

export const WorkRecordListTitle = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 2.5%; */ /* 😊 */
  height: 24px;
  border: solid 1px black;
  border-bottom: none; /* 🚀 */
  background-color: lightGray;
  font-size: 15px;
  font-weight: 600;
`;

/* [TAG] (이하) 출퇴근기록표 카테고리 박스 스타일 - start */
export const TdRecordListCategory = styled.td`
  display: flex;
  height: 5%;
  font-size: 12px;
  font-weight: 600;
`;

export const RecordListCategoryDatesWrap = styled.div`
  width: 10%;
`;

export const RecordListCategoryDatesTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-top: solid 1px black; /* 🚀 */
  border-bottom: solid 1px black;
  border-left: solid 1px black;
  border-right: solid 1px black;
`;

export const RecordListCategoryWrap = styled.div`
  width: 30%;
  border-bottom: solid 1px black;
  border-right: solid 1px black;
`;

export const RecordListCategoryTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50%; */ /* 😊 */
  height: 20px;
  border-bottom: solid 1px black; /* 🚀 */
  border-top: solid 1px black; /* 🚀 */
`;

export const RecordListCategoryContents = styled.div`
  display: flex;
  /* height: 50%; */ /* 😊 */
  height: 20px;
`;

export const RecordListCategoryContentsLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 20px; /* 😊 */
  border-right: solid 1px black;

  &.add-time-start {
    width: calc(50% + 1px);
  }
`;

export const RecordListCategoryContentsRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 20px; /* 😊 */
`;
/* [TAG] (이하) 출퇴근기록표 카테고리 박스 스타일 - end */

/* [TAG] (이하) 출퇴근기록표 기록(시간) 리스트 - start */
export const TdRecordList = styled.td`
  display: flex;
`;

export const RecordListDatesSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 10%;
  border-left: solid 1px black;
`;

export const RecordListDates = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px; /* 🚨🚨🚨🚨🚨 결재 박스 관련(수정 후) */
  border-bottom: solid 1px black;
  border-right: solid 1px black;
  font-size: 10px;
  font-weight: 600;

  p {
    margin-right: 5px;
  }
`;

export const RecordListResultSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  font-size: 10px;
  border-bottom: solid 1px black; /* 🚀 */
  border-right: solid 1px black; /* 🚀 */
`;

export const RecordListResult = styled.div`
  display: flex;
  height: 20px; /* 🚨🚨🚨🚨🚨 결재 박스 관련(수정 후) */
  /* border-bottom: solid 1px black; */ /* 🚀 */
`;

export const RecordListResultLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 16.666%;
  border-right: solid 1px black;
`;

export const RecordListResultRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 16.666%;
  border-right: solid 1px black;

  &.last-element {
    border-right: none; /* 🚀 */
  }
`;
/* [TAG] (이하) 출퇴근기록표 기록(시간) 리스트 - end */

export const EmptyDataBox = styled.div`
  border-top: solid 1px black;
`;
