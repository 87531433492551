import React from 'react';
import styled from 'styled-components';

import DOMPurify from 'dompurify'; // DOMPurify 라이브러리(새니타이즈 라이브러리)

interface NoticeTextDataSectionProps {
  detailedNoticeData: any;
}

export const NoticeTextDataSection = ({
  detailedNoticeData,
}: NoticeTextDataSectionProps) => {
  function createMarkup() {
    // return { __html: detailedNoticeData.Data[0].PostContents };
    const safeHTML = DOMPurify.sanitize(
      detailedNoticeData.Data[0].PostContents
    );
    return { __html: safeHTML };
  }

  function TextComponent() {
    return (
      <BodyTextStyle
        dangerouslySetInnerHTML={createMarkup()}
        style={{ lineHeight: '25px' }}
      />
    );
  }

  return <>{TextComponent()}</>;
};

const BodyTextStyle = styled.div`
  font-size: 14px;
  line-height: 25px;
`;
