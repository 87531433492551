import styled from 'styled-components';

export const TitleSection = styled.div`
  font-size: 13px;
  margin-bottom: 5px;
`;

export const List = styled.select`
  padding: 5px;

  background-color: white;
  border-radius: 0.5em;
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.black};

  select:hover {
    border-color: #888;
  }

  select:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }

  /* option {
    background-color: red;

    &:hover {
      background-color: purple;
    }
  } */

  &.dropbox-short--small {
    /* padding: 10px; */
    width: 150px;
    height: 35px;
    font-size: 13px;

    /* &:focus {
      background-color: green;
    } */
  }

  &.dropbox-short--medium {
    width: 320px;
    height: 44px;
    font-size: 14px;
  }

  &.dropbox-short--large {
    width: 540px;
    height: 48px;
    font-size: 14px;
  }
`;
