import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as S from './Grid.style';

import axios from 'axios';
import { useDispatch } from 'react-redux';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './ag-theme-custom.css';
// import '../../agGridStyle/ag-theme-custom.css'
import { CellDoubleClickedEvent } from 'ag-grid-community';

import {
  TEMP_DEPARTMENT_CODE, // [CATEGORY] (소속) 부서
  TEMP_JOB_CODE, // [CATEGORY] 직무
  TEMP_JOB_GROUP_CODE, // [CATEGORY] 직무 그룹
  TEMP_POSITION_CODE, // [CATEGORY] 직급
  TEMP_AREA_CODE, // [CATEGORY] 근무 지역
  TEMP_STATUS_CODE, // [CATEGORY] 사원 상태
} from '../constants/categories'; // WARNING: 상수값임. 추후 카테고리 데이터 바인딩 할 것.
import { gridOptions } from './gridOptions'; // 그리드 컬럼 목록

import { onLoadBtn } from '../utils/onLoadBtn'; // 불러오기 버튼 API
import { onEncodedString } from '../utils/onEncodedString'; // 문자열을 base64URL로 변환

import { Dropbox } from '../../UI/Dropbox/Short';
import { Button } from '../../UI/Button/Short';
import { Input } from '../../UI/Input/Short';

import {
  nameKR,
  employeeNum,
  userID,
  permitCode,
  jobName,
  registrationNum,
  phoneNum,
  address1,
  address2,
  enterDate,
  exitDate,
} from '../../../redux/slice/targetEmployeeInfoSlice';

import {
  selectedDepartmentCode, // [CATEGORY] (소속) 부서
  selectedJobCode, // [CATEGORY] 직무
  selectedJobGroupCode, // [CATEGORY] 직무 그룹
  selectedPositionCode, // [CATEGORY] 직급
  selectedAreaCode, // [CATEGORY] 근무 지역
  selectedStatusCode, // [CATEGORY] 사원 상태
} from '../../../redux/slice/selectedCategoryAtEmployeeSlice';
import { RequestSliceData } from '../../../lib/utils/RequestSliceData';

import { Modal } from '../Modal/Modal';
import queryString from 'query-string';

// import { Modal2 } from '../Modal2/Modal2';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const Grid = () => {
  // -----------------------------------start
  const permitCode = sessionStorage.getItem('permitCode');

  /**
   * permitCode 권한 코드
   * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
   * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
   * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
   */

  const isHeadOfficeManagerCode =
    permitCode === process.env.HEAD_OFFICE_MANAGER_CODE;

  const isApartmentManagerCode =
    permitCode === process.env.APARTMENT_MANAGER_CODE;

  // -----------------------------------end

  const {
    requestSelectedDepartmentCode, // [CATEGORY] (소속) 부서
    requestSelectedJobCodeValue, // [CATEGORY] 직무
    requestSelectedJobGroupCode, // [CATEGORY] 직무 그룹
    requestSelectedPositionCode, // [CATEGORY] 직급
    requestSelectedAreaCode, // [CATEGORY] 근무 지역
    requestSelectedStatusCode, // [CATEGORY] 사원 상태
  } = RequestSliceData();

  const [onModal, setOnModal] = useState(false);

  const closeModal = () => {
    setOnModal(false);
  };

  const onControlModal = (boolean = true) => {
    setOnModal(boolean);

    if (onModal === true) {
      setOnModal(false);
    }
  };

  const [categoryName, setCategoryName] = useState<string>('');
  const [categoryValue, setCategoryValue] = useState<any>();

  const dispatch = useDispatch(); // redux toolkit

  const location = useLocation();
  const query = queryString.parse(location.search);

  const originUrl = window.location.origin;
  // const fullURL = `${originUrl}/employee`;
  const fullURL = `${originUrl}/SE103`;

  useEffect(() => {
    history.pushState(fullURL, 'admin page', fullURL);

    if (query.id) {
      CallOnDetailedEmployeeInfo(query.id);
    }
  }, [query.id]);

  const CallOnDetailedEmployeeInfo = async (
    targetEmployeeNum: React.SetStateAction<string> | (string | null)[]
  ) => {
    const token = sessionStorage.getItem('token');

    await axios
      .get(`${process.env.COMMON_URL}/common/refuser/${targetEmployeeNum}`, {
        headers: { tokenId: token },
      })
      .then(res => {
        try {
          // console.log(res.data.Data, '임직원 관리-상세정보-유저 데이터');

          dispatch(nameKR(res.data.Data[0].UserNameKR)); // 이름(한글)
          dispatch(employeeNum(res.data.Data[0].EmployeeNo)); // 사번
          dispatch(userID(res.data.Data[0].UserId)); // ID
          dispatch(permitCode(res.data.Data[0].PermitCode)); // 권한 코드
          dispatch(jobName(res.data.Data[0].JobName)); // 직무
          dispatch(registrationNum(res.data.Data[0].RegNumber)); // 주민등록번호
          dispatch(phoneNum(res.data.Data[0].NumberPhone)); // 전화번호
          dispatch(address1(res.data.Data[0].Address1)); // 주소1
          dispatch(address2(res.data.Data[0].Address2)); // 주소2
          dispatch(enterDate(res.data.Data[0].EnterDate)); // 입사일
          dispatch(exitDate(res.data.Data[0].ExitDate)); // 퇴사일

          onControlModal(true);
          // setOnModal2(true);
        } catch (e) {
          //
        }
      });
  };

  const [employeeData, setEmployeeData] = useState<any[]>([]);
  const [targetEmployeeData, setTargetEmployeeData] = useState<any[]>([]);

  const [enteredBirth, setEnteredBirth] = useState(''); // [CATEGORY] 생년월일
  const [enteredEnterDate, setEnteredEnterDate] = useState(''); // [CATEGORY] 입사일
  const [enteredExitDate, setEnteredExitDate] = useState(''); // [CATEGORY] 퇴사일
  const [enteredCategoryCode, setEnteredCategoryCode] = useState(''); // [CATEGORY] 직위(계약단위)
  const [enteredContractIdx, setEnteredContractIdx] = useState(''); // [CATEGORY] 계약 단지 Idx
  const [enteredContractStartDate, setEnteredContractStartDate] = useState(''); // [CATEGORY] 단지 계약 시작일
  const [enteredContractEndDate, setEnteredContractEndDate] = useState(''); // [CATEGORY] 단지 계약 종료일

  const [enteredEmployeeNum, setEnteredEmployeeNum] = useState(''); // [CATEGORY] 사번 (base64)
  const [enteredName, setEnteredName] = useState(''); // [CATEGORY] 이름 (base64)
  const [enteredPhoneNum, setEnteredPhoneNum] = useState(''); // [CATEGORY] 전화번호 (base64)
  const [enteredInsuranceNum, setEnteredInsuranceNum] = useState(''); // [CATEGORY] 4대 보험 증번호 (base64)

  const onDetailedEmployeeInfoClick = (e: CellDoubleClickedEvent) => {
    onEmployeeImage(e);
    onDetailedEmployeeInfo(e);
    onControlModal(true);
  };

  const onDetailedEmployeeInfo = async (e: CellDoubleClickedEvent) => {
    // (property) BaseRowEvent<any>.rowIndex: number | null
    const token = sessionStorage.getItem('token');
    const targetEmployeeNum = employeeData[e.rowIndex].EmployeeNo;

    const originUrl = window.location.origin;
    const targetEmployeeNumQuery = `?id=${targetEmployeeNum}`;
    // const fullURL = `${originUrl}/employee${targetEmployeeNumQuery}`;
    const fullURL = `${originUrl}/SE103${targetEmployeeNumQuery}`;

    location.pathname = `${process.env.COMMON_URL}/common/refuser/${targetEmployeeNum}`;

    await axios
      .get(`${process.env.COMMON_URL}/common/refuser/${targetEmployeeNum}`, {
        headers: { tokenId: token },
      })
      .then(res => {
        try {
          // console.log(res.data.Data[0], '임직원 관리-상세-유저 데이터');
          setTargetEmployeeData(res.data.Data[0]);

          dispatch(nameKR(res.data.Data[0].UserNameKR)); // 이름(한글)
          dispatch(employeeNum(res.data.Data[0].EmployeeNo)); // 사번
          dispatch(userID(res.data.Data[0].UserId)); // ID
          dispatch(permitCode(res.data.Data[0].PermitCode)); // 권한 코드
          dispatch(jobName(res.data.Data[0].JobName)); // 직무
          dispatch(registrationNum(res.data.Data[0].RegNumber)); // 주민등록번호
          dispatch(phoneNum(res.data.Data[0].NumberPhone)); // 전화번호
          dispatch(address1(res.data.Data[0].Address1)); // 주소1
          dispatch(address2(res.data.Data[0].Address2)); // 주소2
          dispatch(enterDate(res.data.Data[0].EnterDate)); // 입사일
          dispatch(exitDate(res.data.Data[0].ExitDate)); // 퇴사일

          onControlModal(true);

          // history.pushState(targetEmployeeNumQuery, null, fullURL);
          history.pushState(targetEmployeeNumQuery, 'admin page', fullURL);
        } catch (e) {
          //
        }
      });
  };

  // TAG: 바이너리 파일 Blob으로 받기 ----- start
  const [imageText, setImageText] = useState('');
  // TAG: 바이너리 파일 Blob으로 받기 ----- end

  const onEmployeeImage = async (e: CellDoubleClickedEvent) => {
    const token = sessionStorage.getItem('token');
    const targetEmployeeNum = employeeData[e.rowIndex].EmployeeNo;

    await axios
      .get(
        `${process.env.COMMON_URL}/setting/se10030/user/image/${targetEmployeeNum}`,
        {
          responseType: 'blob',
          headers: { tokenId: token, 'content-type': 'image / png' },
        }
      )
      .then(res => {
        try {
          console.log('임직원 관리 / 유저 이미지 - 데이터: ', res.data);
          console.log('임직원 관리 / 유저 이미지 - 타입: ', res.data.type);

          // TAG: 바이너리 파일 Blob으로 받기 ----- start
          /* 백업용
          const myFile = new File([res.data], `${targetEmployeeNum}`);
          const reader = new FileReader();
          reader.onload = ev => {
            const previewImage = String(ev.target?.result);
            setImageText(previewImage); // imageText라는 state에 저장
          };
          reader.readAsDataURL(myFile);
          */
          // TAG: 바이너리 파일 Blob으로 받기 ----- end

          if (res.data.type !== 'application/json') {
            // TAG: 바이너리 파일 Blob으로 받기 -----start
            const myFile = new File([res.data], `${targetEmployeeNum}`);
            const reader = new FileReader();
            reader.onload = ev => {
              const previewImage = String(ev.target?.result);
              setImageText(previewImage); // imageText라는 state에 저장

              console.log('SE10-030 임직원 관리 previewImage', previewImage);
            };
            reader.readAsDataURL(myFile);
            // TAG: 바이너리 파일 Blob으로 받기 -----end
          } else if (res.data.type === 'application/json') {
            setImageText('');
          }

          if (res.data.Data.ErrorCode !== 0) return;
        } catch (e) {
          //
        }
      });
  };

  // 사번: enteredEmployeeNumValue (base64)
  // 이름: enteredNameValue (base64)
  // 전화번호: enteredPhoneNumValue (base64)
  // 4대 보험 증번호: enteredInsuranceNumValue (base64)

  // 생년월일: enteredBirthValue
  // 입사일: enteredEnterDateValue
  // 퇴사일: enteredExitDateValue
  // 직위(계약단위): enteredCategoryCodeValue
  // 계약 단지 Idx: enteredContractIdxValue
  // 단지 계약 시작일: enteredContractStartDateValue
  // 단지 계약 종료일: enteredContractEndDateValue

  let departmentCodeValue = '';
  let jobCodeValue = '';
  let jobGroupCodeValue = '';
  let positionCodeValue = '';
  let areaCodeValue = '';
  let statusCodeValue = '';

  // [CATEGORY] (소속) 부서 코드
  if (categoryName === 'departmentCode') {
    departmentCodeValue = categoryValue ? `&DeptCode=${categoryValue}` : '';
    dispatch(selectedDepartmentCode(departmentCodeValue));
  }

  // [CATEGORY] 직무 코드
  if (categoryName === 'jobCode') {
    jobCodeValue = categoryValue ? `&JobCode=${categoryValue}` : '';
    dispatch(selectedJobCode(jobCodeValue));
  }

  // [CATEGORY] 직무 그룹 코드
  if (categoryName === 'jobGroupCode') {
    jobGroupCodeValue = categoryValue ? `&JobGroupCode=${categoryValue}` : '';
    dispatch(selectedJobGroupCode(jobGroupCodeValue));
  }

  // [CATEGORY] 직급 코드
  if (categoryName === 'positionCode') {
    positionCodeValue = categoryValue ? `&PositionCode=${categoryValue}` : '';
    dispatch(selectedPositionCode(positionCodeValue));
  }

  // [CATEGORY] 근무 지역 코드
  if (categoryName === 'areaCode') {
    areaCodeValue = categoryValue ? `&AreaCode=${categoryValue}` : '';
    dispatch(selectedAreaCode(areaCodeValue));
  }

  // [CATEGORY] 사원 상태
  if (categoryName === 'status') {
    statusCodeValue = categoryValue ? `&Status=${categoryValue}` : '';
    dispatch(selectedStatusCode(statusCodeValue));
  }

  // [CATEGORY] 생년월일
  const onBirth = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredBirth(e.target.value);
  };

  // [CATEGORY] 입사일
  const onEnterDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredEnterDate(e.target.value);
  };

  // [CATEGORY] 퇴사일
  const onExitDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredExitDate(e.target.value);
  };

  // [CATEGORY] 직위(계약단위)
  const onCategoryCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredCategoryCode(e.target.value);
  };

  // [CATEGORY] 계약 단지
  const onContractIdx = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredContractIdx(e.target.value);
  };

  const onEnteredContractStartDate = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnteredContractStartDate(e.target.value);
  };

  const onEnteredContractEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredContractEndDate(e.target.value);
  };

  // MEMO: base64URL
  const onEnteredEmployeeNum = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { encodedString } = onEncodedString({ e });

    setEnteredEmployeeNum(encodedString);
  };

  const onEnteredName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { encodedString } = onEncodedString({ e });
    // 검색 테스트: 가산동 (-기호가 나타나야 함)
    setEnteredName(encodedString);
  };

  const onEnteredPhoneNum = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { encodedString } = onEncodedString({ e });

    setEnteredPhoneNum(encodedString);
  };

  const onEnteredInsuranceNum = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { encodedString } = onEncodedString({ e });

    setEnteredInsuranceNum(encodedString);
  };

  const enteredBirthValue = enteredBirth ? `&Birth=${enteredBirth}` : '';

  const enteredEnterDateValue = enteredEnterDate
    ? `&EnterDate=${enteredEnterDate}`
    : '';

  const enteredExitDateValue = enteredExitDate
    ? `&ExitDate=${enteredExitDate}`
    : '';

  const enteredCategoryCodeValue = enteredCategoryCode
    ? `&CategoryCode=${enteredCategoryCode}`
    : '';

  const enteredContractIdxValue = enteredContractIdx
    ? `&ContractIdx=${enteredContractIdx}`
    : '';

  const enteredContractStartDateValue = enteredContractStartDate
    ? `&ContractStartDate=${enteredContractStartDate}`
    : '';

  const enteredContractEndDateValue = enteredContractEndDate
    ? `&ContractEndDate=${enteredContractEndDate}`
    : '';

  const enteredNameValue = enteredName ? `&Name=${enteredName}` : '';

  const enteredEmployeeNumValue = enteredEmployeeNum
    ? `&EmployeeNo=${enteredEmployeeNum}`
    : '';

  const enteredPhoneNumValue = enteredPhoneNum
    ? `&PhoneNo=${enteredPhoneNum}`
    : '';

  const enteredInsuranceNumValue = enteredInsuranceNum
    ? `&InsuranceNo=${enteredInsuranceNum}`
    : '';

  const [subCategory, setSubCategory] = useState(false);
  const isSubCategoryOpen = () => {
    setSubCategory(!subCategory);
  };

  const [essentialCategory, setEssentialCategory] = useState(false);

  function onEssentialCategory() {
    setEssentialCategory(!essentialCategory);
  }

  const [subCategory2, setSubCategory2] = useState(false);

  function onSubCategory() {
    setSubCategory2(!subCategory2);
  }

  return (
    <>
      {onModal && (
        <Modal
          closeModal={closeModal}
          image={imageText}
          targetEmployeeData={targetEmployeeData}
        />
      )}

      <S.Title>임직원 관리</S.Title>

      <S.Wrap>
        <S.EssentialCategorySection
          className={essentialCategory ? 'on-option' : 'off-option'}
          onClick={() => onEssentialCategory()}
        >
          <S.EssentialCategoryWrap
            className={essentialCategory ? 'on-option' : 'off-option'}
          >
            {essentialCategory ? (
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            ) : (
              <KeyboardArrowRightIcon style={{ fontSize: 20 }} />
            )}
            검색 옵션 선택
          </S.EssentialCategoryWrap>
        </S.EssentialCategorySection>

        {essentialCategory && (
          <S.CategorySection>
            <S.CategoryWrap>
              <S.CategoryListWrap>
                <S.MainCategorySection>
                  <S.CategoryGroup>
                    <S.CategoryBox>
                      <Dropbox
                        setCategoryName={setCategoryName}
                        setCategoryValue={setCategoryValue}
                        title="직무 그룹"
                        selectedCategoryName="jobGroupCode"
                        contentsList={TEMP_JOB_GROUP_CODE}
                      />
                    </S.CategoryBox>

                    <S.CategoryBox>
                      {/* MEMO: 이름 - Name */}
                      <S.CategoryName>이름</S.CategoryName>
                      <Input
                        placeholder="이름"
                        type="text"
                        size="small"
                        onChange={onEnteredName}
                      />
                    </S.CategoryBox>

                    <S.CategoryBox>
                      {/* MEMO: 사번 - EmployeeNo */}
                      <S.CategoryName>사번</S.CategoryName>
                      <Input
                        placeholder="사번"
                        type="text"
                        size="small"
                        onChange={onEnteredEmployeeNum}
                      />
                    </S.CategoryBox>

                    <S.CategoryBox>
                      {/* MEMO: 전화번호 - PhoneNo */}
                      <S.CategoryName>전화번호</S.CategoryName>
                      <Input
                        placeholder="전화번호"
                        type="text"
                        size="small"
                        onChange={onEnteredPhoneNum}
                      />
                    </S.CategoryBox>

                    <S.CategoryBox>
                      {/* MEMO: 생년월일 - Birth */}
                      <S.CategoryName>생년월일</S.CategoryName>
                      <Input
                        placeholder="YYYYMMDD"
                        type="text"
                        size="small"
                        onChange={onBirth}
                      />
                    </S.CategoryBox>
                  </S.CategoryGroup>
                </S.MainCategorySection>
              </S.CategoryListWrap>
            </S.CategoryWrap>
          </S.CategorySection>
        )}

        <S.SubCategorySection
          className={subCategory2 ? 'on-option' : 'off-option'}
          onClick={() => onSubCategory()}
        >
          <S.SubCategoryWrap
            className={subCategory2 ? 'on-option' : 'off-sub-option'}
          >
            {subCategory2 ? (
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            ) : (
              <KeyboardArrowRightIcon style={{ fontSize: 20 }} />
            )}
            추가 옵션 선택
          </S.SubCategoryWrap>
        </S.SubCategorySection>

        {subCategory2 && (
          <S.CategorySection>
            <S.CategoryWrap>
              <S.CategoryListWrap>
                <S.MainCategorySection>
                  <S.CategoryGroup>
                    <S.CategoryBox>
                      <Dropbox
                        setCategoryName={setCategoryName}
                        setCategoryValue={setCategoryValue}
                        title="직급"
                        selectedCategoryName="positionCode"
                        contentsList={TEMP_POSITION_CODE}
                      />
                    </S.CategoryBox>

                    <S.CategoryBox>
                      <Dropbox
                        setCategoryName={setCategoryName}
                        setCategoryValue={setCategoryValue}
                        title="부서"
                        selectedCategoryName="departmentCode"
                        contentsList={TEMP_DEPARTMENT_CODE}
                      />
                    </S.CategoryBox>

                    <S.CategoryBox>
                      <Dropbox
                        setCategoryName={setCategoryName}
                        setCategoryValue={setCategoryValue}
                        title="직무"
                        selectedCategoryName="jobCode"
                        contentsList={TEMP_JOB_CODE}
                      />
                    </S.CategoryBox>

                    <S.CategoryBox>
                      <Dropbox
                        setCategoryName={setCategoryName}
                        setCategoryValue={setCategoryValue}
                        title="근무 지역"
                        selectedCategoryName="areaCode"
                        contentsList={TEMP_AREA_CODE}
                      />
                    </S.CategoryBox>

                    <S.CategoryBox>
                      <Dropbox
                        setCategoryName={setCategoryName}
                        setCategoryValue={setCategoryValue}
                        title="근무 상태"
                        selectedCategoryName="status"
                        contentsList={TEMP_STATUS_CODE}
                      />
                    </S.CategoryBox>

                    <S.CategoryBox>
                      {/* MEMO: 입사일 - EnterDate */}
                      <S.CategoryName>입사일</S.CategoryName>
                      <Input
                        placeholder="YYYYMMDD"
                        type="text"
                        size="small"
                        onChange={onEnterDate}
                      />
                    </S.CategoryBox>

                    <S.CategoryBox>
                      {/* MEMO: 퇴사일 - ExitDate */}
                      <S.CategoryName>퇴사일</S.CategoryName>
                      <Input
                        placeholder="YYYYMMDD"
                        type="text"
                        size="small"
                        onChange={onExitDate}
                      />
                    </S.CategoryBox>

                    {/* <S.CategoryBox> */}
                    {/* MEMO: 직위(계약단위) - CategoryCode */}
                    {/* <S.CategoryName>직위(계약단위)</S.CategoryName>
                      <Input
                        placeholder="5자리"
                        type="text"
                        size="small"
                        onChange={onCategoryCode}
                      />*/}
                    {/* </S.CategoryBox> */}

                    {/* <S.CategoryBox> */}
                    {/* MEMO: 4대 보험 증번호 - InsuranceNo */}
                    {/* <S.CategoryName>4대 보험 증번호</S.CategoryName>
                          <Input
                            placeholder="4대 보험 증번호"
                            type="text"
                            size="small"
                            onChange={onEnteredInsuranceNum}
                          />
                        </S.CategoryBox> */}

                    {/* <S.CategoryBox> */}
                    {/* MEMO: 계약 단지 Idx - ContractIdx */}
                    {/* <S.CategoryName>계약 단지 Idx</S.CategoryName>
                        <Input
                          placeholder=""
                          type="text"
                          size="small"
                          onChange={onContractIdx}
                        />
                      </S.CategoryBox> */}

                    {/* <S.CategoryBox>*/}
                    {/* MEMO: 단지 계약 시작일 - ContractStartDate */}
                    {/*<S.CategoryName>단지 계약 시작일</S.CategoryName>
                      <Input
                        placeholder="YYYYMMDD"
                        type="text"
                        size="small"
                        onChange={onEnteredContractStartDate}
                      /> */}
                    {/* </S.CategoryBox> */}

                    <S.CategoryBox>
                      {/* MEMO: 단지 계약 종료일 - ContractEndDate */}
                      <S.CategoryName>단지 계약 종료일</S.CategoryName>
                      <Input
                        placeholder="YYYYMMDD"
                        type="text"
                        size="small"
                        onChange={onEnteredContractEndDate}
                      />
                    </S.CategoryBox>
                  </S.CategoryGroup>
                </S.MainCategorySection>
              </S.CategoryListWrap>
            </S.CategoryWrap>
          </S.CategorySection>
        )}

        <S.Bar />
        <S.LoadBtnSection>
          <Button
            label="불러오기"
            onClick={() =>
              onLoadBtn({
                employeeData,
                setEmployeeData,
                enteredEmployeeNumValue,
                enteredNameValue,
                enteredPhoneNumValue,
                enteredInsuranceNumValue,
                requestSelectedDepartmentCode,
                requestSelectedJobCodeValue,
                requestSelectedJobGroupCode,
                requestSelectedPositionCode,
                enteredBirthValue,
                enteredEnterDateValue,
                enteredExitDateValue,
                enteredCategoryCodeValue,
                enteredContractIdxValue,
                enteredContractStartDateValue,
                enteredContractEndDateValue,
                requestSelectedAreaCode,
                requestSelectedStatusCode,
              })
            }
          />
        </S.LoadBtnSection>

        <S.GridSection>
          <div
            id="myGrid"
            className="ag-theme-alpine ag-theme-custom"
            // style={{ width: '100%', height: '100%' }}
            // style={{ borderRadius: '15px' }}
          >
            <AgGridReact
              headerHeight={45} // 타이틀 행 높이
              rowHeight={35} // 본문 행 높이
              gridOptions={gridOptions}
              rowData={employeeData.map((data, idx) => {
                return {
                  nameKR: data.UserNameKR, // 이름(한글)
                  employeeNum: data.EmployeeNo, // 사번
                  userId: data.UserId, // 아이디
                  permitCode: data.PermitCode, // 직위 코드
                  areaName: data.AreaName, // 지역
                  jobName: data.JobName, // 직군?
                  userStatus: data.UserStatus, // 근무 상태
                  enterDate: data.EnterDate, // 입사일
                  birthDay: data.BirthDay, // 생년월일
                };
              })}
              animateRows={true}
              rowDragManaged={true}
              suppressMoveWhenRowDragging={true}
              pagination={true}
              paginationPageSize={15}
              onCellDoubleClicked={(e: CellDoubleClickedEvent) =>
                employeeData.length
                  ? onDetailedEmployeeInfoClick(e)
                  : alert('없음')
              }
            />
          </div>
        </S.GridSection>
      </S.Wrap>
    </>
  );
};
