import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

interface loadApartmentNameBtnProps {
  apartmentListData: any[];
  setApartmentListData: Dispatch<SetStateAction<any[]>>;
  enteredNameValue: string;
  setOpenResult: Dispatch<SetStateAction<boolean>>;
  setSearchResult: Dispatch<SetStateAction<any>>;
}

export const searchApartmentName = async ({
  apartmentListData,
  setApartmentListData,
  enteredNameValue, // 계약 단지 이름
  setOpenResult,
  setSearchResult,
}: loadApartmentNameBtnProps) => {
  const token = sessionStorage.getItem('token');
  console.log(enteredNameValue, '📢📢📢📢📢');

  await axios
    .get(
      `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${enteredNameValue}&Valid=1`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      console.log(
        '(@모달안)API확인',
        `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${enteredNameValue}&Valid=1`
      );
      try {
        setSearchResult(res.data.Data);
        setOpenResult(true);
        // setApartmentListData(res.data.Data[0]);
        console.log(res.data.Data, '(@모달안)res.data.Data');
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
