// src/PersonalInfoPage/PersonalInfoPage.tsx

import { createSlice } from '@reduxjs/toolkit';

export const selectedCategoryAtEmployeeSlice = createSlice({
  name: 'Selected Code Value at Employee Information Page',
  initialState: {
    selectedDepartmentCodeValue: '', // (소속) 부서
    selectedJobCodeValue: '', // 직무
    selectedJobGroupCodeValue: '', // 직무 그룹
    selectedPositionCodeValue: '', // 직급
    selectedAreaCodeValue: '', // 근무 지역
    selectedStatusCodeValue: '', // 사원 상태
  },
  reducers: {
    selectedDepartmentCode: (state, action) => {
      state.selectedDepartmentCodeValue = action.payload;
    },
    selectedJobCode: (state, action) => {
      state.selectedJobCodeValue = action.payload;
    },
    selectedJobGroupCode: (state, action) => {
      state.selectedJobGroupCodeValue = action.payload;
    },
    selectedPositionCode: (state, action) => {
      state.selectedPositionCodeValue = action.payload;
    },
    selectedAreaCode: (state, action) => {
      state.selectedAreaCodeValue = action.payload;
    },
    selectedStatusCode: (state, action) => {
      state.selectedStatusCodeValue = action.payload;
    },
  },
});

export const {
  selectedDepartmentCode, // (소속) 부서
  selectedJobCode, // 직무
  selectedJobGroupCode, // 직무 그룹
  selectedPositionCode, // 직급
  selectedAreaCode, // 근무 지역
  selectedStatusCode, // 사원 상태
} = selectedCategoryAtEmployeeSlice.actions;
