import axios from 'axios';

interface onDownloadContractDoneFileProps {
  contract: { contractIdx?: number };
}

export const onDownloadContractDoneFile = async ({
  contract,
}: onDownloadContractDoneFileProps) => {
  const token = sessionStorage.getItem('token');
  const targetContractIdx = contract.contractIdx;

  try {
    if (res.data.ErrorCode === 0) {
      const response = await axios.get(
        `${process.env.COMMON_URL}/cost/cs10040/contractdownload/${targetContractIdx}?type=S`,
        {
          headers: {
            tokenId: token,
          },
          responseType: 'blob',
        }
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const $a = document.createElement('a');
      $a.href = downloadUrl;
      $a.download = targetContractIdx!.toString();
      document.body.appendChild($a);
      $a.click();
      document.body.removeChild($a);
      window.URL.revokeObjectURL(downloadUrl);
    }
  } catch (e) {
    console.error('파일 다운로드 중 에러가 발생했습니다:', e);
  }
};
