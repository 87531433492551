// src/PersonalInfoPage/PersonalInfoPage.tsx

import { createSlice } from '@reduxjs/toolkit';

export const loggedInUserDetailSlice = createSlice({
  name: 'User Detail Information When Logged In',
  initialState: {
    userIDValue: '',
    registrationNumValue: '',
    phoneNumValue: '',
    addressValue: '',
  },
  reducers: {
    userID: (state, action) => {
      // ID
      state.userIDValue = action.payload;
    },
    registrationNum: (state, action) => {
      // 주민등록번호
      state.registrationNumValue = action.payload;
    },
    phoneNum: (state, action) => {
      // 전화번호
      state.phoneNumValue = action.payload;
    },
    address: (state, action) => {
      // 주소
      state.addressValue = action.payload;
    },
  },
});

export const { userID, registrationNum, phoneNum, address } =
  loggedInUserDetailSlice.actions;
