import React from 'react';
import styled from 'styled-components';

import { WorkAreaSetting } from '../../components/SA20-010-work-area-setting/WorkAreaSetting';

import RouterTrackerForAnalytics from '../../lib/utils/RouterTrackerForAnalytics'; // [MEMO] 구글 애널리틱스 라우터 트래커

const WorkAreaSettingPage = () => {
  const $title = document.head.querySelector('title');
  $title!.innerText = '근무 구역 설정';
  RouterTrackerForAnalytics();

  return (
    <>
      <WorkAreaSetting />
    </>
  );
};

export default WorkAreaSettingPage;
