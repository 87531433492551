import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { ModalContents } from './ModalContent';

interface PayslipModalProps {
  selectedGridData: any;
  setOpenPayslipModal: Dispatch<SetStateAction<boolean>>;
  selectedDate: string;
  MMILogoImg: string;
  // apartmentIdx: number;
  // setDetectChangingData: Dispatch<SetStateAction<boolean>>; // 개별 데이터 삭제시, 데이터 불러오기 트리거
}

export const PayslipModal = ({
  selectedGridData,
  setOpenPayslipModal,
  selectedDate,
  MMILogoImg,
}: // apartmentIdx,
// setDetectChangingData,
PayslipModalProps) => {
  const closeModal = () => {
    setOpenPayslipModal(false);
  };

  return (
    <Background onClick={closeModal}>
      <Container
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ModalContents
          closeModal={closeModal}
          selectedGridData={selectedGridData}
          selectedDate={selectedDate}
          MMILogoImg={MMILogoImg}
          // apartmentIdx={apartmentIdx}
          // setDetectChangingData={setDetectChangingData}
        />
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: white;
`;
