import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

interface loadDetailedNoticeDataProps {
  setDetailedNoticeData: Dispatch<SetStateAction<any[]>>;
  noticeIdx: number;
  setDetailedNoticeModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const loadDetailedNoticeData = async ({
  setDetailedNoticeData,
  noticeIdx,
  setDetailedNoticeModalVisible,
}: loadDetailedNoticeDataProps) => {
  const token = sessionStorage.getItem('token');
  const employeeNum = sessionStorage.getItem('employeeNum');
  //
  await axios
    .get(
      // https://dev.mmigroup.co.kr:8020/dashboard/da10010/boardlist/1/9?employeeno=000001
      `${process.env.COMMON_URL}/dashboard/da10010/boardlist/1/${noticeIdx}?employeeno=${employeeNum}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      try {
        if (res.data.ErrorCode === 0) {
          setDetailedNoticeData(res.data);
          setDetailedNoticeModalVisible(true);
        }
      } catch (e) {
        console.error(e);
      }
    });
};
