import { Dispatch, SetStateAction } from 'react';

interface onPlusOneMonthProps {
  calStartYear: string;
  calStartMonth: string;
  calStartDate: string;
  setStartDate: Dispatch<SetStateAction<any>>;
}

// MEMO: 1개월씩 감소
export const onMinusOneMonth = ({
  calStartYear,
  calStartMonth,
  calStartDate,
  setStartDate,
}: onPlusOneMonthProps) => {
  let targetMonth = Number(calStartMonth);
  let targetYear = Number(calStartYear);
  // const targetDate = Number(calStartDate);
  const targetDate = 1;

  /* 증가일 때 조건
  if (fooMonth < 12) {
    fooMonth = fooMonth + 1;
  } else {
    fooMonth = 1;
    fooYear = fooYear + 1;
  }
  */

  if (targetMonth > 1) {
    targetMonth = targetMonth - 1;
  } else {
    targetMonth = 12;
    targetYear = targetYear - 1;
  }

  setStartDate(new Date(`${targetYear}-${targetMonth}-${targetDate}`));
};
