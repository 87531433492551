// [AC10-030] 근무 현황(월간) 수정 API
import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { incorrectToken } from '../../../../lib/utils/incorrectToken';

interface onModifyDataProps {
  closeModal: () => void;
  setIsUpdateStatus: Dispatch<SetStateAction<boolean>>;
  cellData: any;
  employeeNum: string;
  selectedStartHours: string;
  selectedStartMinutes: string;
  selectedEndHours: string;
  selectedEndMinutes: string;

  calYear: string;
  calMonth: string;
  currentDateToString: string;
  isNextDay: boolean;

  selectedStatusIdx: string; // 🔰
  selectedStatusType: string; // 🔰
}

export const onModifyData = async ({
  closeModal,
  setIsUpdateStatus,
  cellData,
  employeeNum,
  selectedStartHours,
  selectedStartMinutes,
  selectedEndHours,
  selectedEndMinutes,
  isNextDay,

  calYear,
  calMonth,
  currentDateToString,

  selectedStatusIdx, // 🔰
  selectedStatusType, // 🔰
}: onModifyDataProps) => {
  console.log('🧀시작🧀선택한-시작(시): ', selectedStartHours);
  console.log('🧀시작🧀선택한-시작(분): ', selectedStartMinutes);
  console.log('🍕종료🍕선택한-종류(시): ', selectedEndHours);
  console.log('🍕종료🍕선택한-종료(분): ', selectedEndMinutes);
  console.log('🧀🍕cellData🍕🧀: ', cellData);
  console.log('🧀🍕cellData.statusCode🍕🧀: ', cellData.statusCode);
  // console.log(
  //   '🥗월간🥗`년-월-일: ',
  //   `${calYear}-${calMonth}-${currentDateToString}`
  // );

  if (!selectedStatusIdx) {
    return;
  }

  if (cellData.length <= 0) {
    return;
  }

  if (
    selectedStartHours === selectedEndHours &&
    selectedStartMinutes === selectedEndMinutes &&
    selectedStatusType === 'D'
  ) {
    alert('유효한 시간이 아닙니다');
    return;
  }

  function addString(str: string) {
    if (str.length === 1) {
      return '0' + str;
    } else return str;
  }

  const token = sessionStorage.getItem('token');
  const contractIdx = sessionStorage.getItem('contractIdx'); // 아파트 계약 인덱스

  console.log('💟selectedStatusIdx: ', selectedStatusIdx);
  console.log('💟💟cellData.statusInfo', cellData.statusInfo);
  console.log('💟💟selectedStatusIdx', selectedStatusIdx);
  // console.log(
  //   '💟ddd💟: ',
  //   cellData.statusInfo
  //     .filter(d => Number(d.statusIdx) === Number(selectedStatusIdx))
  //     .forEach(d => d.statusCode)
  // );

  const filteredData = cellData.statusInfo
    .filter(d => d.statusIdx == Number(selectedStatusIdx))
    .map(d => d.statusCode)[0];
  console.log('💟💟💟filteredData', filteredData);
  console.log('💟💟💟filteredData[0]', filteredData[0]);

  await axios({
    // https://dev.mmigroup.co.kr:8020/attendance/ac10030/attendancereport/630037?idx=2805
    url: `${process.env.COMMON_URL}/attendance/ac10030/attendancereport/${employeeNum}?idx=${selectedStatusIdx}`,
    method: 'put',
    headers: { tokenId: token },
    data: {
      idx: selectedStatusIdx,
      AttendStatusCode: cellData.statusInfo
        .filter(d => d.statusIdx === Number(selectedStatusIdx))
        .map(d => d.statusCode)[0],
      AttendStatusName: cellData.statusInfo
        .filter(d => d.statusIdx === Number(selectedStatusIdx))
        .map(d => d.statusName)[0],
      Working: selectedStatusType,

      StatusTardy: cellData.statusTardy,
      StatusLeave: cellData.statusLeave,
      ChangedRecord: cellData.ChangedRecord,
      DeleteRecord: cellData.DeleteRecord,
      TriggerDate: `${calYear}-${calMonth}-${currentDateToString}`,
      // TriggerTimeStart: '02:00' 형식
      TriggerTimeStart: `${addString(selectedStartHours)}:${addString(
        selectedStartMinutes
      )}`,
      // TriggerTimeEnd: '12:48' 형식
      TriggerTimeEnd: !cellData.timeToHome
        ? ''
        : `${addString(selectedEndHours)}:${addString(selectedEndMinutes)}`,
      // EndTimeDay: detailWorkData[0].EndTimeDay,
      EndTimeDay: isNextDay,
      ModifyAvailableTimeStart: '',
      ModifyAvailableTimeEnd: '',
      ModifyEndTimeDay: '',
      AttedNote: '',
    },
  }).then(res => {
    console.log(
      '[AC10-030-REPORT] 근무 현황(주간) 수정 API: ',
      `${process.env.COMMON_URL}/attendance/ac10030/attendancereport/${employeeNum}?idx=${selectedStatusIdx}`
    );
    console.log('다음날 퇴근?: ', isNextDay);
    console.log('시간 변경 완료 - res.data.ErrorCode: ', res.data);

    try {
      if (res.data.ErrorCode === 0) {
        return (
          closeModal(), alert('변경이 완료되었습니다'), setIsUpdateStatus(true)
        );
      }

      incorrectToken(res);
    } catch (e) {
      console.error(e);
      console.log(
        `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
      );
    }
  });
};
