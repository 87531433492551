import React, { useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/agGridStyle/ag-theme-custom.css';

import { gridOptions } from './gridOptions'; // 그리드 항목

import { PayslipModal } from '../PayslipModal/PayslipModal';
import { comma } from '../../../lib/utils/comma';
import { CellClickedEvent } from 'ag-grid-community';

interface CellFieldNameType {
  data: string;
  value: string;
  단지: string; // ✅
  구분: string; // ✅
  근무자: string; // ✅근무자 이름
  생년월일: string; // ✅
  입사일: string; // ✅
  기본급: string[]; // ✅과세(ST)
  직책수당: string[]; // ✅과세(ST)
  연차수당: string[]; // ✅과세(ST)
  '시간외/야간(과)': string[]; // ✅과세(ST)
  '시간외/연장(과)': string[]; // ✅과세(ST)
  복지수당: string[]; // ✅과세(ST)
  식대: string[]; // ✅과세(ST)
  차량유지비: string[]; // ✅과세(ST)
  '기타(과)': string[]; // ✅과세(ST)
  과세총계: string[]; // ✅과세(ST) / ='소계1'
  '시간외/야간(비)': string[]; // ✅비과세(S)
  '시간외/연장(비)': string[]; // ✅비과세(S)
  '식대/복지': string[]; // ✅비과세(S)
  '기타(비)': string[]; // ✅비과세(S)
  비과세총계: string[]; // ✅비과세(S) / ='소계2'
  급여총계: string; // ✅급여총계(S9999)
  소득세: string[]; // ✅공제내역(E)
  지방세: string[]; // ✅공제내역(E) / =지방소득세
  국민연금: string[]; // ✅공제내역(E)
  건강보험: string[]; // ✅공제내역(E)
  장기요양: string[]; // ✅공제내역(E)
  고용보험: string[]; // ✅공제내역(E)
  신원보증: string[]; // ✅공제내역(E)
  선지급금: string[]; // ✅공제내역(E)
  연말정산: string[]; // ✅공제내역(E)
  기타공제: string[]; // ✅공제내역(E)
  공제총계: string; //✅공제총계(E9999) / =공제총액
  총액: string; // ✅총액(N9999) / =실제지급액
  명세서: string;
}
interface GridDataType {
  ContractName: string;
  CategoryName: string;
  EmployeeName: string;
  BirthDay: string;
  EnterDate: string;

  STData: Array<{
    ClassCode: string;
    Value: number;
  }>;
  SData: Array<{
    ClassCode: string;
    Value: number;
  }>;
  S9999Value: number;

  EData: Array<{
    ClassCode: string;
    Value: number;
  }>;
  E9999Value: number;
  N9999Value: number;
}

interface GridProps {
  data: [{ ContractData: GridDataType[] }];

  selectedDate: string;
  MMILogoImg: string;
  // setDetectChangingData: Dispatch<SetStateAction<boolean>>; // 개별 데이터 삭제시, 데이터 불러오기 트리거
}

export const Grid = ({ data, selectedDate, MMILogoImg }: GridProps) => {
  console.log('🍏data::: ', data);

  const gridData = data[0]?.ContractData;

  const [openPayslipModal, setOpenPayslipModal] = useState(false);
  const [selectedGridData, setSelectedGridData] = useState({});

  const controlPayslipModal = (
    e: CellClickedEvent<any>
    // e: CellClickedEvent<CellFieldNameType>
    // : CellClickedEvent<
    //   {
    //     단지: string; // ✅
    //     구분: string; // ✅
    //     근무자: string; // ✅근무자 이름
    //     생년월일: string; // ✅
    //     입사일: string; // ✅
    //     기본급: string[]; // ✅과세(ST)
    //     직책수당: string[]; // ✅과세(ST)
    //     연차수당: string[]; // ✅과세(ST)
    //     '시간외/야간(과)': string[]; // ✅과세(ST)
    //     '시간외/연장(과)': string[]; // ✅과세(ST)
    //     복지수당: string[]; // ✅과세(ST)
    //     식대: string[]; // ✅과세(ST)
    //     차량유지비: string[]; // ✅과세(ST)
    //     '기타(과)': string[]; // ✅과세(ST)
    //     과세총계: string[]; // ✅과세(ST) / ='소계1'
    //     '시간외/야간(비)': string[]; // ✅비과세(S)
    //     '시간외/연장(비)': string[]; // ✅비과세(S)
    //     '식대/복지': string[]; // ✅비과세(S)
    //     '기타(비)': string[]; // ✅비과세(S)
    //     비과세총계: string[]; // ✅비과세(S) / ='소계2'
    //     급여총계: string; // ✅급여총계(S9999)
    //     소득세: string[]; // ✅공제내역(E)
    //     지방세: string[]; // ✅공제내역(E) / =지방소득세
    //     국민연금: string[]; // ✅공제내역(E)
    //     건강보험: string[]; // ✅공제내역(E)
    //     장기요양: string[]; // ✅공제내역(E)
    //     고용보험: string[]; // ✅공제내역(E)
    //     신원보증: string[]; // ✅공제내역(E)
    //     선지급금: string[]; // ✅공제내역(E)
    //     연말정산: string[]; // ✅공제내역(E)
    //     기타공제: string[]; // ✅공제내역(E)
    //     공제총계: string; //✅공제총계(E9999) / =공제총액
    //     총액: string; // ✅총액(N9999) / =실제지급액
    //     명세서: string;
    //   },
    //   any
    // >
  ) => {
    if (e.value !== '명세서') {
      return;
    }

    if (e.value === '명세서') {
      setSelectedGridData(e.data);
      setOpenPayslipModal(true);
    }
  };

  return (
    <>
      {openPayslipModal && (
        <PayslipModal
          selectedGridData={selectedGridData}
          setOpenPayslipModal={setOpenPayslipModal}
          selectedDate={selectedDate}
          MMILogoImg={MMILogoImg}
          // apartmentIdx={apartmentIdx}
          // setDetectChangingData={setDetectChangingData}
        />
      )}

      <div id="myGrid" className="ag-theme-alpine ag-theme-custom">
        <AgGridReact
          headerHeight={45} // 타이틀 행 높이
          rowHeight={35} // 본문 행 높이
          gridOptions={gridOptions}
          rowData={
            gridData &&
            gridData.map((data: GridDataType) => {
              console.log('로우데이터data: ', data);

              return {
                단지: data.ContractName, // ✅
                구분: data.CategoryName, // ✅
                근무자: data.EmployeeName, // ✅근무자 이름
                생년월일: data.BirthDay, // ✅
                입사일: data.EnterDate, // ✅

                기본급: data.STData.filter(
                  (item: { ClassCode: string }) => item.ClassCode === 'S1000'
                ).map(item => comma(item.Value)), // ✅과세(ST)
                직책수당: data.STData.filter(
                  item => item.ClassCode === 'S2000'
                ).map(item => comma(item.Value)), // ✅과세(ST)
                연차수당: data.STData.filter(
                  item => item.ClassCode === 'S2100'
                ).map(item => comma(item.Value)), // ✅과세(ST)
                '시간외/야간(과)': data.STData.filter(
                  item => item.ClassCode === 'S2200'
                ).map(item => comma(item.Value)), // ✅과세(ST)
                '시간외/연장(과)': data.STData.filter(
                  item => item.ClassCode === 'S2300'
                ).map(item => comma(item.Value)), // ✅과세(ST)
                복지수당: data.STData.filter(
                  item => item.ClassCode === 'S2400'
                ).map(item => comma(item.Value)), // ✅과세(ST)
                식대: data.STData.filter(
                  item => item.ClassCode === 'S3000'
                ).map(item => comma(item.Value)), // ✅과세(ST)
                차량유지비: data.STData.filter(
                  item => item.ClassCode === 'S3100'
                ).map(item => comma(item.Value)), // ✅과세(ST)
                '기타(과)': data.STData.filter(
                  item => item.ClassCode === 'S2500'
                ).map(item => comma(item.Value)), // ✅과세(ST)
                과세총계: data.STData.filter(
                  item => item.ClassCode === 'S4990'
                ).map(item => comma(item.Value)), // ✅과세(ST) / ='소계1'

                '시간외/야간(비)': data.SData.filter(
                  item => item.ClassCode === 'S5100'
                ).map(item => comma(item.Value)), // ✅비과세(S)
                '시간외/연장(비)': data.SData.filter(
                  item => item.ClassCode === 'S5200'
                ).map(item => comma(item.Value)), // ✅비과세(S)
                '식대/복지': data.SData.filter(
                  item => item.ClassCode === 'S5300'
                ).map(item => comma(item.Value)), // ✅비과세(S)
                '기타(비)': data.SData.filter(
                  item => item.ClassCode === 'S5400'
                ).map(item => comma(item.Value)), // ✅비과세(S)
                비과세총계: data.SData.filter(
                  item => item.ClassCode === 'S9990'
                ).map(item => comma(item.Value)), // ✅비과세(S) / ='소계2'

                급여총계: comma(data.S9999Value), // ✅급여총계(S9999)

                소득세: data.EData.filter(
                  item => item.ClassCode === 'E1000'
                ).map(item => comma(item.Value)), // ✅공제내역(E)
                지방세: data.EData.filter(
                  item => item.ClassCode === 'E1100'
                ).map(item => comma(item.Value)), // ✅공제내역(E) / =지방소득세

                국민연금: data.EData.filter(
                  item => item.ClassCode === 'E2000'
                ).map(item => comma(item.Value)), // ✅공제내역(E)
                건강보험: data.EData.filter(
                  item => item.ClassCode === 'E2100'
                ).map(item => comma(item.Value)), // ✅공제내역(E)
                장기요양: data.EData.filter(
                  item => item.ClassCode === 'E2150'
                ).map(item => comma(item.Value)), // ✅공제내역(E)
                고용보험: data.EData.filter(
                  item => item.ClassCode === 'E2200'
                ).map(item => comma(item.Value)), // ✅공제내역(E)

                신원보증: data.EData.filter(
                  item => item.ClassCode === 'E3000'
                ).map(item => comma(item.Value)), // ✅공제내역(E)
                선지급금: data.EData.filter(
                  item => item.ClassCode === 'E4000'
                ).map(item => comma(item.Value)), // ✅공제내역(E)
                연말정산: data.EData.filter(
                  item => item.ClassCode === 'E4100'
                ).map(item => comma(item.Value)), // ✅공제내역(E)
                기타공제: data.EData.filter(
                  item => item.ClassCode === 'E5000'
                ).map(item => comma(item.Value)), // ✅공제내역(E)

                공제총계: comma(data.E9999Value), //✅공제총계(E9999) / =공제총액
                총액: comma(data.N9999Value), // ✅총액(N9999) / =실제지급액

                명세서: '명세서',
              };
            })
          }
          animateRows={true}
          rowDragManaged={true}
          suppressMoveWhenRowDragging={true}
          pagination={true}
          paginationPageSize={15}
          onCellClicked={e => {
            controlPayslipModal(e);
          }}
        />
        {/* <AgGridReact
          headerHeight={45} // 타이틀 행 높이
          rowHeight={35} // 본문 행 높이
          gridOptions={gridOptions}
          rowData={data.map((data, idx) => {
            return {
              idx: data.idx, // 동
              Addr1: data.Addr1, // 동
              Addr2: data.Addr2, // 호
              CarNo: data.CarNo, // 차량 번호
              // CarFlag: data.CarFlag, // 등록 유형?
              CarFlag: onCarFlag(data.CarFlag), // 등록 유형?
              CreateDateTime: data.CreateDateTime.slice(0, 10), // 등록일
              CarExpireDate: data.CarExpireDate.slice(0, 10), // 만료일

              OwnerName: data.OwnerName, // 차량 소유자
              OwnerPhone: onOwnerPhone(data.OwnerPhone), // 차량 소유자 전화번호

              // EmployeName: data.EmployeName, // 관리자
              // RegEmployeeNo: data.RegEmployeeNo, // 관리자 사번
              modify: data.idx ? '수정' : '', // 수정
              delete: data.idx ? '삭제' : '', // 삭제
            };
          })}
          animateRows={true}
          rowDragManaged={true}
          suppressMoveWhenRowDragging={true}
          pagination={true}
          paginationPageSize={15}
          onCellClicked={e => {
            controlModifyDataModal(e);
            deleteSingleServerData({ e, apartmentIdx, setDetectChangingData });
          }}
          // defaultColDef={{
          //   cellStyle: { textAlign: 'center' }, // 중앙 정렬 스타일 추가
          // }}
        /> */}
      </div>
    </>
  );
};
