import styled from 'styled-components';

export const Bar = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

export const Title = styled.div`
  ${({ theme }) => theme.contentsMainTitle};
  ${({ theme }) => theme.stopDrag};
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EssentialCategorySection = styled.div`
  ${({ theme }) => theme.stopDrag};
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 15px;

  &.on-option {
    padding-bottom: 0;
  }
`;

export const EssentialCategoryWrap = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  padding-left: 10px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-radius: 0.5em;
  color: ${({ theme }) => theme.fontColor.black};
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;

  &.on-option {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  :hover {
    color: ${({ theme }) => theme.mainColor.main};
  }
`;

export const CategorySection = styled.div`
  ${({ theme }) => theme.stopDrag};
  display: flex;
  flex-direction: column;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 15px;
`;

export const CategoryWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0;
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-top: none;
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  background-color: ${({ theme }) => theme.backgroundColor.gray};
`;

export const LoadBtnSection = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 30px;
  margin: 5px 0;
`;

export const CategoryGroup = styled.div`
  /* */
`;

export const CategoryBox = styled.div`
  float: left;
  margin: 0 10px;
`;

export const CategoryName = styled.div`
  font-size: 13px;
  margin-bottom: 5px;
`;

export const GridSection = styled.div`
  height: ${({ theme }) => theme.gridStyle.height};
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

export const ApartmentNameBox = styled.div`
  display: flex;
  align-items: center;
  /* margin-right: 5px; */
  font-weight: 500;
  font-size: 13px;

  p {
    color: ${({ theme }) => theme.fontColor.darkGray};
  }
`;

export const ApartmentIdx = styled.div`
  /* display: flex; */
  color: ${({ theme }) => theme.mainColor.main};
`;

export const ApartmentName = styled.div`
  /* */
`;

export const LoadInfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
`;

export const LoadInfoList = styled.div`
  display: flex;
`;
