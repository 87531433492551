import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid } from '../Grid/Grid';

export const ApartmentInfo = (): any => {
  const navigate = useNavigate();

  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');

  /**
   * permitCode 권한 코드
   * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
   * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
   * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
   */

  const isHeadOfficeManagerCode =
    permitCode === process.env.HEAD_OFFICE_MANAGER_CODE;

  if (!isHeadOfficeManagerCode && !token) {
    alert('로그인부터 해주세요.');
    navigate('/login');
    return;
  } else if (!isHeadOfficeManagerCode) {
    alert('접근 권한이 없습니다.');
    navigate('/');
    return;
  }

  return (
    <>
      <Grid />
    </>
  );
};
