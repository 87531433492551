import React, { useState, useEffect } from 'react';
import Styled from 'styled-components';

import { onLoadList } from '../utils/onLoadList';
import { onSaveData } from '../utils/onSaveData';

interface User {
  OrderNo: number;
  ApprovalName: string;
  isActive: boolean;
}

export const CreateApprovalTable = () => {
  const [validApprovalTable, setValidApprovalTable] = useState(false);

  useEffect(() => {
    onLoadList({ setListData, setInputList });
    // onLoadList({ setListData, setUserList, setInputList });
    // onLoadList({ setUserList });
  }, [validApprovalTable === true]);

  const [listData, setListData] = useState([]);
  const [userList, setUserList] = useState<User[]>([
    { OrderNo: 3, ApprovalName: '', isActive: true },
    { OrderNo: 2, ApprovalName: '', isActive: true },
    { OrderNo: 1, ApprovalName: '', isActive: true },
    { OrderNo: 0, ApprovalName: '', isActive: true },
  ]);
  const [inputList, setInputList] = useState<string[]>([]);
  const [boxIds, setBoxIds] = useState<number[]>([3, 2, 1, 0]); // 박스 ID 변경

  useEffect(() => {
    // if (listData[0]) {
    //   const updatedUserList = listData[0].ApprovalWebData.map((data, idx) => {
    //     const orderNo = listData[0].ApprovalWebData.length - idx - 1; // 역순으로 OrderNo 할당

    //     if (data.ApprovalName === '') {
    //       // return { OrderNo: data.OrderNo, ApprovalName: '', isActive: false };
    //       return { OrderNo: orderNo, ApprovalName: '', isActive: false };
    //     }
    //     // return { OrderNo: data.OrderNo, ApprovalName: '', isActive: true };
    //     return { OrderNo: orderNo, ApprovalName: '', isActive: true };
    //   });

    //   setUserList(updatedUserList);
    // }

    if (listData[0]) {
      const approvalWebData = listData[0].ApprovalWebData;
      const updatedUserList =
        approvalWebData.length > 0
          ? approvalWebData.map((data, idx) => {
              const orderNo = approvalWebData.length - idx - 1;
              if (data.ApprovalName === '') {
                return { OrderNo: orderNo, ApprovalName: '', isActive: false };
              }
              return { OrderNo: orderNo, ApprovalName: '', isActive: true };
            })
          : [
              { OrderNo: 3, ApprovalName: '', isActive: false },
              { OrderNo: 2, ApprovalName: '', isActive: false },
              { OrderNo: 1, ApprovalName: '', isActive: false },
              { OrderNo: 0, ApprovalName: '', isActive: false },
            ];

      setUserList(updatedUserList);
    }
  }, [listData]); // listData가 변경될 때만 실행

  // 👇 user.OrderNo(=boxId 파라미터)가 정확히 일치하는 단 하나에만, 토글 적용을 원할 때 -----start
  // const toggleBoxActivity = (boxId: number) => {
  //   const updatedUserList = userList.map(user => {
  //     if (user.OrderNo === boxId) {
  //       return { ...user, isActive: !user.isActive }; // 상태를 토글
  //     }
  //     return user;
  //   });
  //   setUserList(updatedUserList);
  // };
  // 👆 user.OrderNo(=boxId 파라미터)가 정확히 일치하는 단 하나에만, 토글 적용을 원할 때 -----end

  // 👇 user.OrderNo(=boxId 파라미터)가 일치하는 지점에서 부터 그 이전 값들까지, 토글 적용을 원할 때 -----start
  // const toggleBoxActivity = (boxId: number) => {
  //   const updatedUserList = userList.map(user => {
  //     if (user.isActive === false && user.OrderNo < boxId) {
  //       return user;
  //     }

  //     if (user.OrderNo >= boxId) {
  //       return { ...user, isActive: !user.isActive }; // 상태를 토글
  //     }
  //     return user;
  //   });

  //   setUserList(updatedUserList);
  // };
  // 👆 user.OrderNo(=boxId 파라미터)가 일치하는 지점에서 부터 그 이전 값들까지, 토글 적용을 원할 때 -----start

  const toggleBoxActivity = (boxId: number) => {
    const updatedUserList = userList.map((user, idx) => {
      // [MEMO] boxId = OrderNo (앞에서부터 3 2 1 0)
      if (user.OrderNo === boxId) {
        if (userList[idx].isActive === false) {
          return { ...userList[idx], isActive: true };
        }
      }

      if (user.OrderNo >= boxId) {
        for (let i = idx; i >= 0; i--) {
          if (userList[idx].isActive === true) {
            return { ...userList[i], isActive: false };
          } else if (userList[idx].isActive === false) {
            // false(미사용)일 때 누름(누르면 사용 되어야) -> 현재 포함 왼쪽까지 사용(true)으로
            return { ...userList[i], isActive: false };
          } else if (userList.every(data => data.isActive === true)) {
            return { ...userList[i], isActive: true };
          }
        }
        // return { ...user, isActive: !user.isActive };
        // return { ...userList[i], isActive: !userList[idx].isActive };
        // return { ...user, isActive: false };
      }

      // [MEMO] boxId = OrderNo (앞에서부터 3 2 1 0)
      if (user.OrderNo <= boxId) {
        for (let j = idx; j <= userList.length; j++) {
          if (userList[idx].isActive === true) {
            return { ...userList[j], isActive: true };
          } else if (userList[idx].isActive === false) {
            return { ...userList[j], isActive: true };
          }
        }
      }

      return user;
    });

    setUserList(updatedUserList);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedInputList = [...inputList];
    updatedInputList[index] = e.target.value;
    setInputList(updatedInputList);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === 'Backspace' && e.target.value.length === 1) {
      const updatedInputList = [...inputList];
      updatedInputList[index] = '';
      setInputList(updatedInputList);
    }
  };

  const handleSaveData = async () => {
    const updatedUserList = userList.map((user, index) => ({
      ...user,
      ApprovalName: user.isActive ? inputList[index] : '',
      // ApprovalName: inputList[index] ? inputList[index] : '',
      // OrderNo: index, // 🚨🚨🚨
      OrderNo: user.OrderNo,
    }));
    setUserList(updatedUserList);
    console.log('userList: ', userList );
    console.log('updatedUserList: ', updatedUserList );
    await onSaveData({ userList: updatedUserList, setListData });
  };

  //console.log('😎userList: ', userList);
  //console.log('😎😎inputList: ', inputList);
  //console.log('😎😎😎listData: ', listData);
  //console.log('😎😎😎😎listData[0]: ', listData[0] ? listData[0] : '없다구');

  return (
    <>
      <NoticeBox>
        · 박스 안에 결재자 직급(혹은 이름)을 입력 후, 저장하기 버튼을
        눌러주세요.
        <br />
        · 사용(혹은 미사용) 버튼을 눌러 입력이 가능하거나 가능하지 않은 상태로
        전환 할 수 있습니다.
        <br />
        <div>
          ·{' '}
          <p className="alert">
            미사용이 있는 상태에서 저장하기 버튼을 누를 경우, 미사용 상태인 칸은
            어떠한 정보도 저장하지 않으니 유의해 주세요.
          </p>
        </div>
      </NoticeBox>

      {
        <div>
          {/* <h2 style={{ marginBottom: '20px' }}>미리보기</h2> */}
          <div className="box-container" style={{ display: 'flex' }}>
            {userList.map((user, index) => (
              <ApprovalBoxWrap key={boxIds[index]}>
                {/* 사용/미사용 토글 버튼 */}
                <ApprovalBoxActiveBtn className={`${user.isActive ? 'on' : 'off'}`} onClick={() => toggleBoxActivity(user.OrderNo)} >
                  {user.isActive ? '사용' : '미사용'}
                </ApprovalBoxActiveBtn>
                <ApprovalBox className={`box-${index} ${user.isActive ? 'on' : 'off'}`} >
                  <ApprovalBoxInput
                    type="text"
                    placeholder={user.isActive ? '직급 혹은 이름' : ''}
                    className={`box-input ${user.isActive ? 'on' : 'off'}`}
                    // defaultValue={user.isActive ? inputList[index] : ''}
                    // value={user.isActive ? inputList[index] : ''}
                    value={user.isActive ? (inputList[index] || '') : ''}
                    onChange={e => handleInputChange(e, index)}
                    onKeyDown={e => handleKeyDown(e, index)}
                    disabled={!user.isActive} // isActive가 false일 경우에만 미사용 상태로(비활성화)
                    maxLength={5}
                  />
                </ApprovalBox>
              </ApprovalBoxWrap>
            ))}
          </div>

          <SaveBtn
            // onClick={() => onSaveData({ userList, setListData })}
            onClick={handleSaveData}
          >
            저장하기
          </SaveBtn>
        </div>
      }

      {/* {ApprovalList()} */}
    </>
  );
};

const NoticeBox = Styled.div`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.backgroundColor.gray};
  border-radius:0.5em;
  line-height:20px;

  p {
    display: flex;

    &.bold {
      font-weight: 500;
    }

    &.alert {
      color: red;
    }
  }
`;

const ApprovalBoxWrap = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ApprovalBoxActiveBtn = Styled.button`
  ${({ theme }) => theme.printBtnStyle};
  margin-bottom: 5px;
  cursor: pointer;

  &.on {
    background-color: rgba(168, 242, 167, 0.8);
    /* background-color: ${({ theme }) => theme.statusColor.working}; */
  }

  &.off {
    background-color:  #ededed;
  }
`;

const ApprovalBox = Styled.div`
  display: flex;
  align-items:start;
  width: 100px;
  height: 140px;
  margin-bottom: 20px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;

  &.box-3 {
    border-right: solid 1px black;
    border-left: solid 1px black;
  }

  &.off {
    /* background-color: ${({ theme }) => theme.backgroundColor.gray}; */
    background-color: white;
    border: solid 1px white;
  }
`;

const ApprovalBoxInput = Styled.input`
  display: flex;
  text-align: center;
  width: 100px; /* 임시로 추가 */
  height: 45px;
  padding: 5px;
  border-bottom: 1px solid black;

  ::placeholder {
    color: ${({ theme }) => theme.fontColor.lightGray};
  }

  &.off {
    border-bottom: 1px solid white;
  }
`;

const SaveBtn = Styled.button`
  ${({ theme }) => theme.printBtnStyle};
`;
