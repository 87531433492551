import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../../lib/utils/incorrectToken';

interface onAddDataProps {
  closeModal: () => void;
  setIsUpdateStatus: Dispatch<SetStateAction<boolean>>;
  cellData: any;
  employeeNum: string;
  targetWorkStatusIdx: string | null;
  selectedStartHours: string;
  selectedStartMinutes: string;
  selectedEndHours: string;
  selectedEndMinutes: string;
  calYear: string;
  calMonth: string;
  currentDateToString: string;
  isNextDay: boolean;
  selectedStatusCode: string; // 🔰
  selectedStatusType: string; // 🔰
  selectedStatusIdx: string; // 🔰
}

export const onAddData = async ({
  closeModal,
  setIsUpdateStatus,
  cellData,
  employeeNum,
  targetWorkStatusIdx,
  selectedStartHours,
  selectedStartMinutes,
  selectedEndHours,
  selectedEndMinutes,
  calYear,
  calMonth,
  currentDateToString,
  isNextDay,
  selectedStatusCode, // 🔰
  selectedStatusType, // 🔰
  selectedStatusIdx, // 🔰
}: onAddDataProps) => {
  console.log('💥주간💥cellData: ', cellData);
  // console.log('💥주간💥targetWorkStatusIdx: ', targetWorkStatusIdx);
  console.log('💥주간💥selectedStatusIdx: ', selectedStatusIdx);
  console.log('💥주간💥selectedStatusCode: ', selectedStatusCode);
  console.log('💥주간💥selectedStatusType: ', selectedStatusType);

  const token = sessionStorage.getItem('token');

  function addString(str: string) {
    if (str.length === 1) {
      return '0' + str;
    } else return str;
  }

  function onCurrentYear() {
    if (cellData.isNextMonth) {
      if (cellData.monthOfSunday + 1 > 12) {
        return (cellData.yearOfSunday + 1).toString();
      }
    }
    return cellData.yearOfSunday;
  }

  function onCurrentMonth() {
    if (cellData.isNextMonth) {
      if (cellData.monthOfSunday + 1 > 12) {
        return '01';
      }
      return addString((cellData.monthOfSunday + 1).toString());
    }
    return addString(cellData.monthOfSunday.toString());
  }

  console.log(
    '🎃1🎃주간onAddData/cellData.isNextMonth: ',
    cellData.isNextMonth
  );
  console.log(
    '🎃2🎃주간onAddData/cellData.monthOfSunday: ',
    cellData.monthOfSunday
  );
  console.log('🎃2🎃주간onAddData/onCurrentMonth(): ', onCurrentMonth());

  console.log(
    '⏳1⏳TriggerTimeEnd(selectedStartHours:selectedStartMinutes): ',
    `${addString(selectedEndHours)}:${addString(selectedEndMinutes)}`
  );
  console.log(
    '⏳1⏳년-월-일: ',
    `${onCurrentYear()}-${onCurrentMonth()}-${currentDateToString}`
  );

  await axios({
    url: `${process.env.COMMON_URL}/attendance/ac10030/attendancereport/${employeeNum}`,
    method: 'post',
    headers: { tokenId: token },
    data: {
      // idx: targetWorkStatusIdx,
      idx: 0,
      // AttendStatusCode: 'W1000',
      AttendStatusCode: selectedStatusCode,
      AttendStatusName: '',
      // Working: 'D',
      Working: selectedStatusType,
      StatusTardy: '',
      StatusLeave: '',
      ChangedRecord: '',
      DeleteRecord: '',
      // TriggerDate: `${calYear}-${calMonth}-${currentDateToString}`,
      TriggerDate: `${onCurrentYear()}-${onCurrentMonth()}-${currentDateToString}`,
      // [MEMO] TriggerTimeStart: '02:00' 형식,
      TriggerTimeStart: `${addString(selectedStartHours)}:${addString(
        selectedStartMinutes
      )}`,
      // [MEMO] TriggerTimeEnd: '12:48' 형식,
      TriggerTimeEnd: `${addString(selectedEndHours)}:${addString(
        selectedEndMinutes
      )}`,
      // EndTimeDay: isNextDay,
      EndTimeDay: !isNextDay ? false : true,
      ModifyAvailableTimeStart: '',
      ModifyAvailableTimeEnd: '',
      ModifyEndTimeDay: '',
      AttedNote: '',
    },
    // data: {
    //   idx: targetWorkStatusIdx,
    //   AttendStatusCode: detailWorkData[0].AttendStatusCode,
    //   AttendStatusName: detailWorkData[0].AttendStatusName,
    //   Working: detailWorkData[0].Working,
    //   StatusTardy: detailWorkData[0].StatusTardy,
    //   StatusLeave: detailWorkData[0].StatusLeave,
    //   ChangedRecord: detailWorkData[0].ChangedRecord,
    //   DeleteRecord: detailWorkData[0].DeleteRecord,
    //   TriggerDate: detailWorkData[0].TriggerDate,
    //   // TriggerTimeStart: '02:00',
    //   TriggerTimeStart: `${addString(selectedStartHours)}:${addString(
    //     selectedStartMinutes
    //   )}`,
    //   // TriggerTimeEnd: '12:48',
    //   TriggerTimeEnd: !detailWorkData[0].TriggerTimeEnd
    //     ? ''
    //     : `${addString(selectedEndHours)}:${addString(selectedEndMinutes)}`,
    //   // EndTimeDay: detailWorkData[0].EndTimeDay,
    //   EndTimeDay: isNextDay,
    //   ModifyAvailableTimeStart: detailWorkData[0].ModifyAvailableTimeStart,
    //   ModifyAvailableTimeEnd: detailWorkData[0].ModifyAvailableTimeEnd,
    //   ModifyEndTimeDay: detailWorkData[0].ModifyEndTimeDay,
    //   AttedNote: detailWorkData[0].AttedNote,
    // },
  }).then(res => {
    console.log(
      '[AC10-030-REPORT] 근무 현황(주간) 추가 API: ',
      `${process.env.COMMON_URL}/attendance/ac10030/attendancereport/${employeeNum}`
    );

    try {
      console.log(
        `🙏🙏ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
      );

      if (res.data.ErrorCode === 0) {
        return (
          closeModal(), alert('변경이 완료되었습니다'), setIsUpdateStatus(true)
        );
      }

      incorrectToken(res);
    } catch (e) {
      console.error(e);
      console.log(
        `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
      );
    }
  });
};
