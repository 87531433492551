// [MEMO] 근태 현황
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface loadWorkStatusDataProps {
  setWorkStatusData: Dispatch<SetStateAction<never[]>>;
  workStatusYearPicker: string;
  workStatusMonthPicker: string;
  workStatusDatePicker: string;
}

export const loadWorkStatusData = async ({
  setWorkStatusData,
  workStatusYearPicker,
  workStatusMonthPicker,
  workStatusDatePicker,
}: loadWorkStatusDataProps) => {
  const token = sessionStorage.getItem('token');

  await axios
    .get(
      // https://apihr.mmigroup.co.kr:9020/dashboard/da10010/attendancestatus?Year=2023&Month=5&Day=30'
      `${process.env.COMMON_URL}/dashboard/da10010/attendancestatus?Year=${workStatusYearPicker}&Month=${workStatusMonthPicker}&Day=${workStatusDatePicker}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '메인화면 / 근태 현황 API: ',
      //   `${process.env.COMMON_URL}/dashboard/da10010/attendancestatus?Year=${workStatusYearPicker}&Month=${workStatusMonthPicker}&Day=${workStatusDatePicker}`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          // console.log('메인화면 / 근태 현황 res.data.Data: ', res.data.Data);
          setWorkStatusData(res.data.Data);
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
