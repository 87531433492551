// [MEMO] 임시 사용자 등록 현황
import React from 'react';
import styled from 'styled-components';

interface TemporaryEmployeeSectionProps {
  temporaryEmployeeData: any[];
}

export const TemporaryEmployeeSection = ({
  temporaryEmployeeData,
}: TemporaryEmployeeSectionProps) => {
  // console.log('메인 - temporaryEmployeeData: ', temporaryEmployeeData);

  return (
    <Tables>
      <thead>
        <tr>
          <ThHeader className="start-th">근무지</ThHeader>
          <ThHeader>이름</ThHeader>
          <ThHeader>생년월일</ThHeader>
          <ThHeader>직무</ThHeader>
          <ThHeader>연락처</ThHeader>
          <ThHeader className="end-th">등록일</ThHeader>
        </tr>
      </thead>

      <tbody>
        {temporaryEmployeeData.map((data, idx) => (
          <tr key={data.idx}>
            <TdHeader>{data.ContractName}</TdHeader>
            <TdHeader>{data.TempUserName}</TdHeader>
            <TdHeader>{data.BirthDate}</TdHeader>
            <TdHeader>{data.JobGroupName}</TdHeader>
            <TdHeader>{data.PhoneNumber}</TdHeader>
            <TdHeader>{data.CreateDate}</TdHeader>
          </tr>
        ))}
      </tbody>
    </Tables>
  );
};

const Tables = styled.table`
  width: 100%;
  font-size: 13px;
`;

const ThHeader = styled.th`
  padding: 5px 0;
  background-color: ${({ theme }) => theme.backgroundColor.gray};
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};

  &.end-th {
    border-right: none;
  }
`;

const TdHeader = styled.td`
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
`;
