import React, { useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { modifySingleServerData } from '../utils/modifySingleServerData';

import { ko } from 'date-fns/esm/locale';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import '../style/DatePicker.css';

interface ModalContentsProps {
  closeModal: () => void;
  selectedGridData: {};
  apartmentIdx: number;
  setDetectChangingData: Dispatch<SetStateAction<boolean>>; // 개별 데이터 삭제시, 데이터 불러오기 트리거
}

export const ModalContents = ({
  closeModal,
  selectedGridData,
  apartmentIdx,
  setDetectChangingData,
}: ModalContentsProps) => {
  console.log('🌞selectedGridData', selectedGridData);

  //   RegEmployeeNo: data.RegEmployeeNo
  //   ? data.RegEmployeeNo.toString()
  //   : '',
  // Addr1: data.Addr1 ? data.Addr1.toString() : '',
  // Addr2: data.Addr2 ? data.Addr2.toString() : '',
  // OwnerName: data.OwnerName ? data.OwnerName : '',
  // OwnerPhone: data.OwnerPhone ? data.OwnerPhone.toString() : '',
  // CarNo: data.CarNo,
  // CarFlag: data.CarFlag ? data.CarFlag : '',
  // CarExpireDate: data.CarExpireDate ? data.CarExpireDate : '',
  let modifiedResultData = []; // 새로 추가한 데이터
  const beforeModifyData = selectedGridData;

  const [modifiedAddr1, setModifiedAddr1] = useState(''); // 동
  const [modifiedAddr2, setModifiedAddr2] = useState(''); // 호
  const [modifiedCarFlag, setModifiedCarFlag] = useState(''); // 등록 유형
  const [modifiedOwnerName, setModifiedOwnerName] = useState(''); // 소유주 이름
  const [modifiedOwnerPhone, setModifiedOwnerPhone] = useState(''); // 소유주 연락처

  // 🔥🔥🔥🔥🔥🔥🔥
  // const [modifiedExpiredDate, setModifiedExpiredDate] = useState(new Date()); // 만료일
  const [modifiedExpiredDate, setModifiedExpiredDate] = useState<Date | null>(
    // 사용자가 선택한 날짜가 있을 경우 해당 날짜로 초기화
    selectedGridData.CarExpireDate
      ? new Date(selectedGridData.CarExpireDate)
      : new Date()
  );

  console.log('🍩🍩🍩modifiedExpiredDate: ', modifiedExpiredDate);

  const handleExpiredDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setStartDate(e);
    setModifiedExpiredDate(e.target.value);
  };

  const [carFlagTag, setCarFlagTag] = useState('');

  const handleModifiedAddr1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModifiedAddr1(e.target.value);
  };

  const handleModifiedAddr2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModifiedAddr2(e.target.value);
  };

  const handleOwnerName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModifiedOwnerName(e.target.value);
  };

  const handleOwnerPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModifiedOwnerPhone(e.target.value);
  };

  const handleCarFlag = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModifiedCarFlag(e.target.value);
    setCarFlagTag(e.target.value);
  };

  // 👇 [TAG] 데이트 피커(만료일 날짜) -----start
  const [startDate, setStartDate] = useState(new Date()); // MEMO: Date Picker 라이브러리

  const year = modifiedExpiredDate
    ? modifiedExpiredDate.getFullYear().toString()
    : startDate.getFullYear().toString();
  const month = modifiedExpiredDate
    ? (modifiedExpiredDate.getMonth() + 1).toString()
    : (startDate.getMonth() + 1).toString();
  const date = modifiedExpiredDate
    ? modifiedExpiredDate.getDate().toString()
    : startDate.getDate().toString();

  const calYear = String(year).padStart(2, '0');
  const calMonth = String(month).padStart(2, '0');
  const calDate = String(date).padStart(2, '0');

  // const targetDate = `${calYear}-${calMonth}-${calDate}`;

  console.log('🍺calYear: ', calYear);
  console.log('🍺calMonth: ', calMonth);
  console.log('🍺calDate: ', calDate);

  const targetDate = `${
    modifiedExpiredDate
      ? modifiedExpiredDate.getFullYear().toString().padStart(2, '0')
      : startDate.getFullYear().toString().padStart(2, '0')
  }-${
    modifiedExpiredDate
      ? (modifiedExpiredDate.getMonth() + 1).toString().padStart(2, '0')
      : (startDate.getMonth() + 1).toString().padStart(2, '0')
  }-${
    modifiedExpiredDate
      ? modifiedExpiredDate.getDate().toString().padStart(2, '0')
      : startDate.getDate().toString().padStart(2, '0')
  }`;
  // 👆 [TAG] 데이트 피커(만료일 날짜) -----end

  function filteringAddedData(item) {
    // 👇 동번호(Addr1): '동' 문자열 제거, item = data.Addr1
    if (item === modifiedAddr1 && modifiedAddr1.includes('동')) {
      return modifiedAddr1.replaceAll('동', '');
    }

    // 👇 호수(Addr2): '호' 문자열 제거, item = data.Addr2
    if (item === modifiedAddr2 && modifiedAddr2.includes('호')) {
      return modifiedAddr2.replaceAll('호', '');
    }

    // 👇 전화번호: '-' 문자열 제거, item = data.OwnerPhone
    if (item === modifiedOwnerPhone && modifiedOwnerPhone.includes('-')) {
      return modifiedOwnerPhone.replaceAll('-', '');
    }

    /**
     * 공백 제거 정규표현식 적용 예시:
     * const stringWithSpaces = 'Hello    World    Example';
     * const stringWithoutSpaces = stringWithSpaces.replace(/\s+/g, '');
     * console.log(stringWithoutSpaces); // 'HelloWorldExample'
     */
    // 👇 글자 사이 공백 제거
    if (item) {
      return item.toString().replace(/\s+/g, '');
    } else if (!item) {
      return '';
    }
  }

  function onProcessingAddedData() {
    console.log('modifiedAddr2::: ', modifiedAddr2);
    console.log('::: modifiedExpiredDate::: ', modifiedExpiredDate);

    let targetDate = '';

    // if (onCarFlag(modifiedCarFlag) === 'D') {
    //   const year = modifiedExpiredDate.getFullYear().toString();
    //   const month = (modifiedExpiredDate.getMonth() + 1).toString();
    //   const date = modifiedExpiredDate.getDate().toString();

    //   const calYear = String(year).padStart(2, '0');
    //   const calMonth = String(month).padStart(2, '0');
    //   const calDate = String(date).padStart(2, '0');

    //   targetDate = `${calYear}-${calMonth}-${calDate}`;
    // }

    if (onCarFlag(modifiedCarFlag) === 'D') {
      // targetDate = `${modifiedExpiredDate
      //   .getFullYear()
      //   .toString()
      //   .padStart(2, '0')}-${(modifiedExpiredDate.getMonth() + 1)
      //   .toString()
      //   .padStart(2, '0')}-${modifiedExpiredDate
      //   .getDate()
      //   .toString()
      //   .padStart(2, '0')}`;
      targetDate = `${calYear}-${calMonth}-${calDate}`;
    }

    function onCarFlag(data: string) {
      if (data === '등록') {
        return 'R';
      } else if (data === '임시') {
        return 'T';
      } else if (data === '기간') {
        return 'D';
      }
      return data;
    }

    modifiedResultData = {
      idx: selectedGridData.idx,
      CarNo: selectedGridData.CarNo, // 차량 번호
      // 👇 글자 사이 공백 제거
      // 예시: Addr1: data.Addr1.toString().replace(/\s+/g, ''),
      Addr1:
        modifiedAddr1.length > 0
          ? filteringAddedData(modifiedAddr1.toString()).replace(/\s+/g, '')
          : selectedGridData.Addr1,
      Addr2:
        modifiedAddr2.length > 0
          ? filteringAddedData(modifiedAddr2.toString()).replace(/\s+/g, '')
          : selectedGridData.Addr2,
      // 👇 CarFlag: 등록 유형
      CarFlag: modifiedCarFlag
        ? onCarFlag(modifiedCarFlag)
        : onCarFlag(selectedGridData.CarFlag),

      CarExpireDate: onCarFlag(modifiedCarFlag) === 'D' ? targetDate : '',

      OwnerPhone:
        modifiedOwnerPhone.length > 0
          ? filteringAddedData(modifiedOwnerPhone.toString()).replace(
              /\s+/g,
              ''
            )
          : selectedGridData.OwnerPhone,
      OwnerName:
        modifiedOwnerName.length > 0
          ? modifiedOwnerName.toString().replace(/\s+/g, '')
          : selectedGridData.OwnerName,
    };
  }

  onProcessingAddedData();

  const carFlagOptions = [
    {
      value: '',
      label: selectedGridData.CarFlag
        ? selectedGridData.CarFlag
        : '등록 유형을 선택해주세요',
    },
    { value: 'R', label: '등록' },
    { value: 'T', label: '임시' },
    { value: 'D', label: '기간' },
  ];

  // // selectedGridData.CarFlag에 해당하는 객체를 찾기
  // const selectedOption = carFlagOptions.find(
  //   option => option.value === selectedGridData.CarFlag
  // );

  // // 선택된 옵션이 있으면 해당 레이블을 초기 값으로 설정
  // const initialCarFlagLabel = selectedOption
  //   ? selectedOption.label
  //   : '등록 유형을 선택해주세요';

  // 🔥🔥🔥🔥🔥🔥🔥🔥🔥
  console.log(
    '🚀selectedGridData.CarExpireDate: ',
    selectedGridData.CarExpireDate
  );
  const expiredDate = new Date(selectedGridData.CarExpireDate);
  const expiredDateDate = expiredDate.getDate().toString();

  console.log('🚀expiredDate: ', expiredDate);
  console.log('🚀date: ', date);
  console.log('🚀startDate: ', startDate);

  console.log('🚀🚀modifiedCarFlag: ', modifiedCarFlag);

  // function formatDate(date) {
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const day = String(date.getDate()).padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // }

  // console.log(
  //   '🔥formatDate(selectedGridData.CarExpireDate): ',
  //   selectedGridData.CarExpireDate && formatDate(selectedGridData.CarExpireDate)
  // );

  return (
    <Wrap>
      <SignTextSection>
        <SignText>항목을 수정해주세요</SignText>
      </SignTextSection>

      <CategoryListSection>
        <CategoryBox className="car-number">
          <CategoryTitle>차량 번호</CategoryTitle>
          <CarNumberText>{selectedGridData.CarNo}</CarNumberText>
        </CategoryBox>

        <CategoryBox>
          <LabelStyle htmlFor={'check-invalid-contract'}>동</LabelStyle>
          <InputStyle
            type="text"
            id={'check-invalid-contract'}
            // placeholder={`'동'을 제외하고 입력해주세요`}
            placeholder={
              selectedGridData.Addr1
                ? selectedGridData.Addr1
                : `'동'을 제외하고 입력해주세요`
            }
            onChange={e => handleModifiedAddr1(e)}
          />
        </CategoryBox>

        <CategoryBox>
          <LabelStyle htmlFor={'check-invalid-contract'}>호</LabelStyle>
          <InputStyle
            type="text"
            id={'check-invalid-contract'}
            placeholder={
              selectedGridData.Addr2
                ? selectedGridData.Addr2
                : `'호'를 제외하고 입력해주세요`
            }
            onChange={e => handleModifiedAddr2(e)}
          />
        </CategoryBox>

        {/* 🧡🧡🧡🧡🧡 */}
        <CategoryBox>
          <CategoryTitle>
            등록 유형<p>(필수)</p>
          </CategoryTitle>
          <SelectStyle value={modifiedCarFlag} onChange={handleCarFlag}>
            {carFlagOptions.map(option => (
              <option
                key={option.value}
                value={option.value}
                // defaultValue={selectedGridData.OwnerName}
              >
                {option.label}
              </option>
            ))}
          </SelectStyle>
        </CategoryBox>

        {carFlagTag === 'D' && (
          <CategoryBox>
            <CategoryTitle>만료일</CategoryTitle>
            <div style={{ width: '180px' }}>
              <DatePicker
                locale={ko}
                dateFormat="yyyy-MM-dd"
                // selected={startDate}

                // selected={
                //   selectedGridData.CarExpireDate ? expiredDate : startDate
                // }
                // onChange={
                //   (date: React.SetStateAction<any>) =>
                //     // 🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥
                //     selectedGridData.CarExpireDate
                //       ? setModifiedExpiredDate(date)
                //       : setStartDate(date)

                //   // handleExpiredDate(date)
                //   // setStartDate(date)
                // }

                selected={
                  carFlagTag === 'D'
                    ? modifiedExpiredDate ||
                      new Date(selectedGridData.CarExpireDate)
                    : startDate
                }
                onChange={(date: React.SetStateAction<any>) => {
                  if (carFlagTag === 'D') {
                    setModifiedExpiredDate(date);
                  } else {
                    setStartDate(date);
                  }
                }}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </CategoryBox>
        )}

        <CategoryBox>
          <LabelStyle htmlFor={'check-invalid-contract'}>소유주</LabelStyle>
          <InputStyle
            type="text"
            id={'check-invalid-contract'}
            placeholder={
              selectedGridData.OwnerName
                ? selectedGridData.OwnerName
                : '소유주 이름을 입력해주세요'
            }
            onChange={e => handleOwnerName(e)}
          />
        </CategoryBox>

        <CategoryBox className="last-category">
          <LabelStyle htmlFor={'check-invalid-contract'}>연락처</LabelStyle>
          <InputStyle
            type="text"
            id={'check-invalid-contract'}
            placeholder={
              selectedGridData.OwnerPhone
                ? selectedGridData.OwnerPhone
                : `'-'없이 숫자만 입력해주세요`
            }
            onChange={e => handleOwnerPhone(e)}
          />
        </CategoryBox>
      </CategoryListSection>

      <ButtonList>
        <ButtonStyle
          className="modify-button"
          onClick={() =>
            modifySingleServerData({
              modifiedResultData,
              apartmentIdx,
              closeModal,
              setDetectChangingData,
            })
          }
        >
          수정하기
        </ButtonStyle>
        <ButtonStyle className="close-button" onClick={closeModal}>
          닫기
        </ButtonStyle>
      </ButtonList>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
`;

const SignTextSection = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 30px;
  width: 100%;
`;

const SignText = styled.div`
  font-size: 14px;
`;

const CategoryListSection = styled.div`
  padding: 14px 10px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  border-radius: 5px;
`;

const ButtonList = styled.div`
  margin-top: 15px;
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.printBtnStyle}

  width: 80px;
  cursor: pointer;

  &.modify-button {
    border: solid 1px ${({ theme }) => theme.mainColor.dark};
    background-color: ${({ theme }) => theme.mainColor.main};
    color: white;

    :hover {
      background-color: ${({ theme }) => theme.mainColor.dark};
    }
  }

  &.close-button {
    margin-left: 10px;
  }
`;

const CategoryBox = styled.div`
  display: flex;
  margin-bottom: 5px;

  &.car-number {
    align-items: center;
  }

  &.last-category {
    margin-bottom: 0;
  }
`;

const CarNumberText = styled.div`
  font-size: 13px;
`;

const CategoryTitle = styled.label`
  display: flex;
  align-items: center;
  width: 80px;
  height: 30px;
  font-size: 13px;

  p {
    margin-left: 2px;
    color: red;
    font-size: 11px;
  }
`;

const LabelStyle = styled.label`
  display: flex;
  align-items: center;
  width: 80px;
  height: 30px;
  font-size: 13px;

  p {
    margin-left: 2px;
    color: red;
    font-size: 11px;
  }
`;

const InputStyle = styled.input`
  padding: 0 5px;
  width: 180px;
  height: 30px;
  border-radius: 5px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};

  ::placeholder {
    margin: 0;
    /* color: #999; */
    color: ${({ theme }) => theme.fontColor.lightGray};
    font-size: 13px;
    /* font-style: italic; */
  }
`;

const SelectStyle = styled.select`
  padding: 0 5px;
  text-align: center;
  width: 180px;
  height: 30px;
  background-color: ${({ theme }) => theme.backgroundColor.gray};
  color: ${({ theme }) => theme.fontColor.black};
  border: 1px solid ${({ theme }) => theme.backgroundColor.gray};
  font-size: 13px;
  border-radius: 5px;

  appearance: none; /* 기본 스타일 제거 */
`;
