import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

interface ModalContentsProps {
  detailInfoData: any[];
  image: string[];
}

export const ModalContents = ({
  detailInfoData,
  image,
}: ModalContentsProps) => {
  console.log('🏃‍♀️🏃‍♀️detailInfoData', detailInfoData);
  const token = sessionStorage.getItem('token');

  const dataArray: {
    employeeName: string; // EmployeeName 근무자
    employeeNum: string; // EmployeeNo 사번
    categoryName: string; // CategoryName 소속
    categoryCode: string; //  CategoryCode 소속번호

    GPSAltitude: string; // GPSAltitude GPS고도
    GPSLatitude: string; // GPSLatitude GPS위도
    GPSLongitude: string; // GPSLongitude GPS경도

    locationName: string; // LocationName 장소명
    message: string; // Message 메시지
    reportType: string; // ReportType 특이사항 유형

    trackingGroup: string; // TrackingGroup 종류
    trackingLocation: string; // TrackingLocation 순찰구역
    trackingName: string; // TrackingName 기기이름
    trackingType: string; // TrackingType 기기유형
    triggerType: string; // TrigerType

    attachedFile: {
      // AttachedFile 파일 관련 객체
      FileIdx: number; // FileIdx 파일 인덱스
      FileName: string; // FileName 파일 이름(업로드할 당시 원본 이름)
      ServerFileName: string; // ServerFileName 서버상 파일 이름
    }[];
  }[] = [];

  function loadDetailInfoData() {
    detailInfoData &&
      detailInfoData.forEach(data => {
        let trackingGroupName = ''; // 분류
        let trackingTypeName = ''; // 기기

        if (data.TrackingGroup === 'W') {
          trackingGroupName = '근무';
        } else if (data.TrackingGroup === 'A') {
          // 근태 = 출퇴근
          trackingGroupName = '근태';
        }

        if (data.TrackingType === 'N') {
          trackingTypeName = 'NFC';
        } else if (data.TrackingType === 'B') {
          trackingTypeName = 'Beacon';
        } else if (data.TrackingType === 'G') {
          trackingTypeName = 'GPS';
        } else if (data.TrackingType === 'W') {
          trackingTypeName = 'WIFI';
        }

        const resultData = {
          employeeName: data.EmployeeName, // EmployeeName 근무자
          employeeNum: data.EmployeeNo, // EmployeeNo 사번
          categoryName: data.CategoryName, // CategoryName 소속
          categoryCode: data.CategoryCode, //  CategoryCode 소속번호

          GPSAltitude: data.GPSAltitude, // GPSAltitude GPS고도
          GPSLatitude: data.GPSLatitude, // GPSLatitude GPS위도
          GPSLongitude: data.GPSLongitude, // GPSLongitude GPS경도

          locationName: data.LocaionName, // LocaionName 장소명
          message: data.Message, // Message 메시지
          reportType: data.ReportType, // ReportType 특이사항 유형

          trackingGroup: trackingGroupName, // TrackingGroup 종류
          trackingLocation: data.TrackingLocation, // TrackingLocation 순찰구역
          trackingName: data.TrackingName, // TrackingName 기기이름
          trackingType: data.TrackingType, // TrackingType 기기유형
          triggerType: data.TrigerType, // TrigerType

          attachedFile: data.AttachedFile,

          // attachedFile: {
          //   // AttachedFile 파일 관련 객체
          //   fileIdx: number; // FileIdx 파일 인덱스
          //   fileName: string; // FileName 파일 이름(업로드할 당시 원본 이름)
          //   fileNameAtServer: string; // ServerFileName 서버상 파일 이름
          // }[];
        };

        dataArray.push(resultData);
      });
  }
  loadDetailInfoData();

  console.log('🍕dataArray: ', dataArray);
  console.log('🍕dataArray[0].GPSAltitude고도: ', dataArray[0].GPSAltitude);
  console.log('🍕dataArray[0].GPSLatitude위도: ', dataArray[0].GPSLatitude);
  console.log('🍕dataArray[0].GPSLongitude경도: ', dataArray[0].GPSLongitude);

  const GPSLatitude = dataArray[0].GPSLatitude; // 위도
  const GPSLongitude = dataArray[0].GPSLongitude; // 경도

  const onDownloadReport = async (
    idx: number,
    fileName: string,
    serverFileName: string
  ) => {
    const targetIdx = idx.toString().trim();
    // const targetFileName = fileName.toString(); // 🚨 수정전
    const targetFileName = fileName.toString().trim();
    const targetServerFileName = serverFileName.toString().trim();

    try {
      const response = await axios.get(
        `${process.env.COMMON_URL}/attendance/ar10020/workrecordadd/download/${targetIdx}?attachedfilename=${targetServerFileName}`,
        {
          headers: {
            tokenId: token,
          },
          responseType: 'blob',
        }
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const $a = document.createElement('a');
      $a.href = downloadUrl;
      // $a.download = targetServerFileName; // 🚨 수정전
      $a.download = targetFileName; // 🚨수정후 ->다운로드시 해당 이름(targetServerFileName)이 받은 파일명이 됨
      document.body.appendChild($a);
      $a.click();
      document.body.removeChild($a);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (e) {
      console.error('파일 다운로드 중 에러가 발생했습니다:', e);
    }
  };

  const mapKey = process.env.GOOGLE_MAP_API;
  const GOOGLE_MAP_API = process.env.GOOGLE_MAP_API;
  // const mapURL = `https://maps.googleapis.com/maps/api/staticmap?center=40.714728,-73.998672&zoom=12&size=400x400&key=${mapKey}&signature=MAP`;
  // const mapURL = `https://maps.googleapis.com/maps/api/staticmap?center=40.714728,-73.998672&format=gif&zoom=14&size=400x400&key=${mapKey}&signature=YOUR_SIGNATURE`;
  // center=위도,경도
  // zoom 숫자가 클수록 확대
  // const mapURL = `https://maps.googleapis.com/maps/api/staticmap?center=${GPSLatitude},${GPSLongitude}&format=gif&zoom=14&size=400x400&key=${GOOGLE_MAP_API}`;
  // const mapURL = `https://maps.googleapis.com/maps/api/staticmap?center=${GPSLatitude},${GPSLongitude}&zoom=20&size=600x450&scale=1&markers=color:red%7Clabel:S%7C${GPSLatitude},${GPSLongitude}&key=${GOOGLE_MAP_API}`;

  function locationMapViewer() {
    if (Number(GPSLatitude) <= 0 && Number(GPSLongitude) <= 0) {
      return <div>위치 정보 없음</div>;
    } else {
      const mapURL = `https://maps.googleapis.com/maps/api/staticmap?center=${GPSLatitude},${GPSLongitude}&zoom=19&size=550x450&scale=1&markers=color:red%7Clabel:%7C${GPSLatitude},${GPSLongitude}&key=${GOOGLE_MAP_API}`;
      return <img src={mapURL} />;
    }
  }

  console.log('mapKey: ', mapKey);

  return (
    <Wrap>
      {/* <MapWrap> */}
      {/* <MapSection>
          지도
          <img src={mapURL} />
        </MapSection> */}
      {/* </MapWrap> */}

      <TableWrap>
        <Tables>
          <Thead>
            <Tr className="header">
              {/* 👇 locationName */}
              <Th>위치</Th>
              {/* 👇 message */}
              <Th>메시지</Th>
              {/* 👇 trackingGroup */}
              <Th>종류</Th>
              {/* 👇 trackingLocation */}
              <Th>순찰구역</Th>
              {/* 👇 trackingName */}
              <Th>기기이름</Th>
              {/* 👇 trackingType */}
              <Th>기기유형</Th>
              {/* 👇 reportType */}
              <Th>특이사항</Th>
              <Th className="last">첨부파일</Th>
            </Tr>
          </Thead>

          <tbody>
            {dataArray.map(data => {
              return (
                <>
                  <tr>
                    <Td>{data.locationName}</Td>
                    <Td>{data.message}</Td>
                    <Td>{data.trackingGroup}</Td>
                    <Td>{data.trackingLocation}</Td>
                    <Td>{data.trackingName}</Td>
                    <Td>{data.trackingType}</Td>
                    <Td>{data.reportType}</Td>
                    <Td className="file">
                      <DownloadSection>
                        {/* ----- 첨부파일 -----*/}
                        {/* {image.map((image, index) => (
                          <UserImg
                            key={index}
                            src={image}
                            alt={`image ${index}`}
                          />
                        ))} */}
                        {data.attachedFile.map(file => {
                          {
                            file.FileName ? file.FileName : '';
                          }
                          return (
                            <FileWrap key={file.FileIdx}>
                              <FileList>
                                {file.FileName ? file.FileName : ''}
                              </FileList>
                              {file.FileName && (
                                <DownloadButton
                                  onClick={() =>
                                    onDownloadReport(
                                      file.FileIdx,
                                      file.FileName,
                                      file.ServerFileName
                                    )
                                  }
                                >
                                  다운로드
                                </DownloadButton>
                              )}
                            </FileWrap>
                          );
                        })}
                      </DownloadSection>
                    </Td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Tables>
      </TableWrap>

      {/* <DownloadWrap>
        <CategoryTitleWrap>첨부파일</CategoryTitleWrap>
        <ImageWrap>
          <ImageSection>이미지</ImageSection>
          <DownloadButtonSection> */}
      {/* <DownloadButton>
              <UserImg src={`${image}`} />
              다운로드
            </DownloadButton> */}
      {/* <DownloadButton> */}
      {/* <UserImg src={`${image}`} /> */}
      {/* ----- 첨부파일 -----*/}
      {/* {dataArray.map(data => {
                {
                  data.attachedFile.map(file => {
                    <DownloadButton
                      onClick={() =>
                        onDownloadReport(
                          file.FileIdx,
                          file.FileName,
                          file.ServerFileName
                        )
                      }
                    >
                      다운로드
                    </DownloadButton>;
                  });
                }
              })} */}
      {/* </DownloadButton> */}
      {/* </DownloadButtonSection>
        </ImageWrap>
      </DownloadWrap> */}

      {/* 👇 첨부파일 사진 미리보기 */}
      {/* <DownloadWrap>
        <CategoryTitleWrap>첨부파일</CategoryTitleWrap>
        <ImageWrap>
          {image.map((image, index) => (
            <UserImg key={index} src={image} alt={`image ${index}`} />
          ))}
        </ImageWrap>
      </DownloadWrap> */}
      {/* 👆 첨부파일 사진 미리보기 */}

      <div>
        <CategoryTitleWrap className="location-map">위치정보</CategoryTitleWrap>
        <LocationMapImgWrap>
          {/* <img src={mapURL} /> */}
          {locationMapViewer()}
        </LocationMapImgWrap>
      </div>

      {/* <div>닫기</div> */}
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  color: ${({ theme }) => theme.fontColor.black};
  font-size: 13px;

  margin: 15px;

  /* ----- */
  /* width: auto; */
  width: 600px;
`;

const TableWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border-radius: 0.5em; */
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
`;

const CategoryTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 3px 8px;
  background-color: ${({ theme }) => theme.backgroundColor.gray};
  color: ${({ theme }) => theme.fontColor.black};
  /* border-top-right-radius: 0.5em; */
  /* border-top-left-radius: 0.5em; */
  border-top: none;

  .location-map {
    /*  */
  }
`;

const Tables = styled.table`
  font-size: 13px;
`;

const Thead = styled.thead`
  /*  */
`;

const Tr = styled.tr`
  background-color: ${({ theme }) => theme.backgroundColor.gray};
`;

const Th = styled.th`
  padding: 3px 8px;
  border-right: solid 1px white;

  &.last {
    border-right: ${({ theme }) => theme.backgroundColor.gray};
    /* border-right: solid 1px red; */
  }
`;

const Td = styled.td`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  /* padding: 5px; */

  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray};

  &.file {
    vertical-align: start;
    border-right: none;
  }

  /* 👇 테이블 셀당 스타일 */
  /* height: 120px; */
  padding: 15px 10px;
`;

const DownloadSection = styled.div`
  font-size: 13px;
`;

const FileWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
`;

const FileList = styled.div`
  /* display: flex; */
  /* margin-right: 5px; */

  p {
    /* margin-right: 5px; */
  }
`;

const DownloadButton = styled.button`
  ${({ theme }) => theme.printBtnStyle};

  margin-left: 5px;
`;

const LocationMapImgWrap = styled.div`
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 5px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  border-top: none;

  /* 아래 너비값은 임시 */
  width: 600px;
`;
