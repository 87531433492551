import { Dispatch, SetStateAction } from 'react';

interface onMinusOneYearProps {
  calStartYear: string;
  calStartMonth: string;
  calStartDate: string;
  setStartDate: Dispatch<SetStateAction<any>>;
}

// MEMO: 12개월씩 감소
export const onMinusOneYear = ({
  calStartYear,
  calStartMonth,
  calStartDate,
  setStartDate,
}: onMinusOneYearProps) => {
  const targetMonth = Number(calStartMonth);
  let targetYear = Number(calStartYear);
  // const targetDate = Number(calStartDate);
  const targetDate = 1;

  targetYear = targetYear - 1;

  setStartDate(new Date(`${targetYear}-${targetMonth}-${targetDate}`));
};
