import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/agGridStyle/ag-theme-custom.css';

import { gridOptionsOfficeManager } from './gridOptionsOfficeManager';
import { gridOptionsApartmentManager } from './gridOptionsApartmentManager';
import axios from 'axios';

import { AttachedImageFileModal } from '../AttachedImageFileModal/AttachedImageFileModal';
import { CellClickedEvent } from 'ag-grid-community';

/**
 * permitCode 권한 코드
 * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
 * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
 * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
 */

interface GridProps {
  data: {
    idx: number;
    Addr1: string;
    Addr2: string;
    CarNo: string;
    CarType: string;

    DetArea: string; // 인식 크기(관리자만 확인 가능)
    Conf: string; // 신뢰도1(관리자만 확인 가능)
    RConf: string; // 신뢰도2(관리자만 확인 가능)

    CarFlag: string;
    RepeatCnt: number;
    Flag: string;
    FlagCnt: number;
    CarRegDate: string; // 등록일
    CheckingDateTime: string; // 차량 조회일
    OwnerName: string;
    OwnerPhone: string;
    EmployeName: string;
    RegEmployeeNo: string;
    AttImgSvrFile: string;
  }[];
  imageText: string;
  setImageText: Dispatch<SetStateAction<string>>;
}

export const Grid = ({ data, imageText, setImageText }: GridProps) => {
  console.log('👀data: ', data);

  const dataFoo = data.filter(d => d.AttImgSvrFile);
  console.log('👀사진있는 data: ', dataFoo);

  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련

  // const [imageText, setImageText] = useState('');
  const [openAttachedImageFileModal, setOpenAttachedImageFileModal] =
    useState(false);

  const [downloadFileIdx, setDownloadFileIdx] = useState('');
  const [downloadFileName, setDownloadFileName] = useState('');

  const onPreviewFile = async (e: CellClickedEvent) => {
    // const targetIdx = e.data.recordIdx.toString();
    const targetIdx = e.data.idx.toString();
    const targetFileName = e.data.attachedFileName;
    setDownloadFileIdx(targetIdx);
    setDownloadFileName(targetFileName);

    if (e.colDef.headerName === '첨부파일' && targetFileName.length > 0) {
      // if (e.vale === '파일') {
      try {
        const response = await axios.get(
          // https://dev.mmigroup.co.kr:8020/vehicle/cv10030/checkcarmanage/download/1?attachedfilename=aaa
          `${process.env.COMMON_URL}/vehicle/cv10030/checkcarmanage/download/${targetIdx}?attachedfilename=${targetFileName}`,
          {
            responseType: 'blob',
            headers: {
              tokenId: token,
            },
          }
        );

        // 👇 이미지 다운로드 (임시 주석. 기능은 정상 작동함) -----start
        // const downloadUrl = window.URL.createObjectURL(
        //   new Blob([response.data])
        // );

        // const $a = document.createElement('a');
        // $a.href = downloadUrl;
        // $a.download = targetFileName;
        // document.body.appendChild($a);
        // $a.click();
        // document.body.removeChild($a);
        // window.URL.revokeObjectURL(downloadUrl);
        // 👆 이미지 다운로드 (임시 주석. 기능은 정상 작동함) -----end

        // 👇 이미지 보이기 -----start
        if (response.data instanceof Blob) {
          const previewImage = await new Promise<string>(resolve => {
            const reader = new FileReader();
            reader.onload = (ev: ProgressEvent<FileReader>) => {
              resolve(ev.target?.result as string);
            };
            reader.readAsDataURL(response.data);
          });

          // previewImage를 사용해 필요한 작업 수행하기
          setImageText(previewImage);
          setOpenAttachedImageFileModal(true);
          // 👆 이미지 보이기 -----end
        }
      } catch (e) {
        console.error('파일 다운로드 중 에러가 발생했습니다:', e);
      }
    }
  };

  const onDownloadFile = async (
    downloadFileIdx: string,
    downloadFileName: string
  ) => {
    const targetIdx = downloadFileIdx;
    const targetFileName = downloadFileName;

    try {
      const response = await axios.get(
        // https://dev.mmigroup.co.kr:8020/vehicle/cv10030/checkcarmanage/download/1?attachedfilename=aaa
        `${process.env.COMMON_URL}/vehicle/cv10030/checkcarmanage/download/${targetIdx}?attachedfilename=${targetFileName}`,
        {
          responseType: 'blob',
          headers: {
            tokenId: token,
          },
        }
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const $a = document.createElement('a');
      $a.href = downloadUrl;
      $a.download = targetFileName;
      document.body.appendChild($a);
      $a.click();
      document.body.removeChild($a);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (e) {
      console.error('파일 다운로드 중 에러가 발생했습니다:', e);
    }
  };

  // [MEMO] onCarType: 차량 유형(번호판 구분)
  function onCarType(data: string) {
    if (data === 'N') {
      return '일반';
    } else if (data === 'E') {
      return '전기차';
    } else if (data === 'C') {
      return '영업';
    } else if (data === 'G') {
      return '녹색';
    } else if (data === 'T') {
      return '건설기계';
    } else if (data === 'F') {
      return '미분류';
    }
    return data;
  }

  // [MEMO] onCarFlag: 등록 유형
  function onCarFlag(data: string) {
    if (data === 'R') {
      return '등록';
    } else if (data === 'T') {
      return '임시';
    } else if (data === 'A') {
      return '방문';
    } else if (data === 'N') {
      return '미등록';
    }
    return data;
  }

  // [MEMO] onFlag: 위반 여부
  function onFlag(data: string) {
    if (data === 'N') {
      return '';
    } else if (data === 'M') {
      return '위반';
    }
    return data;
  }

  function onOwnerPhone(data: string) {
    /**
     * String.prototype.startsWith()
     * -https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
     * -startsWith() 메서드는 어떤 문자열이 특정 문자로 시작하는지를 확인하여,
     * 그 결과를 true 혹은 false로 반환한다.
     */
    if (data.length === 11) {
      if (data.startsWith('010')) {
        return `${data.slice(0, 3)}-${data.slice(3, 7)}-${data.slice(7, 11)}`;
      }
    }
    return data;
  }

  // function loadGridOptions() {
  //   if (permitCode === headOfficerManagerCode) {
  //     return gridOptionsOfficeManager;
  //   }
  //   if (permitCode === apartmentManagerCode) {
  //     return gridOptionsApartmentManager;
  //   }
  // }

  function loadRowData(data) {
    if (permitCode === headOfficerManagerCode) {
      return {
        idx: data.idx, //
        Addr1: data.Addr1, // 동
        Addr2: data.Addr2, // 호

        CarNo: data.CarNo, // 차량 번호
        CarType: onCarType(data.CarType), // 차량 유형(번호 유형, 번호판 구분)

        DetArea: data.DetArea, // 인식 크기(관리자만 확인 가능)
        confidenceCheck: `${data.Conf}/${data.RConf}`, // 신뢰도(관리자만 확인 가능)

        CarFlag: onCarFlag(data.CarFlag), // 등록 유형
        RepeatCnt: data.RepeatCnt, // 외부 주차 횟수
        Flag: onFlag(data.Flag), // 위반 여부
        FlagCnt: data.FlagCnt, // 위반 횟수

        CarRegDate: data.CarRegDate.slice(0, 10), // 차량 등록일
        CheckingDateTime: `${data.CheckingDateTime.slice(
          0,
          10
        )} ${data.CheckingDateTime.slice(11, 19)}`, // 주차 확인일

        OwnerName: data.OwnerName, // 차량 소유자
        // OwnerPhone: data.OwnerPhone, // 차량 소유자 전화번호
        OwnerPhone: onOwnerPhone(data.OwnerPhone), // 차량 소유자 전화번호
        EmployeName: data.EmployeName, // 등록인
        // RegEmployeeNo: data.RegEmployeeNo, // 등록인 사번
        attachedFile: data.AttImgSvrFile.length > 0 ? '미리보기' : '', // 첨부파일
        attachedFileName: data.AttImgSvrFile, // 첨부 파일 이름
      };
    }
    if (permitCode === apartmentManagerCode) {
      return {
        idx: data.idx, //
        Addr1: data.Addr1, // 동
        Addr2: data.Addr2, // 호

        CarNo: data.CarNo, // 차량 번호
        CarType: onCarType(data.CarType), // 차량 유형(번호판 구분)

        CarFlag: onCarFlag(data.CarFlag), // 등록 유형
        RepeatCnt: data.RepeatCnt, // 외부주차 횟수
        Flag: onFlag(data.Flag), // 위반 여부
        FlagCnt: data.FlagCnt, // 위반 횟수(🚨API 불러 올 때 FlagCnt(=외부주차 및 위반 확인 기간)와는 별개)

        CarRegDate: data.CarRegDate.slice(0, 10), // 차량 등록일
        CheckingDateTime: `${data.CheckingDateTime.slice(
          0,
          10
        )} ${data.CheckingDateTime.slice(11, 19)}`, // 주차 확인일

        OwnerName: data.OwnerName, // 차량 소유자
        // OwnerPhone: data.OwnerPhone, // 차량 소유자 전화번호
        OwnerPhone: onOwnerPhone(data.OwnerPhone), // 차량 소유자 전화번호
        EmployeName: data.EmployeName, // 등록인
        // RegEmployeeNo: data.RegEmployeeNo, // 등록인 사번
        attachedFile: data.AttImgSvrFile.length > 0 ? '미리보기' : '', // 첨부파일
        attachedFileName: data.AttImgSvrFile, // 첨부 파일 이름
      };
    }
  }

  return (
    <>
      {openAttachedImageFileModal && (
        <AttachedImageFileModal
          setOpenAttachedImageFileModal={setOpenAttachedImageFileModal}
          imageText={imageText}
          onDownloadFile={() =>
            onDownloadFile(downloadFileIdx, downloadFileName)
          }
          downloadFileIdx={downloadFileIdx}
          downloadFileName={downloadFileName}
        />
      )}

      <div id="myGrid" className="ag-theme-alpine ag-theme-custom">
        {permitCode === headOfficerManagerCode && (
          <AgGridReact
            headerHeight={45} // 타이틀 행 높이
            rowHeight={35} // 본문 행 높이
            gridOptions={gridOptionsOfficeManager}
            rowData={data.map(data => loadRowData(data))}
            animateRows={true}
            rowDragManaged={true}
            suppressMoveWhenRowDragging={true}
            pagination={true}
            paginationPageSize={15}
            onCellClicked={e => {
              onPreviewFile(e);
            }}
          />
        )}
        {permitCode === apartmentManagerCode && (
          <AgGridReact
            headerHeight={45} // 타이틀 행 높이
            rowHeight={35} // 본문 행 높이
            gridOptions={gridOptionsApartmentManager}
            rowData={data.map(data => loadRowData(data))}
            animateRows={true}
            rowDragManaged={true}
            suppressMoveWhenRowDragging={true}
            pagination={true}
            paginationPageSize={15}
            onCellClicked={e => {
              onPreviewFile(e);
            }}
          />
        )}
      </div>
    </>
  );
};
