// [MEMO] 일간 근무현황 상태바 우클릭시 나타나는 메뉴 모달 컴포넌트
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import MoreTimeIcon from '@mui/icons-material/MoreTime';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

interface RightClickModalOfAddTimeProps {
  position: { x: number; y: number };
  closeModal: React.MouseEventHandler<HTMLDivElement> | undefined;
  detailWorkData: any;
  setAddModalVisible: Dispatch<SetStateAction<boolean>>;
  rowInfo: any;
}

export const RightClickModalAtEmpty = ({
  position,
  closeModal,
  detailWorkData,
  setAddModalVisible,
  rowInfo,
}: RightClickModalOfAddTimeProps) => {
  const modalStyle = {
    top: position.y + 'px',
    left: position.x + 'px',
  };

  const handleAddModal = () => {
    setAddModalVisible(true);
  };

  const activeAddBtn = () => {
    const currentDate = new Date();
    // Mon Sep 04 2023 15:31:55 GMT+0900
    const timeToHome = rowInfo.expectedTimeToHome;
    // const toHomeDate = new Date('2023-09-04T06:21:13+09:00');
    const toHomeDate = new Date(timeToHome);
    // Mon Sep 04 2023 06:21:13 GMT+0900

    handleAddModal();
  };

  return (
    <Background onClick={closeModal}>
      <Wrap className="modal" style={modalStyle}>
        <Box>
          <MenuButton className="add-menu" onClick={() => activeAddBtn()}>
            <EventAvailableIcon
              sx={{
                fontSize: '18px',
              }}
            />
            <p style={{ marginLeft: '2px' }}>일정 추가</p>
          </MenuButton>
        </Box>
      </Wrap>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 101;
`;

const Wrap = styled.div`
  position: fixed;
  z-index: 100;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;

  /* padding: 8px; */
  padding: 5px;
  padding-top: 0;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  border-radius: 0.5em;
  background-color: white;
`;

const MenuButton = styled.button`
  ${({ theme }) => theme.printBtnStyle}
  display:flex;
  align-items: center;
  margin-top: 5px;
  width: 120px;

  &.add-menu {
    /* margin-bottom: 5px; */
  }
`;
