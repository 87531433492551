import React from 'react';
import styled from 'styled-components';

import { ApartmentInfo } from '../../components/SS10-020-apartment-info/ApartmentInfo/ApartmentInfo';
import RouterTrackerForAnalytics from '../../lib/utils/RouterTrackerForAnalytics'; // [MEMO] 구글 애널리틱스 라우터 트래커

const ApartmentInfoPage = () => {
  const $title = document.head.querySelector('title');
  $title!.innerText = '단지 정보 관리';
  RouterTrackerForAnalytics();

  return (
    <>
      <ApartmentInfo />
    </>
  );
};

export default ApartmentInfoPage;
