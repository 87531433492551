// src/Login/Login.tsx

import { createSlice } from '@reduxjs/toolkit';

export const loggedInUserBasicSlice = createSlice({
  name: 'User Basic Information When Logged In',
  initialState: {
    userInfoValue: '',
    nameKRValue: '',
    employeeNumValue: '',
    userIDValue: '',
    permitCodeValue: '',
    jobNameValue: '',
  },
  reducers: {
    userInfo: (state, action) => {
      state.userInfoValue = action.payload;
    },
    nameKR: (state, action) => {
      // 이름(한글)
      state.nameKRValue = action.payload;
    },
    employeeNum: (state, action) => {
      // 사번
      state.employeeNumValue = action.payload;
    },
    userID: (state, action) => {
      // ID
      state.userIDValue = action.payload;
    },
    permitCode: (state, action) => {
      // 권한 코드
      state.permitCodeValue = action.payload;
    },
    jobName: (state, action) => {
      // 직무
      state.jobNameValue = action.payload;
    },
  },
});

export const { userInfo, nameKR, employeeNum, userID, permitCode, jobName } =
  loggedInUserBasicSlice.actions;
