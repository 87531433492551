import { Buffer } from 'buffer'; // 문자열을 base64로 변환

interface onEncodedStringProps {
  date: string;
}

export const onEncodedString = ({ date }: onEncodedStringProps) => {
  const encodedString = Buffer.from(date)
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');

  return { encodedString };
};
