import React from 'react';
import styled from 'styled-components';

import { Grid } from '../Grid/Grid';

interface HistorySectionSectionProps {
  loading: boolean;
  recordListData: any[];
  apartmentName: string;
}

export const HistorySection = ({
  loading,
  recordListData,
  apartmentName,
}: HistorySectionSectionProps) => {
  if (loading) {
    return (
      <>
        <Bar />
        <LoadingWrap>
          <LoadingGIF src="/loading.gif" />
        </LoadingWrap>
      </>
    );
  }

  return (
    <>
      <Grid recordListData={recordListData} apartmentName={apartmentName} />
    </>
  );
};

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const LoadingGIF = styled.img`
  ${({ theme }) => theme.loadingGIF}
`;

const Bar = styled.div`
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;
