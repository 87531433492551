// [MEMO] 출퇴근 카드 인쇄
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as S from './PersonalWorkCardPrinting.style';

import { onWorkStatusLoadBtnMonthly } from '../utils/onWorkStatusLoadBtnMonthly';
import { ApprovalList } from '../../SP10-020-approval-table-setting/ApprovalList/ApprovalList'; // 결재 박스

import { read, utils, writeFileXLSX, WorkSheet } from 'xlsx'; // 🎅🎅 SheetJS

const PersonalWorkCardPrinting = () => {
  /* 👇 🥺🥺🥺🥺🥺 */
  const queryParams = new URLSearchParams(window.location.search);
  const receivedObject = JSON.parse(queryParams.get('dataObject'));

  console.log('🍕receivedObject: ', receivedObject);
  /* 👆 🥺🥺🥺🥺🥺 */

  /* 👇 🥺🥺🥺🥺🥺 */
  const [employeeDataMonthly, setEmployeeDataMonthly] = useState<any[]>([]);

  const [monthlySchedulerLoading, setMonthlySchedulerLoading] = useState(true);
  const [monthlyPrintingLoading, setMonthlyPrintingLoading] = useState(true);
  const [personalWorkCardLoading, setPersonalWorkCardLoading] = useState(true);

  const employeeData = employeeDataMonthly;
  const calYear = receivedObject.calYear;
  const calMonth = receivedObject.calMonth;

  useEffect(() => {
    onWorkStatusLoadBtnMonthly({
      setEmployeeDataMonthly,
      apartmentContractIdx: receivedObject.apartmentContractIdx, // 아파트 계약 인덱스
      calYear: receivedObject.calYear,
      calMonth: receivedObject.calMonth,
      // setMonthlySchedulerLoading: receivedObject.setMonthlySchedulerLoading,
      // setMonthlyPrintingLoading: receivedObject.setMonthlyPrintingLoading, // [MEMO] 인쇄 관련
      // setPersonalWorkCardLoading: receivedObject.setPersonalWorkCardLoading, // [MEMO] 인쇄 관련
      setMonthlySchedulerLoading,
      setMonthlyPrintingLoading,
      setPersonalWorkCardLoading,
    });
  }, []);
  /* 👆 🥺🥺🥺🥺🥺 */

  const [isListData, setIsListData] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentNameAtSessionStorage = sessionStorage.getItem('apartmentName');

  // 👇👇👇 데이터 받기(useLocation)
  // const dataReceived = location.state; // 기존 (startDate, endDate없이 단독으로 받을때)
  // const employeeData = location.state.employeeData;
  // const calYear = location.state.calYear;
  // const calMonth = location.state.calMonth;
  // 👆👆👆 데이터 받기(useLocation)

  // if (calYear) {
  //   sessionStorage.setItem('selectedCalYear', calYear);
  // }
  // const selectedCalYear = sessionStorage.getItem('selectedCalYear');

  // if (calMonth) {
  //   sessionStorage.setItem('selectedCalMonth', calMonth);
  // }
  // const selectedCalMonth = sessionStorage.getItem('selectedCalMonth');

  const DAYS = ['일', '월', '화', '수', '목', '금', '토'];

  /**
   * [MEMO] targetMonth:
   * Date객체의 월 인덱스는 0부터 시작하기 때문에, 데이터 피커에서 가져 온 달에서 1을 빼야 함
   */
  const targetYear = Number(calYear);
  const targetMonth = Number(calMonth) - 1;
  const date = new Date(targetYear, targetMonth);

  const firstDayOfMonth = new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  ).getDay();

  const lastDateOfMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).getDate();

  // [MEMO] dateArray: 해당 달의 정보(날짜, 요일)
  const dateArray: {
    date: number;
    day: string;
  }[] = [];

  for (let i = 1; i <= lastDateOfMonth; i++) {
    const dayOfMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      i
    ).getDay();

    dateArray.push({
      date: i,
      day: DAYS[dayOfMonth],
    });
  }

  // const beforePage = () => {
  //   // navigate('/workStatusEmployee/monthlySection');
  //   navigate('/AC103/monthlySection');
  // };

  // 👇 [TAG] 인쇄시 불필요한 추가 페이지 없애기 위한 코드(임시)
  useEffect(() => {
    // 페이지가 로드된 후에 실행됩니다.
    const printStyle = document.createElement('style');
    printStyle.innerHTML = `
      @media print {
        /* 여기에 인쇄 시에 적용될 스타일을 정의합니다. */
        .element-to-hide { display: none; }
      }
    `;

    // 스타일을 `<head>` 요소에 추가합니다.
    document.head.appendChild(printStyle);

    // 컴포넌트가 언마운트될 때 스타일을 제거합니다.
    return () => {
      document.head.removeChild(printStyle);
    };
  }, []);
  // 👆 [TAG] 인쇄시 불필요한 추가 페이지 없애기 위한 코드(임시)

  /* 👇 excel export 1 -----start 🎅🎅 */
  /* the component state is an HTML string */
  const [__html, setHtml] = useState('');
  /* the ref is used in export */
  const tableRef = useRef(null);

  /* Fetch and update the state once */
  useEffect(() => {
    (async () => {
      const f = await (
        await fetch('https://sheetjs.com/pres.xlsx')
      ).arrayBuffer();
      const wb = read(f); // parse the array buffer
      const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
      const data = utils.sheet_to_html(ws); // generate HTML
      setHtml(data); // update state
    })();
  }, []);

  /* get live table and export to XLSX */
  const exportExcelFile = useCallback(() => {
    const $table = tableRef.current.getElementsByTagName('table')[0];
    const $tr = $table.querySelectorAll('tr');
    const rowData = Array.from($tr).map(row =>
      Array.from(row.children).map(cell => cell.textContent || cell.innerText)
    );
    const body = rowData;

    // const ws: WorkSheet = utils.aoa_to_sheet([header, ...body]);
    const ws: WorkSheet = utils.aoa_to_sheet([...body]);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');

    writeFileXLSX(wb, '출퇴근카드.xlsx');
  }, [tableRef]);

  const dataForExcel = () => {
    return (
      <div style={{ display: 'none' }}>
        <div ref={tableRef}>
          <table>
            <tbody>
              {employeeData.map(rowEmployeeData => (
                <>
                  <tr>
                    <td>이름</td>
                    <td>사번</td>
                    <td>부서</td>
                    <td>일자</td>
                  </tr>

                  <tr>
                    <td>{rowEmployeeData.UserNameKR}</td>
                    <td>{rowEmployeeData.EmployeeNo}</td>
                    <td>{rowEmployeeData.JobName}</td>
                    <td>
                      {calYear}년 {calMonth}월
                    </td>
                  </tr>

                  <tr>
                    <td>날짜</td>
                    <td>요일</td>
                    <td>출퇴근(출)</td>
                    <td>출퇴근(퇴)</td>
                    <td>연장(시)</td>
                    <td>연장(종)</td>
                    <td>야근(시)</td>
                    <td>야근(종)</td>
                  </tr>

                  {dateArray.map((dateData, index) => (
                    <tr key={index}>
                      <td>{dateData.date}</td>
                      <td>{dateData.day}</td>
                      {rowEmployeeData.WorkingDate.map(rowWorkingData => {
                        if (rowEmployeeData) {
                          const dateSlice = rowWorkingData.TimeToWork.slice(
                            8,
                            10
                          );
                          const formattedDate =
                            dateData.date.length === 1
                              ? `0${dateData.date}`
                              : dateData.date;

                          if (parseInt(dateSlice) === parseInt(formattedDate)) {
                            return (
                              <>
                                {/* 출퇴근 */}
                                <td>
                                  {rowWorkingData.TimeToWork.slice(11, 16)}
                                </td>
                                <td>
                                  {rowWorkingData.TimeToHome
                                    ? rowWorkingData.TimeToHome.slice(11, 16)
                                    : ''}
                                  {rowWorkingData.WorkingData[0].EndTimeDay &&
                                    '(+1)'}
                                  {/* {rowWorkingData.isNextDateTimeToHome && '(+1)'} */}
                                </td>

                                {/* 연장 */}
                                <td />
                                <td />

                                {/* 야근 */}
                                <td />
                                <td />
                              </>
                            );
                          }
                        } else if (!rowEmployeeData) {
                          return (
                            <>
                              {/* 출퇴근 */}
                              <td />
                              <td />

                              {/* 연장 */}
                              <td />
                              <td />

                              {/* 야근 */}
                              <td />
                              <td />
                            </>
                          );
                        }
                      })}
                    </tr>
                  ))}
                  <tr />
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  /* 👆 excel export 1 -----end 🎅🎅 */

  return (
    <>
      <ViewSection>
        <Button className="print" onClick={() => window.print()}>
          인쇄하기
        </Button>
        <Button className="close" onClick={() => window.close()}>
          닫기
        </Button>
        <Button
          style={{ marginLeft: '5px', cursor: 'pointer' }}
          onClick={exportExcelFile}
        >
          근무 카드 내보내기(엑셀)
        </Button>
      </ViewSection>

      {dataForExcel()}

      {/* 추후 Wrap 대신 PrintSection으로 변환할 것 */}
      {employeeData.map((row, idx) => {
        const lastPageIndex = employeeData.length - 1;

        return (
          <>
            <Wrap
            // className={`${idx === lastPageIndex ? 'last-page' : ''}`}
            >
              <S.Tables
                key={idx}
                // style={idx === lastPageIndex ? { display: 'none' } : {}}
                // style={
                //   idx >= employeeData.length - 1 ? { display: 'none' } : {}
                // }

                // 👇 [TAG] 인쇄시 불필요한 추가 페이지 없애기 위한 코드(임시)
                className={`${
                  idx === employeeData.length && 'element-to-hide'
                }`}
                // 👆 [TAG] 인쇄시 불필요한 추가 페이지 없애기 위한 코드(임시)
              >
                <>{`${idx + 1}/${employeeData.length}`}</>
                <tbody>
                  <tr
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      // height: '297mm',
                    }}
                  >
                    <td>
                      <S.TitleSection>
                        <S.TitleBox>출퇴근 카드</S.TitleBox>

                        {/* 👇 🚨🚨🚨🚨🚨 결재 박스 🚨🚨🚨🚨🚨 */}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginBottom: '5px',
                            width: '100%',
                          }}
                        >
                          <ApprovalList setIsListData={setIsListData} />
                        </div>
                        {/* 👆 🚨🚨🚨🚨🚨 결재 박스 🚨🚨🚨🚨🚨 */}

                        <S.InfoBox>
                          <div>단지명: {apartmentNameAtSessionStorage}</div>
                          <div>주식회사 앰앰아이</div>
                        </S.InfoBox>
                      </S.TitleSection>
                    </td>

                    <S.TdEmployeeInfoCategory>
                      <S.EmployeeInfoBox>
                        <S.EmployeeInfoTitle>이름</S.EmployeeInfoTitle>
                        <S.EmployeeInfoContents>
                          {row.UserNameKR}
                        </S.EmployeeInfoContents>
                      </S.EmployeeInfoBox>

                      <S.EmployeeInfoBox>
                        <S.EmployeeInfoTitle>사번</S.EmployeeInfoTitle>
                        <S.EmployeeInfoContents className="right-section">
                          {row.EmployeeNo}
                        </S.EmployeeInfoContents>
                      </S.EmployeeInfoBox>
                    </S.TdEmployeeInfoCategory>

                    <S.TdEmployeeInfoCategory>
                      <S.EmployeeInfoBox className="bottom">
                        <S.EmployeeInfoTitle>부서</S.EmployeeInfoTitle>
                        <S.EmployeeInfoContents>
                          {row.JobName}
                        </S.EmployeeInfoContents>
                      </S.EmployeeInfoBox>

                      <S.EmployeeInfoBox className="bottom">
                        <S.EmployeeInfoTitle>일자</S.EmployeeInfoTitle>
                        <S.EmployeeInfoContents className="right-section">
                          {calYear}년 {calMonth}월
                          {/* {selectedCalYear}년 {selectedCalMonth}월 */}
                        </S.EmployeeInfoContents>
                      </S.EmployeeInfoBox>
                    </S.TdEmployeeInfoCategory>

                    <S.TdResultStatus>
                      <S.DateCountBox className="first-element">
                        <S.DateCountTitle>출근(일)</S.DateCountTitle>
                        <S.DateCountResultNum> - </S.DateCountResultNum>
                      </S.DateCountBox>

                      <S.DateCountBox>
                        <S.DateCountTitle>결근(일)</S.DateCountTitle>
                        <S.DateCountResultNum> - </S.DateCountResultNum>
                      </S.DateCountBox>

                      <S.DateCountBox>
                        <S.DateCountTitle>휴가(일)</S.DateCountTitle>
                        <S.DateCountResultNum> - </S.DateCountResultNum>
                      </S.DateCountBox>

                      <S.DateCountBox>
                        <S.DateCountTitle>출장(일)</S.DateCountTitle>
                        <S.DateCountResultNum> - </S.DateCountResultNum>
                      </S.DateCountBox>

                      <S.ExtraWorkBox>
                        <S.ExtraWorkTitle>연장/야근</S.ExtraWorkTitle>

                        <S.ExtraWorkResultSection>
                          <S.ExtraWorkResultWrap>
                            <S.ExtraWorkResultLeftTitle>
                              연장(시)
                            </S.ExtraWorkResultLeftTitle>
                            <S.ExtraWorkResultLeftNum>
                              0
                            </S.ExtraWorkResultLeftNum>
                          </S.ExtraWorkResultWrap>

                          <S.ExtraWorkResultWrap>
                            <S.ExtraWorkResultRightTitle>
                              야근(시)
                            </S.ExtraWorkResultRightTitle>
                            <S.ExtraWorkResultRightNum>
                              0
                            </S.ExtraWorkResultRightNum>
                          </S.ExtraWorkResultWrap>
                        </S.ExtraWorkResultSection>
                      </S.ExtraWorkBox>

                      <S.ExtraWorkBox>
                        <S.ExtraWorkTitle>지각</S.ExtraWorkTitle>

                        <S.ExtraWorkResultSection>
                          <S.ExtraWorkResultWrap>
                            <S.ExtraWorkResultLeftTitle>
                              (회)
                            </S.ExtraWorkResultLeftTitle>
                            <S.ExtraWorkResultLeftNum>
                              0
                            </S.ExtraWorkResultLeftNum>
                          </S.ExtraWorkResultWrap>
                          <S.ExtraWorkResultWrap>
                            <S.ExtraWorkResultRightTitle>
                              (분)
                            </S.ExtraWorkResultRightTitle>
                            <S.ExtraWorkResultRightNum>
                              0
                            </S.ExtraWorkResultRightNum>
                          </S.ExtraWorkResultWrap>
                        </S.ExtraWorkResultSection>
                      </S.ExtraWorkBox>

                      <S.ExtraWorkBox>
                        <S.ExtraWorkTitle>조퇴</S.ExtraWorkTitle>

                        <S.ExtraWorkResultSection>
                          <S.ExtraWorkResultWrap>
                            <S.ExtraWorkResultLeftTitle>
                              (회)
                            </S.ExtraWorkResultLeftTitle>
                            <S.ExtraWorkResultLeftNum>
                              0
                            </S.ExtraWorkResultLeftNum>
                          </S.ExtraWorkResultWrap>
                          <S.ExtraWorkResultWrap>
                            <S.ExtraWorkResultRightTitle>
                              (분)
                            </S.ExtraWorkResultRightTitle>
                            <S.ExtraWorkResultRightNum>
                              0
                            </S.ExtraWorkResultRightNum>
                          </S.ExtraWorkResultWrap>
                        </S.ExtraWorkResultSection>
                      </S.ExtraWorkBox>
                    </S.TdResultStatus>

                    <S.WorkRecordListTitle>출퇴근기록표</S.WorkRecordListTitle>

                    <S.TdRecordListCategory>
                      <S.RecordListCategoryDatesWrap>
                        <S.RecordListCategoryDatesTitle>
                          일자
                        </S.RecordListCategoryDatesTitle>
                      </S.RecordListCategoryDatesWrap>

                      <S.RecordListCategoryWrap>
                        <S.RecordListCategoryTitle>
                          출퇴근
                        </S.RecordListCategoryTitle>
                        <S.RecordListCategoryContents>
                          <S.RecordListCategoryContentsLeft>
                            출근
                          </S.RecordListCategoryContentsLeft>
                          <S.RecordListCategoryContentsRight>
                            퇴근
                          </S.RecordListCategoryContentsRight>
                        </S.RecordListCategoryContents>
                      </S.RecordListCategoryWrap>

                      <S.RecordListCategoryWrap>
                        <S.RecordListCategoryTitle>
                          연장
                        </S.RecordListCategoryTitle>
                        <S.RecordListCategoryContents>
                          <S.RecordListCategoryContentsLeft className="add-time-start">
                            시작
                          </S.RecordListCategoryContentsLeft>
                          <S.RecordListCategoryContentsRight>
                            종료
                          </S.RecordListCategoryContentsRight>
                        </S.RecordListCategoryContents>
                      </S.RecordListCategoryWrap>

                      <S.RecordListCategoryWrap>
                        <S.RecordListCategoryTitle>
                          야근
                        </S.RecordListCategoryTitle>
                        <S.RecordListCategoryContents>
                          <S.RecordListCategoryContentsLeft>
                            시작
                          </S.RecordListCategoryContentsLeft>
                          <S.RecordListCategoryContentsRight>
                            종료
                          </S.RecordListCategoryContentsRight>
                        </S.RecordListCategoryContents>
                      </S.RecordListCategoryWrap>
                    </S.TdRecordListCategory>

                    {dateArray.map(dateData => (
                      <S.TdRecordList key={dateData.date}>
                        <S.RecordListDatesSection>
                          <S.RecordListDates>
                            {parseInt(dateData.date) < 10
                              ? `0${dateData.date}`
                              : dateData.date}{' '}
                            {dateData.day}
                          </S.RecordListDates>
                        </S.RecordListDatesSection>

                        <S.RecordListResultSection>
                          {row.WorkingDate.map((data, index) => {
                            if (row) {
                              const dateSlice = data.TimeToWork.slice(8, 10);
                              const formattedDate =
                                dateData.date.length === 1
                                  ? `0${dateData.date}`
                                  : dateData.date;

                              if (
                                parseInt(dateSlice) === parseInt(formattedDate)
                              ) {
                                return (
                                  <S.RecordListResult key={data.date}>
                                    <S.RecordListResultLeft key={index}>
                                      {data.TimeToWork.slice(11, 16)}
                                    </S.RecordListResultLeft>

                                    <S.RecordListResultRight>
                                      {data.TimeToHome
                                        ? data.TimeToHome.slice(11, 16)
                                        : ''}
                                      {data.WorkingData[0].EndTimeDay && '(+1)'}
                                      {/* {data.isNextDateTimeToHome && '(+1)'} */}
                                    </S.RecordListResultRight>

                                    <S.RecordListResultLeft>
                                      {/* (연장/시작) */}
                                    </S.RecordListResultLeft>

                                    <S.RecordListResultRight>
                                      {/* (연장/종료) */}
                                    </S.RecordListResultRight>

                                    <S.RecordListResultLeft>
                                      {/* (야근/시작) */}
                                    </S.RecordListResultLeft>

                                    <S.RecordListResultRight className="last-element">
                                      {/* (야근/종료) */}
                                    </S.RecordListResultRight>
                                  </S.RecordListResult>
                                );
                              }
                            }
                            // return null; // 조건에 맞지 않으면 null 반환
                            else if (!row) {
                              return (
                                <S.RecordListResult key={data.date}>
                                  <S.RecordListResultLeft key={index}>
                                    {/*  */}
                                  </S.RecordListResultLeft>

                                  <S.RecordListResultRight>
                                    {/*  */}
                                  </S.RecordListResultRight>

                                  <S.RecordListResultLeft>
                                    {/* (연장/시작) */}
                                  </S.RecordListResultLeft>

                                  <S.RecordListResultRight>
                                    {/* (연장/종료) */}
                                  </S.RecordListResultRight>

                                  <S.RecordListResultLeft>
                                    {/* (야근/시작) */}
                                  </S.RecordListResultLeft>

                                  <S.RecordListResultRight className="last-element">
                                    {/* (야근/종료) */}
                                  </S.RecordListResultRight>
                                </S.RecordListResult>
                              );
                            }
                          })}
                        </S.RecordListResultSection>
                      </S.TdRecordList>
                    ))}
                  </tr>
                </tbody>
              </S.Tables>
            </Wrap>

            {/* <p style={idx === lastPageIndex ? { display: 'none' } : {}} /> */}
          </>
        );
      })}
    </>
  );
};

export default PersonalWorkCardPrinting;

const ViewSection = styled.div`
  position: fixed;
  top: 10px;
  left: 10px;

  /* 인쇄 시에만 출력할 스타일 지정 */
  @media print {
    display: none;
  }
`;

const PrintSection = styled.div`
  background-color: white;

  /* 인쇄 시에만 출력할 스타일 지정 */
  @media print {
    display: block;
    /* size: A4; */
  }

  /* 웹 페이지에서는 보이지 않도록 숨김 처리 */
  /* display: none; */
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 210mm;
  /* height: 297mm; */
  background-color: white;
  padding: 0.5cm;

  @media print {
    display: block;
    size: A4;
    width: 210mm;
    height: 297mm;
    margin: 0;
  }

  &.extra-page {
    @media print {
      display: none;
    }
  }
`;

const Button = styled.button`
  ${({ theme }) => theme.printBtnStyle};

  &.print {
    margin-right: 5px;
    width: 60px;
    height: 30px;
  }

  &.close {
    width: 60px;
    height: 30px;
  }
`;
