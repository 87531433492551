import React from 'react';
import styled from 'styled-components';

import { EmployeeContract } from '../../components/CS10-040-employee-contract/EmployeeContract/EmployeeContract';

import RouterTrackerForAnalytics from '../../lib/utils/RouterTrackerForAnalytics'; // [MEMO] 구글 애널리틱스 라우터 트래커

const EmployeeContractPage = () => {
  const $title = document.head.querySelector('title');
  $title!.innerText = '조직원 계약';
  RouterTrackerForAnalytics();

  return (
    <>
      <EmployeeContract />
    </>
  );
};

export default EmployeeContractPage;
