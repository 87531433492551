// src/Login/Login.tsx

import { createSlice } from '@reduxjs/toolkit';

export const loggedInMenuDataSlice = createSlice({
  name: 'Menu List Received When Logged In',
  initialState: {
    menuDataValue: [],
  },
  reducers: {
    menuData: (state, action) => {
      state.menuDataValue = action.payload;
    },
  },
});

export const { menuData } = loggedInMenuDataSlice.actions;
