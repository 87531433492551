// [MEMO] 근무 보고서 인쇄
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as S from './WorkRecordReportPrinting.styled';

import { onLoadBtn } from '../utils/onLoadBtn';

import { ApprovalList } from '../../SP10-020-approval-table-setting/ApprovalList/ApprovalList'; // 결재 박스

import { read, utils, writeFileXLSX, WorkSheet } from 'xlsx'; //🎅🎅 SheetJS

/**
 * permitCode 권한 코드
 * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
 * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
 * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
 */

interface dataReceivedProps {
  // dataReceived 객체의 프로퍼티 및 타입을 정의
  TrackingLocation: string; // 순찰구역
  CategoryName: string; // 소속
  EmployeeName: string; // 근무자
  EmployeeNo: string; // 사번
  TrigerTime: string;
  Action: string;
  TrackingGroup: string;
  TrackingType: string;
  AttacheFile: string;
  AttacheReport: number;
  idx: number;
}

const WorkRecordReportPrinting = () => {
  /* 👇 🥺🥺🥺🥺🥺 */
  const queryParams = new URLSearchParams(window.location.search);
  const receivedObject = JSON.parse(queryParams.get('dataObject'));
  /* 👆 🥺🥺🥺🥺🥺 */

  const location = useLocation();

  const [isListData, setIsListData] = useState(false);

  /* 👇 🥺🥺🥺🥺🥺 */
  const [recordListData, setRecordListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataReceived = recordListData;

  useEffect(() => {
    onLoadBtn({
      setLoading,
      setRecordListData,
      apartmentContractIdx: receivedObject.apartmentContractIdx,
      selectedContractStart: receivedObject.selectedContractStart,
      selectedContractEnd: receivedObject.selectedContractEnd,
    });
  }, []);
  /* 👆 🥺🥺🥺🥺🥺 */

  // 👇 [TAG] 데이터 받기(useLocation) -----start
  // const dataReceived = location.state; // 기존 (startDate, endDate없이 단독으로 받을때)
  // const dataReceived = location.state.recordListData;
  // const calStartYear = location.state.calStartYear;
  // const calStartMonth = location.state.calStartMonth;
  // const calStartDate = location.state.calStartDate;
  // const calEndYear = location.state.calEndYear;
  // const calEndMonth = location.state.calEndMonth;
  // const calEndDate = location.state.calEndDate;
  // 👆 [TAG] 데이터 받기(useLocation) -----end

  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentNameAtSessionStorage = sessionStorage.getItem('apartmentName');

  const startDate = `${receivedObject.selectedContractStart.slice(
    0,
    4
  )}-${receivedObject.selectedContractStart.slice(
    4,
    6
  )}-${receivedObject.selectedContractStart.slice(6, 8)}`;
  const endDate = `${receivedObject.selectedContractEnd.slice(
    0,
    4
  )}-${receivedObject.selectedContractEnd.slice(
    4,
    6
  )}-${receivedObject.selectedContractEnd.slice(6, 8)}`;

  const dataArray: {
    apartmentName: string; // 단지명
    trackingLocation: string; // 순찰구역
    categoryName: string; // 소속
    employeeName: string; // 근무자
    employeeNum: string; // 사번
    date: string; // 날짜
    time: string; // 시간
    action: string;
    trackingGroup: string;
    trackingType: string;
    checkAttachedReport: boolean; // 보고서
    checkAttachedFile: boolean; // 첨부파일
    attachedFileName: string;
    recordIdx: number;
  }[] = [];

  function loadRecordListData() {
    dataReceived &&
      dataReceived.forEach((data: dataReceivedProps) => {
        let trackingGroupName = ''; // 분류
        let trackingTypeName = ''; // 기기
        let actionName = ''; // 행동
        let checkAttachedReport = false; // 보고서
        let checkAttachedFile = false; // 첨부파일

        if (data.TrackingGroup === 'W') {
          trackingGroupName = '근무';
        } else if (data.TrackingGroup === 'A') {
          // 근태 = 출퇴근
          trackingGroupName = '근태';
        }

        if (data.TrackingType === 'N') {
          trackingTypeName = 'NFC';
        } else if (data.TrackingType === 'B') {
          trackingTypeName = 'Beacon';
        } else if (data.TrackingType === 'G') {
          trackingTypeName = 'GPS';
        } else if (data.TrackingType === 'W') {
          trackingTypeName = 'WIFI';
        }

        if (data.Action === 'I') {
          actionName = 'IN';
        } else if (data.Action === 'L') {
          // L = 특정 구역 벗어남(전체 범위 내에는 있음)
          actionName = 'LEAVE';
        } else if (data.Action === 'O') {
          // O = 전체 구역 벗어남
          actionName = 'OUT';
        } else if (data.Action === 'A') {
          actionName = '태그';
        }

        if (data.AttacheFile.length > 0) {
          checkAttachedReport = true;
        } else checkAttachedReport = false;

        if (data.AttacheReport > 0) {
          checkAttachedFile = true;
        } else checkAttachedFile = false;

        const resultData = {
          apartmentName: apartmentNameAtSessionStorage, // 단지명
          trackingLocation: data.TrackingLocation, // 순찰구역
          categoryName: data.CategoryName, // 소속
          employeeName: data.EmployeeName, // 근무자
          employeeNum: data.EmployeeNo, // 사번
          date: data.TrigerTime.slice(0, 10), // 날짜
          time: data.TrigerTime.slice(11, 19), // 시간
          action: actionName,
          trackingGroup: trackingGroupName,
          trackingType: trackingTypeName,
          checkAttachedReport: checkAttachedReport,
          checkAttachedFile: checkAttachedFile,
          attachedFileName: data.AttacheFile,
          recordIdx: data.idx,
        };

        dataArray.push(resultData);
      });
  }
  loadRecordListData();

  // 👇 오늘 날짜
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const today = `${year}-${month}-${day}`;

  /* 👇 [TAG] 배열 자르기 -----start */
  // const itemsPerFirstPage = 30; // 첫 페이지에 출력할 항목 수
  // isListData === true -> 결재자가 아무도 없는 상태(결재박스 없는 상태)
  const itemsPerFirstPage = isListData === true ? 36 : 32; // 첫 페이지에 출력할 항목 수
  const itemsPerPage = 44; // (첫 페이지 이후) 한 페이지에 출력할 항목 수

  // 데이터를 페이지당 항목 수로 분할
  const pages = [];
  let pageIndex = 0;

  for (let i = 0; i < dataArray.length; i++) {
    if (!pages[pageIndex]) {
      pages[pageIndex] = [];
    }

    pages[pageIndex].push(dataArray[i]);

    // if (pages[pageIndex].length === itemsPerPage) {
    //   pageIndex++;
    // }

    if (
      pages[pageIndex].length ===
      (pageIndex === 0 ? itemsPerFirstPage : itemsPerPage)
    ) {
      pageIndex++;
    }
  }
  /* 👆 [TAG] 배열 자르기 -----end */

  /* 👇 excel export 1 -----start 🎅🎅 */
  /* the component state is an HTML string */
  const [__html, setHtml] = useState('');
  /* the ref is used in export */
  const tableRef = useRef(null);

  /* Fetch and update the state once */
  useEffect(() => {
    (async () => {
      const f = await (
        await fetch('https://sheetjs.com/pres.xlsx')
      ).arrayBuffer();
      const wb = read(f); // parse the array buffer
      const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
      const data = utils.sheet_to_html(ws); // generate HTML
      setHtml(data); // update state
    })();
  }, []);

  /* get live table and export to XLSX */
  const exportExcelFile = useCallback(() => {
    const columns = [
      {
        name: '날짜',
      },
      {
        name: '소속',
      },
      {
        name: '근무자',
      },
      {
        name: '시간',
      },
      {
        name: '순찰구역',
      },
      {
        name: '기기유형',
      },
    ];

    const $table = tableRef.current.getElementsByTagName('table')[0];
    console.log('🥗1🥗tableRef: ', tableRef);
    console.log('🥗2🥗tableRef.current: ', tableRef.current);
    const $tr = $table.querySelectorAll('tr');

    // 🍔🍔기존
    // const data = Array.from(rows).map(row =>
    //   Array.from(row.children).map(cell => cell.textContent || cell.innerText)
    // );
    const columnData = columns.map(column => column.name);

    // 🍔🍔추가
    const rowData = Array.from($tr).map(row =>
      Array.from(row.children).map(cell => cell.textContent || cell.innerText)
    );
    console.log('🍖1🍖rowData: ', rowData);
    console.log(
      '🍖2🍖Array.from...: ',
      Array.from($tr).map(row => Array.from(row.children))
    );

    // const header = data[0]; // 첫 번째 행을 헤더로 사용 🍔🍔기존
    // const body = data.slice(1); // 나머지는 본문 데이터 🍔🍔기존
    const header = columnData; //
    const body = rowData;

    const ws: WorkSheet = utils.aoa_to_sheet([header, ...body]);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');

    writeFileXLSX(wb, '근무보고서.xlsx');
  }, [tableRef]);

  const dataForExcel = () => {
    return (
      <div style={{ display: 'none' }}>
        <div ref={tableRef}>
          <Tables>
            <tbody>
              {dataArray.map((row, index) => (
                <tr key={index}>
                  <td>{row.date}</td>
                  <td>{row.categoryName}</td>
                  <td>{row.employeeName}</td>
                  <td>{row.time}</td>
                  <td>{row.trackingLocation}</td>
                  <td>{row.trackingType}</td>
                </tr>
              ))}
            </tbody>
          </Tables>
        </div>
      </div>
    );
  };
  /* 👆 excel export 1 -----end 🎅🎅 */

  return (
    <>
      <ViewSection>
        <Button className="print" onClick={() => window.print()}>
          인쇄하기
        </Button>
        <Button className="close" onClick={() => window.close()}>
          닫기
        </Button>
        <Button
          style={{ marginLeft: '5px', cursor: 'pointer' }}
          onClick={exportExcelFile}
        >
          보고서 내보내기(엑셀)
        </Button>
      </ViewSection>

      {/* <div style={{ display: 'none' }}> */}
      {/* <div ref={tableRef}>
          <Tables>
            <tbody>
              {dataArray.map((row, index) => (
                <tr key={index}>
                  <td>{row.date}</td>
                  <td>{row.categoryName}</td>
                  <td>{row.employeeName}</td>
                  <td> {row.time}</td>
                  <td> {row.trackingLocation}</td>
                  <td> {row.trackingType}</td>
                </tr>
              ))}
            </tbody>
          </Tables>
        </div> */}
      {/* </div> */}

      {dataForExcel()}

      {/* <PrintSection ref={tableRef}> 🍔🍔 */}
      <PrintSection>
        <TitleSection>
          <TitleBox>근무 보고서</TitleBox>
          {/* 👇 [MEMO] 결재 박스 */}
          <ApprovalListSection>
            <ApprovalList setIsListData={setIsListData} />
          </ApprovalListSection>
          {/* 👆 [MEMO] 결재 박스 */}

          <InfoBox>
            <div>
              단지명:
              {permitCode === apartmentManagerCode &&
                (apartmentNameAtSessionStorage
                  ? apartmentNameAtSessionStorage
                  : '')}
              {permitCode === headOfficerManagerCode &&
                (receivedObject.apartmentName
                  ? receivedObject.apartmentName
                  : '')}
            </div>
            <div>주식회사 앰앰아이</div>
          </InfoBox>
        </TitleSection>

        <ReportHeaderLineWrap>
          <ReportHeaderLineBox>
            <ReportHeaderTitle>기간</ReportHeaderTitle>
            <ReportHeaderContents>
              {startDate} ~ {endDate}
            </ReportHeaderContents>
          </ReportHeaderLineBox>

          <ReportHeaderLineBox>
            <ReportHeaderTitle>작성일</ReportHeaderTitle>
            <ReportHeaderContents className="right-section">
              {today}
            </ReportHeaderContents>
          </ReportHeaderLineBox>
        </ReportHeaderLineWrap>

        <ReportHeaderLineWrap>
          <ReportHeaderLineBox>
            <ReportHeaderTitle>소속</ReportHeaderTitle>
            <ReportHeaderContents>전체</ReportHeaderContents>
          </ReportHeaderLineBox>

          <ReportHeaderLineBox>
            <ReportHeaderTitle>근무자</ReportHeaderTitle>
            <ReportHeaderContents className="right-section">
              전체
            </ReportHeaderContents>
          </ReportHeaderLineBox>
        </ReportHeaderLineWrap>

        <ReportHeaderLineWrap>
          <ReportHeaderLineBox className="bottom">
            <ReportHeaderTitle>행동</ReportHeaderTitle>
            <ReportHeaderContents>순찰</ReportHeaderContents>
          </ReportHeaderLineBox>

          <ReportHeaderLineBox>
            <ReportHeaderTitle>건수</ReportHeaderTitle>
            <ReportHeaderContents className="right-section">
              {dataArray.length}
            </ReportHeaderContents>
          </ReportHeaderLineBox>
        </ReportHeaderLineWrap>

        {pages.map((page, pageIndex) => {
          const lastPageIndex = pages.length - 1;

          return (
            <>
              <RecordListTitle>근무기록표</RecordListTitle>
              <RecordListHeaderSection>
                <RecordListHeaderBox className="date">
                  <RecordListCategoryTitle>날짜</RecordListCategoryTitle>
                </RecordListHeaderBox>

                <RecordListHeaderBox className="job-group">
                  <RecordListCategoryTitle>소속</RecordListCategoryTitle>
                </RecordListHeaderBox>

                <RecordListHeaderBox className="employee-name">
                  <RecordListCategoryTitle>근무자</RecordListCategoryTitle>
                </RecordListHeaderBox>

                <RecordListHeaderBox className="time">
                  <RecordListCategoryTitle>시간</RecordListCategoryTitle>
                </RecordListHeaderBox>

                <RecordListHeaderBox className="location">
                  <RecordListCategoryTitle>순찰구역</RecordListCategoryTitle>
                </RecordListHeaderBox>

                <RecordListHeaderBox className="action">
                  <RecordListCategoryTitle>기기유형</RecordListCategoryTitle>
                </RecordListHeaderBox>
              </RecordListHeaderSection>
              <Tables>
                <tbody>
                  {page.map((row, index) => (
                    <>
                      <TrRecordListResultWrap key={pageIndex}>
                        <RecordListResultBox className="date">
                          <TdRecordListResultContents>
                            {row.date}
                          </TdRecordListResultContents>
                        </RecordListResultBox>
                        <RecordListResultBox className="job-group">
                          <TdRecordListResultContents>
                            {row.categoryName}
                          </TdRecordListResultContents>
                        </RecordListResultBox>
                        <RecordListResultBox className="employee-name">
                          <TdRecordListResultContents>
                            {row.employeeName}
                          </TdRecordListResultContents>
                        </RecordListResultBox>
                        <RecordListResultBox className="time">
                          <TdRecordListResultContents>
                            {row.time}
                          </TdRecordListResultContents>
                        </RecordListResultBox>
                        <RecordListResultBox className="location">
                          <TdRecordListResultContents>
                            {row.trackingLocation}
                          </TdRecordListResultContents>
                        </RecordListResultBox>
                        <RecordListResultBox className="action">
                          <TdRecordListResultContents>
                            {row.trackingType}
                          </TdRecordListResultContents>
                        </RecordListResultBox>
                      </TrRecordListResultWrap>
                    </>
                  ))}
                </tbody>
              </Tables>
              {pageIndex + 1} / {pages.length}
              <p
                style={
                  pageIndex === lastPageIndex
                    ? { display: 'none' }
                    : { pageBreakBefore: 'always' }
                }
              />
            </>
          );
        })}
      </PrintSection>
    </>
  );
};

export default WorkRecordReportPrinting;

const ViewSection = styled.div`
  position: fixed;
  top: 10px;
  left: 10px;

  /* 인쇄 시에만 출력할 스타일 지정 */
  @media print {
    display: none;
  }
`;

const PrintSection = styled.div`
  /* 웹 페이지 상에서 보이는 스타일 */
  /* display: none; -> 웹 페이지에서는 보이지 않도록 숨김 처리 */
  width: 210mm;
  height: 297mm;
  margin: 0;
  padding: 0.5cm;
  background-color: white;

  /* 인쇄 시에만 유효한 스타일(출력할 스타일 지정) */
  @media print {
    display: block;
    size: A4;
    width: 210mm;
    height: 297mm;
    margin: 0;
  }
`;

const Button = styled.button`
  ${({ theme }) => theme.printBtnStyle};

  &.print {
    margin-right: 5px;
    width: 60px;
    height: 30px;
  }

  &.close {
    width: 60px;
    height: 30px;
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5mm;
  margin-bottom: 5.8mm;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
`;

const ApprovalListSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  width: 100%;
`;

const InfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`;

const ReportHeaderLineWrap = styled.div`
  display: flex;
  font-size: 14px;
`;

const ReportHeaderLineBox = styled.div`
  display: flex;
  width: 50%;
  border-top: solid 1px black;
`;

const ReportHeaderTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  width: 30%;
  border-right: solid 1px black;
  border-left: solid 1px black;
  font-weight: 600;
`;

const ReportHeaderContents = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  padding: 5px 0;
  width: 70%;

  &.right-section {
    border-right: solid 1px black;
  }
`;

const RecordListTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  border: solid 1px black;
  background-color: lightGray;
  font-size: 15px;
  font-weight: 600;
`;

const RecordListHeaderSection = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 600;
`;

const RecordListHeaderBox = styled.div`
  padding: 5px 0;
  border-bottom: solid 1px black;

  &.date {
    width: 10%;
    border-left: solid 1px black; /* 필수 - 바깥 테두리 */
  }

  &.job-group {
    width: 20%;
  }

  &.employee-name {
    width: 20%;
  }

  &.time {
    width: 20%;
  }

  &.location {
    width: 20%;
  }

  &.action {
    width: 10%;
    border-right: solid 1px black; /* 필수 - 바깥 테두리 */
  }
`;

const RecordListCategoryTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Tables = styled.table`
  width: 100%;
`;

// const RecordListResultWrap = styled.div`
//   display: flex;
//   /* height: 25px; */ /* 결재 박스 관련(기존) */
//   height: 23px; /* 결재 박스 관련(수정 후) */
// `;

const TrRecordListResultWrap = styled.tr`
  display: flex;
  /* height: 25px; */ /* 결재 박스 관련(기존) */
  height: 23px; /* 결재 박스 관련(수정 후) */
`;

const RecordListResultBox = styled.div`
  display: flex;
  flex-direction: column;

  &.date {
    width: 10%;
    border-left: solid 1px black; /* 필수 - 바깥 테두리 */
  }

  &.job-group {
    width: 20%;
  }

  &.employee-name {
    width: 20%;
  }

  &.time {
    width: 20%;
  }

  &.location {
    width: 20%;
  }

  &.action {
    width: 10%;
    border-right: solid 1px black; /* 필수 - 바깥 테두리 */
  }
`;

// const RecordListResultContents = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 25px;
//   border-bottom: solid 1px black;
//   font-size: 10px;
//   font-weight: 600;

//   p {
//     margin-right: 5px;
//   }
// `;

const TdRecordListResultContents = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25px;
  border-bottom: solid 1px black;
  font-size: 10px;
  font-weight: 600;

  p {
    margin-right: 5px;
  }
`;
