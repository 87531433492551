import React from 'react';
import styled from 'styled-components';

import { CarResistManagement } from '../../components/CV10-020-car-resist-management/CarResistManagement/CarResistManagement';

import RouterTrackerForAnalytics from '../../lib/utils/RouterTrackerForAnalytics'; // [MEMO] 구글 애널리틱스 라우터 트래커

const CarRegistManagementPage = () => {
  const $title = document.head.querySelector('title');
  $title!.innerText = '차량 등록 관리';
  RouterTrackerForAnalytics();

  return (
    <>
      <CarResistManagement />
    </>
  );
};

export default CarRegistManagementPage;
