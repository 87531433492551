import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';

import { uploadNewData } from '../utils/uploadNewData'; // 신규 등록
import { uploadSyncData } from '../utils/uploadSyncData'; // 동기화

interface ModalContentsProps {
  closeModal: () => void;
  apartmentIdx: number;

  filteredNewAddedData: [];
  modifiedData: [];
  onlyExistentServerData: [];
  resultData: [];
  resultData2: [];

  setLoadedData: Dispatch<SetStateAction<[]>>;

  setLoading: Dispatch<SetStateAction<boolean>>;
  setOpenBeforeResistGuideModal: Dispatch<SetStateAction<boolean>>;
  setDetectChangingData: Dispatch<SetStateAction<boolean>>;
}

export const ModalContents = ({
  closeModal,
  apartmentIdx,

  filteredNewAddedData,
  modifiedData,
  onlyExistentServerData,
  resultData,
  resultData2,

  setLoadedData,
  setLoading,
  setOpenBeforeResistGuideModal,
  setDetectChangingData,
}: ModalContentsProps) => {
  const [checkedNewRegist, setCheckedNewRegist] = useState(true);
  const [checkedSync, setCheckedSync] = useState(false);
  const [isCheckedSync, setIsCheckedSync] = useState(false);

  function switchCheckedNewRegist() {
    setCheckedNewRegist(true);
    setCheckedSync(false);
    setIsCheckedSync(false);
  }

  function switchCheckedSync() {
    setCheckedNewRegist(false);
    setCheckedSync(true);
    setIsCheckedSync(true);
  }

  const checkedSyncNum = () => {
    if (isCheckedSync === false) {
      // 신규/수정
      // resultData 사용
      return 0;
    }
    if (isCheckedSync === true) {
      // 동기화
      // resultData2 사용
      return 1;
    }
  };

  return (
    <Wrap>
      <SignTextSection>
        <SignText>다음 사항이 변경됩니다. 계속 진행하시겠습니까?</SignText>
      </SignTextSection>

      <OptionSection>
        <CheckBoxList>
          <div>
            <input
              type="checkbox"
              id={'check-new-regist'}
              checked={checkedNewRegist}
              onChange={() => switchCheckedNewRegist()}
            />
            <LabelStyle htmlFor={'check-new-regist'}>신규/수정</LabelStyle>
          </div>
          <div>
            <input
              type="checkbox"
              id={'check-sync'}
              checked={checkedSync}
              onChange={() => switchCheckedSync()}
            />
            <LabelStyle htmlFor={'check-sync'}>동기화</LabelStyle>
          </div>
        </CheckBoxList>

        <Guide>
          <div style={{ color: '#717171' }}>
            신규/수정: 기존 데이터와 새 엑셀 파일을 결합합니다.
          </div>
          <div style={{ color: '#717171' }}>
            동기화: 기존 데이터를 새 엑셀 파일로 덮습니다.
            <div style={{ color: 'red' }}>
              동기화를 진행할 경우, 가져온 새 엑셀 파일에는 존재하지 않고 기존
              데이터에만 있는 정보는 삭제됩니다.
            </div>
          </div>
        </Guide>
      </OptionSection>

      <div>
        <DataSectionTitle style={{ marginTop: '15px' }}>
          신규 데이터 추가
          <p className="count-number" style={{ marginLeft: '5px' }}>
            {filteredNewAddedData.length >= 0
              ? `${filteredNewAddedData.length}`
              : '0'}
          </p>
          건
        </DataSectionTitle>

        {filteredNewAddedData.length <= 0 ? (
          <NoneData>관련 데이터가 존재하지 않습니다</NoneData>
        ) : (
          <>
            <DataListHeader>
              <DataHeaderItem className="car-number">차량 번호</DataHeaderItem>
              <DataHeaderItem className="owner-name">소유주</DataHeaderItem>
              <DataHeaderItem className="owner-phone">연락처</DataHeaderItem>
              <DataHeaderItem className="add1">동</DataHeaderItem>
              <DataHeaderItem className="add2">호</DataHeaderItem>
            </DataListHeader>
            <DataListContentsBox key="NewData">
              {filteredNewAddedData.map((addedData, idx) => {
                return (
                  <div key={idx}>
                    <DataContentsItemWrap key={addedData.CarNo}>
                      <DataContentsItem key={'CarNo-${idx}'} className="car-number">
                        {addedData.CarNo}
                      </DataContentsItem>
                      <DataContentsItem key={'OwnerName-${idx}'} className="owner-name">
                        {addedData.OwnerName}
                      </DataContentsItem>
                      <DataContentsItem key={'OwnerPhone-${idx}'} className="owner-phone">
                        {addedData.OwnerPhone}
                      </DataContentsItem>
                      <DataContentsItem key={'Addr1-${idx}'} className="add1">
                        {addedData.Addr1}
                      </DataContentsItem>
                      <DataContentsItem key={'Addr2-${idx}'} className="add2">
                        {addedData.Addr2}
                      </DataContentsItem>
                    </DataContentsItemWrap>
                  </div>
                );
              })}
            </DataListContentsBox>
          </>
        )}
      </div>

      <div>
        <DataSectionTitle style={{ marginTop: '15px' }}>
          기존 데이터 변경
          <p className="count-number" style={{ marginLeft: '5px' }}>
            {modifiedData.length >= 0 ? `${modifiedData.length}` : '0'}
          </p>
          건
        </DataSectionTitle>

        {modifiedData.length <= 0 ? (
          <NoneData>관련 데이터가 존재하지 않습니다</NoneData>
        ) : (
          <>
            <DataListHeader>
              <DataHeaderItem className="car-number">차량 번호</DataHeaderItem>
              <DataHeaderItem className="owner-name">소유주</DataHeaderItem>
              <DataHeaderItem className="owner-phone">연락처</DataHeaderItem>
              <DataHeaderItem className="add1">동</DataHeaderItem>
              <DataHeaderItem className="add2">호</DataHeaderItem>
            </DataListHeader>
            <DataListContentsBox key="ModifyData">
              {modifiedData.map((addedData,idx)=> {
                return (
                  <div key={idx}>
                    <DataContentsItemWrap key={addedData.CarNo}>
                      <DataContentsItem key={'CarNo-${idx}'} className="car-number">
                        {addedData.CarNo}
                      </DataContentsItem>
                      <DataContentsItem key={'OwnerName-${idx}'} className="owner-name">
                        {addedData.OwnerName}
                      </DataContentsItem>
                      <DataContentsItem key={'OwnerPhone-${idx}'}  className="owner-phone">
                        {addedData.OwnerPhone}
                      </DataContentsItem>
                      <DataContentsItem key={'Addr1-${idx}'} className="add1">
                        {addedData.Addr1}
                      </DataContentsItem>
                      <DataContentsItem key={'Addr2-${idx}'} className="add2">
                        {addedData.Addr2}
                      </DataContentsItem>
                    </DataContentsItemWrap>
                  </div>
                );
              })}
            </DataListContentsBox>
          </>
        )}
      </div>

      {isCheckedSync && (
        <div>
          <DataSectionTitle style={{ marginTop: '15px' }}>
            기존 데이터 삭제
            <p className="count-number" style={{ marginLeft: '5px' }}>
              {onlyExistentServerData.length >= 0
                ? `${onlyExistentServerData.length}`
                : '0'}
            </p>
            건
          </DataSectionTitle>

          {onlyExistentServerData.length <= 0 ? (
            <NoneData>관련 데이터가 존재하지 않습니다</NoneData>
          ) : (
            <>
              <DataListHeader>
                <DataHeaderItem className="car-number">차량 번호</DataHeaderItem>
                <DataHeaderItem className="owner-name">소유주</DataHeaderItem>
                <DataHeaderItem className="owner-phone">연락처</DataHeaderItem>
                <DataHeaderItem className="add1">동</DataHeaderItem>
                <DataHeaderItem className="add2">호</DataHeaderItem>
              </DataListHeader>
              <DataListContentsBox key="DeleteData">
                {onlyExistentServerData.map((addedData, idx) => {
                  return (
                    <div key={idx}>
                      <DataContentsItemWrap key={addedData.CarNo}>
                        <DataContentsItem key={'CarNo-${idx}'} className="car-number">
                          {addedData.CarNo}
                        </DataContentsItem>
                        <DataContentsItem key={'OwnerName-${idx}'} className="owner-name">
                          {addedData.OwnerName}
                        </DataContentsItem>
                        <DataContentsItem key={'OwnerPhone-${idx}'}  className="owner-phone">
                        {addedData.OwnerPhone}
                        </DataContentsItem>
                        <DataContentsItem key={'Addr1-${idx}'} className="add1">
                          {addedData.Addr1}
                        </DataContentsItem>
                        <DataContentsItem key={'Addr2-${idx}'} className="add2">
                          {addedData.Addr2}
                        </DataContentsItem>
                      </DataContentsItemWrap>
                    </div>
                  );
                })}
              </DataListContentsBox>
            </>
          )}
        </div>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '15px',
        }}
      >
        <ButtonStyle
          className="yes-button"
          onClick={() =>
            checkedSyncNum() === 0
              ? uploadNewData({
                  apartmentIdx,
                  resultData,
                  isCheckedSync,
                  setLoading,
                  setOpenBeforeResistGuideModal,
                  setDetectChangingData,
                })
              : uploadSyncData({
                  apartmentIdx,
                  resultData,
                  onlyExistentServerData,
                  isCheckedSync,
                  setLoading,
                  setOpenBeforeResistGuideModal,
                  setDetectChangingData,
                })
          }
        >
          등록하기
        </ButtonStyle>
        <ButtonStyle className="close-button" onClick={closeModal}>
          닫기
        </ButtonStyle>
      </div>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 600px;
  padding: 15px;
`;

const SignTextSection = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 30px;
  width: 100%;
`;

const SignText = styled.div`
  font-size: 14px;
`;

const OptionSection = styled.div`
  padding: 14px 10px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  border-radius: 5px;
`;

const Guide = styled.div`
  margin-top: 10px;
  font-size: 13px;
  line-height: 1.2;
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.printBtnStyle}

  /* margin-left:5px; */
  width: 80px;

  &.yes-button {
    border: solid 1px ${({ theme }) => theme.mainColor.dark};
    background-color: ${({ theme }) => theme.mainColor.main};
    color: white;

    :hover {
      background-color: ${({ theme }) => theme.mainColor.dark};
    }
  }

  &.close-button {
    margin-left: 10px;
  }
`;

const CheckBoxList = styled.div`
  display: flex;
  align-items: center;
`;

const LabelStyle = styled.label`
  font-size: 13px;
`;

const DataSectionTitle = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.fontColor.lightGray};

  padding: 5px 5px 5px 0;

  p.count-number {
    color: ${({ theme }) => theme.mainColor.main};
  }
`;

const NoneData = styled.div`
  padding: 15px 5px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  border-radius: 5px;
  color: ${({ theme }) => theme.fontColor.lightGray};
  font-size: 13px;
`;

const DataListHeader = styled.div`
  display: flex;
  align-items: center;
  /* border: solid 1px gray; */
  background-color: ${({ theme }) => theme.backgroundColor.gray};
  font-size: 13px;
  padding: 2px 0;

  border: solid 1px ${({ theme }) => theme.mainColor.main};
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const DataHeaderItem = styled.div`
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  justify-content: center;

  &.add1 {
    width: 50px;
  }
  &.add2 {
    width: 50px;
  }
  &.car-number {
    width: 100px;
  }
  &.owner-name {
    width: 80px;
  }
`;

const DataListContentsBox = styled.div`
  ${({ theme }) => theme.scrollBarStyle}

  height: 150px;
  /* border: solid 1px ${({ theme }) => theme.backgroundColor.gray}; */
  border: solid 1px ${({ theme }) => theme.mainColor.main};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  border-top: none;
  font-size: 13px;
  overflow: auto;
  padding: 2px 0;
`;

const DataContentsItemWrap = styled.div`
  display: flex;
  padding: 3px 0;
  border-bottom: dotted 1px ${({ theme }) => theme.backgroundColor.gray};
`;

const DataContentsItem = styled.div`
  display: flex;
  /* flex-direction: column; */
  justify-content: center;

  padding-left: 5px;

  &.add1 {
    width: 50px;
  }
  &.add2 {
    width: 50px;
  }
  &.car-number {
    width: 100px;
  }
  &.owner-name {
    width: 80px;
  }
`;
