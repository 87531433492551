import React from 'react';
import styled from 'styled-components';

import { WorkHistory } from '../../components/AR10-020-work-history/WorkHistory/WorkHistory';

import RouterTrackerForAnalytics from '../../lib/utils/RouterTrackerForAnalytics'; // [MEMO] 구글 애널리틱스 라우터 트래커

const WorkHistoryPage = () => {
  const $title = document.head.querySelector('title');
  $title!.innerText = '스마트 근무내역';
  RouterTrackerForAnalytics();

  return (
    <>
      <WorkHistory />
    </>
  );
};

export default WorkHistoryPage;
