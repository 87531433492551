import React from 'react';
import ReactDOM from 'react-dom/client';
// import ReactDOM from 'react-dom'; ->React 18 업데이트 이후, 더 이상 사용되지 않음.
/* MEMO: React 18 업데이트 이후 createRoot를 사용하기 위해,
'react-dom' 대신 'react-dom/client'가 사용됨. */
import Router from './Router';
import GlobalStyle from './styles/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';

import { Provider } from 'react-redux'; // Redux Toolkit
import { store } from './redux/store/store'; // Redux Toolkit

import { persistStore } from 'redux-persist'; // redux-persist
import { PersistGate } from 'redux-persist/integration/react'; // redux-persist

import { QueryClient, QueryClientProvider } from 'react-query'; // React Query
import { ReactQueryDevtools } from 'react-query/devtools'; // React Query (devtool)

import * as Sentry from '@sentry/react'; // MEMO: Sentry
// import { BrowserTracing } from '@sentry/tracing'; // MEMO: Sentry

import ReactGA from 'react-ga4';

import { DndProvider } from 'react-dnd'; // 드래그앤드롭 라이브러리
import { HTML5Backend } from 'react-dnd-html5-backend'; // 드래그앤드롭 라이브러리

const persistor = persistStore(store); // redux-persist
// export const persistor = persistStore(store); // redux-persist
const queryClient = new QueryClient(); // React Query

const SENTRY_KEY = process.env.SENTRY_KEY; // MEMO: Sentry Key
const FOR_SENTRY_COMMON_URL = process.env.FOR_SENTRY_COMMON_URL;

Sentry.init({
  environment:
    process.env.NODE_ENV === 'production' ? 'production' : 'development',
  dsn: SENTRY_KEY,
  // integrations: [new BrowserTracing()],
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        // 'localhost'
        // /^https:\/\/`${FOR_SENTRY_COMMON_URL}`\.io\/api/,
        process.env.NODE_ENV === 'production' ? 'hr' : 'localhost',
        process.env.NODE_ENV === 'production'
          ? /^https:\/\/apihr.welltechlab.co.kr:9010\.io\/api/
          : /^https:\/\/apidev.welltechlab.co.kr:8010\.io\/api/,
      ],
    }),
  ],
  tracesSampleRate: 1.0,
});

// 구글 애널리틱스 적용
if (process.env.GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID);
}

// production 환경으로 빌드시 console.log 제거(안보이는 상태로 빌드됨)
// if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'demo') {
//   console.log = () => {};
//   console.error = () => {};
//   console.debug = () => {};
// }

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={true} />
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router />
            </PersistGate>
          </Provider>
        </QueryClientProvider>
      </ThemeProvider>
    </DndProvider>
  </React.StrictMode>
);

//ReactDOM.render(<Router />, document.getElementById('root'));

// 1. React, React-Router, GlobalStyle, theme을 적용한 경우 예시 -----start
/*
import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './Router';
import GlobalStyle from './styles/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  </React.StrictMode>
);
*/
// 1. React, React-Router, GlobalStyle, theme을 적용한 경우 예시 -----end

// 2. React-Router를 적용하지 않은 경우 예시 -----start
/*
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/
// 2. React-Router를 적용하지 않은 경우 예시 -----end
