// ref 있음. 단지 '고유' 정보 ('계약' 인덱스 아님(계약은 ref 없음))
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface onApartmentUniqueInfoInfoProps {
  apartmentUniqueData: any[];
  setApartmentUniqueData: Dispatch<SetStateAction<any[]>>;
  setOnApartmentModal: Dispatch<SetStateAction<boolean>>;
}

export const onApartmentUniqueInfo = async ({
  apartmentUniqueData,
  setApartmentUniqueData,
  setOnApartmentModal,
}: onApartmentUniqueInfoInfoProps) => {
  const token = sessionStorage.getItem('token');

  await axios
    .get(
      // https://apihr.mmigroup.co.kr:9020/common/refcontractinfo?ItemPerPage=10&ItemStartPage=0'
      `${process.env.COMMON_URL}/common/refcontractinfo?ItemPerPage=0&ItemStartPage=0&Valid=1`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '[CM10-010]계약 정보 조회-단지 고유 정보 API: ',
      //   `${process.env.COMMON_URL}/common/refcontractinfo?ItemPerPage=0&ItemStartPage=0&Valid=1`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          setApartmentUniqueData(res.data.Data);
          setOnApartmentModal(true);
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
