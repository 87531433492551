// AR10-030 스마트 근무일지
import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import ReactToPrint from 'react-to-print';

interface WorkLogListPrintingProps {
  closeWorkLogListPrintingModal: () => void;
  logListData: any;
  startDateForPrinting: string;
  endDateForPrinting: string;
  apartmentName: string;
}

export const WorkLogListPrinting = ({
  closeWorkLogListPrintingModal,
  logListData,
  startDateForPrinting,
  endDateForPrinting,
  apartmentName,
}: WorkLogListPrintingProps) => {
  const ref: { current: any } = useRef();

  //
  //
  //

  let horizontalData: any[];
  let verticalData: any[];
  const tableData = [];

  if (logListData.Data) {
    horizontalData = logListData.Data.Data.map(
      (data: { TrackingLocation: string }) => data.TrackingLocation
    );
    console.log('horizontalData:', horizontalData);

    // 👇 세로축
    verticalData = logListData.Data.Data.reduce((accumulator, data) => {
      const dataItems = data.Data.map(item => ({
        location: data.TrackingLocation,
        employeeName: item.EmployeeName,
        triggerDate: item.TrigerTime.slice(0, 10),
        triggerTime: item.TrigerTime.slice(11, 19),
      }));

      return [...accumulator, ...dataItems];
    }, []);

    console.log('verticalData:', verticalData);

    /**
     * 조건을 나열하는 순서에 따라 다른 결과값을 반환하는 이유는, && 연산자의 처리 순서 때문.
     *
     * 1.
     * item.triggerTime === verticalItem.triggerTime &&
     * item.triggerDate === verticalItem.triggerDate
     * -> 먼저 item.triggerTime === verticalItem.triggerTime 조건 체크, true인 데이터 반환,
     * 그 다음에 item.triggerDate === verticalItem.triggerDate이 true인 데이터 반환.
     *
     * 2.
     * item.triggerDate === verticalItem.triggerDate &&
     * item.triggerTime === verticalItem.triggerTime
     * -> 1번과 반대로 위치한 경우, 먼저 item.triggerDate === verticalItem.triggerDate 조건 체크, true인 데이터 반환,
     * 그 다음에 item.triggerTime === verticalItem.triggerTime이 true인 데이터 반환.
     */

    // tableData = verticalData.reduce(
    //   (tableAccumulator, verticalItem, vIndex) => {
    //     const row = horizontalData.map((horizontalItem, hIndex) => {
    //       const matchedData = verticalData.find(
    //         item =>
    //           item.employeeName === verticalItem.employeeName && // 세로 매칭
    //           item.triggerTime === verticalItem.triggerTime && // 세로 매칭
    //           item.triggerDate === verticalItem.triggerDate && // 세로 매칭
    //           item.location === horizontalItem // 가로 매칭
    //       );

    //       return matchedData
    //         ? {
    //             location: matchedData.location,
    //             employeeName: matchedData.employeeName,
    //             triggerDate: matchedData.triggerDate,
    //             triggerTime: matchedData.triggerTime,
    //           }
    //         : '';
    //     });

    //     tableAccumulator.push(row);
    //     return tableAccumulator;
    //   },
    //   []
    // );

    // 세로 데이터를 기준으로 결과 테이블 생성
    verticalData.forEach(verticalItem => {
      const rowIndex = tableData.findIndex(row =>
        row.some(cell => cell && cell.location === verticalItem.location)
      );

      if (rowIndex === -1) {
        // 새로운 행 추가
        console.log('#1 tableData: ', tableData);
        tableData.push([verticalItem]);
      } else {
        // 이미 있는 행에 추가
        console.log('#2 tableData: ', tableData);
        tableData[rowIndex].push(verticalItem);
      }
    });

    // 빈 스트링으로 채우기
    tableData.forEach(row => {
      console.log('#3 row: ', row);
      while (row.length < horizontalData.length) {
        row.push(null); // 빈 스트링 대신 null로 채움
        console.log('#4 row: ', row);
      }
    });

    // 👇 에휴 -----start
    // 세로 데이터 중복 제거
    // verticalData = Array.from(
    //   new Set(verticalData.map(JSON.stringify)),
    //   JSON.parse
    // );

    // // 세로 데이터를 기준으로 이중배열 생성
    // verticalData.forEach(verticalItem => {
    //   const rowIndex = tableData.findIndex(row =>
    //     row.every(cell => cell !== null)
    //   );

    //   if (rowIndex === -1) {
    //     // 새로운 행 추가
    //     tableData.push([verticalItem]);
    //   } else {
    //     // 이미 있는 행에 추가
    //     tableData[rowIndex].push(verticalItem);
    //   }
    // });
    // 👆 에휴 -----end

    console.log('🍑🍑🍑tableData: ', tableData);
    console.log('🍑🍑🍑tableData[][]: ', tableData[2][2]);
  }

  //
  //
  //

  const pageSize = 20;
  const [currentPage, setCurrentPage] = useState(0);

  const uniqueDates = [...new Set(verticalData.map(item => item.triggerDate))];
  const itemsPerPage = 20;
  const paginatedData = uniqueDates.map(date => ({
    date,
    data: verticalData.filter(item => item.triggerDate === date),
  }));
  const totalPages = Math.ceil(uniqueDates.length / itemsPerPage);

  // 테이블 렌더링 함수
  const renderTable = (pageData, pageIndex) => {
    if (!logListData.Data) return null;

    const visibleVerticalData = pageData.data.slice(
      pageIndex * itemsPerPage,
      (pageIndex + 1) * itemsPerPage
    );

    return (
      <div key={pageIndex}>
        <h2>Date: {pageData.date}</h2>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              {horizontalData.map((location, index) => (
                <th key={index}>{location}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {visibleVerticalData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>{row.triggerDate}</td>
                {horizontalData.map((location, colIndex) => {
                  const matchedData = verticalData.find(
                    item =>
                      item.employeeName === row.employeeName &&
                      item.triggerDate === row.triggerDate &&
                      item.triggerTime === row.triggerTime &&
                      item.location === location
                  );

                  return (
                    <td key={colIndex}>
                      {matchedData
                        ? `${matchedData.triggerTime} - ${matchedData.location}`
                        : ''}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const handlePrint = () => {
    if (!logListData.Data) return;

    const printContent = document.createElement('div');
    printContent.appendChild(
      renderTable(paginatedData[currentPage], currentPage)
    );

    const printWindow = window.open('', '_blank');
    printWindow.document.body.appendChild(printContent);
    printWindow.print();
  };

  return (
    <div>
      <button onClick={handlePrint}>인쇄하기</button>
      <div ref={ref}>
        {renderTable(paginatedData[currentPage], currentPage)}
        <div>
          {Array.from({ length: totalPages }, (_, index) => (
            <button key={index} onClick={() => setCurrentPage(index)}>
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>

    // <div>
    //   <ReactToPrint
    //     trigger={() => <button>Print</button>}
    //     content={() => ref.current}
    //   />
    //   <div ref={ref}>{renderTable()}</div>
    //   <div>
    //     {/* 페이지네이션 컨트롤 */}
    //     {Array.from({ length: totalPages }, (_, index) => (
    //       <button
    //         key={index}
    //         onClick={() => setCurrentPage(index)}
    //         disabled={index === currentPage}
    //       >
    //         Page {index + 1}
    //       </button>
    //     ))}
    //   </div>
    // </div>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 210mm;
  height: 297mm;
  background-color: white;

  /* width: 100%; */
  border-collapse: collapse;
  /* Define your print styles */
  @media print {
    width: 210mm; /* A4 width */
    height: 297mm; /* A4 height */
    margin: 0;
    padding: 0;
    overflow: hidden;
    /* Start a new page for each print */
    /* page-break-after: always; */
    break-after: auto;
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 5mm;
  margin-bottom: 7mm;
`;

const InfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`;

const TableWrap = styled.div`
  ${({ theme }) => theme.stopDrag};
  ${({ theme }) => theme.scrollBarStyle};
  color: ${({ theme }) => theme.fontColor.black};
  border: solid 1px ${({ theme }) => theme.fontColor.black};
  /* width: 100%; */
`;

const Tables = styled.table`
  /* width: 100%; */
`;

const THead = styled.thead`
  font-weight: 400;
  font-size: 8px;
`;

const TBody = styled.tbody`
  @media print {
    .page-break {
      /* page-break-after: always; */
      break-after: auto;
      /* clear: both; */
    }
  }
  /* @media print {
    .page-break {
      clear: both;
      page-after: auto;
    }
  } */
`;

const TrForHeader = styled.tr`
  /*  */
  background-color: lightGray;
`;

export const ThEmployeeNameHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 25px; /* [MEMO] 이름 너비 */
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-right: solid 1px ${({ theme }) => theme.fontColor.black};
  height: 24px; /* ✅ 구분 높이 */
`;

export const ThTotalWorkingTimeHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 15px; /* [MEMO] 총 근무시간 너비 */
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-right: solid 1px ${({ theme }) => theme.fontColor.black};
  height: 24px; /* ✅ 구분 높이 */
`;

const ThDateHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 100px; /* [MEMO] 날짜 너비 */
  height: 24px; /* ✅ 구분 높이 */

  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-left: solid 1px ${({ theme }) => theme.fontColor.black};

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 5px;
    width: 5px; /* [MEMO] 날짜 너비 */
  }
`;

// 🚀🚀🚀
const ThLogNumberHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 120px; /* [MEMO] 날짜 너비 */

  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-left: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  color: ${({ theme }) => theme.fontColor.lightGray};
`;

const TdEmployeeName = styled.td`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  // height: 10px;
  width: 30px;
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-right: solid 1px ${({ theme }) => theme.fontColor.black};

  font-size: 10px;
  font-weight: 600;
`;

const TdTotalWorkingTime = styled.td`
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-right: solid 1px ${({ theme }) => theme.fontColor.black};
  font-size: 8px;
  font-weight: 400;
  width: 5px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    &.time-to-work {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: solid 1px ${({ theme }) => theme.fontColor.gray};
      height: 8mm; /* 📌 날짜 높이 */
      width: 100%;
    }

    &.time-to-home {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: solid 1px ${({ theme }) => theme.fontColor.gray};
      height: 8mm; /* 📌 날짜 높이 */
      width: 100%;
    }

    &.time-to-break {
      display: flex;
      justify-content: center;
      align-items: center;
      // border-bottom: solid 1px ${({ theme }) => theme.fontColor.darkGray};
      height: 8mm; /* 📌 날짜 높이 */
      width: 100%;
    }
  }
`;

export const TdTimeInfo = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
  border-left: solid 1px ${({ theme }) => theme.fontColor.black};
  width: 20px;
`;

export const StatusResultBox = styled.div`
  div {
    font-size: 10px;
    font-weight: 600;

    &.nothing {
      /* [MEMO] 출근 태그 */
      display: flex;
      justify-content: center;

      border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
      height: 8mm; /* 📌 날짜 높이 */
      width: 100%;
    }

    &.time-to-work {
      /* [MEMO] 출근 태그 */
      display: flex;
      justify-content: center;
      align-items: center;

      border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
      height: 8mm; /* 📌 날짜 높이 */
      width: 100%;
    }

    &.time-to-home {
      /* [MEMO] 퇴근 태그 */
      display: flex;
      justify-content: center;
      align-items: center;

      border-bottom: solid 1px ${({ theme }) => theme.fontColor.black};
      height: 8mm; //* 📌 날짜 높이 */
      width: 100%;
    }

    /* working */
    &.time-to-break {
      /* [MEMO] 연차 태그 */
      display: flex;
      justify-content: center;
      align-items: center;

      height: 8mm; /* 📌 높이 */
      width: 100%;
    }
  }
`;

//
//
//

const TdLocationName = styled.td`
  display: table-cell;
  vertical-align: middle;
  padding-left: 30px;
  height: 70px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray}; /* 2번 스타일링(가운데 정렬) */
  color: ${({ theme }) => theme.fontColor.darkGray};
  font-weight: 500;

  div {
    display: flex;
    justify-content: center; /* 2번 스타일링(가운데 정렬) */
    /* justify-content: start; */ /* 1번 스타일링(왼쪽 정렬) */
    align-items: center;
    width: 170px; /* 부모 요소에 width: 100%; 없음 + 여기에 width부분에 값을 적용해, 셀 width 값을 고정시킴 */

    p {
      padding: 4px 6px;
      border-radius: 0.5em;
      background-color: ${({ theme }) => theme.statusColor.working};
    }
  }
`;

const TdTrackingType = styled.td`
  display: table-cell;
  vertical-align: middle;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  padding: 0 5px;

  div {
    display: flex;
    justify-content: center; /* 2번 스타일링(가운데 정렬) */
    /* justify-content: start; */ /* 1번 스타일링(왼쪽 정렬) */
    align-items: center;
    width: 120px; /* 부모 요소에 width: 100%; 없음 + 여기에 width부분에 값을 적용해, 셀 width 값을 고정시킴 */

    p {
      padding: 4px 6px;
      border-radius: 0.5em;
      background-color: ${({ theme }) => theme.statusColor.working};
      color: white;
      font-size: 12px;
      font-weight: 600;
    }
  }
`;

export const TdLogDataList = styled.td`
  padding: 5px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /* border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray}; */
  border-left: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  font-size: 12px;
  line-height: 1.5;

  div {
    border-radius: 3px;
    font-weight: 500;
    width: 120px; /* 부모 요소에 width: 100%; 없음 + 여기에 width부분에 값을 적용해, 셀 width 값을 고정시킴 */

    &.employee-name {
      display: flex;
      justify-content: center; /* 2번 스타일링(가운데 정렬) */
      align-items: center;
      margin-bottom: 5px;
      background: ${({ theme }) => theme.statusColor.startWorkBackground};
      color: ${({ theme }) => theme.fontColor.darkGray};

      p {
        /* margin-left: 5px; */ /* 1번 스타일링(왼쪽 정렬) */
        font-weight: 600;
      }
    }

    &.info-box {
      display: flex;
      flex-direction: column;
      text-align: end; /* 2번 스타일링(오른쪽 정렬) */
      background: ${({ theme }) => theme.statusColor.grayBackground};
      color: ${({ theme }) => theme.fontColor.darkGray};

      p {
        /* margin-left: 5px; */ /* 1번 스타일링(왼쪽 정렬) */
        margin-right: 5px; /* 2번 스타일링(오른쪽 정렬) */
      }
    }
  }
`;
