import React, { useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
// import '../../agGridStyle/ag-theme-custom.css';
import '../../../styles/agGridStyle/ag-theme-custom.css';
import { gridOptions } from './gridOptions'; // 그리드 항목

import { deleteSingleServerData } from './utils/deleteSingleServerData'; // 개별 삭제 기능
import { ModifyDataModal } from './ModifyDataModal/ModifyDataModal'; // 개별 수정 모달

interface GridProps {
  loading: boolean;
  apartmentIdx: number;
  data: {
    idx: number;
    Addr1: string;
    Addr2: string;
    CarNo: string;
    CarFlag: string;
    CarExpireDate: string;
    OwnerName: string;
    OwnerPhone: string;
    EmployeName: string;
    RegEmployeeNo: string;
  }[];
  setDetectChangingData: Dispatch<SetStateAction<boolean>>; // 개별 데이터 삭제시, 데이터 불러오기 트리거
}

export const Grid = ({
  loading,
  apartmentIdx,
  data,
  setDetectChangingData,
}: GridProps) => {
  // if (loading) {
  //   return (
  //     <>
  //       <Bar />
  //       <LoadingWrap>
  //         <LoadingGIF src="/loading.gif" />
  //       </LoadingWrap>
  //     </>
  //   );
  // }

  const [openModifyDataModal, setOpenModifyDataModal] = useState(false);
  const [selectedGridData, setSelectedGridData] = useState({});

  const controlModifyDataModal = e => {
    if (e.value !== '수정') {
      return;
    }

    if (e.value === '수정') {
      setSelectedGridData(e.data);
      setOpenModifyDataModal(true);
    }
  };

  function onCarFlag(data: string) {
    if (data === 'R') {
      return '등록';
    } else if (data === 'T') {
      return '임시';
    } else if (data === 'D') {
      return '기간';
    }
    return data;
  }

  function onOwnerPhone(data: string) {
    /**
     * String.prototype.startsWith()
     * -https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
     * -startsWith() 메서드는 어떤 문자열이 특정 문자로 시작하는지를 확인하여,
     * 그 결과를 true 혹은 false로 반환한다.
     */
    if (data.length === 11) {
      if (data.startsWith('010')) {
        return `${data.slice(0, 3)}-${data.slice(3, 7)}-${data.slice(7, 11)}`;
      }
    }
    return data;
  }

  return (
    <>
      {openModifyDataModal && (
        <ModifyDataModal
          selectedGridData={selectedGridData}
          setOpenModifyDataModal={setOpenModifyDataModal}
          apartmentIdx={apartmentIdx}
          setDetectChangingData={setDetectChangingData}
        />
      )}

      <div id="myGrid" className="ag-theme-alpine ag-theme-custom">
        <AgGridReact
          headerHeight={45} // 타이틀 행 높이
          rowHeight={35} // 본문 행 높이
          gridOptions={gridOptions}
          rowData={data.map((data, idx) => {
            return {
              idx: data.idx, // 동
              Addr1: data.Addr1, // 동
              Addr2: data.Addr2, // 호
              CarNo: data.CarNo, // 차량 번호
              // CarFlag: data.CarFlag, // 등록 유형?
              CarFlag: onCarFlag(data.CarFlag), // 등록 유형?
              CreateDateTime: data.CreateDateTime.slice(0, 10), // 등록일
              CarExpireDate: data.CarExpireDate.slice(0, 10), // 만료일

              OwnerName: data.OwnerName, // 차량 소유자
              OwnerPhone: onOwnerPhone(data.OwnerPhone), // 차량 소유자 전화번호

              // EmployeName: data.EmployeName, // 관리자
              // RegEmployeeNo: data.RegEmployeeNo, // 관리자 사번
              modify: data.idx ? '수정' : '', // 수정
              delete: data.idx ? '삭제' : '', // 삭제
            };
          })}
          animateRows={true}
          rowDragManaged={true}
          suppressMoveWhenRowDragging={true}
          pagination={true}
          paginationPageSize={15}
          onCellClicked={e => {
            controlModifyDataModal(e);
            deleteSingleServerData({ e, apartmentIdx, setDetectChangingData });
          }}
          // defaultColDef={{
          //   cellStyle: { textAlign: 'center' }, // 중앙 정렬 스타일 추가
          // }}
        />
      </div>
    </>
  );
};

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const LoadingGIF = styled.img`
  ${({ theme }) => theme.loadingGIF}
`;

const Bar = styled.div`
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;
