// CV10-020 차량 등록 관리 - 서버로 '신규' 데이터 보내기
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../../lib/utils/incorrectToken';

interface uploadNewSingleDataProps {
  closeModal: () => void;
  resultData: any[];
  apartmentIdx: number;
}

export const uploadNewSingleData = async ({
  closeModal,
  resultData,
  apartmentIdx,
}: uploadNewSingleDataProps) => {
  const token = sessionStorage.getItem('token');
  const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx');

  console.log('uploadNewSingleData.tsx/주차개별등록-resultData: ', resultData);

  if (!apartmentIdx && Number(apartmentRefIdx) === 0) {
    return alert('먼저 단지를 선택해주세요');
  }

  if (resultData.CarNo.length <= 0) {
    return alert('차량 번호를 입력해주세요');
  }

  if (resultData.CarFlag === '') {
    return alert('등록 유형을 선택해주세요');
  }

  let currentApartmentRefIdx = '';
  if (Number(apartmentRefIdx) !== 0) {
    currentApartmentRefIdx = `${apartmentRefIdx}`;
  } else {
    currentApartmentRefIdx = `${apartmentIdx}`;
  }

  console.log('개별등록-currentApartmentRefIdx: ', currentApartmentRefIdx);

  await axios({
    // https://dev.mmigroup.co.kr:8020/vehicle/cv10020/regcarmanage/1
    url: `${process.env.COMMON_URL}/vehicle/cv10020/regcarmanage/${currentApartmentRefIdx}`,
    method: 'post',
    headers: { tokenId: token },
    data: {
      Data: [
        {
          RegEmployeeNo: resultData.RegEmployeeNo
            ? resultData.RegEmployeeNo
            : '',
          Addr1: resultData.Addr1 ? resultData.Addr1 : '',
          Addr2: resultData.Addr2 ? resultData.Addr2 : '',
          OwnerName: resultData.OwnerName ? resultData.OwnerName : '',
          OwnerPhone: resultData.OwnerPhone ? resultData.OwnerPhone : '',
          CarNo: resultData.CarNo ? resultData.CarNo : '',
          CarFlag: resultData.CarFlag ? resultData.CarFlag : '',
          CarExpireDate: resultData.CarExpireDate
            ? resultData.CarExpireDate
            : '',
        },
      ],
    },
  }).then(res => {
    console.log(
      '📢url: ',
      `${process.env.COMMON_URL}/vehicle/cv10020/regcarmanage/${currentApartmentRefIdx}`
    );
    //console.log('👍uploadNewData.tsx-resultData: ', resultData);
    //console.log('👍res.data: ', res.data);

    try {
      if (res.data.ErrorCode === 0) {
        alert('차량 정보가 성공적으로 등록되었습니다');
        closeModal();
      }
      incorrectToken(res);
    } catch (e) {
      console.error(e);
    }
  });
};
