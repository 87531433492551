import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/store';

export const RequestSliceData = () => {
  // [CATEGORY] (소속) 부서
  const requestSelectedDepartmentCode = useSelector(
    (state: RootState) =>
      state.selectedCategoryAtEmployee.selectedDepartmentCodeValue
  );

  // [CATEGORY] 직무
  const requestSelectedJobCodeValue = useSelector(
    (state: RootState) => state.selectedCategoryAtEmployee.selectedJobCodeValue
  );

  // [CATEGORY] 직무 그룹
  const requestSelectedJobGroupCode = useSelector(
    (state: RootState) =>
      state.selectedCategoryAtEmployee.selectedJobGroupCodeValue
  );

  // [CATEGORY] 직급
  const requestSelectedPositionCode = useSelector(
    (state: RootState) =>
      state.selectedCategoryAtEmployee.selectedPositionCodeValue
  );

  // [CATEGORY] 근무 지역
  const requestSelectedAreaCode = useSelector(
    (state: RootState) => state.selectedCategoryAtEmployee.selectedAreaCodeValue
  );

  // [CATEGORY] 사원 상태
  const requestSelectedStatusCode = useSelector(
    (state: RootState) =>
      state.selectedCategoryAtEmployee.selectedStatusCodeValue
  );

  return {
    requestSelectedDepartmentCode,
    requestSelectedJobCodeValue,
    requestSelectedJobGroupCode,
    requestSelectedPositionCode,
    requestSelectedAreaCode,
    requestSelectedStatusCode,
  };
};

export default RequestSliceData;
