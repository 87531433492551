import styled from 'styled-components';

export const Button = styled.button`
  &.button-short {
    display: inline-block;
    border: 0;
    border-radius: 0.5em;
    font-weight: 500;
    line-height: 1;
    cursor: pointer;
  }

  &.button-short--primary {
    background-color: ${({ theme }) => theme.mainColor.main};
    color: white;

    :hover {
      background-color: ${({ theme }) => theme.mainColor.dark};
    }
  }

  &.button-short--secondary {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
    color: ${({ theme }) => theme.fontColor.black};
  }

  &.button-short--small {
    width: 66px;
    height: 32px;
    font-size: 13px;
  }

  &.button-short--medium {
    width: 76px;
    height: 34px;
    font-size: 14px;
  }

  &.button-short--large {
    width: 80px;
    height: 36px;
    font-size: 16px;
  }
`;
