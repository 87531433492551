import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { ModalContents } from './ModalContents';

interface BeforeInitialAlertModalProps {
  setOpenBeforeInitialAlertModal: Dispatch<SetStateAction<boolean>>;
  apartmentIdx: number;
  setDetectChangingData: Dispatch<SetStateAction<boolean>>;
}

export const BeforeInitialAlertModal = ({
  setOpenBeforeInitialAlertModal,
  apartmentIdx,
  setDetectChangingData,
}: BeforeInitialAlertModalProps) => {
  const closeModal = () => {
    setOpenBeforeInitialAlertModal(false);
  };

  return (
    <Background onClick={closeModal}>
      <Container
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ModalContents
          closeModal={closeModal}
          setOpenBeforeInitialAlertModal={setOpenBeforeInitialAlertModal}
          apartmentIdx={apartmentIdx}
          setDetectChangingData={setDetectChangingData}
        />
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: white;
`;
