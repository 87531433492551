import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { uploadNewSingleData } from './utils/uploadNewSingleData';

import { ko } from 'date-fns/esm/locale';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import '../style/DatePicker.css';

interface ModalContentsProps {
  closeModal: () => void;
  apartmentIdx: number;
  // selectedGridData: {};
}

export const ModalContents = ({
  closeModal,
  apartmentIdx,
}: // selectedGridData,
ModalContentsProps) => {
  let resultData = []; // 새로 추가한 데이터

  const [newAddr1, setNewAddr1] = useState('');
  const [newAddr2, setNewAddr2] = useState('');
  const [newCarNo, setNewCarNo] = useState('');
  const [newCarFlag, setNewCarFlag] = useState('');
  const [newOwnerName, setNewOwnerName] = useState('');
  const [newOwnerPhone, setNewOwnerPhone] = useState('');
  const [newCarExpireDate, setNewCarExpireDate] = useState('');

  const [carFlagTag, setCarFlagTag] = useState('');

  const handleNewAddr1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAddr1(e.target.value);
  };

  const handleNewAddr2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAddr2(e.target.value);
  };

  const handleNewCarNo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewCarNo(e.target.value);
  };

  const handleCarFlag = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewCarFlag(e.target.value);
    setCarFlagTag(e.target.value);
  };

  const handleOwnerName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewOwnerName(e.target.value);
  };

  const handleOwnerPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewOwnerPhone(e.target.value);
  };

  const handleCarExpireDate = dateData => {
    const year = dateData.getFullYear().toString();
    const month = (dateData.getMonth() + 1).toString();
    const date = dateData.getDate().toString();

    const calYear = String(year).padStart(2, '0');
    const calMonth = String(month).padStart(2, '0');
    const calDate = String(date).padStart(2, '0');

    const targetDate = `${calYear}-${calMonth}-${calDate}`;

    setStartDate(dateData);
    setNewCarExpireDate(targetDate);
  };

  const carFlagOptions = [
    { value: '', label: '등록 유형을 선택해주세요' },
    { value: 'R', label: '등록' },
    { value: 'T', label: '임시' },
    { value: 'D', label: '기간' },
  ];

  function filteringAddedData(item) {
    // 👇 동번호(Addr1): '동' 문자열 제거, item = data.Addr1
    if (item === newAddr1 && newAddr1.includes('동')) {
      return newAddr1.replaceAll('동', '');
    }

    // 👇 호수(Addr2): '호' 문자열 제거, item = data.Addr2
    if (item === newAddr2 && newAddr2.includes('호')) {
      return newAddr2.replaceAll('호', '');
    }

    // 👇 전화번호: '-' 문자열 제거, item = data.OwnerPhone
    if (item === newOwnerPhone && newOwnerPhone.includes('-')) {
      return newOwnerPhone.replaceAll('-', '');
    }

    /**
     * 공백 제거 정규표현식 적용 예시:
     * const stringWithSpaces = 'Hello    World    Example';
     * const stringWithoutSpaces = stringWithSpaces.replace(/\s+/g, '');
     * console.log(stringWithoutSpaces); // 'HelloWorldExample'
     */
    // 👇 글자 사이 공백 제거
    if (item) {
      return item.toString().replace(/\s+/g, '');
    } else if (!item) {
      return '';
    }
  }

  function onProcessingAddedData() {
    function onCarFlag(data: string) {
      if (data === '등록') {
        return 'R';
      } else if (data === '임시') {
        return 'T';
      } else if (data === '방문') {
        return 'A';
      }
      return data;
    }

    resultData = {
      // 👇 글자 사이 공백 제거
      // Addr1: newAddr1 ? newAddr1.toString().replace(/\s+/g, '') : '',

      Addr1: newAddr1
        ? filteringAddedData(newAddr1.toString()).replace(/\s+/g, '')
        : '',

      Addr2: newAddr2
        ? filteringAddedData(newAddr2.toString()).replace(/\s+/g, '')
        : '',

      CarNo: newCarNo ? filteringAddedData(newCarNo).replace(/\s+/g, '') : '',

      OwnerPhone: newOwnerPhone
        ? filteringAddedData(newOwnerPhone.toString()).replace(/\s+/g, '')
        : '',

      CarFlag: newCarFlag ? onCarFlag(newCarFlag) : '',

      CarExpireDate: newCarExpireDate ? newCarExpireDate : '',

      OwnerName: newOwnerName
        ? newOwnerName.toString().replace(/\s+/g, '')
        : '',
    };
  }

  onProcessingAddedData();

  const [startDate, setStartDate] = useState(new Date()); // MEMO: Date Picker 라이브러리

  return (
    <Wrap>
      <SignTextSection>
        <SignText>새로 등록할 차량 정보를 입력해주세요</SignText>
      </SignTextSection>

      <CategoryListSection>
        <CategoryBox>
          <LabelStyle htmlFor={'check-invalid-contract'}>
            차량 번호<p>(필수)</p>
          </LabelStyle>
          <InputStyle
            type="text"
            id={'check-invalid-contract'}
            placeholder={'차량 번호를 입력해주세요'}
            onChange={e => handleNewCarNo(e)}
          />
        </CategoryBox>

        <CategoryBox>
          <LabelStyle htmlFor={'check-invalid-contract'}>동</LabelStyle>
          <InputStyle
            type="text"
            id={'check-invalid-contract'}
            placeholder={`'동'을 제외하고 입력해주세요`}
            onChange={e => handleNewAddr1(e)}
          />
        </CategoryBox>

        <CategoryBox>
          <LabelStyle htmlFor={'check-invalid-contract'}>호</LabelStyle>
          <InputStyle
            type="text"
            id={'check-invalid-contract'}
            placeholder={`'호'를 제외하고 입력해주세요`}
            onChange={e => handleNewAddr2(e)}
          />
        </CategoryBox>

        <CategoryBox>
          <CategoryTitle>
            등록 유형<p>(필수)</p>
          </CategoryTitle>
          {/* <SelectStyle value={modifiedCarFlag} onChange={handleCarFlag}> */}
          <SelectStyle value={newCarFlag} onChange={handleCarFlag}>
            {carFlagOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </SelectStyle>
        </CategoryBox>

        {carFlagTag === 'D' && (
          <CategoryBox>
            <CategoryTitle>만료일</CategoryTitle>
            <div>
              <DatePicker
                locale={ko}
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                onChange={(date: React.SetStateAction<any>) =>
                  // setStartDate(date)
                  handleCarExpireDate(date)
                }
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </CategoryBox>
        )}

        <CategoryBox>
          <LabelStyle htmlFor={'check-invalid-contract'}>소유주</LabelStyle>
          <InputStyle
            type="text"
            id={'check-invalid-contract'}
            // placeholder={selectedGridData.OwnerName}
            placeholder={'소유주 이름을 입력해주세요'}
            onChange={e => handleOwnerName(e)}
          />
        </CategoryBox>

        <CategoryBox className="last-category">
          <LabelStyle htmlFor={'check-invalid-contract'}>연락처</LabelStyle>
          <InputStyle
            type="text"
            id={'check-invalid-contract'}
            placeholder={`'-'없이 숫자만 입력해주세요`}
            onChange={e => handleOwnerPhone(e)}
          />
        </CategoryBox>
      </CategoryListSection>

      <ButtonList>
        {/* <button onClick={() => modifySingleServerData({ modifiedResultData })}> */}
        <ButtonStyle
          className="yes-button"
          onClick={() =>
            uploadNewSingleData({ closeModal, resultData, apartmentIdx })
          }
        >
          등록하기
        </ButtonStyle>
        <ButtonStyle className="close-button" onClick={closeModal}>
          닫기
        </ButtonStyle>
      </ButtonList>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
`;

const SignTextSection = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 30px;
  width: 100%;
`;

const SignText = styled.div`
  font-size: 14px;
`;

const CategoryListSection = styled.div`
  padding: 14px 10px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  border-radius: 5px;
`;

const ButtonList = styled.div`
  margin-top: 15px;
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.printBtnStyle}

  width: 80px;
  cursor: pointer;

  &.yes-button {
    border: solid 1px ${({ theme }) => theme.mainColor.dark};
    background-color: ${({ theme }) => theme.mainColor.main};
    color: white;

    :hover {
      background-color: ${({ theme }) => theme.mainColor.dark};
    }
  }

  &.close-button {
    margin-left: 10px;
  }
`;

const CategoryBox = styled.div`
  display: flex;
  margin-bottom: 5px;

  &.last-category {
    margin-bottom: 0;
  }
`;

const CategoryTitle = styled.label`
  display: flex;
  align-items: center;
  width: 90px;
  height: 30px;
  font-size: 13px;

  p {
    margin-left: 2px;
    color: red;
    font-size: 11px;
  }
`;

const LabelStyle = styled.label`
  display: flex;
  align-items: center;
  width: 90px;
  height: 30px;
  font-size: 13px;

  p {
    margin-left: 2px;
    color: red;
    font-size: 11px;
  }
`;

const InputStyle = styled.input`
  padding: 0 5px;
  width: 180px;
  height: 30px;
  border-radius: 5px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};

  ::placeholder {
    margin: 0;
    /* color: #999; */
    color: ${({ theme }) => theme.fontColor.lightGray};
    font-size: 13px;
    /* font-style: italic; */
  }
`;

const SelectStyle = styled.select`
  padding: 0 5px;
  text-align: center;
  width: 180px;
  height: 30px;
  background-color: ${({ theme }) => theme.backgroundColor.gray};
  color: ${({ theme }) => theme.fontColor.black};
  border: 1px solid ${({ theme }) => theme.backgroundColor.gray};
  font-size: 13px;
  border-radius: 5px;

  appearance: none; /* 기본 스타일 제거 */
`;

const SelectOptionStyle = styled.option`
  border: 1px solid ${({ theme }) => theme.backgroundColor.gray};
  border-radius: 5px;

  :hover {
    background-color: ${({ theme }) => theme.backgroundColor.shadowGray};
  }
`;
