import React from 'react';
import { ApprovalTableSetting } from '../../components/SP10-020-approval-table-setting/ApprovalTableSetting/ApprovalTableSetting';
import RouterTrackerForAnalytics from '../../lib/utils/RouterTrackerForAnalytics'; // [MEMO] 구글 애널리틱스 라우터 트래커

const ApprovalTableSettingPage = () => {
  const $title = document.head.querySelector('title');
  $title!.innerText = '결재선 설정';
  RouterTrackerForAnalytics();

  return (
    <>
      <ApprovalTableSetting />
    </>
  );
};

export default ApprovalTableSettingPage;
