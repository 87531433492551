/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import axios, { AxiosResponse } from 'axios';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/agGridStyle/ag-theme-custom.css';
import { CellClickedEvent } from 'ag-grid-community';

import { gridOptions } from './gridOptions'; // 그리드 컬럼 목록

import { AttachedImageFileModal } from '../AttachedImageFileModal/AttachedImageFileModal';
import { DetailInfoModal } from '../DetailedInfoModal/DetailInfoModal';

import DescriptionIcon from '@mui/icons-material/Description';
import FolderIcon from '@mui/icons-material/Folder';

interface GridProps {
  recordListData: any[];
  apartmentName: string;
}

interface dataArrayTypes {
  apartmentName: string; // 단지명
  trackingLocation: string; // 순찰구역
  categoryName: string; // 소속
  employeeName: string; // 근무자
  employeeNum: string; // 사번
  date: string; // 날짜
  time: string; // 시간
  action: string;
  trackingGroup: string;
  trackingType: string;
  checkAttachedReport: boolean; // 보고서
  checkAttachedFile: boolean; // 첨부파일
  attachedFileName: string;
  recordIdx: number;
}

export const Grid = ({ recordListData, apartmentName }: GridProps) => {
  const token = sessionStorage.getItem('token');

  // const dataArray: {
  //   apartmentName: string; // 단지명
  //   trackingLocation: string; // 순찰구역
  //   categoryName: string; // 소속
  //   employeeName: string; // 근무자
  //   employeeNum: string; // 사번
  //   date: string; // 날짜
  //   time: string; // 시간
  //   action: string;
  //   trackingGroup: string;
  //   trackingType: string;
  //   checkAttachedReport: boolean; // 보고서
  //   checkAttachedFile: boolean; // 첨부파일
  //   attachedFileName: string;
  //   recordIdx: number;
  // }[] = [];
  const dataArray: dataArrayTypes[] = [];

  function loadRecordListData() {
    recordListData &&
      recordListData.forEach(data => {
        let trackingGroupName = ''; // 분류
        let trackingTypeName = ''; // 기기
        let actionName = ''; // 행동
        let checkAttachedReport = false; // 보고서
        let checkAttachedFile = false; // 첨부파일

        if (data.TrackingGroup === 'W') {
          trackingGroupName = '근무';
        } else if (data.TrackingGroup === 'A') {
          // 근태 = 출퇴근
          trackingGroupName = '근태';
        }

        if (data.TrackingType === 'N') {
          trackingTypeName = 'NFC';
        } else if (data.TrackingType === 'B') {
          trackingTypeName = 'Beacon';
        } else if (data.TrackingType === 'G') {
          trackingTypeName = 'GPS';
        } else if (data.TrackingType === 'W') {
          trackingTypeName = 'WIFI';
        }

        if (data.Action === 'I') {
          actionName = 'IN';
        } else if (data.Action === 'L') {
          // L = 특정 구역 벗어남(전체 범위 내에는 있음)
          actionName = 'LEAVE';
        } else if (data.Action === 'O') {
          // O = 전체 구역 벗어남
          actionName = 'OUT';
        } else if (data.Action === 'A') {
          actionName = '태그';
        }

        if (data.AttacheFile.length > 0) {
          checkAttachedReport = true;
        } else checkAttachedReport = false;

        if (data.AttacheReport > 0) {
          checkAttachedFile = true;
        } else checkAttachedFile = false;

        const resultData = {
          apartmentName: apartmentName, // 단지명
          trackingLocation: data.TrackingLocation, // 순찰구역
          categoryName: data.CategoryName, // 소속
          employeeName: data.EmployeeName, // 근무자
          employeeNum: data.EmployeeNo, // 사번
          date: data.TrigerTime.slice(0, 10), // 날짜
          time: data.TrigerTime.slice(11, 19), // 시간
          action: actionName,
          trackingGroup: trackingGroupName,
          trackingType: trackingTypeName,
          checkAttachedReport: checkAttachedReport,
          checkAttachedFile: checkAttachedFile,
          attachedFileName: data.AttacheFile,
          recordIdx: data.idx,
        };

        dataArray.push(resultData);
      });
  }
  loadRecordListData();

  const [detailInfoData, setDetailInfoData] = useState([]);
  const [detailInfoLoading, setDetailInfoLoading] = useState(false);
  const [onDetailInfoModal, setOnDetailInfoModal] = useState(false);

  const closeDetailInfoModal = () => {
    setOnDetailInfoModal(false);
  };

  const [imageDataList, setImageDataList] = useState<string[]>([]);
  // [TAG] 바이너리 파일 Blob으로 받기 -----start
  const [imageText, setImageText] = useState<string>('');
  // [TAG] 바이너리 파일 Blob으로 받기 -----end

  const [openAttachedImageFileModal, setOpenAttachedImageFileModal] =
    useState(false);
  const [downloadFileIdx, setDownloadFileIdx] = useState('');
  const [downloadFileName, setDownloadFileName] = useState('');

  const [imageTexts, setImageTexts] = useState<string[]>([]);

  const [modalKey, setModalKey] = useState(0); // key 상태 추가

  useEffect(() => {
    if (imageDataList.length > 0) {
      setModalKey(prevKey => prevKey + 1); // 이미지가 변경될 때마다 key 값을 증가시켜서 컴포넌트를 새로 생성하도록 함
      previewAttachedFileImage();
    }
  }, [imageDataList]);

  const onDetailInfo = async (e: CellClickedEvent) => {
    const targetIdx = e.data.recordIdx.toString();

    if (
      e.colDef.headerName === '보고서' &&
      e.data.attachedReport === '상세정보'
    ) {
      setDetailInfoLoading(true);
      setOnDetailInfoModal(true);

      await axios
        .get(
          `${process.env.COMMON_URL}/attendance/ar10020/workrecordadd/${targetIdx}`,
          {
            headers: {
              tokenId: token,
            },
          }
        )
        .then(res => {
          try {
            setDetailInfoLoading(false);
            setDetailInfoData(res.data.Data);
            setImageDataList(res.data.Data[0].AttachedFile);
          } catch (e) {
            console.error('에러가 발생했습니다:', e);
          }
        });
    }
  };

  const previewAttachedFileImage = async () => {
    const token = sessionStorage.getItem('token');

    const file = imageDataList[0];
    // const fileIdx = file.FileIdx;
    // const fileServerName = file.ServerFileName;

    const imagePromises: Promise<AxiosResponse<any, any>>[] = imageDataList.map(
      (file: { FileIdx: string; ServerFileName: string }) => {
        return axios.get(
          `${process.env.COMMON_URL}/attendance/ar10020/workrecordadd/download/${file.FileIdx}?attachedfilename=${file.ServerFileName}`,
          {
            responseType: 'blob',
            headers: { tokenId: token, 'content-type': 'application/json' },
          }
        );
      }
    );

    try {
      const responses = await Promise.all(imagePromises);

      const imageTexts = await Promise.all(
        responses.map(async res => {
          if (res.data instanceof Blob) {
            return new Promise<string>(resolve => {
              const reader = new FileReader();
              reader.onload = (ev: ProgressEvent<FileReader>) => {
                const previewImage = ev.target?.result as string;
                resolve(previewImage);
              };
              reader.readAsDataURL(res.data);
            });
          } else {
            return '';
          }
        })
      );
      setImageTexts(imageTexts); // 이미지 데이터를 모두 받아온 후, imageTexts 상태 업데이트
    } catch (error) {
      console.error('이미지 다운로드 중 에러가 발생했습니다: ', error);
    }

    // await axios
    //   .get(
    //     `${process.env.COMMON_URL}/attendance/ar10020/workrecordadd/download/${fileIdx}?attachedfilename=${fileServerName}`,
    //     {
    //       responseType: 'blob',
    //       // headers: { tokenId: token, 'content-type': 'image / png' },
    //       headers: { tokenId: token, 'content-type': 'application/json' },
    //     }
    //   )
    //   .then(async res => {
    //     try {
    //       const responses = await Promise.all(imagePromises);

    //       const imageTexts = await Promise.all(
    //         responses.map(async res => {
    //           if (res.data instanceof Blob) {
    //             return new Promise<string>(resolve => {
    //               const reader = new FileReader();
    //               reader.onload = (ev: ProgressEvent<FileReader>) => {
    //                 const previewImage = ev.target?.result as string;
    //                 resolve(previewImage);
    //               };
    //               reader.readAsDataURL(res.data);
    //             });
    //           } else {
    //             return '';
    //           }
    //         })
    //       );

    //       setImageTexts(imageTexts); // 이미지 데이터를 모두 받아온 후, imageTexts 상태 업데이트
    //     } catch (e) {
    //       //
    //     }
    //   });
  };

  // 첨부파일 탭의 '미리보기' 🚀
  const onPreviewFile = async (e: CellClickedEvent) => {
    const targetIdx = e.data.recordIdx.toString();
    // const targetIdx = e.data.idx.toString();
    const targetFileName = e.data.attachedFileName;
    setDownloadFileIdx(targetIdx);
    setDownloadFileName(targetFileName);

    if (e.colDef.headerName === '첨부파일' && targetFileName.length > 0) {
      try {
        const response = await axios.get(
          `${process.env.COMMON_URL}/attendance/ar10020/workrecordadd/download/${targetIdx}?attachedfilename=${targetFileName}`,
          {
            responseType: 'blob',
            headers: {
              tokenId: token,
            },
          }
        );

        // 👇 이미지 보이기 -----start
        if (response.data instanceof Blob) {
          const previewImage = await new Promise<string>(resolve => {
            const reader = new FileReader();
            reader.onload = (ev: ProgressEvent<FileReader>) => {
              resolve(ev.target?.result as string);
            };
            reader.readAsDataURL(response.data);
          });

          // previewImage를 사용해 필요한 작업 수행하기
          setImageText(previewImage);
          setOpenAttachedImageFileModal(true);
          // 👆 이미지 보이기 -----end
        }
      } catch (e) {
        console.error('파일 다운로드 중 에러가 발생했습니다:', e);
      }
    }
  };

  const onDownloadFile = async (
    downloadFileIdx: string,
    downloadFileName: string
  ) => {
    const targetIdx = downloadFileIdx;
    const targetFileName = downloadFileName;

    try {
      const response = await axios.get(
        `${process.env.COMMON_URL}/attendance/ar10020/workrecordadd/download/${targetIdx}?attachedfilename=${targetFileName}`,
        {
          headers: {
            tokenId: token,
          },
          responseType: 'blob',
        }
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const $a = document.createElement('a');
      $a.href = downloadUrl;
      $a.download = targetFileName;
      document.body.appendChild($a);
      $a.click();
      document.body.removeChild($a);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (e) {
      console.error('파일 다운로드 중 에러가 발생했습니다:', e);
    }
  };

  // 👇 '첨부파일' 탭의 이미지 다운로드 (임시 주석. 기능은 정상 작동함) -----start
  // const onDownloadReport = async (e: CellClickedEvent) => {
  //   const targetIdx = e.data.recordIdx.toString();
  //   const targetFileName = e.data.attachedFileName;

  //   if (e.colDef.headerName === '첨부파일') {
  //     try {
  //       const response = await axios.get(
  //         `${process.env.COMMON_URL}/attendance/ar10020/workrecordadd/download/${targetIdx}?attachedfilename=${targetFileName}`,
  //         {
  //           headers: {
  //             tokenId: token,
  //           },
  //           responseType: 'blob',
  //         }
  //       );

  //       const downloadUrl = window.URL.createObjectURL(
  //         new Blob([response.data])
  //       );

  //       const $a = document.createElement('a');
  //       $a.href = downloadUrl;
  //       $a.download = targetFileName;
  //       document.body.appendChild($a);
  //       $a.click();
  //       document.body.removeChild($a);
  //       window.URL.revokeObjectURL(downloadUrl);
  //     } catch (e) {
  //       console.error('파일 다운로드 중 에러가 발생했습니다:', e);
  //     }
  //   }
  // };
  // 👆 '첨부파일' 탭의 이미지 다운로드 (임시 주석. 기능은 정상 작동함) -----end

  return (
    <>
      {onDetailInfoModal && (
        <DetailInfoModal
          key={modalKey} // key 속성 추가
          closeDetailInfoModal={closeDetailInfoModal}
          detailInfoData={detailInfoData}
          detailInfoLoading={detailInfoLoading}
          image={imageTexts}
        />
      )}

      {openAttachedImageFileModal && (
        <AttachedImageFileModal
          setOpenAttachedImageFileModal={setOpenAttachedImageFileModal}
          imageText={imageText}
          onDownloadFile={() =>
            // onDownloadReport(e)
            onDownloadFile(downloadFileIdx, downloadFileName)
          }
          downloadFileIdx={downloadFileIdx}
          downloadFileName={downloadFileName}
        />
      )}

      <GridSection id="myGrid" className="ag-theme-alpine ag-theme-custom">
        <AgGridReact
          headerHeight={45} // 타이틀 행 높이
          rowHeight={35} // 본문 행 높이
          gridOptions={gridOptions}
          rowData={dataArray.map((data, idx) => {
            return {
              // apartmentName: apartmentName, // 단지명
              categoryName: data.categoryName, // 소속
              employeeName: data.employeeName, // 근무자
              employeeNum: data.employeeNum, // 사번
              date: data.date, // 날짜
              time: data.time, // 시간
              action: data.action, // 행동
              trackingGroup: data.trackingGroup, // 종류
              trackingType: data.trackingType, // 기기 유형
              trackingLocation: data.trackingLocation, // 순찰구역
              // attachedReport: data.attachedReport ? <DescriptionIcon /> : '',
              attachedReport: data.checkAttachedReport ? '상세정보' : '',
              attachedFile: data.checkAttachedFile ? '미리보기' : '',
              attachedFileName: data.attachedFileName, // 첨부 파일 이름
              recordIdx: data.recordIdx,
            };
          })}
          animateRows={true}
          rowDragManaged={true}
          suppressMoveWhenRowDragging={true}
          pagination={true}
          paginationPageSize={15}
          onCellClicked={e => {
            onDetailInfo(e);
            previewAttachedFileImage(e);
            onPreviewFile(e);
            // onDownloadReport(e);
          }}
        />
      </GridSection>
    </>
  );
};

const GridSection = styled.div`
  height: ${({ theme }) => theme.gridStyle.height};
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;
