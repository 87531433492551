export const gridOptions = {
  columnDefs: [
    {
      field: 'idx',
      headerName: '번호',
      resizable: true,
      filter: true,
      sortable: true,
      width: 80,
      cellClass: 'cell-class',
    },
    {
      field: 'name',
      headerName: '단지명',
      resizable: true,
      filter: true,
      sortable: true,
      width: 280,
      cellClass: 'cell-class',
    },
    {
      field: 'areaName',
      headerName: '지역',
      resizable: true,
      filter: true,
      sortable: true,
      width: 150,
      cellClass: 'cell-class-contents',
    },
    /*
  {
    field: 'noBuilding',
    headerName: '건물수',
    resizable: true,
    filter: true,
    sortable: true,
    width: 120,
    cellClass: 'cell-class-contents',
  },
  */
    {
      field: 'noHouse',
      headerName: '세대수',
      resizable: true,
      filter: true,
      sortable: true,
      width: 100,
      cellClass: 'cell-class-contents',
    },
    /*
  {
    field: 'noParking',
    headerName: '주차면',
    resizable: true,
    filter: true,
    sortable: true,
    width: 120,
    cellClass: 'cell-class-contents',
  },
  */
    /*
  {
    field: 'manageArea',
    headerName: '관리면적',
    resizable: true,
    filter: true,
    sortable: true,
    width: 150,
    cellClass: 'cell-class-contents',
  },
  */
    /*
  {
    field: 'taxExemptionArea',
    headerName: '면세면적',
    resizable: true,
    filter: true,
    sortable: true,
    width: 150,
    cellClass: 'cell-class-contents',
  },
  */
    /*
  {
    field: 'taxationArea',
    headerName: '과세면적',
    resizable: true,
    filter: true,
    sortable: true,
    width: 150,
    cellClass: 'cell-class-contents',
  },
  */
    {
      field: 'POSTCode',
      headerName: '우편번호',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
    },
    {
      field: 'address1',
      headerName: '주소',
      resizable: true,
      filter: true,
      sortable: true,
      width: 600,
      cellClass: 'cell-class-contents',
    },
  ],

  // custom loading template. the class ag-overlay-loading-center is part of the grid,
  // it gives a white background and rounded border
  overlayLoadingTemplate:
    '<span class="ag-overlay-loading-center" style="padding: 10px; border: 1px solid #444; background: lightgoldenrodyellow;">데이터를 불러오는 중입니다</span>',

  overlayNoRowsTemplate:
    '<span style="padding: 10px; border: 3px solid #ededed; border-radius: 0.5em; background-color: white; box-shadow: 5px 5px 15px -3px #ededed; color: #717171; font-weight: 600;">불러온 데이터가 없습니다</span>',
};
