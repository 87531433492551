import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { Modal } from '../DetailedNoticeModal/Modal';
import { NoticeBoardSection } from '../NoticeBoardSection/NoticeBoardSection';
import { TemporaryEmployeeSection } from '../TemporaryEmployeeSection/TemporaryEmployeeSection';
import { WorkStatusCheckSection } from '../WorkStatusCheckSection/WorkStatusCheckSection';
import { UserDeviceCheckSection } from '../UserDeviceCheckSection/UserDeviceCheckSection';

import { loadNoticeBoardData } from '../utils/loadNoticeBoardData'; // [MEMO] 공지사항 데이터
import { loadToDodoData } from '../utils/loadToDodoData'; // [MEMO] todo리스트(해야 할 일) 데이터 / 관리자 대상
import { loadApartmentContractList } from '../utils/loadApartmentContractList'; // [MEMO] 단지 계약 정보 불러오기 / 관리소장 대상
import { loadWorkStatusData } from '../utils/loadWorkStatusData'; // [MEMO] 근태 현황
import { loadTemporaryEmployeeData } from '../utils/loadTemporaryEmployeeData'; // [MEMO] 임시 사용자 등록 현황
import { loadWorkStatusCheckToWorkData } from '../utils/loadWorkStatusCheckToWorkData'; // [MEMO] 근태 이상 현황 조회(출근=근무)
import { loadWorkStatusCheckToHomeData } from '../utils/loadWorkStatusCheckToHomeData'; // [MEMO] 근태 이상 현황 조회(퇴근)
import { loadUserDeviceCheckInstallData } from '../utils/loadUserDeviceCheckInstallData'; // [MEMO] 스마트폰 상태 조회(설치)
import { loadUserDeviceCheckErrorData } from '../utils/loadUserDeviceCheckErrorData'; // [MEMO] 스마트폰 상태 조회(오류)

import { ko } from 'date-fns/esm/locale';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import './DatePicker.css';
import '../../../styles/DatePicker.css';

import { Button } from '../../UI/Button/Long';

export const Contents = () => {
  /**
   * permitCode 권한 코드
   * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
   * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
   * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
   */
  const navigate = useNavigate();

  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');
  const userName = sessionStorage.getItem('userNameKR');
  const employeeNum = sessionStorage.getItem('employeeNum');

  useEffect(() => {
    if (permitCode === process.env.HEAD_OFFICE_MANAGER_CODE) {
      loadToDodoData({ setToDoData });
    }

    if (permitCode === process.env.APARTMENT_MANAGER_CODE) {
      loadApartmentContractList({ setApartmentData });
    }

    loadNoticeBoardData({ setNoticeData }); // [MEMO] 공지사항
    loadTemporaryEmployeeData({ setTemporaryEmployeeData }); // [MEMO]임시 사용자 등록 현황
    loadWorkStatusData({
      setWorkStatusData, // [MEMO] 근태 현황 데이터
      workStatusYearPicker,
      workStatusMonthPicker,
      workStatusDatePicker,
    });
    loadWorkStatusCheckToWorkData({
      setToWorkStatusCheckData, // [MEMO] 근태 이상 현황 조회(출근=근무) 데이터
      startCheckToWorkYearPicker,
      startCheckToWorkMonthPicker,
      startCheckToWorkDatePicker,
      endCheckToWorkYearPicker,
      endCheckToWorkMonthPicker,
      endCheckToWorkDatePicker,
    });
    loadWorkStatusCheckToHomeData({
      setToHomeStatusCheckData, // [MEMO] 근태 이상 현황 조회(퇴근) 데이터
      startCheckToHomeYearPicker,
      startCheckToHomeMonthPicker,
      startCheckToHomeDatePicker,
      endCheckToHomeYearPicker,
      endCheckToHomeMonthPicker,
      endCheckToHomeDatePicker,
    });
    loadUserDeviceCheckInstallData({
      setUserDeviceInstallCheckData, // [MEMO] 앱 상태 현황(설치 관련) 데이터
      startCheckInstallYearPicker,
      startCheckInstallMonthPicker,
      startCheckInstallDatePicker,
      endCheckInstallYearPicker,
      endCheckInstallMonthPicker,
      endCheckInstallDatePicker,
    });
    loadUserDeviceCheckErrorData({
      setUserDeviceErrorCheckData, // [MEMO] 앱 상태 현황(오류 관련) 데이터
      startCheckErrorYearPicker,
      startCheckErrorMonthPicker,
      startCheckErrorDatePicker,
      endCheckErrorYearPicker,
      endCheckErrorMonthPicker,
      endCheckErrorDatePicker,
    });
    controlToWorkBox();
    controlInstallBox();
  }, []);

  const [noticeData, setNoticeData] = useState<any[]>([]); // [MEMO] 공지사항 데이터
  const [detailedNoticeData, setDetailedNoticeData] = useState<any[]>([]); // [MEMO] 공지사항 상세(내용) 데이터
  const [toDoData, setToDoData] = useState<any[]>([]); // [MEMO] todo리스트(해야 할 일) 데이터
  const [apartmentData, setApartmentData] = useState<any[]>([]); // [MEMO] 단지 계약 정보 불러오기 데이터
  const [temporaryEmployeeData, setTemporaryEmployeeData] = useState([]); // [MEMO] 임시 사용자 등록 현황 데이터
  const [workStatusData, setWorkStatusData] = useState([]); // [MEMO] 근태 현황 데이터
  const [toWorkStatusCheckData, setToWorkStatusCheckData] = useState([]); // [MEMO] 근태 이상 현황 조회(출근=근무) 데이터
  const [toHomeStatusCheckData, setToHomeStatusCheckData] = useState([]); // [MEMO] 근태 이상 현황 조회(퇴근) 데이터
  const [userDeviceInstallCheckData, setUserDeviceInstallCheckData] = useState(
    []
  ); // [MEMO] 스마트폰 상태 조회(설치 여부 확인)
  const [userDeviceErrorCheckData, setUserDeviceErrorCheckData] = useState([]); // [MEMO] 스마트폰 상태 조회(오류 여부 확인)

  // 👇 근태 현황
  const [pickWorkStatusDate, setPickWorkStatusDate] = useState(new Date()); // [MEMO] Date Picker 라이브러리

  // 👇 근태 현황 - 데이터 피커에서 선택한 날짜
  const workStatusYearPicker = pickWorkStatusDate.getFullYear().toString();
  const workStatusMonthPicker = (pickWorkStatusDate.getMonth() + 1).toString();
  const workStatusDatePicker = pickWorkStatusDate.getDate().toString();

  // 👇 근태 이상 현황(출)
  const [pickStartCheckToWorkDate, setPickStartCheckToWorkDate] = useState(
    new Date()
  ); // [MEMO] Date Picker 라이브러리
  const [pickEndCheckToWorkDate, setPickEndCheckToWorkDate] = useState(
    new Date()
  ); // [MEMO] Date Picker 라이브러리

  // 👇 근태 이상 현황(출) / 시작일 - 데이터 피커에서 선택한 날짜
  const startCheckToWorkYearPicker = pickStartCheckToWorkDate
    .getFullYear()
    .toString();
  const startCheckToWorkMonthPicker = (
    pickStartCheckToWorkDate.getMonth() + 1
  ).toString();
  const startCheckToWorkDatePicker = pickStartCheckToWorkDate
    .getDate()
    .toString();

  // 👇 근태 이상 현황(출) / 종료일 - 데이터 피커에서 선택한 날짜
  const endCheckToWorkYearPicker = pickEndCheckToWorkDate
    .getFullYear()
    .toString();
  const endCheckToWorkMonthPicker = (
    pickEndCheckToWorkDate.getMonth() + 1
  ).toString();
  const endCheckToWorkDatePicker = pickEndCheckToWorkDate.getDate().toString();

  // 👇 근태 이상 현황(퇴)
  const [pickStartCheckToHomeDate, setPickStartCheckToHomeDate] = useState(
    new Date()
  ); // [MEMO] Date Picker 라이브러리
  const [pickEndCheckToHomeDate, setPickEndCheckToHomeDate] = useState(
    new Date()
  ); // [MEMO] Date Picker 라이브러리

  // 👇 근태 이상 현황(퇴) / 시작일 - 데이터 피커에서 선택한 날짜
  const startCheckToHomeYearPicker = pickStartCheckToHomeDate
    .getFullYear()
    .toString();
  const startCheckToHomeMonthPicker = (
    pickStartCheckToHomeDate.getMonth() + 1
  ).toString();
  const startCheckToHomeDatePicker = pickStartCheckToHomeDate
    .getDate()
    .toString();

  // 👇 근태 이상 현황(퇴) / 종료일 - 데이터 피커에서 선택한 날짜
  const endCheckToHomeYearPicker = pickEndCheckToHomeDate
    .getFullYear()
    .toString();
  const endCheckToHomeMonthPicker = (
    pickEndCheckToHomeDate.getMonth() + 1
  ).toString();
  const endCheckToHomeDatePicker = pickEndCheckToHomeDate.getDate().toString();

  const [openToWork, setOpenToWork] = useState(false);
  const [openToHome, setOpenToHome] = useState(false);

  const controlToWorkBox = () => {
    setOpenToHome(false);
    setOpenToWork(true);
  };

  const controlToHomeBox = () => {
    setOpenToWork(false);
    setOpenToHome(true);
  };

  // 👇 스마트폰 상태 조회(설치)
  const [pickStartCheckInstallDate, setPickStartCheckInstallDate] = useState(
    new Date()
  ); // [MEMO] Date Picker 라이브러리
  const [pickEndCheckInstallDate, setPickEndCheckInstallDate] = useState(
    new Date()
  ); // [MEMO] Date Picker 라이브러리

  // 👇 스마트폰 상태 조회(설치) / 시작일 - 데이터 피커에서 선택한 날짜
  const startCheckInstallYearPicker = pickStartCheckInstallDate
    .getFullYear()
    .toString();
  const startCheckInstallMonthPicker = (
    pickStartCheckInstallDate.getMonth() + 1
  ).toString();
  const startCheckInstallDatePicker = pickStartCheckInstallDate
    .getDate()
    .toString();

  // 👇 스마트폰 상태 조회(설치) / 종료일 - 데이터 피커에서 선택한 날짜
  const endCheckInstallYearPicker = pickEndCheckInstallDate
    .getFullYear()
    .toString();
  const endCheckInstallMonthPicker = (
    pickEndCheckInstallDate.getMonth() + 1
  ).toString();
  const endCheckInstallDatePicker = pickEndCheckInstallDate
    .getDate()
    .toString();

  // 👇 스마트폰 상태 조회(오류)
  const [pickStartCheckErrorDate, setPickStartCheckErrorDate] = useState(
    new Date()
  ); // [MEMO] Date Picker 라이브러리
  const [pickEndCheckErrorDate, setPickEndCheckErrorDate] = useState(
    new Date()
  ); // [MEMO] Date Picker 라이브러리

  // 👇 스마트폰 상태 조회(오류) / 시작일 - 데이터 피커에서 선택한 날짜
  const startCheckErrorYearPicker = pickStartCheckErrorDate
    .getFullYear()
    .toString();
  const startCheckErrorMonthPicker = (
    pickStartCheckErrorDate.getMonth() + 1
  ).toString();
  const startCheckErrorDatePicker = pickStartCheckErrorDate
    .getDate()
    .toString();

  // 👇 스마트폰 상태 조회(오류) / 종료일 - 데이터 피커에서 선택한 날짜
  const endCheckErrorYearPicker = pickEndCheckErrorDate
    .getFullYear()
    .toString();
  const endCheckErrorMonthPicker = (
    pickEndCheckErrorDate.getMonth() + 1
  ).toString();
  const endCheckErrorDatePicker = pickEndCheckErrorDate.getDate().toString();

  const [openInstall, setOpenInstall] = useState(false);
  const [openError, setOpenError] = useState(false);

  const controlInstallBox = () => {
    setOpenError(false);
    setOpenInstall(true);
  };

  const controlErrorBox = () => {
    setOpenInstall(false);
    setOpenError(true);
  };

  // 👇 공지사항 상세 내용 모달
  const [detailedNoticeModalVisible, setDetailedNoticeModalVisible] =
    useState(false);

  function handleDetailedNoticeModal() {
    setDetailedNoticeModalVisible(!detailedNoticeModalVisible);
  }

  // 👇 공지사항 모달 열린 상태일 때(true), 배경 스크롤바 숨기기
  if (detailedNoticeModalVisible) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'unset';
  }

  const onVerifiedUserTag = () => {
    if (permitCode === process.env.HEAD_OFFICE_MANAGER_CODE) {
      return (
        <div
          style={{
            padding: '5px',
            borderRadius: '0.5em',
            backgroundColor: '#38c0ff',
            color: 'white',
            fontSize: '13px',
          }}
        >
          관리자
        </div>
      );
    }
    if (permitCode === process.env.APARTMENT_MANAGER_CODE) {
      return (
        <div
          style={{
            padding: '5px',
            borderRadius: '0.5em',
            backgroundColor: '#ff8282',
            color: 'white',
            fontSize: '13px',
          }}
        >
          관리소장
        </div>
      );
    }
    if (permitCode === process.env.NORMAL_EMPLOYEE_CODE) {
      return (
        <div
          style={{
            padding: '5px',
            borderRadius: '0.5em',
            backgroundColor: '#ffc300',
            color: 'white',
            fontSize: '13px',
          }}
        >
          일반
        </div>
      );
    }
  };

  return (
    <>
      {detailedNoticeModalVisible && (
        <Modal
          closeModal={handleDetailedNoticeModal}
          detailedNoticeModalVisible={detailedNoticeModalVisible}
          detailedNoticeData={detailedNoticeData}
        />
      )}

      <TitleSection>
        <Title>
          안녕하세요, <p>{userName}</p>님
        </Title>
        <div
          style={{
            display: 'flex',
            paddingLeft: '30px',
          }}
        >
          {onVerifiedUserTag()}
        </div>
      </TitleSection>

      <div>
        {/*  */}
        {/*  */}
        {/*  */}

        <CategorySectionWrap>
          <CategorySection className="notice-board">
            <CategoryTitleWrap style={{ marginBottom: '20px' }}>
              공지사항
            </CategoryTitleWrap>

            <LoadedDataSection>
              <NoticeBoardWrap>
                <NoticeBoardSection
                  noticeData={noticeData}
                  setDetailedNoticeData={setDetailedNoticeData}
                  setDetailedNoticeModalVisible={setDetailedNoticeModalVisible}
                />
              </NoticeBoardWrap>
            </LoadedDataSection>
          </CategorySection>

          {/*  */}
          {/*  */}
          {/*  */}

          {permitCode === process.env.HEAD_OFFICE_MANAGER_CODE && (
            <CategorySection className="to-do">
              <CategoryTitleWrap style={{ marginBottom: '20px' }}>
                해야 할 일
              </CategoryTitleWrap>

              <LoadedDataSection>
                <ToDoWrap>
                  {toDoData.map(data => {
                    //
                    return (
                      <div key={data.idx}>
                        <div
                          style={{
                            marginTop: '10px',
                            marginLeft: '10px',
                            fontSize: '13px',
                          }}
                        >
                          {data.ToDoTitle}
                        </div>
                      </div>
                    );
                  })}
                </ToDoWrap>
              </LoadedDataSection>
            </CategorySection>
          )}

          {permitCode === process.env.APARTMENT_MANAGER_CODE && (
            <CategorySection className="manage-apartment-list">
              <CategoryTitleWrap style={{ marginBottom: '20px' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  관리 단지 목록
                  <SwitchBtn
                    className="instant-move"
                    onClick={() => {
                      navigate('/CM101');
                    }}
                  >
                    바로가기
                  </SwitchBtn>
                </div>
              </CategoryTitleWrap>

              <LoadedDataSection>
                <div>
                  {apartmentData.map(data => {
                    return (
                      <div key={data.idx}>
                        <div
                          style={{
                            marginTop: '10px',
                            marginLeft: '10px',
                            fontSize: '13px',
                          }}
                        >
                          {data.Name} / {data.JobGroupEtc}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </LoadedDataSection>
            </CategorySection>
          )}

          <CategorySection className="work-status">
            <CategoryTitleWrap style={{ marginBottom: '0' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                근태 현황
                <SwitchBtn
                  className="instant-move"
                  onClick={() => {
                    navigate('/AC103');
                  }}
                >
                  바로가기
                </SwitchBtn>
              </div>
            </CategoryTitleWrap>
          </CategorySection>

          {permitCode === process.env.HEAD_OFFICE_MANAGER_CODE && (
            <CategorySection className="temporary-user-list">
              <CategoryTitleWrap>임시 사용자 등록 현황</CategoryTitleWrap>

              <LoadedDataSection>
                <TemporaryEmployeeWrap>
                  <TemporaryEmployeeSection
                    temporaryEmployeeData={temporaryEmployeeData}
                  />
                </TemporaryEmployeeWrap>
              </LoadedDataSection>
            </CategorySection>
          )}

          <CategorySection className="work-status-check">
            <CategoryTitleWrap>
              <TitleList>
                <div>근태 이상 현황</div>

                <OptionList>
                  <SwitchBtn
                    onClick={() => {
                      controlToWorkBox();
                    }}
                  >
                    출근
                  </SwitchBtn>
                  <SwitchBtn
                    onClick={() => {
                      controlToHomeBox();
                    }}
                  >
                    퇴근
                  </SwitchBtn>
                  {openToWork && (
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '5px' }}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={pickStartCheckToWorkDate}
                          onChange={(date: React.SetStateAction<any>) =>
                            setPickStartCheckToWorkDate &&
                            setPickStartCheckToWorkDate(date)
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>

                      <div style={{ marginRight: '5px' }}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={pickEndCheckToWorkDate}
                          onChange={(date: React.SetStateAction<any>) =>
                            setPickEndCheckToWorkDate &&
                            setPickEndCheckToWorkDate(date)
                          }
                          selectsEnd
                          startDate={pickStartCheckToWorkDate}
                          endDate={pickEndCheckToWorkDate}
                          minDate={pickStartCheckToWorkDate}
                        />
                      </div>
                    </div>
                  )}
                  {openToHome && (
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '5px' }}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={pickStartCheckToHomeDate}
                          onChange={(date: React.SetStateAction<any>) =>
                            setPickStartCheckToHomeDate(date)
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>

                      <div style={{ marginRight: '5px' }}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={pickEndCheckToHomeDate}
                          onChange={(date: React.SetStateAction<any>) =>
                            setPickEndCheckToHomeDate(date)
                          }
                          selectsEnd
                          startDate={pickStartCheckToHomeDate}
                          endDate={pickEndCheckToHomeDate}
                          minDate={pickStartCheckToHomeDate}
                        />
                      </div>
                    </div>
                  )}
                  {openToWork && (
                    <Button
                      label="불러오기"
                      onClick={() =>
                        loadWorkStatusCheckToWorkData({
                          setToWorkStatusCheckData,
                          startCheckToWorkYearPicker,
                          startCheckToWorkMonthPicker,
                          startCheckToWorkDatePicker,
                          endCheckToWorkYearPicker,
                          endCheckToWorkMonthPicker,
                          endCheckToWorkDatePicker,
                        })
                      }
                    />
                  )}
                  {openToHome && (
                    <Button
                      label="불러오기"
                      onClick={() =>
                        loadWorkStatusCheckToHomeData({
                          setToHomeStatusCheckData,
                          startCheckToHomeYearPicker,
                          startCheckToHomeMonthPicker,
                          startCheckToHomeDatePicker,
                          endCheckToHomeYearPicker,
                          endCheckToHomeMonthPicker,
                          endCheckToHomeDatePicker,
                        })
                      }
                    />
                  )}
                </OptionList>
              </TitleList>
            </CategoryTitleWrap>

            <LoadedDataSection>
              <TemporaryEmployeeWrap>
                {openToWork && (
                  <WorkStatusCheckSection
                    toWorkStatusCheckData={toWorkStatusCheckData}
                  />
                )}

                {openToHome && (
                  <WorkStatusCheckSection
                    toHomeStatusCheckData={toHomeStatusCheckData}
                  />
                )}
              </TemporaryEmployeeWrap>
            </LoadedDataSection>
          </CategorySection>

          <CategorySection className="app-status-check">
            <CategoryTitleWrap>
              <TitleList>
                앱 상태 현황
                <OptionList>
                  <SwitchBtn
                    onClick={() => {
                      controlInstallBox();
                    }}
                  >
                    설치
                  </SwitchBtn>
                  <SwitchBtn
                    onClick={() => {
                      controlErrorBox();
                    }}
                  >
                    오류
                  </SwitchBtn>

                  {openInstall && (
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '5px' }}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={pickStartCheckInstallDate}
                          onChange={(date: React.SetStateAction<any>) =>
                            setPickStartCheckInstallDate(date)
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>

                      <div style={{ marginRight: '5px' }}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={pickEndCheckInstallDate}
                          onChange={(date: React.SetStateAction<any>) =>
                            setPickEndCheckInstallDate(date)
                          }
                          selectsEnd
                          startDate={pickStartCheckInstallDate}
                          endDate={pickEndCheckInstallDate}
                          minDate={pickStartCheckInstallDate}
                        />
                      </div>
                    </div>
                  )}

                  {openError && (
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '5px' }}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={pickStartCheckErrorDate}
                          onChange={(date: React.SetStateAction<any>) =>
                            setPickStartCheckErrorDate(date)
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>

                      <div style={{ marginRight: '5px' }}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={pickEndCheckErrorDate}
                          onChange={(date: React.SetStateAction<any>) =>
                            setPickEndCheckErrorDate(date)
                          }
                          selectsEnd
                          startDate={pickStartCheckErrorDate}
                          endDate={pickEndCheckErrorDate}
                          minDate={pickStartCheckErrorDate}
                        />
                      </div>
                    </div>
                  )}

                  {openInstall && (
                    <Button
                      label="불러오기"
                      onClick={() =>
                        loadUserDeviceCheckInstallData({
                          setUserDeviceInstallCheckData,
                          startCheckInstallYearPicker,
                          startCheckInstallMonthPicker,
                          startCheckInstallDatePicker,
                          endCheckInstallYearPicker,
                          endCheckInstallMonthPicker,
                          endCheckInstallDatePicker,
                        })
                      }
                    />
                  )}

                  {openError && (
                    <Button
                      label="불러오기"
                      onClick={() =>
                        loadUserDeviceCheckErrorData({
                          setUserDeviceErrorCheckData,
                          startCheckErrorYearPicker,
                          startCheckErrorMonthPicker,
                          startCheckErrorDatePicker,
                          endCheckErrorYearPicker,
                          endCheckErrorMonthPicker,
                          endCheckErrorDatePicker,
                        })
                      }
                    />
                  )}
                </OptionList>
              </TitleList>
            </CategoryTitleWrap>
            <LoadedDataSection>
              <TemporaryEmployeeWrap>
                {openInstall && (
                  <UserDeviceCheckSection
                    userDeviceInstallCheckData={userDeviceInstallCheckData}
                  />
                )}

                {openError && (
                  <UserDeviceCheckSection
                    userDeviceErrorCheckData={userDeviceErrorCheckData}
                  />
                )}
              </TemporaryEmployeeWrap>
            </LoadedDataSection>
          </CategorySection>
        </CategorySectionWrap>
      </div>
    </>
  );
};

const Bar = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const TitleSection = styled.div`
  margin-bottom: 50px;
`;

const Title = styled.div`
  ${({ theme }) => theme.contentsMainTitle};
  ${({ theme }) => theme.stopDrag};

  display: flex;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.fontColor.black};
  font-size: 34px;

  p {
    margin-left: 5px;
    color: ${({ theme }) => theme.mainColor.main};
  }
`;

const Section = styled.div`
  /* width: 100%; */
`;

const CategorySectionWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CategorySection = styled.div`
  margin-bottom: 20px;
  margin-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  border-radius: 0.5em;
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};

  &.notice-board {
    /* 공지사항 */
    margin: 0 0 20px 20px;
    width: calc(50% - 30px);
  }

  &.to-do {
    /* 해야 할 일 - 본사 관리자 한정 */
    margin: 0 20px 20px 20px;
    width: calc(50% - 30px);
  }

  &.manage-apartment-list {
    /* 관리 단지 목록 - 관리소장 한정 */
    margin: 0 20px 20px 20px;
    width: calc(50% - 30px);
  }

  &.work-status {
    /* 근태 현황 */
    margin: 0 20px 20px 20px;
  }

  &.temporary-user-list {
    /* 임시 사용자 등록 현황 */
    margin: 0 20px 20px 20px;
  }

  &.work-status-check {
    /* 근태 이상 현황 */
    /* margin: 0 20px 20px 20px; */
    margin: 0 0 20px 20px;
    width: calc(50% - 30px);
  }

  &.app-status-check {
    /* 앱 상태 현황: 스마트폰 설치(혹은 실행) 상태 조회 */
    /* margin: 0 20px 20px 20px; */
    margin: 0 20px 20px 20px;
    width: calc(50% - 30px);
  }
`;

const CategoryTitleWrap = styled.div`
  ${({ theme }) => theme.contentsMainTitle};
  ${({ theme }) => theme.stopDrag};

  padding-right: 30px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.fontColor.black};
  font-size: 26px;
`;

const TitleList = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OptionList = styled.div`
  display: flex;
`;

const LoadedDataSection = styled.div`
  padding-right: 30px;
  padding-left: 30px;
`;

const NoticeBoardWrap = styled.div`
  /* width: 50%; */
`;

const ToDoWrap = styled.div`
  ${({ theme }) => theme.scrollBarStyle};

  overflow-y: auto;
  height: 100px;
  border-radius: 0.5em;
  border: solid 1px lightGray;
  background-color: white;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
`;

/* 👇 temporaryEmployee: 임시 사용자 등록 */
const TemporaryEmployeeWrap = styled.div`
  ${({ theme }) => theme.scrollBarStyle};

  /* width: 650px; */
  height: 150px;
  border-radius: 0.5em;
  border: solid 1px lightGray;
  overflow-x: auto;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
`;

/* 👇 loadWorkStatus: 근태 현황 */
const LoadWorkStatusWrap = styled.div`
  /* width: 350px; */
  height: 300px;
`;

const SwitchBtn = styled.div`
  ${({ theme }) => theme.printBtnStyle};
  margin-right: 5px;
  padding: 8px;
  color: ${({ theme }) => theme.fontColor.black};

  :hover {
    background-color: ${({ theme }) => theme.backgroundColor.shadowGray};
  }

  &.instant-move {
    /*  */

    :hover {
      /*  */
    }
  }
`;
