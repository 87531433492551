// [MEMO] 차량 주차 정보 인쇄
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { loadExistingData } from '../utils/loadExistingData';

import { ApprovalList } from '../../SP10-020-approval-table-setting/ApprovalList/ApprovalList'; // 결재 박스

import axios from 'axios';

const ParkingViolationPrinting = () => {
  const [loading, setLoading] = useState(false); // [MEMO] 데이터 로딩

  /* 👇 🥺🥺🥺🥺🥺 */
  const queryParams = new URLSearchParams(window.location.search);
  const receivedObject = JSON.parse(queryParams.get('dataObject'));

  console.log('🍕2🍕 receivedObject: ', receivedObject);
  // const imageListData = receivedObject.violationListData;
  // console.log('🍺🍺🍺 imageListData: ', imageListData);
  /* 👆 🥺🥺🥺🥺🥺 */

  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentNameAtSessionStorage = sessionStorage.getItem('apartmentName');

  const [isListData, setIsListData] = useState(false);

  if (
    permitCode === apartmentManagerCode ||
    permitCode === headOfficerManagerCode
  ) {
    useEffect(() => {
      if (receivedObject) {
        loadExistingData({
          setLoadedData: setLoadedDataFoo,
          // setLoading: setLoadingFoo,
          setLoading,
          apartmentIdx: Number(receivedObject.apartmentIdx),
          selectedContractStart: receivedObject.selectedContractStart,
          selectedContractEnd: receivedObject.selectedContractEnd,
          significantFlagSign: receivedObject.significantFlagSign,
          unregisteredCarSign: receivedObject.unregisteredCarSign,
          enteredCarNumber: receivedObject.enteredCarNumber,
          selectedCheckPeriod: receivedObject.selectedCheckPeriod,
          //
          checkImage: 2,
        });

        onValidImageList();
      }
    }, []);
  }

  /* 👇 🥺🥺🥺🥺🥺 */
  //const [loadedData, setLoadedData] = useState([]);
  //const [loading, setLoading] = useState(false);
  //const dataReceived = loadedData;

  // useEffect(() => {
  //   loadExistingData({
  //     setLoadedData,
  //     setLoading,
  //     apartmentIdx: Number(receivedObject.apartmentIdx),
  //     selectedContractStart: receivedObject.selectedContractStart,
  //     selectedContractEnd: receivedObject.selectedContractEnd,
  //     significantFlagSign: receivedObject.significantFlagSign,
  //     unregisteredCarSign: receivedObject.unregisteredCarSign,
  //     enteredCarNumber: receivedObject.enteredCarNumber, // 🍑🍑🍑
  //     selectedCheckPeriod: receivedObject.selectedCheckPeriod,
  //   });
  //   console.log('... %%%%', externalImageArray.length);
  // }, [dataFoo]);
  /* 👆 🥺🥺🥺🥺🥺 */

  // 👇 [TAG] 데이터 받기(useLocation)
  const startDate = `${receivedObject.selectedContractStart.slice(
    0,
    4
  )}-${receivedObject.selectedContractStart.slice(
    4,
    6
  )}-${receivedObject.selectedContractStart.slice(6, 8)}`;
  const endDate = `${receivedObject.selectedContractEnd.slice(
    0,
    4
  )}-${receivedObject.selectedContractEnd.slice(
    4,
    6
  )}-${receivedObject.selectedContractEnd.slice(6, 8)}`;

  // const dataArray: {
  //   idx: string; //
  //   addr1: string; // 동/Addr1
  //   addr2: string; // 호수/Addr2
  //   carNumber: string; // 차량 번호/CarNo

  //   repeatCount: string; // 외부 주차 횟수/RepeatCnt
  //   flag: string; // 위반 여부(M: 위반O, 이외: 위반X)/Flag
  //   flagCount: number; // 위반 횟수/FlagCnt

  //   carFlag: string; // 차량 등록 유형/CarFlag
  //   carRegDate: string; // 차량 등록일/CarRegDate
  //   checkDateTime1: string; // 주차 확인일(날짜)
  //   checkDateTime2: string; // 주차 확인일(시간)

  //   ownerName: string; // 소유주 이름/OwnerName
  //   ownerPhoneNum: string; // 소유주 전화번호/OwnerPhone

  //   employeeName: string; // 근무자(등록인)/EmployeName
  //   employeeNum: string; // 근무자(등록인) 사번/RegEmployeeNo
  // }[] = [];

  function loadReportListData() {
    // [MEMO] onCarType: 차량 유형(번호판 구분)
    function onCarType(data: string) {
      if (data === 'N') {
        return '일반';
      } else if (data === 'E') {
        return '전기차';
      } else if (data === 'C') {
        return '영업';
      } else if (data === 'G') {
        return '녹색';
      } else if (data === 'T') {
        return '건설기계';
      } else if (data === 'F') {
        return '미분류';
      }
      return data;
    }

    // [MEMO] onCarFlag: 등록 유형
    function onCarFlag(data: string) {
      if (data === 'R') {
        return '등록';
      } else if (data === 'T') {
        return '임시';
      } else if (data === 'A') {
        return '방문';
      } else if (data === 'N') {
        return '미등록';
      }
      return data;
    }

    // [MEMO] onFlag: 위반 여부
    function onFlag(data: string) {
      if (data === 'N') {
        return '';
      } else if (data === 'M') {
        return '위반';
      }
      return data;
    }
  }

  function onCarFlag(data: string) {
    if (data === 'R') {
      return '등록';
    } else if (data === 'T') {
      return '임시';
    } else if (data === 'A') {
      return '방문';
    } else if (data === 'N') {
      return '미등록';
    }
    return data;
  }

  function onFlag(data: string) {
    if (data === 'N') {
      return '';
    } else if (data === 'M') {
      return '위반';
    }
    return data;
  }

  // 👇 오늘 날짜
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const today = `${year}-${month}-${day}`;

  const [loadedDataFoo, setLoadedDataFoo] = useState([]);
  const [loadingFoo, setLoadingFoo] = useState(false); // [MEMO] 데이터 로딩

  console.log('🎪🎪loadedDataFoo: ', loadedDataFoo);

  /* 👇 [TAG] 배열 자르기 -----start */
  // const itemsPerFirstPage = 30; // 첫 페이지에 출력할 항목 수
  // isListData === true -> 결재자가 아무도 없는 상태(결재박스 없는 상태)
  // const itemsPerFirstPage = isListData === true ? 8 : 6; // 첫 페이지에 출력할 항목 수
  const itemsPerFirstPage = isListData === true ? 8 : 8; // 첫 페이지에 출력할 항목 수
  // const itemsPerPage = 8; // (첫 페이지 이후) 한 페이지에 출력할 항목 수
  const itemsPerPage = 8; // (첫 페이지 이후) 한 페이지에 출력할 항목 수

  // 데이터를 페이지당 항목 수로 분할
  const pages = [];
  let pageIndex = 0;

  // for (let i = 0; i < dataArray.length; i++) {
  for (let i = 0; i < loadedDataFoo.length; i++) {
    if (!pages[pageIndex]) {
      pages[pageIndex] = [];
    }

    // pages[pageIndex].push(dataArray[i]);
    pages[pageIndex].push(loadedDataFoo[i]);

    if (
      pages[pageIndex].length ===
      (pageIndex === 0 ? itemsPerFirstPage : itemsPerPage)
    ) {
      pageIndex++;
    }
  }
  /* 👆 [TAG] 배열 자르기 -----end */

  /* 👇 [TAG] 배열 자르기 -----start / 지도위치🚀 */
  // const itemsPerFirstPage = 30; // 첫 페이지에 출력할 항목 수
  // isListData === true -> 결재자가 아무도 없는 상태(결재박스 없는 상태)
  // const itemsPerFirstPage = isListData === true ? 8 : 6; // 첫 페이지에 출력할 항목 수
  const itemsPerFirstPageForMap = isListData === true ? 4 : 4; // 첫 페이지에 출력할 항목 수
  // const itemsPerPage = 8; // (첫 페이지 이후) 한 페이지에 출력할 항목 수
  const itemsPerPageForMap = 4; // (첫 페이지 이후) 한 페이지에 출력할 항목 수

  // 데이터를 페이지당 항목 수로 분할
  const pagesForMap = [];
  let pageIndexForMap = 0;

  // for (let i = 0; i < dataArray.length; i++) {
  for (let i = 0; i < loadedDataFoo.length; i++) {
    if (!pagesForMap[pageIndexForMap]) {
      pagesForMap[pageIndexForMap] = [];
    }

    // pages[pageIndex].push(dataArray[i]);
    pagesForMap[pageIndexForMap].push(loadedDataFoo[i]);

    if (
      pagesForMap[pageIndexForMap].length ===
      (pageIndexForMap === 0 ? itemsPerFirstPageForMap : itemsPerPageForMap)
    ) {
      pageIndexForMap++;
    }
  }
  /* 👆 [TAG] 배열 자르기 -----end / 지도위치🚀 */

  function locationMapViewer(GPSLatitude, GPSLongitude) {
    const GOOGLE_MAP_API = process.env.GOOGLE_MAP_API;
    // const GPSLatitude = cellData.GPSLatitude;
    // const GPSLongitude = cellData.GPSLongitude;

    if (Number(GPSLatitude) <= 0 && Number(GPSLongitude) <= 0) {
      return <div>위치 정보 없음</div>;
    } else {
      const mapURL = `https://maps.googleapis.com/maps/api/staticmap?center=${GPSLatitude},${GPSLongitude}&zoom=19&size=550x450&scale=1&markers=color:red%7Clabel:%7C${GPSLatitude},${GPSLongitude}&key=${GOOGLE_MAP_API}`;
      return (
        <img
          style={{
            // width: '300px',
            height: '200px',
          }}
          src={mapURL}
        />
      );
    }
  }

  const [visibleImageList, setVisibleImageList] = useState(false);
  const [visibleLocationMap, setVisibleLocationMap] = useState(false);

  const controlViewer = () => {
    //
  };

  const onValidImageList = () => {
    setVisibleImageList(true);
    setVisibleLocationMap(false);
  };

  const onValidLocationMap = () => {
    setVisibleLocationMap(true);
    setVisibleImageList(false);
  };

  // 🚀🚀🚀🚀🚀
  // const pagesForMap = [];
  // let pageIndexForMap = 0;
  function onLocationMapPage() {
    console.log('🧔지도-위치정보');

    return (
      <>
        {pagesForMap.map((page, pageIndex) => {
          console.log('지도위치🚀/page:', page);
          console.log('지도위치🚀/pagesForMap:', pagesForMap);
          const lastPageIndex = pagesForMap.length - 1;

          return (
            <>
              <Tables>
                <tbody>
                  <tr key={[pageIndex]}>
                    <td>
                      {page.map((row, index) => {
                        console.log('🩳1🩳1🩳: ', page[index * 2]);

                        return (
                          <>
                            <RecordListResultWrap key={index}>
                              <RecordListResultBox className="left-image">
                                {/* 왼쪽 - 차량 이미지 */}
                                {page[index] && (
                                  <RecordListResultContents>
                                    <img
                                      style={{ width: '300px' }}
                                      src={page[index].ImgBase64}
                                    />
                                    <div>
                                      <div>
                                        차량번호:{' '}
                                        {page[index].CarNo && page[index].CarNo}
                                      </div>
                                      <div>
                                        등록:{' '}
                                        {page[index].CarFlag &&
                                          onCarFlag(page[index].CarFlag)}{' '}
                                        {page[index].Flag &&
                                          onFlag(page[index].Flag)}
                                      </div>
                                      <div>
                                        일시:{' '}
                                        {page[index].CheckingDateTime &&
                                          `${page[index].CheckingDateTime.slice(
                                            0,
                                            10
                                          )} ${page[
                                            index
                                          ].CheckingDateTime.slice(11, 19)}`}
                                      </div>
                                      <div>
                                        등록자:{' '}
                                        {page[index].EmployeName &&
                                          onCarFlag(page[index].EmployeName)}
                                      </div>
                                    </div>
                                  </RecordListResultContents>
                                )}
                              </RecordListResultBox>
                              <RecordListResultBox className="right-image">
                                {/* 오른쪽 - 지도 */}
                                <div>
                                  {page && (
                                    <RecordListResultContents>
                                      {locationMapViewer(
                                        page[index].GPSLatitude,
                                        page[index].GPSLongitude
                                      )}
                                    </RecordListResultContents>
                                  )}
                                </div>
                              </RecordListResultBox>
                            </RecordListResultWrap>
                          </>
                        );
                      })}
                    </td>
                  </tr>
                </tbody>
              </Tables>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {pageIndex + 1} / {pagesForMap.length}
              </div>

              <p
                style={
                  pageIndex === lastPageIndex
                    ? { display: 'none', marginTop: '3mm' }
                    : { pageBreakBefore: 'always', marginTop: '3mm' }
                }
              />
            </>
          );
        })}
      </>
    );
  }

  const onImageListPage = () => {
    return (
      <>
        {pages.map((page, pageIndex) => {
          console.log('이미지만😎/page:', page);
          console.log('이미지만😎/pages:', pages);
          const lastPageIndex = pages.length - 1;

          return (
            <>
              <Tables>
                <tbody>
                  <tr key={[pageIndex]}>
                    <td>
                      {page.map((row, index) => {
                        console.log('🩳2🩳2🩳: ', page[index * 2]);

                        return (
                          <>
                            <RecordListResultWrap key={index}>
                              <RecordListResultBox className="left-image">
                                {/* {index * 2 < page.length && ( // 왼쪽 이미지 */}
                                {page[index * 2] && ( // 왼쪽 이미지
                                  <RecordListResultContents>
                                    <img
                                      style={{ width: '300px' }}
                                      src={page[index * 2].ImgBase64}
                                    />
                                    <div>
                                      <div>
                                        차량번호:{' '}
                                        {page[index * 2].CarNo &&
                                          page[index * 2].CarNo}
                                      </div>
                                      <div>
                                        등록:{' '}
                                        {page[index * 2].CarFlag &&
                                          onCarFlag(
                                            page[index * 2].CarFlag
                                          )}{' '}
                                        {page[index * 2].Flag &&
                                          onFlag(page[index * 2].Flag)}
                                      </div>
                                      <div>
                                        일시:{' '}
                                        {page[index * 2].CheckingDateTime &&
                                          `${page[
                                            index * 2
                                          ].CheckingDateTime.slice(
                                            0,
                                            10
                                          )} ${page[
                                            index * 2
                                          ].CheckingDateTime.slice(11, 19)}`}
                                      </div>
                                      <div>
                                        등록자:{' '}
                                        {page[index * 2].EmployeName &&
                                          onCarFlag(
                                            page[index * 2].EmployeName
                                          )}
                                      </div>
                                    </div>
                                  </RecordListResultContents>
                                )}
                              </RecordListResultBox>
                              <RecordListResultBox className="right-image">
                                {/* {index * 2 + 1 < page.length && ( // 오른쪽 이미지 */}
                                {page[index * 2 + 1] && ( // 오른쪽 이미지
                                  <RecordListResultContents>
                                    <img
                                      style={{ width: '300px' }}
                                      src={page[index * 2 + 1].ImgBase64}
                                    />
                                    <div>
                                      차량번호:{' '}
                                      {page[index * 2 + 1].CarNo &&
                                        page[index * 2 + 1].CarNo}
                                    </div>
                                    <div>
                                      등록:{' '}
                                      {page[index * 2 + 1].CarFlag &&
                                        onCarFlag(
                                          page[index * 2 + 1].CarFlag
                                        )}{' '}
                                      {page[index * 2 + 1].Flag &&
                                        onFlag(page[index * 2 + 1].Flag)}
                                    </div>
                                    <div>
                                      일시:{' '}
                                      {page[index * 2 + 1].CheckingDateTime &&
                                        `${page[
                                          index * 2 + 1
                                        ].CheckingDateTime.slice(0, 10)} ${page[
                                          index * 2 + 1
                                        ].CheckingDateTime.slice(11, 19)} `}
                                    </div>
                                    <div>
                                      등록자:{' '}
                                      {page[index * 2 + 1].EmployeName &&
                                        onCarFlag(
                                          page[index * 2 + 1].EmployeName
                                        )}
                                    </div>
                                  </RecordListResultContents>
                                )}
                              </RecordListResultBox>
                            </RecordListResultWrap>
                          </>
                        );
                      })}
                    </td>
                  </tr>
                </tbody>
              </Tables>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {pageIndex + 1} / {pages.length}
              </div>

              <p
                style={
                  pageIndex === lastPageIndex
                    ? { display: 'none', marginTop: '3mm' }
                    : { pageBreakBefore: 'always', marginTop: '3mm' }
                }
              />
            </>
          );
        })}
      </>
    );
  };

  if (loading) {
    return (
      <>
        <Background>
          <LoadingWrap>
            <LoadingGIF src="/loading.gif" />
          </LoadingWrap>
        </Background>
      </>
    );
  }

  return (
    <>
      <ViewSection>
        <Button className="print" onClick={() => window.print()}>
          인쇄하기
        </Button>
        {!visibleLocationMap ? (
          <Button
            className="location-map-open"
            onClick={() => onValidLocationMap()}
          >
            위치정보 열람
          </Button>
        ) : (
          <Button
            className="location-map-close"
            onClick={() => onValidImageList()}
          >
            위치정보 닫기
          </Button>
        )}

        <Button className="close" onClick={() => window.close()}>
          닫기
        </Button>
        {/* <Button
          className="close"
          onClick={() =>
            loadExistingData({
              setLoadedData: setLoadedDataFoo,
              setLoading: setLoadingFoo,
              apartmentIdx: Number(receivedObject.apartmentIdx),
              selectedContractStart: receivedObject.selectedContractStart,
              selectedContractEnd: receivedObject.selectedContractEnd,
              significantFlagSign: receivedObject.significantFlagSign,
              unregisteredCarSign: receivedObject.unregisteredCarSign,
              enteredCarNumber: receivedObject.enteredCarNumber,
              selectedCheckPeriod: receivedObject.selectedCheckPeriod,
              //
              checkImage: 2,
            })
          }
        >
          불러오기
        </Button> */}
      </ViewSection>
      {/*  */}
      <PrintSection>
        <TitleSection>
          <TitleBox>주차 위반 차량</TitleBox>
          {/* <ApprovalListSection>
            <ApprovalList setIsListData={setIsListData} />
          </ApprovalListSection> */}

          <InfoBox>
            <div>
              단지명:
              {permitCode === apartmentManagerCode &&
                (apartmentNameAtSessionStorage
                  ? apartmentNameAtSessionStorage
                  : '')}
              {permitCode === headOfficerManagerCode &&
                (receivedObject.apartmentName
                  ? receivedObject.apartmentName
                  : '')}
            </div>
            <div>주식회사 앰앰아이</div>
          </InfoBox>
        </TitleSection>

        <ReportHeaderLineWrap>
          <ReportHeaderLineBox>
            <ReportHeaderTitle>조회 기간</ReportHeaderTitle>
            <ReportHeaderContents>
              {startDate} ~ {endDate}
            </ReportHeaderContents>
          </ReportHeaderLineBox>

          <ReportHeaderLineBox>
            <ReportHeaderTitle>작성일</ReportHeaderTitle>
            <ReportHeaderContents className="right-section">
              {today}
            </ReportHeaderContents>
          </ReportHeaderLineBox>
        </ReportHeaderLineWrap>

        {visibleLocationMap && onLocationMapPage()}
        {visibleImageList && onImageListPage()}

        {/* {pages.map((page, pageIndex) => {
          console.log('인쇄 페이지:', page);
          const lastPageIndex = pages.length - 1;

          return (
            <>
              <Tables>
                <tbody>
                  <tr key={[pageIndex]}>
                    <td>
                      {page.map((row, index) => {
                        console.log('🩳0🩳0🩳: ', page[index * 2]);

                        return (
                          <>
                            <RecordListResultWrap key={index}>
                              <RecordListResultBox className="left-image">
                                {page[index * 2] && ( // 왼쪽 이미지
                                  <RecordListResultContents>
                                    <img
                                      style={{ width: '300px' }}
                                      src={page[index * 2].ImgBase64}
                                    />
                                    <div>
                                      <div>
                                        차량번호:{' '}
                                        {page[index * 2].CarNo &&
                                          page[index * 2].CarNo}
                                      </div>
                                      <div>
                                        등록:{' '}
                                        {page[index * 2].CarFlag &&
                                          onCarFlag(
                                            page[index * 2].CarFlag
                                          )}{' '}
                                        {page[index * 2].Flag &&
                                          onFlag(page[index * 2].Flag)}
                                      </div>
                                      <div>
                                        일시:{' '}
                                        {page[index * 2].CheckingDateTime &&
                                          `${page[
                                            index * 2
                                          ].CheckingDateTime.slice(
                                            0,
                                            10
                                          )} ${page[
                                            index * 2
                                          ].CheckingDateTime.slice(11, 19)}`}
                                      </div>
                                      <div>
                                        등록자:{' '}
                                        {page[index * 2].EmployeName &&
                                          onCarFlag(
                                            page[index * 2].EmployeName
                                          )}
                                      </div>
                                    </div>
                                  </RecordListResultContents>
                                )}
                              </RecordListResultBox>
                              <RecordListResultBox className="right-image">
                                {page[index * 2 + 1] && ( // 오른쪽 이미지
                                  <RecordListResultContents>
                                    <img
                                      style={{ width: '300px' }}
                                      src={page[index * 2 + 1].ImgBase64}
                                    />
                                    <div>
                                      차량번호:{' '}
                                      {page[index * 2 + 1].CarNo &&
                                        page[index * 2 + 1].CarNo}
                                    </div>
                                    <div>
                                      등록:{' '}
                                      {page[index * 2 + 1].CarFlag &&
                                        onCarFlag(
                                          page[index * 2 + 1].CarFlag
                                        )}{' '}
                                      {page[index * 2 + 1].Flag &&
                                        onFlag(page[index * 2 + 1].Flag)}
                                    </div>
                                    <div>
                                      일시:{' '}
                                      {page[index * 2 + 1].CheckingDateTime &&
                                        `${page[
                                          index * 2 + 1
                                        ].CheckingDateTime.slice(0, 10)} ${page[
                                          index * 2 + 1
                                        ].CheckingDateTime.slice(11, 19)} `}
                                    </div>
                                    <div>
                                      등록자:{' '}
                                      {page[index * 2 + 1].EmployeName &&
                                        onCarFlag(
                                          page[index * 2 + 1].EmployeName
                                        )}
                                    </div>
                                  </RecordListResultContents>
                                )}
                              </RecordListResultBox>
                            </RecordListResultWrap>
                          </>
                        );
                      })}
                    </td>
                  </tr>
                </tbody>
              </Tables>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {pageIndex + 1} / {pages.length}
              </div>

              <p
                style={
                  pageIndex === lastPageIndex
                    ? { display: 'none', marginTop: '3mm' }
                    : { pageBreakBefore: 'always', marginTop: '3mm' }
                }
              />
            </>
          );
        })} */}
      </PrintSection>
    </>
  );
};

export default ParkingViolationPrinting;

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.7); */
  z-index: 999;
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  /* opacity: 50%; */
`;

const LoadingGIF = styled.img`
  ${({ theme }) => theme.loadingGIF};

  /* opacity: 50%; */
`;

const ViewSection = styled.div`
  position: fixed;
  top: 10px;
  left: 10px;

  /* 인쇄 시에만 출력할 스타일 지정 */
  @media print {
    display: none;
  }
`;

const PrintSection = styled.div`
  /* 웹 페이지 상에서 보이는 스타일 */
  /* display: none; -> 웹 페이지에서는 보이지 않도록 숨김 처리 */
  width: 210mm;
  height: 297mm;
  margin: 0;
  /* padding: 5mm; */
  background-color: white;

  /* 인쇄 시에만 유효한 스타일(출력할 스타일 지정) */
  @media print {
    display: block;
    size: A4;
    width: 210mm;
    height: 297mm;
    /* margin: 0; */
    /* margin: 10mm; */
  }
`;

const Button = styled.button`
  ${({ theme }) => theme.printBtnStyle};

  &.print {
    margin-right: 5px;
    width: 60px;
    height: 30px;
  }

  &.close {
    width: 60px;
    height: 30px;
  }

  &.location-map-open {
    margin-right: 5px;
  }

  &.location-map-close {
    margin-right: 5px;
  }
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5mm;
  margin-bottom: 5.8mm;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
`;

const ApprovalListSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  width: 100%;
`;

const InfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`;

const ReportHeaderLineWrap = styled.div`
  display: flex;
  font-size: 14px;
`;

const ReportHeaderLineBox = styled.div`
  display: flex;
  width: 50%;
  border-top: solid 1px black;

  /* 🥩 border-bottom 임시 */
  border-bottom: solid 1px black;
`;

const ReportHeaderTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  width: 30%;
  border-right: solid 1px black;
  border-left: solid 1px black;
  font-weight: 600;
`;

const ReportHeaderContents = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  padding: 5px 0;
  width: 70%;

  &.right-section {
    border-right: solid 1px black;
  }
`;

const RecordListTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  border: solid 1px black;
  background-color: lightGray;
  font-size: 15px;
  font-weight: 600;
`;

const RecordListHeaderSection = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 600;
`;

const RecordListHeaderBox = styled.div`
  padding: 5px 0;
  border-bottom: solid 1px black;
  /* 🍰🍰 헤더 박스 너비를 50%로 설정하여 두 개의 박스가 동일한 너비를 갖도록 함 */
  /* width: 50%;  */

  &.left-image {
    /* width: 10%; */
    width: 50%;
  }

  &.right-image {
    /* width: 10%; */
    width: 50%;
  }

  &.car-number {
    width: 10%;
    border-right: solid 1px black;
    border-left: solid 1px black; /* 필수 - 바깥 테두리 */
  }

  &.addr2 {
    width: 10%;
    border-right: solid 1px black;
  }

  &.addr1 {
    width: 10%;
    border-right: solid 1px black;
  }

  &.employee-name {
    width: 10%;
    border-right: solid 1px black; /* 필수 - 바깥 테두리 */
  }
`;

// const RecordListCategoryTitle = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
// `;

const Tables = styled.table`
  width: 100%;
  /* 🍰🍰 테이블 셀 경계를 겹치게 설정 */
  /* border-collapse: collapse;  */
`;

const RecordListResultWrap = styled.div`
  display: flex;
`;

const RecordListResultBox = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  &.left-image {
    width: 50%;
  }

  &.right-image {
    width: 50%;
  }
`;

const RecordListResultContents = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */

  font-size: 13px;
  /* padding: 15px 0; */
  padding-top: 15px;

  line-height: 1.2em;
`;
