import React from 'react';
import { Button } from '../Button/Short';
import { useNavigate } from 'react-router-dom';

export const LogoutBtn = () => {
  const navigate = useNavigate();
  const onLogout = () => {
    sessionStorage.clear();
    navigate('/login');
  };

  return <Button label="로그아웃" size="small" onClick={onLogout} />;
};
