// CV10-020 차량 등록 관리 - 서버로 '신규' 데이터 보내기
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface uploadSyncDataProps {
  apartmentIdx: number;

  resultData: any[];
  onlyExistentServerData: any[];
  isCheckedSync: boolean;

  setLoading: Dispatch<SetStateAction<boolean>>;
  setOpenBeforeResistGuideModal: Dispatch<SetStateAction<boolean>>;
  setDetectChangingData: Dispatch<SetStateAction<boolean>>;
}

export const uploadSyncData = async ({
  apartmentIdx,
  resultData,
  onlyExistentServerData,
  isCheckedSync,
  setLoading,
  setOpenBeforeResistGuideModal,
  setDetectChangingData,
}: uploadSyncDataProps) => {
  setLoading(true);

  const token = sessionStorage.getItem('token');
  const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx');

  if (!apartmentIdx && Number(apartmentRefIdx) === 0) {
    return alert('단지를 선택해주세요');
  }

  let currentApartmentRefIdx = '';

  if (Number(apartmentRefIdx) !== 0) {
    currentApartmentRefIdx = `${apartmentRefIdx}`;
  } else {
    currentApartmentRefIdx = `${apartmentIdx}`;
  }

  // 1번 API
  /**
   * 1번 API:
   * - 불러온 데이터 저장을 위한 API
   */
  await axios({
    url: `${process.env.COMMON_URL}/vehicle/cv10020/regcarmanage/${currentApartmentRefIdx}`,
    method: 'post',
    headers: { tokenId: token },
    data: {
      Data: resultData.map(data => {
        return {
          RegEmployeeNo: data.RegEmployeeNo
            ? data.RegEmployeeNo.toString()
            : '',
          Addr1: data.Addr1 ? data.Addr1.toString() : '',
          Addr2: data.Addr2 ? data.Addr2.toString() : '',
          OwnerName: data.OwnerName ? data.OwnerName : '',
          OwnerPhone: data.OwnerPhone ? data.OwnerPhone.toString() : '',
          CarNo: data.CarNo,
          CarFlag: data.CarFlag ? data.CarFlag : '',
          CarExpireDate: data.CarExpireDate ? data.CarExpireDate : '',
        };
      }),
    },
  })
    .then(async res => {
      try {
        if (res.data.ErrorCode === 0) {
          console.log('@@ 기본 res : ', res);

          // 2번 API. 1번 API 요청 성공 시 -> 2번 API 요청 실행.
          /**
           * 2번 API:
           * - 1번 API 요청 성공 시 -> 2번 API 요청 실행
           * - 불러온 데이터에는 있으나 기존 데이터에는 없는 데이터 삭제를 위한 API
           */
          return axios({
            url: `${process.env.COMMON_URL}/vehicle/cv10020/regcarmanage/${currentApartmentRefIdx}`,
            method: 'delete',
            headers: { tokenId: token },
            data: {
              Data: onlyExistentServerData.map(data => {
                return {
                  idx: data.idx,
                };
              }),
            },
          });
        }
      } catch (e) {
        console.error(e);
      }
    })
    .then(response => {
      // 두 번째 then(=2번 API 이후 실행되는 then)에서 반환된 response를 받아 처리
      //console.log('@@ 기본 response : ', response);

      if (response?.data?.ErrorCode === 0) {
        //console.log('@@ 성공 response : ', response);

        setLoading(false);
        alert('데이터가 성공적으로 동기화되었습니다');
        setOpenBeforeResistGuideModal(false);
        setDetectChangingData(true);
      } else {
        console.log('@@ 실패 response: ', response);
        const errorMessage =
          response?.data?.ErrorMsg ||
          '데이터를 동기화 하는데 실패했습니다. 등록한 파일 양식이 적절한지 확인해주세요.';
        setLoading(false);
        alert(errorMessage);
        setOpenBeforeResistGuideModal(false);
      }
      incorrectToken(response);
    })
    .catch(e => {
      console.error(e);
    });
};
