import React from 'react';
import { SalaryLedger } from '../../components/CS30-020-salary-ledger/SalaryLedger/SalaryLedger';

import RouterTrackerForAnalytics from '../../lib/utils/RouterTrackerForAnalytics'; // [MEMO] 구글 애널리틱스 라우터 트래커

const SalaryLedgerPage = () => {
  const $title = document.head.querySelector('title');
  $title!.innerText = '급여 대장';
  RouterTrackerForAnalytics();

  return (
    <>
      <SalaryLedger />
    </>
  );
};

export default SalaryLedgerPage;
