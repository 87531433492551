import React, { Dispatch, SetStateAction, useEffect } from 'react';
import axios from 'axios';

interface onChangeURLProps {
  windowLocation: any;
  setCompanyId: Dispatch<SetStateAction<string>>;
}

export const onChangeURL = ({
  windowLocation,
  setCompanyId,
}: onChangeURLProps) => {
  //console.log('🍕windowLocation: ', windowLocation);
  const WELL_TECH_DEMO_URL = process.env.SITE_URL_WELL_TECH_DEMO;

  useEffect(() => {
    // setCompanyId('기업코드');
    if (windowLocation.origin === WELL_TECH_DEMO_URL) {
      setCompanyId('99');
    } else setCompanyId('MM');
  }, []);

  // if (windowLocation === WELL_TECH_DEMO_URL) {
  //   setCompanyId('99');
  // } else setCompanyId('MM');
};
