import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { loadData } from '../utils/loadData';

import axios from 'axios';

import { Grid } from '../Grid/Grid';

import { Button } from '../../UI/Button/Long';

// import { onApartmentSerialNumberList } from '../utils/onApartmentSerialNumberList.tsx'; // ref가 있는 인덱스 API (=단지 고유 번호(계약 아님))
// import { Modal } from '../../apartmentSerialNumberSelectModal/Modal.tsx'; // ref가 있는 인덱스 모달 (=단지 고유 번호(계약 아님))
import { onApartmentContractInfo } from '../utils/onApartmentContractInfo'; // ref가 없는 인덱스 API (=단지 계약 번호(고유 번호 아님))
import { Modal } from '../../apartmentContractInfoSelectModal/Modal'; // ref가 없는 인덱스 모달 (=단지 계약 번호(고유 번호 아님))

import { ko } from 'date-fns/esm/locale';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import './DatePicker.css';

/**
 * permitCode 권한 코드
 * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
 * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
 * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
 */

export const Contents = () => {
  const permitCode = sessionStorage.getItem('permitCode');
  const contractIdx = sessionStorage.getItem('contractIdx'); // 관리소장 로그인시 아파트 계약 인덱스
  //  const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx'); // ref 있음 = 일반 인덱스, 아파트 고유 인덱스(계약 아님)
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련

  const [dataList, setDataList] = useState([]);

  //
  const [onModal, setOnModal] = useState(false); // MEMO: 아파트 계약 정보 리스트가 있는 모달
  const [apartmentListData, setApartmentListData] = useState<any[]>([]); // MEMO: 아파트 계약 정보 API
  const [apartmentIdx, setApartmentIdx] = useState(''); // 아파트 리스트 인덱스
  const [apartmentName, setApartmentName] = useState('');

  const closeModal = () => {
    setOnModal(false);
  };

  // [TAG] 👇 귀속일 -----start
  const [startDate, setStartDate] = useState(new Date()); // MEMO: Date Picker 라이브러리

  // 👇 데이터 피커에서 선택한 날짜
  const year = startDate.getFullYear().toString();
  const month = (startDate.getMonth() + 1).toString();
  // const date = startDate.getDate().toString();

  const calYear = String(year).padStart(2, '0');
  const calMonth = String(month).padStart(2, '0');
  // const calDate = String(date).padStart(2, '0');

  const selectedDate = `${calYear}${calMonth}`;
  // [TAG] 👆 귀속일 -----end

  console.log('🔥🔥🔥selectedDates; ', selectedDate);

  // 👇 [TAG] 권한에 따른 UI 변경 관련
  function onPermitCodeView() {
    if (permitCode === apartmentManagerCode) {
      // 관리소장 권한시: 계약 단지 선택
      useEffect(() => {
        onApartmentContractInfo({
          apartmentListData,
          setApartmentListData,
          setOnModal,
        });
      }, []);
    }

    useEffect(() => {
      previewAttachedFileImage(); // 회사 로고 불러오기

      // 근무 내역 불러오기
      if (apartmentIdx) {
        loadData({ setDataList, setLoading, apartmentIdx, selectedDate });
      }
    }, [selectedDate, apartmentIdx]);
    console.log('apartmentIdx: ', apartmentIdx);
  }
  onPermitCodeView();

  function onClickedButton(item) {
    setApartmentIdx(item.idx);
    setApartmentName(item.Name);
  }
  // [TAG] 👆 권한에 따른 UI 변경 관련

  const [MMILogoImg, setMMILogoImg] = useState('');

  const previewAttachedFileImage = async () => {
    const token = sessionStorage.getItem('token');

    const imagePromise = axios.get(
      `${process.env.COMMON_URL}/common/refcorpinfo/downloadLogo`,
      {
        responseType: 'blob',
        headers: { tokenId: token, 'content-type': 'application/json' },
      }
    );

    try {
      const response = await imagePromise;

      if (response.data instanceof Blob) {
        const previewImage = await new Promise<string>(resolve => {
          const reader = new FileReader();
          reader.onload = (ev: ProgressEvent<FileReader>) => {
            const result = ev.target?.result as string;
            resolve(result);
          };
          reader.readAsDataURL(response.data);
        });

        setMMILogoImg(previewImage); // previewImage를 img태그의 src속성에 넣기
      }
    } catch (error) {
      console.error('이미지 다운로드 중 에러가 발생했습니다:', error);
    }
  };

  const [loading, setLoading] = useState(false); // [MEMO] 일간 데이터 로딩

  if (loading) {
    return (
      <>
        <Background>
          <LoadingWrap>
            <LoadingGIF src="/loading.gif" />
          </LoadingWrap>
        </Background>
      </>
    );
  }

  return (
    <>
      {permitCode === headOfficerManagerCode && onModal && (
        <Modal
          closeModal={closeModal}
          apartmentListData={apartmentListData}
          setApartmentListData={setApartmentListData}
          apartmentIdx={apartmentIdx}
          setApartmentIdx={setApartmentIdx}
          apartmentName={apartmentName}
          setApartmentName={setApartmentName}
        />
      )}
      <Title>급여 대장</Title>
      <Wrap>
        <HeaderSection>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div>
              <DatePickerBox>
                <p>귀속일</p>
                <DatePicker
                  locale={ko}
                  dateFormat="yyyy-MM"
                  selected={startDate}
                  onChange={(date: React.SetStateAction<any>) =>
                    setStartDate(date)
                  }
                  showMonthYearPicker
                />
              </DatePickerBox>
            </div>

            {permitCode === headOfficerManagerCode && (
              <div
                style={{
                  display: 'flex',
                  marginRight: '5px',
                  marginLeft: '5px',
                }}
              >
                <div style={{ marginRight: '5px' }}>
                  <Button
                    label="계약 단지 선택"
                    onClick={() =>
                      onApartmentContractInfo({
                        apartmentListData,
                        setApartmentListData,
                        setOnModal,
                      })
                    }
                  />
                </div>

                <Button
                  label="데이터 불러오기"
                  onClick={() =>
                    loadData({ setDataList, apartmentIdx, selectedDate })
                  }
                />

                <ApartmentNameBox>
                  <ApartmentIdx style={{ marginRight: '5px' }}>
                    {permitCode === headOfficerManagerCode && apartmentIdx}
                  </ApartmentIdx>
                  <div>{apartmentName}</div>
                </ApartmentNameBox>
              </div>
            )}

            {permitCode === apartmentManagerCode &&
              apartmentListData.map((item, key) => {
                return (
                  <div
                    key={key}
                    onClick={() => {
                      onClickedButton(item);
                    }}
                  >
                    <ButtonStyle>{item.JobGroupEtc}</ButtonStyle>
                  </div>
                );
              })}
          </div>
        </HeaderSection>

        <GridSection>
          <Grid
            data={dataList}
            selectedDate={selectedDate}
            MMILogoImg={MMILogoImg}
          />
        </GridSection>
      </Wrap>
    </>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  /* opacity: 50%; */
`;

const LoadingGIF = styled.img`
  ${({ theme }) => theme.loadingGIF};

  /* opacity: 50%; */
`;

const Bar = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const Title = styled.div`
  ${({ theme }) => theme.contentsMainTitle};
  ${({ theme }) => theme.stopDrag};
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: 40px;
  font-size: 13px;
  /* height: 40px;
  font-size: 13px; */
`;

const DatePickerBox = styled.div`
  display: flex;
  align-items: center;

  p {
    width: 100%;
    margin-right: 5px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const GridSection = styled.div`
  height: ${({ theme }) => theme.gridStyle.height};
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.printBtnStyle}

  margin-left:5px;
  font-size: 13px;
  cursor: pointer;
`;

const ApartmentNameBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;

  p {
    color: ${({ theme }) => theme.fontColor.darkGray};
  }
`;

const ApartmentIdx = styled.div`
  color: ${({ theme }) => theme.mainColor.main};
`;
