/**
 * [MEMO] incorrectToken():
 * res.data.ErrorCode === 1 일 때, 로그아웃 처리 + 로그인 페이지로 이동시키는 함수
 * res.data.ErrorCode === 1 의미: 중복 로그인(토큰 두개 이상 생성시)
 * import 하려는 컴포넌트에 다음 코드 입력: incorrectToken(res);
 */

export function incorrectToken(res: any) {
  if (res.data.ErrorCode === 1) {
    alert('중복된 사용자 입니다');
    sessionStorage.clear();
    return (window.location.href = '/login');
  }
}

// 예시
/*
  ...
  .then(res => {
    try {
      if (res.data.ErrorCode === 0) {
        setApartmentListData(res.data.Data);
        setOnModal(true);
      }

      incorrectToken(res); // 👈 여기!
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
    ...
    */
