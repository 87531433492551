// ref가 없는 인덱스 API (=계약 번호(고유 번호 아님))

import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { ModalContents } from './ModalContents';

interface ModalProps {
  closeModal: () => void;
  apartmentListContractData: any[];
  setApartmentListContractData: Dispatch<SetStateAction<any[]>>;
  apartmentContractIdx: string;
  setApartmentContractIdx: Dispatch<SetStateAction<string>>;
  apartmentName: string;
  setApartmentName: Dispatch<SetStateAction<string>>;
}

export const Modal = ({
  closeModal,
  apartmentListContractData,
  setApartmentListContractData,
  apartmentContractIdx,
  setApartmentContractIdx,
  apartmentName,
  setApartmentName,
}: ModalProps) => {
  return (
    <Background onClick={closeModal}>
      <Container
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ModalContents
          closeModal={closeModal}
          apartmentListContractData={apartmentListContractData}
          setApartmentListContractData={setApartmentListContractData}
          apartmentContractIdx={apartmentContractIdx}
          setApartmentContractIdx={setApartmentContractIdx}
          apartmentName={apartmentName}
          setApartmentName={setApartmentName}
        />
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: white;
`;
