import React, { useState, useEffect } from 'react';
import * as S from './Contents.style';

import { ko } from 'date-fns/esm/locale';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import './DatePicker.css';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../Grid/ag-theme-custom.css';

import { columnDefs } from '../Grid/columnDefs';

import { CellDoubleClickedEvent } from 'ag-grid-community';

import { onApartmentContractInfo } from '../utils/onApartmentContractInfo';
import { onEmployeeContractInfo } from '../utils/onEmployeeContractInfo';
import { onDownloadContractDoneFile } from '../utils/onDownloadContractDoneFile';

import { Modal } from '../../apartmentContractInfoSelectModal/Modal';

import { Button } from '../../UI/Button/Long';
import { Input } from '../../UI/Input/Short';

import { onMinusOneMonth } from '../utils/onMinusOneMonth';
import { onMinusThreeMonth } from '../utils/onMinusThreeMonth';
import { onMinusHalfYear } from '../utils/onMinusHalfYear';
import { onMinusOneYear } from '../utils/onMinusOneYear';

import { onEncodedString } from '../utils/onEncodedString';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const Contents = () => {
  const [employeeData, setEmployeeData] = useState<any[]>([]);

  const [onModal, setOnModal] = useState(false); // MEMO: 아파트 계약 정보 리스트가 있는 모달
  const [apartmentListData, setApartmentListData] = useState<any[]>([]); // MEMO: 아파트 계약 정보 API
  const [apartmentIdx, setApartmentIdx] = useState(''); // 아파트 리스트 인덱스
  const [apartmentName, setApartmentName] = useState('');

  const [startDate, setStartDate] = useState(new Date()); // MEMO: Date Picker 라이브러리
  const [endDate, setEndDate] = useState(new Date()); // MEMO: Date Picker 라이브러리

  // 👇 유효한 기간인 근로계약 체크: 지난 계약까지 전부 보기를 원할 경우, false로 보내기
  const [validContract, setValidContract] = useState(true);

  // 👇 진행중인 근로계약 체크: 진행중인 근로 계약을 보기 원할 경우, true로 보내기
  const [processingContact, setProcessingContact] = useState(false);

  // 👇 데이터 피커에서 선택한 날짜 (시작일)
  const pickStartYear = startDate.getFullYear().toString();
  const pickStartMonth = (startDate.getMonth() + 1).toString();
  const pickStartDate = startDate.getDate().toString();

  const calStartYear = String(pickStartYear).padStart(2, '0');
  const calStartMonth = String(pickStartMonth).padStart(2, '0');
  const calStartDate = String(pickStartDate).padStart(2, '0');

  // 👇 데이터 피커에서 선택한 날짜 (종료일)
  const pickEndYear = endDate.getFullYear().toString();
  const pickEndMonth = (endDate.getMonth() + 1).toString();
  const pickEndDate = endDate.getDate().toString();

  const calEndYear = String(pickEndYear).padStart(2, '0');
  const calEndMonth = String(pickEndMonth).padStart(2, '0');
  const calEndDate = String(pickEndDate).padStart(2, '0');

  const [employeeName, setEmployeeName] = useState('');

  const enteredEmployeeName = employeeName
    ? `&employeename=${employeeName}`
    : '';
  const selectedContractStart = `${calStartYear}-${calStartMonth}-${calStartDate}`;
  const selectedContractEnd = `${calEndYear}-${calEndMonth}-${calEndDate}`;

  // [MEMO] 근로자 이름 검색(base64URL)
  const onEnteredName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { encodedString } = onEncodedString({ e });

    setEmployeeName(encodedString);
  };

  const closeModal = () => {
    setOnModal(false);
  };

  function switchValidContract() {
    setValidContract(false);

    if (validContract === false) {
      setValidContract(true);
    }
  }

  function switchProcessingContact() {
    setProcessingContact(true);

    if (processingContact === true) {
      setProcessingContact(false);
    }
  }

  let dataArray: {
    contractArray: {
      templateCode?: string;
      templateTitle?: string;
      contractIdx?: number;
      userContractStart?: string;
      userContractEnd?: string;
      userOpenDate?: string;
      userSignDate?: string; // [MEMO] 근로자가 서명한 날짜(서명완료 날짜)
      userSignRequestDate?: string;
    }[];
    employeeName?: string;
    employeeNum?: string;
    contractDone?: number;
    contractRequested?: number;
    totalContract?: number;
  }[] = [];

  function loadEmployeeContractData() {
    dataArray = [];

    employeeData.forEach(data => {
      const contractListArray: {
        templateCode?: string;
        templateTitle?: string;
        contractIdx?: number;
        userContractStart?: string;
        userContractEnd?: string;
        userOpenDate?: string;
        userSignDate?: string;
        userSignRequestDate?: string;
      }[] = [];

      data.Contracts.forEach(
        (contract: {
          TemplateCode: string;
          TemplateTitle: string;
          idx: number;
          UserContractStart: string;
          UserContractEnd: string;
          UserOpenDate: string;
          UserSignDate: string;
          UserSignRequestDate: string;
        }) => {
          contractListArray.push({
            templateCode: contract.TemplateCode,
            templateTitle: contract.TemplateTitle,
            contractIdx: contract.idx,
            userContractStart: contract.UserContractStart,
            userContractEnd: contract.UserContractEnd,
            userOpenDate: contract.UserOpenDate,
            userSignDate: contract.UserSignDate,
            userSignRequestDate: contract.UserSignRequestDate,
          });
        }
      );

      dataArray.push({
        employeeName: data.NameKR,
        employeeNum: data.EmployeeNo,
        contractDone: data.ContractDone,
        contractRequested: data.ContractRequested,
        totalContract: data.ContractTotal,
        contractArray: contractListArray,
      });
    });
  }
  loadEmployeeContractData();

  // console.log('조직원 계약 - employeeData: ', employeeData);
  // console.log('조직원 계약 - dataArray: ', dataArray);

  const [essentialCategory, setEssentialCategory] = useState(false);

  function onEssentialCategory() {
    setEssentialCategory(!essentialCategory);
  }

  return (
    <>
      {onModal && (
        <Modal
          closeModal={closeModal}
          apartmentListData={apartmentListData}
          setApartmentListData={setApartmentListData}
          apartmentIdx={apartmentIdx}
          setApartmentIdx={setApartmentIdx}
          apartmentName={apartmentName}
          setApartmentName={setApartmentName}
        />
      )}

      <S.Title>조직원 계약</S.Title>

      {/* <S.CategoryWrap> */}
      <S.EssentialCategorySection
        className={essentialCategory ? 'on-option' : 'off-option'}
        onClick={() => onEssentialCategory()}
      >
        <S.EssentialCategoryWrap
          className={essentialCategory ? 'on-option' : 'off-option'}
        >
          {essentialCategory ? (
            <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
          ) : (
            <KeyboardArrowRightIcon style={{ fontSize: 20 }} />
          )}
          검색 옵션 선택
        </S.EssentialCategoryWrap>
      </S.EssentialCategorySection>

      {essentialCategory && (
        <S.CategorySection>
          <S.CategoryWrap style={{ display: 'flex', alignItems: 'center' }}>
            {/* <S.AdditionalCategorySection> */}
            {/* 👇👇👇👇👇👇👇👇👇👇👇👇👇👇👇👇👇 -----이름검색 */}
            <ul style={{ float: 'left', marginRight: '5px' }}>
              <li>
                <Input
                  placeholder="이름"
                  type="text"
                  size="small"
                  onChange={onEnteredName}
                />
              </li>
            </ul>
            {/* 👆👆👆👆👆👆👆👆👆👆👆👆👆👆👆👆👆 -----이름검색 */}
            {/* 👇👇👇👇👇👇👇👇👇👇👇👇👇👇👇👇👇 -----체크박스 */}
            <ul
              style={{
                //float: 'left'
                display: 'flex',
              }}
            >
              <li>
                <input
                  type="checkbox"
                  id={'check-invalid-contract'}
                  checked={validContract}
                  onChange={() => switchValidContract()}
                />
                <label
                  htmlFor={'check-invalid-contract'}
                  style={{ fontSize: '13px', marginRight: '5px' }}
                >
                  유효 계약
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id={'check-processing-contract'}
                  checked={processingContact}
                  onChange={() => switchProcessingContact()}
                />
                <label
                  htmlFor={'check-processing-contract'}
                  style={{ fontSize: '13px' }}
                >
                  진행중 계약
                </label>
              </li>
            </ul>
            {/* 👆👆👆👆👆👆👆👆👆👆👆👆👆👆👆👆👆 -----체크박스 */}
            {/* </S.AdditionalCategorySection> */}
          </S.CategoryWrap>
        </S.CategorySection>
      )}
      {/* </S.CategoryWrap> */}

      <S.Bar />
      <S.HeaderSection>
        <S.PeriodSettingWrap>
          <S.DatePickerWrap>
            <S.DatePickerBox>
              <p>시작일</p>
              <DatePicker
                locale={ko}
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                onChange={(date: React.SetStateAction<any>) =>
                  setStartDate(date)
                }
                showMonthDropdown
                showYearDropdown
              />
            </S.DatePickerBox>

            <S.DatePickerBox>
              <p>종료일</p>
              <DatePicker
                locale={ko}
                dateFormat="yyyy-MM-dd"
                selected={endDate}
                onChange={(date: React.SetStateAction<any>) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </S.DatePickerBox>
          </S.DatePickerWrap>

          {/* 👇👇👇👇👇👇👇👇👇👇👇👇👇👇👇👇👇 -----기간설정 */}
          <S.PeriodBtnListSection>
            <S.PeriodBtn>
              {/* <Button
                label="1개월"
                onClick={() =>
                  onMinusOneMonth({
                    calStartYear,
                    calStartMonth,
                    calStartDate,
                    setStartDate,
                  })
                }
              /> */}
              <button
                onClick={() =>
                  onMinusOneMonth({
                    calStartYear,
                    calStartMonth,
                    calStartDate,
                    setStartDate,
                  })
                }
                style={{
                  fontSize: '13px',
                  backgroundColor: '#ededed',
                  color: '#717171',
                  padding: '5px',
                  borderRadius: '0.5em',
                  border: 'solid 1px #c1c1c1',
                  marginRight: '2px',
                }}
              >
                1개월
              </button>
            </S.PeriodBtn>
            <S.PeriodBtn>
              <button
                onClick={() =>
                  onMinusThreeMonth({
                    calStartYear,
                    calStartMonth,
                    calStartDate,
                    setStartDate,
                  })
                }
                style={{
                  fontSize: '13px',
                  backgroundColor: '#ededed',
                  color: '#717171',
                  padding: '5px',
                  borderRadius: '0.5em',
                  border: 'solid 1px #c1c1c1',
                  marginRight: '2px',
                }}
              >
                3개월
              </button>
            </S.PeriodBtn>
            <S.PeriodBtn>
              <button
                onClick={() =>
                  onMinusHalfYear({
                    calStartYear,
                    calStartMonth,
                    calStartDate,
                    setStartDate,
                  })
                }
                style={{
                  fontSize: '13px',
                  backgroundColor: '#ededed',
                  color: '#717171',
                  padding: '5px',
                  borderRadius: '0.5em',
                  border: 'solid 1px #c1c1c1',
                  marginRight: '2px',
                }}
              >
                6개월
              </button>
            </S.PeriodBtn>
            <S.PeriodBtn>
              <button
                onClick={() =>
                  onMinusOneYear({
                    calStartYear,
                    calStartMonth,
                    calStartDate,
                    setStartDate,
                  })
                }
                style={{
                  fontSize: '13px',
                  backgroundColor: '#ededed',
                  color: '#717171',
                  padding: '5px',
                  borderRadius: '0.5em',
                  border: 'solid 1px #c1c1c1',
                  marginRight: '2px',
                }}
              >
                12개월
              </button>
            </S.PeriodBtn>
          </S.PeriodBtnListSection>
          {/* 👆👆👆👆👆👆👆👆👆👆👆👆👆👆👆👆👆 -----기간설정 */}
        </S.PeriodSettingWrap>
      </S.HeaderSection>

      <S.Bar />

      <S.LoadInfoSection>
        <S.ApartmentNameBox>
          <S.ApartmentIdx>{apartmentIdx}</S.ApartmentIdx>
          <S.ApartmentName>{apartmentName}</S.ApartmentName>
        </S.ApartmentNameBox>

        <S.LoadBtnSection>
          <div style={{ marginRight: '5px' }}>
            <Button
              label="단지 선택"
              onClick={() =>
                onApartmentContractInfo({
                  apartmentListData,
                  setApartmentListData,
                  setOnModal,
                })
              }
            />
          </div>

          <Button
            label="계약 정보 불러오기"
            onClick={() =>
              onEmployeeContractInfo({
                employeeData,
                setEmployeeData,
                apartmentIdx, // [MEMO] 아파트 리스트 인덱스
                validContract,
                processingContact,
                enteredEmployeeName, // 근로자 이름
                // employeeName, // 근로자 이름
                selectedContractStart, // [MEMO] 범위 시작일
                selectedContractEnd, // [MEMO] 범위 종료일
              })
            }
          />
        </S.LoadBtnSection>
      </S.LoadInfoSection>

      <S.Bar />
      <S.TableWrap>
        <S.Tables>
          <S.THead>
            <S.TrHeader>
              <S.ThEmployeeName>이름</S.ThEmployeeName>
              <S.ThContractList>요청한 계약서</S.ThContractList>
              <S.ThRequestDate>요청일</S.ThRequestDate>
              <S.ThOpenDate>열람일</S.ThOpenDate>
              <S.ThSignDate>서명일</S.ThSignDate>

              <S.ThContractStatus>진행 상황</S.ThContractStatus>
            </S.TrHeader>
          </S.THead>

          <S.Tbody>
            {dataArray.map((data, idx) => {
              const { employeeName, contractArray } = data;
              const rowSpan = contractArray.length;
              const isFirstData = idx === 0; // [MEMO] 첫번째 데이터
              const isSecondData = idx === 1; // [MEMO] 두번째 데이터
              const isLastData = idx === dataArray.length - 1; // [MEMO] 마지막 데이터

              return (
                <>
                  {data.contractArray.map((contract, index) => (
                    <S.TrBody
                      key={index}
                      className={`${isFirstData ? 'first-data-row' : ''}
                        ${isSecondData ? 'second-data-row' : ''}
                        ${isLastData ? 'last-data-row' : ''}`}
                    >
                      {index === 0 && (
                        <S.TdEmployeeName rowSpan={rowSpan}>
                          {employeeName}
                        </S.TdEmployeeName>
                      )}
                      <S.TdContractList>
                        <div className="contract-list-box">
                          <div>계약서: {contract.templateTitle}</div>
                          <div>
                            계약기간: {contract.userContractStart}
                            <p>~</p> {contract.userContractEnd}
                          </div>
                          <p>
                            {contract.userSignDate ? (
                              <Button
                                label="다운로드"
                                onClick={() =>
                                  onDownloadContractDoneFile({
                                    contract,
                                  })
                                }
                              />
                            ) : (
                              ''
                            )}
                          </p>
                        </div>
                      </S.TdContractList>

                      <S.TdRequestDate>
                        {/* [MEMO] contract.userSignRequestDate: 요청일 */}
                        <div>{contract.userSignRequestDate}</div>
                      </S.TdRequestDate>

                      <S.TdOpenDate>
                        <div>열람일</div>
                      </S.TdOpenDate>

                      <S.TdSignDate>
                        {/* [MEMO] contract.userSignDate: 서명일 */}
                        <div>
                          {contract.userSignDate
                            ? contract.userSignDate
                            : '진행중'}
                        </div>
                      </S.TdSignDate>

                      {index === 0 && (
                        <S.TdContractStatus rowSpan={rowSpan}>
                          <>
                            <div>진행중: {data.contractRequested}</div>
                            <div>완료: {data.contractDone}</div>
                          </>
                        </S.TdContractStatus>
                      )}
                    </S.TrBody>
                  ))}
                </>
              );
            })}
          </S.Tbody>
        </S.Tables>
      </S.TableWrap>
    </>
  );
};
