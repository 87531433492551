import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import axios from 'axios';
// import { TBody } from '../../AC10-030-work-status-employee/DailySection/Scheduler/Scheduler.style';

interface GridProps {
  dataArray: any[];
  setDataArray: Dispatch<SetStateAction<any[]>>;
  extractedData: any[];
  setExtractedData: Dispatch<SetStateAction<any[]>>;
  areaListData: any[];
  apartmentName: string;
  apartmentIdx: string;
}

const ItemTypes = {
  LIST: 'list',
};

export const Grid: React.FC<GridProps> = ({
  dataArray,
  setDataArray,
  extractedData,
  setExtractedData,
  areaListData,
  apartmentName,
  apartmentIdx,
}) => {
  const token = sessionStorage.getItem('token');

  // const [dataArray, setDataArray] = useState<any[]>([]);
  // const [extractedData, setExtractedData] = useState<any[]>([]);

  useEffect(() => {
    if (areaListData) {
      const newDataArray = areaListData.map(data => {
        let trackingGroupName = '';
        let trackingTypeName = '';

        if (data.TrackingGroup === 'W') {
          trackingGroupName = '근무';
        } else if (data.TrackingGroup === 'A') {
          trackingGroupName = '근태';
        }

        if (data.TrackingType === 'N') {
          trackingTypeName = 'NFC';
        } else if (data.TrackingType === 'B') {
          trackingTypeName = 'Beacon';
        } else if (data.TrackingType === 'G') {
          trackingTypeName = 'GPS';
        } else if (data.TrackingType === 'W') {
          trackingTypeName = 'WIFI';
        }

        return {
          apartmentContractName: data.ContractName,
          apartmentContractIdx: data.ContractIdx,
          trackingLocation: data.TrackingLocation,
          trackingGroup: trackingGroupName,
          trackingType: trackingTypeName,
          orderNumber: data.OrderNo,
          dataIdx: data.idx,
          serialNumber: data.SerialNo,
          trackingName: data.TrackingName,
          trackingNote: data.TrackingNote,
          signalRange: data.SignalRange,
          jobGroupNumber: data.JobGroup,
          jobGroupName: data.JobGroupName,
          regEmployeeNumber: data.RegEmployeeNo,
          refContractInfoIdx: data.RefContractInfoIdx,
        };
      });
      setDataArray(newDataArray);
      setExtractedData(newDataArray.slice()); // 초기 데이터 배열 저장
    }
  }, [areaListData]);

  // const handleSave = async () => {
  //   const extractedData = dataArray.map((data, idx) => ({
  //     idx: Number(data.dataIdx),
  //     NewOrderNo: Number(idx),
  //   }));
  //   setExtracte
  //   dData(extractedData);

  //   if (extractedData.length <= 0) {
  //     return alert('불러온 목록이 없습니다');
  //   }

  //   console.log('extractedData: ', extractedData);

  //   await axios({
  //     url: `${process.env.COMMON_URL}/setting/sa20010/workingareaorder/${apartmentIdx}?TrackingGroup=W`,
  //     method: 'put',
  //     headers: { tokenId: token },
  //     data: { NewOrderIdx: extractedData },
  //   }).then(res => {
  //     console.log(
  //       '[SA20-010] 근무 구역 설정 순서 수정 API: ',
  //       `${process.env.COMMON_URL}/setting/sa20010/workingareaorder/${apartmentIdx}?TrackingGroup=W`
  //     );
  //     try {
  //       if (res.data.ErrorCode === 0) {
  //         return alert('변경이 완료되었습니다');
  //       }
  //     } catch (e) {
  //       console.error(e);
  //       console.log(
  //         `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
  //       );
  //     }
  //   });
  // };

  const DraggableRow: React.FC<{ data: any; idx: number }> = ({
    data,
    idx,
  }) => {
    const [, dragRef] = useDrag(() => ({
      type: ItemTypes.LIST,
      item: { index: idx },
    }));

    const [, dropRef] = useDrop({
      accept: ItemTypes.LIST,
      drop: (item: { index: number }, monitor) => {
        const { index: draggedIndex } = item;
        const dropIndex = idx;

        if (draggedIndex === dropIndex) {
          return;
        }

        const newDataArray = Array.from(dataArray);
        const draggedItem = newDataArray[draggedIndex];
        newDataArray.splice(draggedIndex, 1);
        newDataArray.splice(dropIndex, 0, draggedItem);

        setDataArray(newDataArray);
      },
    });

    // const { employeeName, contractArray } = data;
    // const rowSpan = contractArray.length;
    const isFirstData = idx === 0; // [MEMO] 첫번째 데이터
    const isSecondData = idx === 1; // [MEMO] 두번째 데이터
    const isLastData = idx === data.length - 1; // [MEMO] 마지막 데이터

    return (
      <>
        {/* <DataList
          key={idx}
          ref={node => dropRef(dragRef(node))}
          style={{
            cursor: 'move',
          }}
        > */}
        {/* <ItemWrap className="order-idx"> */}
        {/* <DataListItem className="order-idx">{idx}</DataListItem> */}
        {/* 0부터 시작해서 1씩 증가 (위 -> 아래) */}
        {/* </ItemWrap> */}
        {/* <ItemWrap>
            <DataListItem className="data-idx">{data.dataIdx}</DataListItem>
          </ItemWrap>
          <IdxText>{data.trackingLocation}</IdxText>
          <div>{data.trackingName}</div>
          <div>{data.trackingNote}</div>
          <div>{data.jobGroupName}</div>
          <div>{data.trackingGroup}</div>
          <div>{data.trackingType}</div> */}
        {/* <IdxText>{data.orderNumber}</IdxText> */}
        {/* <div>{data.apartmentContractName}</div> */}
        {/* <div>{data.apartmentContractIdx}</div> */}
        {/* <div>{data.serialNumber}</div> */}
        {/* <div>{data.signalRange}</div> */}
        {/* <div>{data.jobGroupNumber}</div> */}
        {/* <div>{data.regEmployeeNumber}</div> */}
        {/* <div>{data.refContractInfoIdx}</div> */}
        {/* </DataList> */}

        <TrBody
          key={idx}
          ref={node => dropRef(dragRef(node))}
          style={{
            cursor: 'move',
          }}
          className={`${isFirstData ? 'first-data-row' : ''}
          ${isSecondData ? 'second-data-row' : ''}
          ${isLastData ? 'last-data-row' : ''}`}
        >
          {/* <TableTBody> */}
          {/* <Tbody> */}
          {/* <TrBody> */}
          <TdEmployeeName className="order-idx">
            <DataListItem style={{ width: '30px' }} className="order-idx">
              {idx}
            </DataListItem>
            {/* 0부터 시작해서 1씩 증가 (위 -> 아래) */}
          </TdEmployeeName>
          <TdEmployeeName className="data-idx">
            <DataListItem style={{ width: '30px' }} className="data-idx">
              {data.dataIdx}
            </DataListItem>
          </TdEmployeeName>
          <TdContractList className="location-name">
            {data.trackingLocation}
          </TdContractList>
          <TdContractList className="tracking-name">
            {data.trackingName}
          </TdContractList>
          <TdContractList className="tracking-note">
            {data.trackingNote}
          </TdContractList>
          <TdContractList className="tracking-group">
            {data.jobGroupName}
          </TdContractList>
          <TdContractStatus>{data.trackingType}</TdContractStatus>
          {/* <IdxText>{data.orderNumber}</IdxText> */}
          {/* <div>{data.apartmentContractName}</div> */}
          {/* <div>{data.apartmentContractIdx}</div> */}
          {/* <div>{data.serialNumber}</div> */}
          {/* <div>{data.signalRange}</div> */}
          {/* <div>{data.trackingGroup}</div> */}
          {/* <div>{data.jobGroupNumber}</div> */}
          {/* <div>{data.regEmployeeNumber}</div> */}
          {/* <div>{data.refContractInfoIdx}</div> */}
          {/* </TrBody> */}
          {/* </Tbody> */}
          {/* </TableTBody> */}
        </TrBody>
      </>
    );
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {/* <GridSection> */}
        {/*  */}
        <Bar />
        <TableWrap className="table-container">
          <Tables>
            <THead>
              <TrHeader>
                <ThOrderIdx>순서</ThOrderIdx>
                <ThItem className="data-idx">번호</ThItem>
                <ThItem className="location-name">장소</ThItem>
                <ThItem className="tracking-name">이름</ThItem>
                <ThItem className="tracking-note">메모</ThItem>
                <ThItem className="tracking-group">분류</ThItem>
                <ThDeviceType>타입</ThDeviceType>
              </TrHeader>
            </THead>
            {/*  */}
            {/* <button onClick={() => handleSave()}>순서 변경 저장하기</button> */}
            {/* <div> */}
            <Tbody>
              {dataArray.map((data, idx) => (
                <DraggableRow data={data} idx={idx} key={idx} />
              ))}
            </Tbody>
            {/* </div> */}
            {/* </GridSection> */}
          </Tables>
        </TableWrap>
      </DndProvider>
    </>
  );
};

const Bar = styled.div`
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const GridSection = styled.div`
  height: 650px;
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const IdxText = styled.div`
  margin-right: 10px;
  font-weight: 600;
`;

const DataList = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 5px 30px;
  color: ${({ theme }) => theme.fontColor.black};
  font-size: 13px;
`;

const ItemWrap = styled.div`
  width: 45px;

  &.order-idx {
    /* width: 40px; */
    border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
    margin-right: 10px;
  }
`;

const DataListItem = styled.div`
  /* height: 25px;
  margin-right: 10px; */

  &.order-idx {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 4px 6px; */
    /* border-radius: 0.5em; */

    border-radius: 50%;
    /* background-color: ${({ theme }) => theme.statusColor.working}; */
    /* color: white; */
    font-weight: 600;
  }

  &.data-idx {
    display: flex;
    justify-content: start;
    align-items: center;
    /* padding: 4px 6px; */

    border-radius: 0.5em;
    /* background-color: ${({ theme }) => theme.backgroundColor.gray}; */
    font-weight: 600;
  }
`;

// -----------------------------------------------------------
// -----------------------------------------------------------
// -----------------------------------------------------------

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  //justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 30px;
  padding-left: 30px;
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ApartmentInfoBox = styled.div`
  display: flex;
`;

export const CategorySection = styled.div`
  ${({ theme }) => theme.stopDrag};
  display: flex;
  flex-direction: column;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 15px;
`;

export const Wrap = styled.div`
  display: flex;
`;

export const CategoryWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0;
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-top: none;
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  background-color: ${({ theme }) => theme.backgroundColor.gray};

  @media (max-width: 900px) {
    //
  }
`;

export const PeriodSettingWrap = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    //flex-direction: column;
  }
`;

export const EssentialCategorySection = styled.div`
  ${({ theme }) => theme.stopDrag};
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 15px;

  &.on-option {
    padding-bottom: 0;
  }

  &.on-sub-option {
    padding-bottom: 0;
  }
`;

export const EssentialCategoryWrap = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  padding-left: 10px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-radius: 0.5em;
  color: ${({ theme }) => theme.fontColor.black};
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;

  &.on-option {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.on-sub-option {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  :hover {
    color: ${({ theme }) => theme.mainColor.main};
  }
`;

export const DatePickerWrap = styled.ul`
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    //margin-right: 0;
  }
`;

export const DatePickerBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;

  p {
    width: 50px;
  }

  @media (max-width: 900px) {
    //flex-direction: column;
    //align-items: flex-start;

    p {
      // margin-bottom: 5px;
    }
  }
`;

export const PeriodBtnListSection = styled.ul`
  float: left;
  display: flex;
  align-items: center;
`;

export const PeriodBtn = styled.div`
  float: left;
  margin-right: 2px;
`;

export const AdditionalCategorySection = styled.ul`
  display: flex;
`;

export const GridWrap = styled.div`
  width: 100%;
  // display: flex;
  padding: 0 35px;
  //overflow-x: auto;
  //overflow-y: auto;
`;

export const TableWrap = styled.div`
  width: 100%;

  padding-top: 15px;
  padding-right: 30px;
  padding-left: 30px;

  .table-container {
    max-height: 10px; /* 원하는 높이로 설정 */
    overflow-y: auto; /* 세로 스크롤을 보여주는 영역 */
  }
`;

export const Tables = styled.table`
  background-color: white;
  width: 100%;
  // table-layout: fixed; // overflow(스크롤) 구현하고 싶으면 fixed 활성화 해야 함
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};

  border-collapse: separate;
  border-radius: 0.5em;
`;

export const THead = styled.thead`
  background-color: white;
  height: 60px;
  font-weight: 500;
`;

export const TrHeader = styled.tr`
  font-size: 13px;
`;

export const ThOrderIdx = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: start;
  width: 150px;
  height: 60px;
  padding-left: 10px;
  border-top-left-radius: 1em; /* [MEMO] Tables에서 적용한 0.5em에 영향 미치지 않으려고 더 크게 잡음 */
  border-bottom-left-radius: 1em; /* [MEMO] Tables에서 적용한 0.5em에 영향 미치지 않으려고 더 크게 잡음 */
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};
`;

export const ThItem = styled.th`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 150px;
  height: 60px;
  padding-left: 10px;
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};

  &.data-idx {
    width: 150px;
  }

  &.location-name {
    width: 600px;
  }

  &.tracking-name {
    width: 400px;
  }

  &.tracking-note {
    width: 400px;
  }

  &.tracking-group {
    width: 200px;
  }
`;

export const ThDeviceType = styled.th`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 150px;
  height: 60px;
  padding-left: 10px;
  border-top-right-radius: 1em; /* [MEMO] Tables에서 적용한 0.5em에 영향 미치지 않으려고 더 크게 잡음 */
  border-bottom-right-radius: 1em; /* [MEMO] Tables에서 적용한 0.5em에 영향 미치지 않으려고 더 크게 잡음 */
  color: ${({ theme }) => theme.fontColor.darkGray};
`;

export const TableTBody = styled.table`
  background-color: white;
  width: 100%;
  // table-layout: fixed; // overflow(스크롤) 구현하고 싶으면 fixed 활성화 해야 함
  /* border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray}; */

  border-collapse: separate;
  border-radius: 0.5em;
`;

export const Tbody = styled.tbody`
  color: ${({ theme }) => theme.fontColor.darkGray};
  font-size: 13px;
  font-weight: 400;
  /*  */
  table-layout: fixed;
  border-collapse: separate;

  /* height: 50px;
  overflow-y: auto; */
`;

export const TrBody = styled.tr`
  /* border-bottom: solid 1px ${({ theme }) =>
    theme.backgroundColor.shadowGray}; */

  :hover {
    background-color: ${({ theme }) => theme.backgroundColor.gray};
  }

  &.first-data-row {
    border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  }

  &.first-data-row td {
    border-top: inherit;
    border-bottom: inherit;
  }

  &.last-data-row {
    border-bottom: none;
  }

  &.last-data-row td {
    border-bottom: inherit;
  }
`;

export const TdEmployeeName = styled.td`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: start;
  width: 150px;
  height: 60px;
  padding-left: 10px;
  /* border-bottom: solid 1px ${({ theme }) =>
    theme.backgroundColor.shadowGray}; */
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};

  &.order-idx {
    padding-left: 0;
    /* background-color: pink; */
    /* background-color: ${({ theme }) => theme.statusColor.working}; */
    background-color: ${({ theme }) => theme.backgroundColor.gray};
  }
`;

// TdContractList TdItem
export const TdContractList = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 600px;
  height: 60px;
  padding-left: 10px;
  /* border-bottom: solid 1px ${({ theme }) =>
    theme.backgroundColor.shadowGray}; */
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};

  &.data-idx {
    width: 150px;
  }

  &.location-name {
    width: 600px;
    font-weight: 600;
  }

  &.tracking-name {
    width: 400px;
  }

  &.tracking-note {
    width: 400px;
  }

  &.tracking-group {
    width: 200px;
  }
`;

export const TdRequestDate = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 100px;
  height: 60px;
  padding-left: 10px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

export const TdOpenDate = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 100px;
  height: 60px;
  padding-left: 10px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

export const TdSignDate = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 100px;
  height: 60px;
  padding-left: 10px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

export const TdContractStatus = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 150px;
  height: 60px;
  padding-left: 10px;
`;

export const ApartmentNameBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  font-weight: 500;
  font-size: 13px;

  p {
    color: ${({ theme }) => theme.fontColor.darkGray};
  }
`;

export const ApartmentIdx = styled.div`
  /* display: flex; */
  color: ${({ theme }) => theme.mainColor.main};
`;

export const ApartmentName = styled.div`
  /* font-weight: 600; */
`;

export const LoadBtnSection = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 30px;
  margin: 5px 0;
`;

export const LoadInfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
`;
