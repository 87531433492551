import React, { useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { searchApartmentName } from './searchApartmentName';
import { onEncodedString } from './onEncodedString'; // 문자열을 base64URL로 변환

import { Input } from '../UI/Input/Short';
import { Button } from '../UI/Button/Short';

interface ModalContentsProps {
  closeManagerListModal: () => void | undefined;
  managerData: any[];
  // apartmentListData: any[];
  // setApartmentListData: Dispatch<SetStateAction<any[]>>;
  managerEmployeeNum: string;
  setManagerEmployeeNum: Dispatch<SetStateAction<string>>;
  managerName: string;
  setManagerName: Dispatch<SetStateAction<string>>;
}

export const ModalContents = ({
  closeManagerListModal,
  managerData,
  managerEmployeeNum,
  managerName,
  setManagerEmployeeNum,
  setManagerName,
}: ModalContentsProps) => {
  const [enteredName, setEnteredName] = useState('');

  // [MEMO] onEnteredName: 일반 문자열을 BASE64로 인코딩
  const onEnteredName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { encodedString } = onEncodedString({ e });
    setEnteredName(encodedString);
  };

  // [MEMO] enteredNameValue: 계약 단지 이름으로 검색시
  // const enteredNameValue = enteredName ? `&Name=${enteredName}` : '';
  const enteredNameValue = `&Name=${enteredName}`;

  const [openResult, setOpenResult] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  return (
    <>
      <Section>
        <SelectedApartmentTitle>선택한 관리자</SelectedApartmentTitle>
        <SelectedApartmentBox>
          <SelectedApartmentContents>
            <SelectedApartmentIdx>{managerEmployeeNum}</SelectedApartmentIdx>
            <SelectedApartmentName>{managerName}</SelectedApartmentName>
          </SelectedApartmentContents>
          <ButtonWrap>
            <Button label="확인" onClick={closeManagerListModal} />
          </ButtonWrap>
        </SelectedApartmentBox>

        <ApartmentTitle>관리자 리스트</ApartmentTitle>
        <ApartmentSearchBox>
          {/* [MEMO] 👇 계약 단지 입력(검색) */}
          <Input placeholder="단지 이름" onChange={onEnteredName} />
          <ButtonWrap>
            <Button
              label="검색"
              onClick={() =>
                searchApartmentName({
                  managerData,
                  // setApartmentListData,
                  // enteredNameValue,
                  // setOpenResult,
                  // setSearchResult,
                })
              }
            />
          </ButtonWrap>
        </ApartmentSearchBox>

        <SearchResultBox>
          {openResult ? (
            <>
              {searchResult.map(data => {
                return (
                  <ApartmentListBox
                    key={data.idx}
                    onClick={() => {
                      setManagerEmployeeNum(data.EmployeeNo);
                      setManagerName(data.UserNameKR);
                    }}
                  >
                    <ApartmentIdx>{data.EmployeeNo}</ApartmentIdx>
                    <ApartmentName>{data.UserNameKR}</ApartmentName>
                  </ApartmentListBox>
                );
              })}
            </>
          ) : (
            <DefaultSearchBox>
              <div>검색 결과</div>
            </DefaultSearchBox>
          )}
        </SearchResultBox>

        <Box>
          {managerData.map(data => {
            return (
              <ApartmentListBox
                key={data.idx}
                onClick={() => {
                  setManagerEmployeeNum(data.EmployeeNo);
                  setManagerName(data.UserNameKR);
                }}
              >
                <ApartmentIdx>{data.EmployeeNo}</ApartmentIdx>
                <ApartmentName>{data.UserNameKR}</ApartmentName>
              </ApartmentListBox>
            );
          })}
        </Box>
      </Section>
    </>
  );
};

const Section = styled.div`
  padding: 20px;
  font-size: 14px;
`;

const SelectedApartmentTitle = styled.div`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.mainColor.main};
  font-weight: 600;
`;

const SelectedApartmentBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600;
`;

const SelectedApartmentContents = styled.div`
  display: flex;
  margin-left: 5px;
`;

const SelectedApartmentIdx = styled.div`
  margin-right: 5px;
  color: ${({ theme }) => theme.mainColor.main};
`;

const SelectedApartmentName = styled.div`
  /* padding: 20px; */
`;

const ButtonWrap = styled.div`
  /* margin-left: 5px; */
`;

const ApartmentTitle = styled.div`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.mainColor.main};
  font-weight: 600;
`;

const ApartmentSearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Box = styled.div`
  ${({ theme }) => theme.scrollBarStyle};
  height: 450px;
  overflow: auto;
`;

const ApartmentListBox = styled.div`
  display: flex;
  padding: 10px 0 10px 5px;

  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.mainColor.main};
    color: white;
  }
`;

const ApartmentIdx = styled.div`
  margin-right: 5px;
`;

const ApartmentName = styled.div``;

const SearchResultBox = styled.div`
  ${({ theme }) => theme.scrollBarStyle};
  height: 100px;
  padding: 10px;
  font-size: 14px;
  overflow: auto;

  border-radius: 0.5em;
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};

  margin-bottom: 5px;
`;

const DefaultSearchBox = styled.div`
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 13px;
  color: ${({ theme }) => theme.fontColor.lightGray}; ;
`;
