import React, { useState } from 'react';
import * as S from './Grid.style';

import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/store';
import { selectedAreaCode } from '../../../redux/slice/selectedCategoryAtApartmentSlice';

// import { Modal } from '../DetailedGridInfo/Modal';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../../styles/agGridStyle/ag-theme-custom.css';
import { CellDoubleClickedEvent } from 'ag-grid-community';

import { TEMP_AREA_CODE } from '../constants/categories'; // WARNING: 상수값임. 추후 카테고리 데이터 바인딩 할 것.
import { gridOptions } from './gridOptions';

import { onEncodedString } from '../utils/onEncodedString'; // 문자열을 base64URL로 변환
import { onLoadBtn } from '../utils/onLoadBtn';

import { Dropbox } from '../../UI/Dropbox/Short';
import { Button } from '../../UI/Button/Short';
import { Input } from '../../UI/Input/Short';

import {
  apartmentIndex,
  nameKR,
  address1, // 주소
  areaName, // 지역(이름)
  postCode, // 우편번호
  buildingNum, // 건물수
  houseNum, // 세대수
  parkingNum, // 주차대수
  manageArea, // 전체면적
  taxExemptionArea, // 면세면적
  taxationArea, // 과세면적
} from '../../../redux/slice/targetApartmentInfoSlice';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const Grid = () => {
  const dispatch = useDispatch(); // redux toolkit

  const [apartmentData, setApartmentData] = useState<any[]>([]);

  const [categoryName, setCategoryName] = useState<string>('');
  const [categoryValue, setCategoryValue] = useState<any>();

  const [enteredIdx, setEnteredIdx] = useState(''); // [CATEGORY] (단지) 번호
  const [enteredName, setEnteredName] = useState(''); // [CATEGORY] 이름 (base64)

  // [CATEGORY] 근무 지역
  const requestSelectedAreaCode = useSelector(
    (state: RootState) =>
      state.selectedCategoryAtApartment.selectedAreaCodeValue
  );

  let areaCodeValue = '';

  // [CATEGORY] 근무 지역 코드
  if (categoryName === 'areaCode') {
    areaCodeValue = categoryValue ? `&AreaCode=${categoryValue}` : '';
    dispatch(selectedAreaCode(areaCodeValue));
  }

  const onEnteredIdx = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredIdx(e.target.value);
  };

  const onEnteredName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { encodedString } = onEncodedString({ e });
    setEnteredName(encodedString);
  };

  const enteredNameValue = enteredName ? `&Name=${enteredName}` : '';

  const [onModal, setOnModal] = useState(false);

  const onControlModal = (boolean = true) => {
    setOnModal(boolean);

    if (onModal === true) {
      setOnModal(false);
    }
  };

  const closeModal = () => {
    setOnModal(false);
  };

  const onDetailedEmployeeInfoClick = (e: CellDoubleClickedEvent) => {
    onDetailedApartmentInfo(e);
  };

  const onDetailedApartmentInfo = async (e: CellDoubleClickedEvent) => {
    const targetApartmentNum = e.data.idx;

    console.log(e, 'e !!');
    console.log(targetApartmentNum, 'targetApartmentNum !!');

    await axios
      .get(
        `${process.env.COMMON_URL}/common/contractinfo/${targetApartmentNum}`
      )
      .then(res => {
        try {
          dispatch(apartmentIndex(res.data.Data[0].idx)); // 아파트 인덱스
          dispatch(nameKR(res.data.Data[0].Name)); // 아파트 이름
          dispatch(address1(res.data.Data[0].Address1)); // 주소
          dispatch(areaName(res.data.Data[0].AreaName)); // 지역(이름)
          dispatch(postCode(res.data.Data[0].POSTCode)); // 우편번호
          dispatch(buildingNum(res.data.Data[0].NoBuilding)); // 건물수
          dispatch(houseNum(res.data.Data[0].NoHouse)); // 세대수
          dispatch(parkingNum(res.data.Data[0].NoParking)); // 주차대수
          dispatch(manageArea(res.data.Data[0].ManageArea)); // 전체면적
          dispatch(taxExemptionArea(res.data.Data[0].TaxExemptionArea)); // 면세면적
          dispatch(taxationArea(res.data.Data[0].TaxationArea)); // 과세면적

          onControlModal(true);
        } catch (e) {
          //
        }
      });
  };

  const [essentialCategory, setEssentialCategory] = useState(false);

  function onEssentialCategory() {
    setEssentialCategory(!essentialCategory);
  }

  return (
    <>
      <S.Title>단지 정보 관리</S.Title>
      {/* <img src={`${imageText}`} /> */}
      <S.Wrap>
        {/* onModal && <Modal closeModal={closeModal} /> */}
        {/* <Modal closeModal={closeModal} /> */}

        <S.EssentialCategorySection
          className={essentialCategory ? 'on-option' : 'off-option'}
          onClick={() => onEssentialCategory()}
        >
          <S.EssentialCategoryWrap
            className={essentialCategory ? 'on-option' : 'off-option'}
          >
            {essentialCategory ? (
              <KeyboardArrowDownIcon style={{ fontSize: 20 }} />
            ) : (
              <KeyboardArrowRightIcon style={{ fontSize: 20 }} />
            )}
            검색 옵션 선택
          </S.EssentialCategoryWrap>
        </S.EssentialCategorySection>

        {essentialCategory && (
          <S.CategorySection>
            <S.CategoryWrap>
              <S.CategoryGroup>
                <S.CategoryBox>
                  <Dropbox
                    setCategoryName={setCategoryName}
                    setCategoryValue={setCategoryValue}
                    title="근무 지역(AreaCode)"
                    selectedCategoryName="areaCode"
                    contentsList={TEMP_AREA_CODE}
                  />
                </S.CategoryBox>

                <S.CategoryBox>
                  <S.CategoryName className="category-name">
                    단지 번호
                  </S.CategoryName>
                  <Input placeholder="단지 번호" onChange={onEnteredIdx} />
                </S.CategoryBox>

                <S.CategoryBox>
                  <S.CategoryName>단지명</S.CategoryName>
                  <Input placeholder="단지명" onChange={onEnteredName} />
                </S.CategoryBox>
              </S.CategoryGroup>
            </S.CategoryWrap>
          </S.CategorySection>
        )}

        <S.Bar />
        <S.LoadBtnSection>
          <Button
            label="불러오기"
            onClick={() =>
              onLoadBtn({
                apartmentData,
                setApartmentData,
                enteredNameValue,
                requestSelectedAreaCode,
              })
            }
          />
        </S.LoadBtnSection>

        <S.GridSection>
          <div id="myGrid" className="ag-theme-alpine ag-theme-custom">
            <AgGridReact
              headerHeight={45} // 타이틀 행 높이
              rowHeight={35} // 본문 행 높이
              gridOptions={gridOptions}
              rowData={apartmentData.map(data => {
                return {
                  idx: data.idx, // (단지)번호
                  name: data.Name, // 단지명
                  areaName: data.AreaName, // 지역(이름)
                  // noBuilding: data.NoBuilding, // 건물수
                  noHouse: data.NoHouse, // 세대수
                  // noParking: data.NoParking, // 주차면
                  // manageArea: data.ManageArea, // 관리면적
                  // taxExemptionArea: data.TaxExemptionArea, // 면세면적
                  // taxationArea: data.TaxationArea, // 과세면적
                  POSTCode: data.POSTCode, // 우편번호
                  address1: data.Address1, // 주소
                };
              })}
              animateRows={true}
              rowDragManaged={true}
              suppressMoveWhenRowDragging={true}
              pagination={true}
              paginationPageSize={15}
              onCellDoubleClicked={(e: CellDoubleClickedEvent) =>
                apartmentData.length
                  ? onDetailedEmployeeInfoClick(e)
                  : alert('없음')
              }
            />
          </div>
        </S.GridSection>
      </S.Wrap>
    </>
  );
};
