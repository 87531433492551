import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

interface loadApartmentNameBtnProps {
  apartmentUniqueData: any[];
  setApartmentUniqueData: Dispatch<SetStateAction<any[]>>;
  enteredNameValue: string;
  setOpenResult: Dispatch<SetStateAction<boolean>>;
  setSearchResult: Dispatch<SetStateAction<any>>;
}

export const searchApartmentName = async ({
  apartmentUniqueData,
  setApartmentUniqueData,
  enteredNameValue, // 계약 단지 이름
  setOpenResult,
  setSearchResult,
}: loadApartmentNameBtnProps) => {
  const token = sessionStorage.getItem('token');
  console.log(enteredNameValue, '📢📢📢📢📢');

  await axios
    .get(
      // 'https://apihr.mmigroup.co.kr:9020/common/refcontractinfo?ItemPerPage=10&ItemStartPage=0&Name=%E3%85%8B%E3%85%8B%E3%85%8B'
      `${process.env.COMMON_URL}/common/refcontractinfo?ItemPerPage=0&ItemStartPage=0${enteredNameValue}&Valid=1`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      console.log(
        '(@모달안)API확인',
        `${process.env.COMMON_URL}/common/refcontractinfo?ItemPerPage=0&ItemStartPage=0${enteredNameValue}&Valid=1`
      );
      try {
        setSearchResult(res.data.Data);
        setOpenResult(true);
        // setApartmentUniqueData(res.data.Data[0]);
        console.log(res.data.Data, '(@모달안)res.data.Data');
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
