import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 0;
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Box = styled.ul`
  list-style: none;
`;

export const ItemBox = styled.li`
  float: left;
  margin: 10px 10px;
`;

export const ItemName = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
`;
