import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { Scheduler } from './Scheduler/Scheduler';

interface MonthlySectionProps {
  employeeData: any[];
  apartmentContractIdx: string;
  calYear: string;
  calMonth: string;
  // calDate: string;
  schedulerLoading: boolean;
  setIsUpdateStatus: Dispatch<SetStateAction<boolean>>;
}

const MonthlySection = ({
  employeeData,
  apartmentContractIdx,
  calYear,
  calMonth,
  // calDate,
  schedulerLoading,
  setIsUpdateStatus,
}: MonthlySectionProps) => {
  if (schedulerLoading) {
    return (
      <>
        <Bar />
        <LoadingWrap>
          <LoadingGIF src="/loading.gif" />
        </LoadingWrap>
      </>
    );
  }

  return (
    <>
      {/* [MEMO] 월간 근무 현황 */}
      <Scheduler
        employeeData={employeeData}
        apartmentContractIdx={apartmentContractIdx}
        calYear={calYear}
        calMonth={calMonth}
        // calDate={calDate}
        setIsUpdateStatus={setIsUpdateStatus}
      />
    </>
  );
};
export default MonthlySection;
const Bar = styled.div`
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const LoadingGIF = styled.img`
  ${({ theme }) => theme.loadingGIF}
`;
