// ref 없음. 단지 '계약' 정보 ('고유' 인덱스 아님(고유는 ref 있음))
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

/**
 * permitCode 권한 코드
 * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
 * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
 * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
 */

interface onApartmentContractInfoProps {
  apartmentListContractData: any[];
  setApartmentListContractData: Dispatch<SetStateAction<any[]>>;
  setOnModal: Dispatch<SetStateAction<boolean>>;
}

export const onApartmentContractInfo = async ({
  apartmentListContractData,
  setApartmentListContractData,
  setOnModal,
}: onApartmentContractInfoProps) => {
  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 관리자(본사)
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 관리소장

  if (permitCode === headOfficerManagerCode) {
    await axios
      .get(
        // /common/contractinfo?ItemPerPage=0&ItemStartPage=0&Valid=0&Duplicate=1
        `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0&Valid=1`,
        {
          headers: { tokenId: token },
        }
      )
      .then(res => {
        console.log(
          '[AR10-030] 스마트 근무 일지-계약 단지 리스트 API/관리자:',
          `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0&Valid=1`
        );
        try {
          if (res.data.ErrorCode === 0) {
            setApartmentListContractData(res.data.Data);
            setOnModal(true);
          }

          incorrectToken(res);
        } catch (e) {
          console.error(e);
          console.log(
            `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
          );
        }
      });
  }

  if (permitCode === apartmentManagerCode) {
    await axios
      .get(
        // /common/contractinfo?ItemPerPage=0&ItemStartPage=0&Valid=0&Duplicate=1
        // Valid=0 -> string / Duplicate=1 -> string
        `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0&Valid=0&Duplicate=1`,

        {
          headers: { tokenId: token },
        }
      )
      .then(res => {
        console.log(
          '[AR10-030]스마트 근무 일지-계약 단지 리스트 API/관리소장:',
          `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0&Valid=0&Duplicate=1`
        );
        try {
          if (res.data.ErrorCode === 0) {
            setApartmentListContractData(res.data.Data);
            setOnModal(true);
          }

          incorrectToken(res);
        } catch (e) {
          console.error(e);
          console.log(
            `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
          );
        }
      });
  }

  // await axios
  //   .get(
  //     // 'https://apihr.mmigroup.co.kr:9020/common/contractinfo?ItemPerPage=10&ItemStartPage=0'
  //     // `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${apartmentNameForm}&Valid=1${managerNumForm}`,
  //     `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0&Valid=1`,
  //     {
  //       headers: { tokenId: token },
  //     }
  //   )
  //   .then(res => {
  //     // console.log(
  //     //   '[AR10-030] 스마트 근무 일지 - 계약 단지 리스트 API:',
  //     //   `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0&Valid=1`
  //     // );
  //     try {
  //       if (res.data.ErrorCode === 0) {
  //         setApartmentListContractData(res.data.Data);
  //         setOnModal(true);
  //       }

  //       incorrectToken(res);
  //     } catch (e) {
  //       console.error(e);
  //       console.log(
  //         `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
  //       );
  //     }
  //   });
};
