// [MEMO] 일간 근무 시간 수정을 위한 모달 컴포넌트
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { onModifyData } from '../utils/onModifyData';

interface ModalContentsProps {
  closeModal: () => void;
  setIsUpdateStatusBar: Dispatch<SetStateAction<boolean>>;
  detailWorkData: any[];
  targetWorkStatusIdx: string | null;
  employeeNum: string;
}

export const ModalContents = ({
  closeModal,
  setIsUpdateStatusBar,
  targetWorkStatusIdx,
  detailWorkData,
  employeeNum,
}: ModalContentsProps) => {
  // [TAG] 👇 (화면에 보이는) 시간 설정 -----start
  useEffect(() => {
    setSelectedStartHours(onSelectedStartHours());
    setSelectedStartMinutes(onSelectedStartMinutes());
    setSelectedEndHours(onSelectedEndHours());
    setSelectedEndMinutes(onSelectedEndMinutes());
  }, []);

  function onSelectedStartHours(): number {
    if (detailWorkData[0].TriggerTimeStart) {
      const hours = Number(detailWorkData[0].TriggerTimeStart.slice(0, 2));
      return hours;
    } else return selectedStartHours;
  }

  function onSelectedStartMinutes(): number {
    if (detailWorkData[0].TriggerTimeStart) {
      const Minutes = Number(detailWorkData[0].TriggerTimeStart.slice(3, 5));
      return Minutes;
    } else return selectedStartMinutes;
  }

  function onSelectedEndHours(): number {
    if (detailWorkData[0].TriggerTimeEnd) {
      const hours = Number(detailWorkData[0].TriggerTimeEnd.slice(0, 2));
      return hours;
    } else return selectedEndHours;
  }

  function onSelectedEndMinutes(): number {
    if (detailWorkData[0].TriggerTimeEnd) {
      const minutes = Number(detailWorkData[0].TriggerTimeEnd.slice(3, 5));
      return minutes;
    } else return selectedEndMinutes;
  }
  // [TAG] 👆 (화면에 보이는) 시간 설정 -----end

  // [MEMO] 👇 내일 날짜 표기
  const targetDate = new Date(detailWorkData[0].TriggerDate);
  const tomorrowDateObject = new Date(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    targetDate.getDate() + 2
  );
  const tomorrowDate = tomorrowDateObject.toISOString().substring(0, 10);

  // [MEMO] 👇 다음 날 퇴근인지 체크 true면 다음 날까지 근무, false면 당일 근무
  const [isNextDay, setIsNextDay] = useState(
    detailWorkData[0].EndTimeDay === true ? true : false
  );

  function switchValidContract() {
    setIsNextDay(false);

    if (isNextDay === false) {
      setIsNextDay(true);
    }
  }

  // [TAG] 시작 시간
  const [selectedStartHours, setSelectedStartHours] = useState(0);
  const [selectedStartMinutes, setSelectedStartMinutes] = useState(0);

  const startHours = Array.from({ length: 24 }, (_, index) => index); // [MEMO] 0부터 23까지 숫자 배열 생성
  const startMinutes = Array.from({ length: 60 }, (_, index) => index); // [MEMO] 0부터 59까지 숫자 배열 생성

  const handleStartHourChange = (event: { target: { value: string } }) => {
    setSelectedStartHours(parseInt(event.target.value));
  };

  const handleStartMinuteChange = (event: { target: { value: string } }) => {
    setSelectedStartMinutes(parseInt(event.target.value));
  };

  // [TAG] 종료 시간
  const [selectedEndHours, setSelectedEndHours] = useState(0);
  const [selectedEndMinutes, setSelectedEndMinutes] = useState(0);

  const endHours = Array.from({ length: 24 }, (_, index) => index); // [MEMO] 0부터 23까지 숫자 배열 생성
  const endMinutes = Array.from({ length: 60 }, (_, index) => index); // [MEMO] 0부터 59까지 숫자 배열 생성

  const handleEndHourChange = (event: { target: { value: string } }) => {
    setSelectedEndHours(parseInt(event.target.value));
  };

  const handleEndMinuteChange = (event: { target: { value: string } }) => {
    setSelectedEndMinutes(parseInt(event.target.value));
  };

  // [MEMO] 👇 내일 날짜인지 여부에 따라, '변경 후'에 있는 드롭박스 배열(시간, 분) 조절하기
  let availableEndHours: number[] = endHours;

  if (!isNextDay) {
    availableEndHours = endHours.slice(selectedStartHours);
  } else if (isNextDay) {
    availableEndHours = endHours;
  }

  let availableEndMinutes: number[] = endMinutes;

  if (!isNextDay && selectedStartHours < selectedEndHours) {
    availableEndMinutes = endMinutes;
  } else if (!isNextDay) {
    availableEndMinutes = endMinutes.slice(selectedStartMinutes);
  } else if (isNextDay) {
    availableEndMinutes = endMinutes;
  }

  return (
    <Wrap>
      <ClassificationTitleBox>
        <CategoryTitle>근무 현황</CategoryTitle>
      </ClassificationTitleBox>

      <Section className="first-section">
        <CategoryTitleBox>
          <CategoryTitle>상태</CategoryTitle>
        </CategoryTitleBox>
        <StatusNameBox>{detailWorkData[0].AttendStatusName}</StatusNameBox>
      </Section>

      <Section>
        <CategoryTitleBox>
          <CategoryTitle>변경 전</CategoryTitle>
        </CategoryTitleBox>
        <div style={{ display: 'flex' }}>
          <DateBox style={{ marginLeft: '5px' }}>
            <p className="year">
              {detailWorkData[0].TriggerDate.slice(0, 4)}년
            </p>
            <p className="month">
              {detailWorkData[0].TriggerDate.slice(5, 7)}월
            </p>
            <p className="date">
              {detailWorkData[0].TriggerDate.slice(8, 10)}일
            </p>
          </DateBox>
          <TimeBox className="before-modify">
            {detailWorkData[0].TriggerTimeStart}
          </TimeBox>
        </div>

        <p style={{ margin: '0 5px' }}>~</p>

        <div style={{ display: 'flex' }}>
          {detailWorkData[0].EndTimeDay ? (
            <DateBox>
              <p className="year">{tomorrowDate.slice(0, 4)}년</p>
              <p className="month">{tomorrowDate.slice(5, 7)}월</p>
              <p className="date">{tomorrowDate.slice(8, 10)}일</p>
            </DateBox>
          ) : (
            <DateBox>
              <p className="year">
                {detailWorkData[0].TriggerDate.slice(0, 4)}년
              </p>
              <p className="month">
                {detailWorkData[0].TriggerDate.slice(5, 7)}월
              </p>
              <p className="date">
                {detailWorkData[0].TriggerDate.slice(8, 10)}일
              </p>
            </DateBox>
          )}
          <TimeBox className="before-modify">
            {detailWorkData[0].TriggerTimeEnd
              ? detailWorkData[0].TriggerTimeEnd
              : ''}
          </TimeBox>
        </div>
      </Section>

      <Section className="last-section">
        <CategoryTitleBox>
          <CategoryTitle>변경 후</CategoryTitle>
        </CategoryTitleBox>
        <div style={{ display: 'flex' }}>
          <DateBox style={{ marginLeft: '5px' }}>
            <p className="year">
              {detailWorkData[0].TriggerDate.slice(0, 4)}년
            </p>
            <p className="month">
              {detailWorkData[0].TriggerDate.slice(5, 7)}월
            </p>
            <p className="date">
              {detailWorkData[0].TriggerDate.slice(8, 10)}일
            </p>
          </DateBox>
          <TimeBox>
            {selectedStartHours.toString().length === 1
              ? '0' + selectedStartHours
              : selectedStartHours}
            :
            {selectedStartMinutes.toString().length === 1
              ? '0' + selectedStartMinutes
              : selectedStartMinutes}
          </TimeBox>
        </div>

        <p style={{ margin: '0 5px' }}>~</p>

        <div style={{ display: 'flex' }}>
          {isNextDay ? (
            <DateBox>
              <p className="year">{tomorrowDate.slice(0, 4)}년</p>
              <p className="month">{tomorrowDate.slice(5, 7)}월</p>
              <p className="date">{tomorrowDate.slice(8, 10)}일</p>
            </DateBox>
          ) : (
            <DateBox>
              <p className="year">
                {detailWorkData[0].TriggerDate.slice(0, 4)}년
              </p>
              <p className="month">
                {detailWorkData[0].TriggerDate.slice(5, 7)}월
              </p>
              <p className="date">
                {detailWorkData[0].TriggerDate.slice(8, 10)}일
              </p>
            </DateBox>
          )}
          <TimeBox>
            {detailWorkData[0].TriggerTimeEnd
              ? `${
                  selectedEndHours.toString().length === 1
                    ? '0' + selectedEndHours
                    : selectedEndHours
                }:${
                  selectedEndMinutes.toString().length === 1
                    ? '0' + selectedEndMinutes
                    : selectedEndMinutes
                }`
              : ''}
          </TimeBox>
        </div>
      </Section>

      <ClassificationTitleBox>
        <CategoryTitle>수정 사항</CategoryTitle>
      </ClassificationTitleBox>

      <Section
        className={
          detailWorkData[0].TriggerTimeEnd
            ? 'after-modify-start-time'
            : 'after-modify-start-time none-end-time'
        }
      >
        <CategoryTitleBox>
          <CategoryTitle>시작 시간</CategoryTitle>
        </CategoryTitleBox>

        <TimeBoxList className="start-time">
          <AfterModifyTimeBox>
            <div>
              <LabelStyle className="hours start-time">
                {/* Start Hour: */}
                <SelectStyle
                  value={selectedStartHours}
                  onChange={handleStartHourChange}
                >
                  {startHours.map(hour => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </SelectStyle>
              </LabelStyle>
            </div>
            <CategoryTitle className="hours select-box">시</CategoryTitle>
          </AfterModifyTimeBox>

          <AfterModifyTimeBox>
            <div>
              <LabelStyle className="start-time">
                {/* Start Minute: */}
                <SelectStyle
                  value={selectedStartMinutes}
                  onChange={handleStartMinuteChange}
                >
                  {startMinutes.map(minute => (
                    <option key={minute} value={minute}>
                      {minute}
                    </option>
                  ))}
                </SelectStyle>
              </LabelStyle>
            </div>
            <CategoryTitle
              className={
                detailWorkData[0].TriggerTimeEnd
                  ? 'select-box'
                  : 'select-box none-end-time'
              }
            >
              분
            </CategoryTitle>
          </AfterModifyTimeBox>
        </TimeBoxList>
      </Section>

      {detailWorkData[0].TriggerTimeEnd ? (
        <Section className="after-modify-end-time">
          <CategoryTitleBox>
            <CategoryTitle>종료 시간</CategoryTitle>
          </CategoryTitleBox>

          <TimeBoxList className="end-time">
            <AfterModifyTimeBox>
              <div>
                <LabelStyle className="hours end-time">
                  {/* End Hour: */}
                  <SelectStyle
                    value={selectedEndHours}
                    onChange={handleEndHourChange}
                  >
                    {/* {endHours.map(hour => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))} */}
                    {availableEndHours.map(hour => (
                      <option key={hour} value={hour}>
                        {hour}
                      </option>
                    ))}
                  </SelectStyle>
                </LabelStyle>
              </div>
              <CategoryTitle className="hours select-box">시</CategoryTitle>
            </AfterModifyTimeBox>

            <AfterModifyTimeBox>
              <div>
                <LabelStyle className="end-time">
                  {/* End Minute: */}
                  <SelectStyle
                    value={selectedEndMinutes}
                    onChange={handleEndMinuteChange}
                  >
                    {/* {endMinutes.map(minute => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))} */}
                    {availableEndMinutes.map(minute => (
                      <option key={minute} value={minute}>
                        {minute}
                      </option>
                    ))}
                  </SelectStyle>
                </LabelStyle>
              </div>
              <CategoryTitle className="select-box">분</CategoryTitle>
            </AfterModifyTimeBox>
          </TimeBoxList>

          <NextDayCheckBox>
            <input
              type="checkbox"
              id={'check-invalid-contract'}
              style={{ margin: '0' }}
              checked={isNextDay}
              onChange={() => switchValidContract()}
            />
            <label
              htmlFor={'check-invalid-contract'}
              style={{ marginLeft: '3px', fontSize: '13px' }}
            >
              익일
            </label>
          </NextDayCheckBox>
        </Section>
      ) : (
        ''
      )}

      <ResultButtonBox>
        <ButtonStyle
          onClick={() =>
            onModifyData({
              closeModal,
              setIsUpdateStatusBar,
              detailWorkData,
              employeeNum,
              targetWorkStatusIdx,
              selectedStartHours: selectedStartHours.toString(),
              selectedStartMinutes: selectedStartMinutes.toString(),
              selectedEndHours: selectedEndHours.toString(),
              selectedEndMinutes: selectedEndMinutes.toString(),
              isNextDay,
            })
          }
        >
          수정
        </ButtonStyle>
        <ButtonStyle className="close" onClick={closeModal}>
          닫기
        </ButtonStyle>
      </ResultButtonBox>
    </Wrap>
  );
};

const Wrap = styled.div`
  padding: 15px;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};

  &.first-section {
    border-top: none;
    border-bottom: none;
  }

  &.last-section {
    border-top: none;
    border-bottom: none;
  }

  &.after-modify-start-time {
    border-top: none;
  }

  &.after-modify-start-time.none-end-time {
    /* border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em; */
  }

  &.after-modify-end-time {
    border-top: none;
  }
`;

const ClassificationTitleBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 0;
  background-color: ${({ theme }) => theme.backgroundColor.gray};
`;

const CategoryTitleBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  width: 80px;
  height: 40px;
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray};
`;

const CategoryTitle = styled.div`
  color: ${({ theme }) => theme.fontColor.gray};
  font-size: 13px;
  font-weight: 500;

  &.hours.select-box {
    margin-left: 5px;
    margin-right: 10px;
  }

  &.select-box {
    margin-left: 5px;
  }

  &.select-box.none-end-time {
    margin-right: 5px;
  }
`;

const StatusNameBox = styled.div`
  margin-left: 5px;
  font-size: 13px;
`;

const DateBox = styled.div`
  display: flex;
  font-size: 13px;

  p {
    &.year {
      margin-right: 5px;
    }

    &.month {
      margin-right: 5px;
    }
  }
`;

const TimeBox = styled.div`
  margin-left: 5px;
  font-size: 13px;
`;

const NextDayCheckBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 10px;
`;

const LabelStyle = styled.label`
  display: block;
  font-weight: bold;
`;

const SelectStyle = styled.select`
  margin: 0;
  padding: 0;
  width: 150px;
  height: 30px;
  border-radius: 0.5em;
  border: 1px solid ${({ theme }) => theme.backgroundColor.shadowGray};
  font-size: 13px;
`;

const TimeBoxList = styled.div`
  display: flex;
  margin-left: 5px;
`;

const AfterModifyTimeBox = styled.div`
  display: flex;
  align-items: center;
`;

const ResultButtonBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.printBtnStyle};
  width: 80px;

  &.close {
    margin-left: 10px;
  }
`;
