// [MEMO] 주간 일정 추가를 위한 모달 컴포넌트
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { onAddData } from '../utils/onAddData';

import { onLoadStatusCode } from '../../utils/onLoadStatusCode'; // 근무 코드(W1000, H1000 등) 🔰

interface AddContentsProps {
  closeModal: () => void;
  setIsUpdateStatus: Dispatch<SetStateAction<boolean>>;
  detailWorkData: any[];
  cellData: any;
  employeeNum: string;
  targetWorkStatusIdx: string | null;
  calYear: string;
  calMonth: string;
}

export const AddContents = ({
  closeModal,
  setIsUpdateStatus,
  detailWorkData,
  cellData,
  employeeNum,
  targetWorkStatusIdx,
  calYear,
  calMonth,
}: AddContentsProps) => {
  console.log('🥰3🥰주간AddContents/detailWorkData: ', detailWorkData);
  console.log(
    '🥰4🥰주간AddContents/cellData.isNextMonth: ',
    cellData.isNextMonth
  );
  console.log(
    '🥰5🥰주간AddContents/cellData.monthOfSunday: ',
    cellData.monthOfSunday
  );

  // [TAG] 👇 (화면에 보이는) 시간 설정 -----start
  useEffect(() => {
    setSelectedStartHours(onSelectedStartHours());
    setSelectedStartMinutes(onSelectedStartMinutes());
    setSelectedEndHours(onSelectedEndHours());
    setSelectedEndMinutes(onSelectedEndMinutes());

    onLoadStatusCode({ setScheduleStatusCodeList }); // 🔰
  }, []);

  function onSelectedStartHours(): number {
    return selectedStartHours;
  }

  function onSelectedStartMinutes(): number {
    return selectedStartMinutes;
  }

  function onSelectedEndHours(): number {
    return selectedEndHours;
  }

  function onSelectedEndMinutes(): number {
    return selectedEndMinutes;
  }
  // [TAG] 👆 (화면에 보이는) 시간 설정 -----end

  // [MEMO] 👇 내일 날짜 표기
  const currentDateToString =
    cellData.date.toString().length === 1
      ? '0' + cellData.date
      : cellData.date.toString();

  // const targetDate = new Date(detailWorkData[0].TriggerDate);
  // const tomorrowDateObject = new Date(
  //   targetDate.getFullYear(),
  //   targetDate.getMonth(),
  //   targetDate.getDate() + 2
  // );
  // const tomorrowDate = tomorrowDateObject.toISOString().substring(0, 10);

  // [MEMO] 👇 다음 날 퇴근인지 체크 true면 다음 날까지 근무, false면 당일 근무
  // const [isNextDay, setIsNextDay] = useState(
  //   detailWorkData[0].EndTimeDay === true ? true : false
  // );
  const [isNextDay, setIsNextDay] = useState(false);

  function switchValidContract() {
    setIsNextDay(false);

    if (isNextDay === false) {
      setIsNextDay(true);
    }
  }

  // [TAG] 시작 시간
  const [selectedStartHours, setSelectedStartHours] = useState(0);
  const [selectedStartMinutes, setSelectedStartMinutes] = useState(0);

  const startHours = Array.from({ length: 24 }, (_, index) => index); // [MEMO] 0부터 23까지 숫자 배열 생성
  const startMinutes = Array.from({ length: 60 }, (_, index) => index); // [MEMO] 0부터 59까지 숫자 배열 생성

  const handleStartHourChange = (event: { target: { value: string } }) => {
    setSelectedStartHours(parseInt(event.target.value));
  };

  const handleStartMinuteChange = (event: { target: { value: string } }) => {
    setSelectedStartMinutes(parseInt(event.target.value));
  };

  // [TAG] 종료 시간
  const [selectedEndHours, setSelectedEndHours] = useState(0);
  const [selectedEndMinutes, setSelectedEndMinutes] = useState(0);

  const endHours = Array.from({ length: 24 }, (_, index) => index); // [MEMO] 0부터 23까지 숫자 배열 생성
  const endMinutes = Array.from({ length: 60 }, (_, index) => index); // [MEMO] 0부터 59까지 숫자 배열 생성

  const handleEndHourChange = (event: { target: { value: string } }) => {
    setSelectedEndHours(parseInt(event.target.value));
  };

  const handleEndMinuteChange = (event: { target: { value: string } }) => {
    setSelectedEndMinutes(parseInt(event.target.value));
  };

  // [MEMO] 👇 내일 날짜인지 여부에 따라, '변경 후'에 있는 드롭박스 배열(시간, 분) 조절하기
  let availableEndHours: number[] = endHours;

  if (!isNextDay) {
    availableEndHours = endHours.slice(selectedStartHours);
  } else if (isNextDay) {
    availableEndHours = endHours;
  }

  let availableEndMinutes: number[] = endMinutes;

  if (!isNextDay && selectedStartHours < selectedEndHours) {
    availableEndMinutes = endMinutes;
  } else if (!isNextDay) {
    availableEndMinutes = endMinutes.slice(selectedStartMinutes);
  } else if (isNextDay) {
    availableEndMinutes = endMinutes;
  }

  console.log('✋selectedStartHours: ', selectedStartHours);
  console.log('✋selectedStartMinutes: ', selectedStartMinutes);
  console.log('✋selectedEndHours: ', selectedEndHours);
  console.log('✋selectedEndMinutes: ', selectedEndMinutes);
  console.log('^^^^^✋currentDateToString✋^^^^^: ', currentDateToString);

  // ----- ----- -----

  const [scheduleStatusCodeList, setScheduleStatusCodeList] = useState([]);
  const [selectedStatusCode, setSelectedStatusCode] = useState('');
  const [dayOffType, setDayOffType] = useState('');
  const [selectedStatusType, setSelectedStatusType] = useState('');

  const [selectedStatusIdx, setSelectedStatusIdx] = useState('');

  console.log('🍞scheduleStatusCodeList🍞: ', scheduleStatusCodeList);

  const onChangeStatusValue = e => {
    console.log('🍞🍞🍞 e: ', e);
    console.log('🍞🍞🍞 e.target.value: ', e.target.value);
    setSelectedStatusCode(e.target.value);
    // setSelectedStatusIdx(e.target.value);
    if (e.target.value === 'W1000') {
      setSelectedStatusType('D');
    }
  };

  useEffect(() => {
    setSelectedStatusCode('W1000');
    // setSelectedStatusIdx(cellData.statusInfo[0].statusIdx);
  }, []);

  console.log('🍞selectedStatusCode: ', selectedStatusCode);

  const [isHalfDayOff, setIsHalfDayOff] = useState(false);
  const [isAllDayOff, setIsAllDayOff] = useState(false);

  function switchHalfDayOff() {
    setIsHalfDayOff(false);
    setIsAllDayOff(true);
    setSelectedStatusType('A');

    if (isHalfDayOff === false) {
      setIsHalfDayOff(true);
      setIsAllDayOff(false);
      setSelectedStatusType('H');
    }
  }

  function switchAllDayOff() {
    setIsAllDayOff(false);
    setIsHalfDayOff(true);
    setSelectedStatusType('H');

    if (isAllDayOff === false) {
      setIsAllDayOff(true);
      setIsHalfDayOff(false);
      setSelectedStatusType('A');
    }
  }

  // -----

  const filteredScheduleStatusCodeList = scheduleStatusCodeList.filter(item => {
    const reg = /W1000|H/;
    if (reg.test(item.AttendStatusCode)) {
      return item;
    }
  });

  const statusCodeList =
    cellData.statusInfo && cellData.statusInfo.map(d => d.statusCode);

  return (
    <Wrap>
      <div style={{ marginBottom: '5px' }}>
        <label>
          <select
            onChange={onChangeStatusValue}
            style={{
              width: '140px',
            }}
          >
            <option key="default" value="">
              유형을 선택해주세요
            </option>
            {filteredScheduleStatusCodeList.map(item => {
              // [MEMO] currentDate: 오늘 날짜('일')
              const current = new Date();
              const currentDate = current.getDate();

              if (cellData.statusInfo) {
                if (!statusCodeList.includes(item.AttendStatusCode)) {
                  if (
                    cellData.date > currentDate &&
                    item.AttendStatusCode !== 'W1000'
                  ) {
                    return (
                      <option
                        key={item.AttendStatusCode}
                        value={item.AttendStatusCode}
                      >
                        {item.AttendStatusName}
                      </option>
                    );
                  } else if (cellData.date <= currentDate) {
                    return (
                      <option
                        key={item.AttendStatusCode}
                        value={item.AttendStatusCode}
                      >
                        {item.AttendStatusName}
                      </option>
                    );
                  }
                }
              } else {
                if (
                  cellData.date > currentDate &&
                  item.AttendStatusCode !== 'W1000'
                ) {
                  return (
                    <option
                      key={item.AttendStatusCode}
                      value={item.AttendStatusCode}
                    >
                      {item.AttendStatusName}
                    </option>
                  );
                } else if (cellData.date <= currentDate) {
                  return (
                    <option
                      key={item.AttendStatusCode}
                      value={item.AttendStatusCode}
                    >
                      {item.AttendStatusName}
                    </option>
                  );
                }
              }
            })}
          </select>
        </label>
      </div>

      <ClassificationTitleBox>
        <CategoryTitle>상세 설정</CategoryTitle>
      </ClassificationTitleBox>

      {/* <Section className="first-section">
        <CategoryTitleBox>
          <CategoryTitle>상태</CategoryTitle>
        </CategoryTitleBox>
        <StatusNameBox>{detailWorkData[0].AttendStatusName}</StatusNameBox>
      </Section> */}

      {selectedStatusCode &&
      selectedStatusCode !== 'H1000' &&
      selectedStatusCode !== 'H2000' &&
      selectedStatusCode !== 'H3000' ? (
        <>
          <Section className={'after-modify-start-time none-end-time'}>
            <CategoryTitleBox>
              <CategoryTitle>일정 시작</CategoryTitle>
            </CategoryTitleBox>

            <TimeBoxList className="start-time">
              <AfterModifyTimeBox>
                <div>
                  <LabelStyle className="hours start-time">
                    <SelectStyle
                      value={selectedStartHours}
                      onChange={handleStartHourChange}
                    >
                      {startHours.map(hour => (
                        <option key={hour} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </SelectStyle>
                  </LabelStyle>
                </div>
                <CategoryTitle className="hours select-box">시</CategoryTitle>
              </AfterModifyTimeBox>

              <AfterModifyTimeBox>
                <div>
                  <LabelStyle className="start-time">
                    <SelectStyle
                      value={selectedStartMinutes}
                      onChange={handleStartMinuteChange}
                    >
                      {startMinutes.map(minute => (
                        <option key={minute} value={minute}>
                          {minute}
                        </option>
                      ))}
                    </SelectStyle>
                  </LabelStyle>
                </div>
                <CategoryTitle className={'select-box none-end-time'}>
                  분
                </CategoryTitle>
              </AfterModifyTimeBox>
            </TimeBoxList>
          </Section>

          <Section className="after-modify-end-time">
            <CategoryTitleBox>
              <CategoryTitle>근무 종료</CategoryTitle>
            </CategoryTitleBox>

            <TimeBoxList className="end-time">
              <AfterModifyTimeBox>
                <div>
                  <LabelStyle className="hours end-time">
                    <SelectStyle
                      value={selectedEndHours}
                      onChange={handleEndHourChange}
                    >
                      {availableEndHours.map(hour => (
                        <option key={hour} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </SelectStyle>
                  </LabelStyle>
                </div>
                <CategoryTitle className="hours select-box">시</CategoryTitle>
              </AfterModifyTimeBox>

              <AfterModifyTimeBox>
                <div>
                  <LabelStyle className="end-time">
                    <SelectStyle
                      value={selectedEndMinutes}
                      onChange={handleEndMinuteChange}
                    >
                      {availableEndMinutes.map(minute => (
                        <option key={minute} value={minute}>
                          {minute}
                        </option>
                      ))}
                    </SelectStyle>
                  </LabelStyle>
                </div>
                <CategoryTitle className="select-box">분</CategoryTitle>
              </AfterModifyTimeBox>
            </TimeBoxList>

            <NextDayCheckBox>
              <input
                type="checkbox"
                id={'check-invalid-contract'}
                style={{ margin: '0' }}
                checked={isNextDay}
                onChange={() => switchValidContract()}
              />
              <label
                htmlFor={'check-invalid-contract'}
                style={{ marginLeft: '3px', fontSize: '13px' }}
              >
                익일
              </label>
            </NextDayCheckBox>
          </Section>
        </>
      ) : (
        <Section style={{ width: '480px', height: '80px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                marginLeft: '5px',
                marginBottom: '5px',
                fontSize: '13px',
                fontWeight: '600',
              }}
            >
              {calYear}년 {calMonth}월 {cellData.date}일
            </div>
            <div
              style={{
                display: 'flex',
                marginLeft: '5px',
              }}
            >
              <div>
                <input
                  type="checkbox"
                  id={'check-half-day-off'}
                  style={{ margin: '0' }}
                  checked={isHalfDayOff}
                  onChange={() => switchHalfDayOff()}
                />
                <label
                  htmlFor={'check-half-day-off'}
                  style={{ marginLeft: '3px', fontSize: '13px' }}
                >
                  반차
                </label>
              </div>
              <div style={{ marginLeft: '15px' }}>
                <input
                  type="checkbox"
                  id={'check-all-day-off'}
                  style={{ margin: '0' }}
                  checked={isAllDayOff}
                  onChange={() => switchAllDayOff()}
                />
                <label
                  htmlFor={'check-all-day-off'}
                  style={{ marginLeft: '3px', fontSize: '13px' }}
                >
                  종일
                </label>
              </div>
            </div>
          </div>
        </Section>
      )}

      <ResultButtonBox>
        <ButtonStyle
          onClick={() =>
            onAddData({
              closeModal,
              setIsUpdateStatus,
              cellData,
              employeeNum,
              targetWorkStatusIdx,
              selectedStartHours: selectedStartHours.toString(),
              selectedStartMinutes: selectedStartMinutes.toString(),
              selectedEndHours: selectedEndHours.toString(),
              selectedEndMinutes: selectedEndMinutes.toString(),
              calYear,
              calMonth,
              currentDateToString,
              isNextDay,
              selectedStatusCode, // 🔰
              selectedStatusType, // 🔰
              selectedStatusIdx, // 🔰
            })
          }
        >
          추가
        </ButtonStyle>
        <ButtonStyle className="close" onClick={closeModal}>
          닫기
        </ButtonStyle>
      </ResultButtonBox>
    </Wrap>
  );
};

const Wrap = styled.div`
  padding: 15px;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};

  &.first-section {
    border-top: none;
    /* border-bottom: none; */
  }

  &.last-section {
    border-top: none;
    border-bottom: none;
  }

  &.after-modify-start-time {
    border-top: none;
  }

  &.after-modify-start-time.none-end-time {
    /* border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em; */
  }

  &.after-modify-end-time {
    border-top: none;
  }
`;

const ClassificationTitleBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 0;
  background-color: ${({ theme }) => theme.backgroundColor.gray};
`;

const CategoryTitleBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  width: 80px;
  height: 40px;
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray};
`;

const CategoryTitle = styled.div`
  color: ${({ theme }) => theme.fontColor.gray};
  font-size: 13px;
  font-weight: 500;

  &.hours.select-box {
    margin-left: 5px;
    margin-right: 10px;
  }

  &.select-box {
    margin-left: 5px;
  }

  &.select-box.none-end-time {
    margin-right: 5px;
  }
`;

const StatusNameBox = styled.div`
  margin-left: 5px;
  font-size: 13px;
`;

const DateBox = styled.div`
  display: flex;
  font-size: 13px;

  p {
    &.year {
      margin-right: 5px;
    }

    &.month {
      margin-right: 5px;
    }
  }
`;

const TimeBox = styled.div`
  margin-left: 5px;
  font-size: 13px;
`;

const NextDayCheckBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 10px;
`;

const LabelStyle = styled.label`
  display: block;
  font-weight: bold;
`;

const SelectStyle = styled.select`
  margin: 0;
  padding: 0;
  width: 150px;
  height: 30px;
  border-radius: 0.5em;
  border: 1px solid ${({ theme }) => theme.backgroundColor.shadowGray};
  font-size: 13px;
`;

const TimeBoxList = styled.div`
  display: flex;
  margin-left: 5px;
`;

const AfterModifyTimeBox = styled.div`
  display: flex;
  align-items: center;
`;

const ResultButtonBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.printBtnStyle};
  width: 80px;

  &.close {
    margin-left: 10px;
  }
`;
