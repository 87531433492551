import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Contents } from '../Contents/Contents';

export const Main = () => {
  const navigate = useNavigate();

  const token = sessionStorage.getItem('token');
  const permitCode = sessionStorage.getItem('permitCode');

  /**
   * permitCode 권한 코드
   * 1. process.env.HEAD_OFFICE_MANAGER_CODE = 'AA000' : 본사 관리자 코드
   * 2. process.env.APARTMENT_MANAGER_CODE = 'HM000' : 관리 소장 코드
   * 3. process.env.NORMAL_EMPLOYEE_CODE = 'UU000' : 일반 직원 코드
   */

  const isHeadOfficeManagerCode =
    permitCode === process.env.HEAD_OFFICE_MANAGER_CODE;

  const isApartmentManagerCode =
    permitCode === process.env.APARTMENT_MANAGER_CODE;

  if (!token) {
    alert('로그인부터 해주세요.');
    navigate('/login');
    return null;
  }

  return (
    <>
      <Contents />
    </>
  );
};
