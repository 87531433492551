import React from 'react';
import styled from 'styled-components';

import { CellClickedEvent } from 'ag-grid-community';

interface ModalContentsProps {
  closeModal: () => void;
  imageText: string;
  onDownloadFile: (
    downloadFileIdx: string,
    downloadFileName: string
  ) => Promise<void>;
  downloadFileIdx: string;
  downloadFileName: string;
}

export const ModalContents = ({
  closeModal,
  imageText,
  onDownloadFile,
  downloadFileIdx,
  downloadFileName,
}: ModalContentsProps) => {
  return (
    <Wrap>
      <ButtonList>
        <ButtonStyle
          className="download-button"
          onClick={() => onDownloadFile(downloadFileIdx, downloadFileName)}
        >
          다운로드
        </ButtonStyle>
        <ButtonStyle
          className="close-button"
          style={{ marginLeft: '5px' }}
          onClick={closeModal}
        >
          X
        </ButtonStyle>
      </ButtonList>
      <ImageStyle src={`${imageText}`} />
    </Wrap>
  );
};

const Wrap = styled.div`
  padding: 15px;
`;

const ButtonList = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.printBtnStyle}

  width: 80px;
  cursor: pointer;

  &.download-button {
    border: solid 1px ${({ theme }) => theme.mainColor.dark};
    background-color: ${({ theme }) => theme.mainColor.main};
    color: white;

    :hover {
      background-color: ${({ theme }) => theme.mainColor.dark};
    }
  }

  &.close-button {
    margin-left: 10px;
  }
`;

const ImageStyle = styled.img`
  width: auto;
  height: 500px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;
