import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

interface LogSectionProps {
  logListData: any;
  logDataLoading: boolean;
}

export const LogSection = ({
  logListData,
  logDataLoading,
}: LogSectionProps) => {
  if (logDataLoading) {
    return (
      <>
        <Bar />
        <LoadingWrap>
          <LoadingGIF src="/loading.gif" />
        </LoadingWrap>
      </>
    );
  }

  // [TAG] 👇 아예 태그가 존재하지 않는 데이터의 경우 -----start
  if (logListData.Data) {
    if (logListData.Data.Data.length === 0) {
      return <Bar />;
    }
  }
  // [TAG] 👆 아예 태그가 존재하지 않는 데이터의 경우 -----end

  console.log('[AR10-030]LogSection - logListData: ', logListData);

  let shouldApplyStyle;
  if (logListData.Data) {
    /**
     * const shouldApplyStyle = logListData != null;:
     * logListData가 존재하는지 여부에 따라 스타일을 적용할지 결정하기 위한 변수(true, false 반환을 통해서)
     * logListData != null 의미: =logListData가 null이 아닌 경우에 따라 true나 false를 반환
     * logListData와 null의 일치 여부를 따져서 logListData가 null이 아니라면(!= 비교연산자를 사용했으므로) true를 반환,
     * logListData가 null이 라면 false를 반환한다, 라는 의미이다.
     * 다음과 동일하다: const shouldApplyStyle = logListData ? true : false;
     */
    shouldApplyStyle = logListData.Data.Data[0].Data.length > 0;
  }

  function yAxisComponents() {
    if (logListData.Data) {
      // console.log('[AR10-030]LogSection - logListData.Data: ', logListData.Data);
      // console.log('[AR10-030]LogSection - logListData.Data.Data: ', logListData.Data.Data);

      return logListData.Data.Data.map((data: { TrackingLocation: string }) => {
        return (
          <>
            <div>{data.TrackingLocation}</div>
          </>
        );
      });
    } else return;
  }

  function xAxisComponents() {
    if (logListData.Data) {
      return logListData.Data.Data.map(
        (data: {
          Data: {
            EmployeeName: string;
            AttacheFile: string;
            TrigerTime: string;
          }[];
        }) => {
          return data.Data.map(
            (item: {
              EmployeeName: string;
              AttacheFile: string;
              TrigerTime: string;
            }) => {
              return (
                <>
                  <div>
                    <div className="employee-name">
                      <p>{item.EmployeeName}</p>
                      <p>
                        {item.AttacheFile.length > 0 ? (
                          <InsertDriveFileIcon
                            sx={{
                              color: 'black',
                              fontSize: '12px',
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </p>
                    </div>
                    <div className="info-box">
                      <p>{item.TrigerTime.slice(0, 10)}</p>
                      <p>{item.TrigerTime.slice(11, 19)}</p>
                    </div>
                  </div>
                </>
              );
            }
          );
        }
      );
    } else return;
  }

  let maxXArrayLength = '';
  let emptyXRows: string[] = [];

  if (logListData.Data) {
    maxXArrayLength = logListData.Data.MaxRecordCount;
    emptyXRows = new Array(maxXArrayLength).fill('');
  }

  // const [showNoDataMessage, setShowNoDataMessage] = useState(false); // 상태 추가

  // 데이터 길이를 체크하고 경고 메시지를 띄우는 함수
  // const checkAndShowNoDataMessage = () => {
  //   if (logListData.Data && logListData.Data.Data[0].Data.length === 0) {
  //     setShowNoDataMessage(true);
  //     // console.log('🍒1:', showNoDataMessage);
  //   } else {
  //     setShowNoDataMessage(false);
  //     // console.log('🍒2:', showNoDataMessage);
  //   }
  // };

  // useEffect(() => {
  //   checkAndShowNoDataMessage(); // 컴포넌트가 렌더링 될 때마다 함수 실행
  //   // console.log('🍒useEffect:', showNoDataMessage);
  // }, [logListData]); // logListData가 변경될 때마다 실행

  // useEffect(() => {
  //   if (showNoDataMessage) {
  //     alert('데이터 없음');
  //   }
  // }, [showNoDataMessage]);

  // console.log('🍒바깥:', showNoDataMessage);

  return (
    <>
      <Bar />

      {/* {showNoDataMessage && <div>데이터가 없습니다</div>} */}

      <TableWrap className={`table-wrap-${shouldApplyStyle ? 'style' : ''}`}>
        {logListData.Data && (
          <Tables>
            <THead>
              <TrForHeader>
                {/* <ThLocationOrderHeader>순서</ThLocationOrderHeader> */}
                <ThLocationNameHeader>{/* 근무구역 */}</ThLocationNameHeader>
                <ThTrackingTypeHeader>{/* 기기유형 */}</ThTrackingTypeHeader>
                {emptyXRows.map((col: string, colIndex: number) => (
                  <ThLogNumberHeader key={col}>
                    {colIndex + 1}
                  </ThLogNumberHeader>
                ))}
              </TrForHeader>
            </THead>
            <TBody>
              {yAxisComponents().map((col: string, colIndex: number) => {
                // const isLastCol = colIndex === yAxisComponents().length - 1;

                const maxXAxisLength = Math.max(
                  ...xAxisComponents().map((arr: string) => arr.length)
                );
                /**
                 * maxXAxisLength:
                 * 서버로부터 x축 최대 길이를 받지 못할 경우 사용하는 변수
                 * (만약 logListData.Data.MaxRecordCount이 없을 경우)
                 */
                const maxXLength = logListData.Data.MaxRecordCount;
                const xData = xAxisComponents()[colIndex];
                const emptyRows = new Array(maxXLength - xData.length).fill(
                  null
                );

                function onProcessedTrackingType() {
                  return logListData.Data.Data.map(
                    (data: { TrackingType: string }, idx: number) => {
                      if (data.TrackingType === 'N') {
                        return (
                          <TdTrackingType key={idx}>
                            <div>
                              <p>NFC</p>
                            </div>
                          </TdTrackingType>
                        );
                      } else if (data.TrackingType === 'B') {
                        return (
                          <TdTrackingType key={idx}>
                            <div>
                              <p>Beacon</p>
                            </div>
                          </TdTrackingType>
                        );
                      } else if (data.TrackingType === 'G') {
                        return (
                          <TdTrackingType key={idx}>
                            <div>
                              <p>GPS</p>
                            </div>
                          </TdTrackingType>
                        );
                      } else if (data.TrackingType === 'W') {
                        return (
                          <TdTrackingType key={idx}>
                            <div>
                              <p>WIFI</p>
                            </div>
                          </TdTrackingType>
                        );
                      } else {
                        return <TdTrackingType key={idx}></TdTrackingType>;
                      }
                    }
                  );
                }

                return (
                  <tr
                    key={colIndex}
                    // style={
                    //   colIndex === yAxisComponents().length - 1
                    //     ? { borderBottom: 'none' }
                    //     : {}
                    // }
                    // style={isLastCol ? { borderBottom: 'none' } : {}}
                  >
                    {/* <TdLocationOrder>{colIndex + 1}</TdLocationOrder> */}
                    <TdLocationName>{col}</TdLocationName>
                    {onProcessedTrackingType()[colIndex]}

                    {xData.map((row: string, rowIndex: number) => (
                      <TdLogDataList
                        key={rowIndex}
                        // style={
                        //   rowIndex === xData.length - 1
                        //     ? { background: 'blue' }
                        //     : {}
                        // }
                      >
                        {row}
                      </TdLogDataList>
                    ))}
                    {emptyRows.map((_, emptyRowIndex) => (
                      <TdLogDataList
                        key={xData.length + emptyRowIndex}
                        // style={
                        //   xData.length + emptyRowIndex === maxXLength - 1
                        //     ? { background: 'red' }
                        //     : {}
                        // }
                      />
                    ))}
                  </tr>
                );
              })}
            </TBody>
          </Tables>
        )}
      </TableWrap>
    </>
  );
};

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

const LoadingGIF = styled.img`
  ${({ theme }) => theme.loadingGIF}
`;

const Bar = styled.div`
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const TableWrap = styled.div`
  ${({ theme }) => theme.stopDrag};
  ${({ theme }) => theme.scrollBarStyle};

  /* width: 100%; */ /* 이 부분 주석처리를 통해, 셀 width 값을 고정시킴 */
  overflow-x: auto;

  background-color: white;
`;

const Tables = styled.table`
  background-color: white;
  /* width: 100%; */ /* 이 부분 주석처리를 통해, 셀 width 값을 고정시킴 */
  table-layout: fixed;
  font-size: 13px;
`;

const THead = styled.thead`
  background-color: white;
  height: 60px;
  font-weight: 500;

  /*  */
  /*  */
  /*  */
  position: sticky;
`;

const TBody = styled.tbody`
  /*  */
`;

const TrForHeader = styled.tr`
  /*  */
`;

export const ThLocationOrderHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 80px; /* [MEMO] 순서 너비 */
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  color: ${({ theme }) => theme.fontColor.darkGray};
`;

export const ThLocationNameHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 170px; /* [MEMO] 근무구역 너비 */
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray}; /* 2번 스타일링(가운데 정렬) */
  color: ${({ theme }) => theme.fontColor.darkGray};
`;

export const ThTrackingTypeHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 80px; /* [MEMO] 기기유형 너비 */
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};
`;

const ThLogNumberHeader = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  width: 120px; /* [MEMO] 날짜 너비 */

  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-left: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  color: ${({ theme }) => theme.fontColor.lightGray};
`;

const TdLocationOrder = styled.td`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  /* text-align: center; */ /* 테이블 셀 중앙정렬 */
  padding-left: 30px;
  height: 70px;
  text-align: start;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  color: ${({ theme }) => theme.fontColor.darkGray};
  font-weight: 500;
`;

const TdLocationName = styled.td`
  display: table-cell;
  vertical-align: middle;
  padding-left: 30px;
  height: 70px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray}; /* 2번 스타일링(가운데 정렬) */
  color: ${({ theme }) => theme.fontColor.darkGray};
  font-weight: 500;

  div {
    display: flex;
    justify-content: center; /* 2번 스타일링(가운데 정렬) */
    /* justify-content: start; */ /* 1번 스타일링(왼쪽 정렬) */
    align-items: center;
    width: 170px; /* 부모 요소에 width: 100%; 없음 + 여기에 width부분에 값을 적용해, 셀 width 값을 고정시킴 */

    p {
      padding: 4px 6px;
      border-radius: 0.5em;
      background-color: ${({ theme }) => theme.statusColor.working};
    }
  }
`;

const TdTrackingType = styled.td`
  display: table-cell;
  vertical-align: middle;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  padding: 0 5px;

  div {
    display: flex;
    justify-content: center; /* 2번 스타일링(가운데 정렬) */
    /* justify-content: start; */ /* 1번 스타일링(왼쪽 정렬) */
    align-items: center;
    width: 120px; /* 부모 요소에 width: 100%; 없음 + 여기에 width부분에 값을 적용해, 셀 width 값을 고정시킴 */

    p {
      padding: 4px 6px;
      border-radius: 0.5em;
      background-color: ${({ theme }) => theme.statusColor.working};
      color: white;
      font-size: 12px;
      font-weight: 600;
    }
  }
`;

export const TdLogDataList = styled.td`
  padding: 5px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  /* border-right: solid 1px ${({ theme }) => theme.backgroundColor.gray}; */
  border-left: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  font-size: 12px;
  line-height: 1.5;

  div {
    border-radius: 3px;
    font-weight: 500;
    width: 120px; /* 부모 요소에 width: 100%; 없음 + 여기에 width부분에 값을 적용해, 셀 width 값을 고정시킴 */

    &.employee-name {
      display: flex;
      justify-content: center; /* 2번 스타일링(가운데 정렬) */
      align-items: center;
      margin-bottom: 5px;
      background: ${({ theme }) => theme.statusColor.startWorkBackground};
      color: ${({ theme }) => theme.fontColor.darkGray};

      p {
        /* margin-left: 5px; */ /* 1번 스타일링(왼쪽 정렬) */
        font-weight: 600;
      }
    }

    &.info-box {
      display: flex;
      flex-direction: column;
      text-align: end; /* 2번 스타일링(오른쪽 정렬) */
      background: ${({ theme }) => theme.statusColor.grayBackground};
      color: ${({ theme }) => theme.fontColor.darkGray};

      p {
        /* margin-left: 5px; */ /* 1번 스타일링(왼쪽 정렬) */
        margin-right: 5px; /* 2번 스타일링(오른쪽 정렬) */
      }
    }
  }
`;
