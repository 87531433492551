import styled from 'styled-components';

export const DoubleLineButtonStyle = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 5px;
  /* width: 160px; */

  /* border: 3px solid #57d49e; */
  border-radius: 0.5em;
  background-color: white;
  color: ${({ theme }) => theme.fontColor.gray};
  font-size: 13px;
  font-weight: 500;
  z-index: 10;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.backgroundColor.gray};
  }

  &::before {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    /* border: 1px solid #57d49e; */
    border-radius: 5px;
  }

  &.upload-local-data-button {
    width: 160px;
    border: 3px solid #57d49e;
    /* box-sizing: border-box; */

    &::before {
      border: 1px solid #57d49e;
    }
  }

  &.upload-local-data-button.disabled {
    &:hover {
      /* cursor: not-allowed; */
      cursor: inherit;
      background-color: white;
    }
  }

  &.export-local-data {
    width: 160px;
    border: 3px solid #ffc936;

    &::before {
      border: 1px solid #ffc936;
    }
  }

  .guide-modal {
    position: absolute;
    display: none;
    margin-bottom: 5px;
    text-align: left;
    width: 260px;
    padding: 10px;
    bottom: 100%;
    border-radius: 0.5em;
    /* box-shadow: 5px 5px 10px -3px ${({ theme }) =>
      theme.backgroundColor.shadowGray}; */
    box-shadow: 0px 5px 10px -3px ${({ theme }) => theme.backgroundColor.shadowGray};
    /* MEMO: 좌우 위아래 블러 (크기) 색상 */
    background-color: white;
    color: ${({ theme }) => theme.fontColor.gray};
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    z-index: 100;

    :after {
      content: '';
      position: absolute;
      bottom: -1em;
      left: 50%;
      width: 0px;
      height: 0px;
      border-top: 10px solid white;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      transform: translate(-50%, -50%);
    }
  }

  .bold {
    color: #57d49e;
    font-weight: 600;
  }

  .warning-disabled {
    color: #ff6038;
    font-weight: 600;
  }

  &:hover {
    .guide-modal {
      display: block;
    }
  }
`;

export const LoadLocalLabelButtonStyle = styled.label`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 5px;
  width: 160px;

  border: 3px solid #57d49e;
  border-radius: 0.5em;
  background-color: white;
  color: ${({ theme }) => theme.fontColor.gray};
  font-size: 13px;
  font-weight: 500;
  z-index: 10;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.backgroundColor.gray};
  }

  &::before {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border: 1px solid #57d49e;
    border-radius: 5px;
  }

  .guide-modal {
    position: absolute;
    display: none;
    margin-bottom: 5px;
    text-align: left;
    width: 260px;
    padding: 10px;
    bottom: 100%;
    border-radius: 0.5em;
    box-shadow: 0px 5px 10px -3px ${({ theme }) => theme.backgroundColor.shadowGray};
    /* MEMO: 좌우 위아래 블러 (크기) 색상 */
    background-color: white;
    color: ${({ theme }) => theme.fontColor.gray};
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    z-index: 100;

    :after {
      content: '';
      position: absolute;
      bottom: -1em;
      left: 50%;
      width: 0px;
      height: 0px;
      border-top: 10px solid white;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      transform: translate(-50%, -50%);
    }
  }

  .bold {
    color: #57d49e;
    font-weight: 600;
  }

  &:hover .guide-modal {
    display: block;
  }
`;

export const InnerLineButtonStyle = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 5px;
  /* width: 160px; */

  /* border: 3px solid #ededed; */
  border: 3px solid #c1c1c1;
  border-radius: 0.5em;
  background-color: white;
  color: ${({ theme }) => theme.fontColor.gray};
  font-size: 13px;
  font-weight: 500;
  z-index: 10;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.backgroundColor.gray};
  }

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    border: 3px solid #ededed;
    border-radius: 5px;
  }

  .guide-modal {
    position: absolute;
    display: none;
    margin-bottom: 5px;
    text-align: left;
    width: 260px;
    padding: 10px;
    bottom: 100%;
    border-radius: 0.5em;
    /* box-shadow: 5px 5px 10px -3px ${({ theme }) =>
      theme.backgroundColor.shadowGray}; */
    box-shadow: 0px 5px 10px -3px ${({ theme }) => theme.backgroundColor.shadowGray};
    /* MEMO: 좌우 위아래 블러 (크기) 색상 */
    /* background-color: ${({ theme }) => theme.backgroundColor.darkGray}; */
    background-color: white;
    color: ${({ theme }) => theme.fontColor.gray};
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    z-index: 100;

    :after {
      content: '';
      position: absolute;
      bottom: -1em;
      left: 50%;
      width: 0px;
      height: 0px;
      border-top: 10px solid white;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      transform: translate(-50%, -50%);
    }
  }

  .bold {
    color: #57d49e;
    font-weight: 600;
  }

  .warning-disabled {
    color: #ff6038;
    font-weight: 600;
  }

  &:hover {
    .guide-modal {
      display: block;
    }
  }
`;
