import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface onWorkStatusLoadBtnWeeklyProps {
  // setEmployeeData: Dispatch<SetStateAction<any[]>>;
  setEmployeeDataWeekly: Dispatch<SetStateAction<any[]>>;
  apartmentContractIdx: string; // 아파트 계약 인덱스
  calYear: string;
  calMonth: string;
  calDate: string;
  setWeeklySchedulerLoading: Dispatch<SetStateAction<boolean>>;
}

export const onWorkStatusLoadBtnWeekly = async ({
  // setEmployeeData,
  setEmployeeDataWeekly,
  apartmentContractIdx, // 아파트 계약 인덱스
  calYear,
  calMonth,
  calDate,
  setWeeklySchedulerLoading,
}: onWorkStatusLoadBtnWeeklyProps) => {
  const token = sessionStorage.getItem('token');
  const contractIdx = sessionStorage.getItem('contractIdx'); // 아파트 계약 인덱스

  if (!apartmentContractIdx) {
    return alert('단지를 선택해주세요');
  }

  setWeeklySchedulerLoading(true);

  await axios
    .get(
      // `/sample/AC10-030-weekly-sample.json` // ✅ 목데이터 (2023-03-01, idx: 14)
      `${
        process.env.COMMON_URL
      }/attendance/ac10030/attendancestatus/${apartmentContractIdx}?Year=${calYear}&Month=${calMonth}&Day=${calDate}&Week=${1}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   'onWorkStatusLoadBtnDaily(주간 근무현황 API확인): ',
      //   `${
      //     process.env.COMMON_URL
      //   }/attendance/ac10030/attendancestatus/${apartmentContractIdx}?Year=${calYear}&Month=${calMonth}&Day=${calDate}&Week=${1}`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          setWeeklySchedulerLoading(false);
          setEmployeeDataWeekly(res.data.Data);
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
