import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { loadData } from '../utils/loadData';

import { Grid } from '../Grid/Grid';

import { onApartmentContractInfo } from '../utils/onApartmentContractInfo';
import { Modal } from '../../apartmentContractInfoSelectModal/Modal';

import { ko } from 'date-fns/esm/locale';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import './DatePicker.css';

export const Contents = () => {
  const permitCode = sessionStorage.getItem('permitCode');
  const contractIdx = sessionStorage.getItem('contractIdx'); // 관리소장 로그인시 아파트 계약 인덱스
  const headOfficerManagerCode = process.env.HEAD_OFFICE_MANAGER_CODE; // 권한에 따른 UI 변경 관련
  const apartmentManagerCode = process.env.APARTMENT_MANAGER_CODE; // 권한에 따른 UI 변경 관련

  const [dataList, setDataList] = useState([]);

  const [startDate, setStartDate] = useState(new Date()); // [MEMO] Date Picker 라이브러리
  const [endDate, setEndDate] = useState(new Date()); // [MEMO] Date Picker 라이브러리

  // 👇 데이터 피커에서 선택한 날짜 (시작일)
  const pickStartYear = startDate.getFullYear().toString();
  const pickStartMonth = (startDate.getMonth() + 1).toString();
  const pickStartDate = startDate.getDate().toString();

  const calStartYear = String(pickStartYear).padStart(2, '0');
  const calStartMonth = String(pickStartMonth).padStart(2, '0');
  const calStartDate = String(pickStartDate).padStart(2, '0');

  // 👇 데이터 피커에서 선택한 날짜 (종료일)
  const pickEndYear = endDate.getFullYear().toString();
  const pickEndMonth = (endDate.getMonth() + 1).toString();
  const pickEndDate = endDate.getDate().toString();

  const calEndYear = String(pickEndYear).padStart(2, '0');
  const calEndMonth = String(pickEndMonth).padStart(2, '0');
  const calEndDate = String(pickEndDate).padStart(2, '0');

  const selectedContractStart = `${calStartYear}${calStartMonth}${calStartDate}`;
  const selectedContractEnd = `${calEndYear}${calEndMonth}${calEndDate}`;

  const [onModal, setOnModal] = useState(false); // MEMO: 아파트 계약 정보 리스트가 있는 모달
  const [apartmentListData, setApartmentListData] = useState<any[]>([]); // MEMO: 아파트 계약 정보 API
  const [apartmentIdx, setApartmentIdx] = useState(''); // 아파트 리스트 인덱스
  const [apartmentName, setApartmentName] = useState('');

  // 👇 [TAG] 권한에 따른 UI 변경 관련
  function onPermitCodeView() {
    if (permitCode === apartmentManagerCode) {
      // 관리소장 권한시: 계약 단지 선택
      useEffect(() => {
        onApartmentContractInfo({
          apartmentListData,
          setApartmentListData,
          setOnModal,
        });
      }, []);
    }

    useEffect(() => {
      // 근무 내역 불러오기
      if (apartmentIdx) {
        onLoadBtn({
          recordListData,
          setRecordListData,
          selectedContractStart,
          selectedContractEnd,
          apartmentIdx,
          setLoading,
        });
      }
    }, [selectedContractStart, selectedContractEnd, apartmentIdx]);
    console.log('apartmentIdx: ', apartmentIdx);
  }
  onPermitCodeView();

  return (
    <>
      <Title>급여 명세서</Title>

      <Wrap>
        {/* <div onClick={() => loadData({ setDataList })}>불러오기</div> */}

        <Grid data={setDataList} />
      </Wrap>
    </>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  /* opacity: 50%; */
`;

const LoadingGIF = styled.img`
  ${({ theme }) => theme.loadingGIF};

  /* opacity: 50%; */
`;

const Bar = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const Title = styled.div`
  ${({ theme }) => theme.contentsMainTitle};
  ${({ theme }) => theme.stopDrag};
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  /* height: 40px;
  font-size: 13px; */
`;

const DatePickerBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;

  p {
    width: 100%;
    margin-right: 5px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const GridSection = styled.div`
  height: ${({ theme }) => theme.gridStyle.height};
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.printBtnStyle}

  cursor: pointer;
`;

const ApartmentNameBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;

  p {
    color: ${({ theme }) => theme.fontColor.darkGray};
  }
`;

const ApartmentIdx = styled.div`
  color: ${({ theme }) => theme.mainColor.main};
`;
