// [MEMO] 근태 이상 현황 조회(출근=근무) UI
import React from 'react';
import styled from 'styled-components';

interface WorkStatusCheckSectionProps {
  toWorkStatusCheckData?: any[];
  toHomeStatusCheckData?: any[];
}

export const WorkStatusCheckSection = ({
  toWorkStatusCheckData,
  toHomeStatusCheckData,
}: WorkStatusCheckSectionProps) => {
  return (
    <Tables>
      <thead>
        <tr>
          <ThHeader className="start-th">근무지</ThHeader>
          <ThHeader>이름</ThHeader>
          <ThHeader>사번</ThHeader>
          <ThHeader>직무</ThHeader>
          <ThHeader>직위</ThHeader>
          <ThHeader>정상</ThHeader>
          <ThHeader className="end-th">기록</ThHeader>
        </tr>
      </thead>

      <tbody>
        {toWorkStatusCheckData &&
          toWorkStatusCheckData.map(data => (
            <tr key={data.idx}>
              <TdHeader>{data.ContractName}</TdHeader>
              <TdHeader>{data.EmployeName}</TdHeader>
              <TdHeader>{data.EmployeeNo}</TdHeader>
              <TdHeader>{data.JobGroupName}</TdHeader>
              <TdHeader>{data.CategoryName}</TdHeader>
              <TdHeader>{data.PlannedTimeStart}</TdHeader>
              <TdHeader>{data.TriggerTimeStart}</TdHeader>
            </tr>
          ))}
        {toHomeStatusCheckData &&
          toHomeStatusCheckData.map(data => (
            <tr key={data.idx}>
              <TdHeader>{data.ContractName}</TdHeader>
              <TdHeader>{data.EmployeName}</TdHeader>
              <TdHeader>{data.EmployeeNo}</TdHeader>
              <TdHeader>{data.JobGroupName}</TdHeader>
              <TdHeader>{data.CategoryName}</TdHeader>
              <TdHeader>{data.PlannedTimeEnd}</TdHeader>
              <TdHeader>{data.TriggerTimeEnd}</TdHeader>
            </tr>
          ))}
      </tbody>
    </Tables>
  );
};

const Tables = styled.table`
  width: 100%;
  font-size: 13px;
`;

const ThHeader = styled.th`
  padding: 5px 0;
  background-color: ${({ theme }) => theme.backgroundColor.gray};
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};

  &.end-th {
    border-right: none;
  }
`;

const TdHeader = styled.td`
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
`;
