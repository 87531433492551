// MEMO: 분을 시간 단위로 환산하기
export function minutesToHours(num: string) {
  const minutes = Number(num);
  const hours = minutes / 60;

  /* 👇 1번 방법 */
  // const roundedHours = Math.floor(hours); // 소수점 자리 버림
  /* 👆 1번 방법 */

  /* 👇 2번 방법 */
  const roundedHours = hours.toFixed(2); // 소수점 두 번째 자리까지 반올림

  if (roundedHours.endsWith('.00')) {
    return roundedHours.slice(0, -3); // 소수점 이하 부분이 '.00'일 경우 0 생략
  }

  if (roundedHours.endsWith('0')) {
    return roundedHours.slice(0, -1); // 소수점 두 번째 자리가 0일 경우 0 생략
  }
  /* 👆 2번 방법 */

  return roundedHours;
}

// 예시)
// console.log(convertMinutesToHours(150)); // 2.5 시간
// console.log(convertMinutesToHours(120)); // 2 시간
// console.log(convertMinutesToHours(90)); // 1.5 시간
// console.log(convertMinutesToHours(60)); // 1 시간
// console.log(convertMinutesToHours(45)); // 0.75 시간
