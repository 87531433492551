import React from 'react';

export const gridOptions = {
  columnDefs: [
    {
      headerClass: 'header-custom-CV302-1',
      // suppressMovable: true,
      lockPosition: true,
      children: [
        {
          // suppressMovable: true,
          lockPosition: true,
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '단지',
              field: '단지',
              // suppressMovable: true,
              lockPosition: true,
              resizable: true,
              width: 160,
              headerClass: 'header-custom-CV302-1',
              // cellClass: 'cell-class-contents',
              cellStyle: { textAlign: 'center' },
            },
          ],
        },
      ],
    },

    {
      // suppressMovable: true,
      lockPosition: true,
      headerClass: 'header-custom-CV302-1',
      children: [
        {
          // suppressMovable: true,
          lockPosition: true,
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '명세서',
              field: '명세서',
              resizable: true,
              // suppressMovable: true,
              lockPosition: true,
              width: 100,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'center' },
            },
          ],
        },
      ],
    },

    {
      headerClass: 'header-custom-CV302-1',
      children: [
        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '구분',
              field: '구분',
              sortable: true,
              resizable: true,
              width: 100,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'center' },
            },
          ],
        },
      ],
    },
    {
      headerClass: 'header-custom-CV302-1',
      children: [
        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '근무자',
              field: '근무자',
              sortable: true,
              resizable: true,
              width: 100,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'center' },
            },
          ],
        },
      ],
    },
    {
      headerClass: 'header-custom-CV302-1',
      children: [
        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '생년월일',
              field: '생년월일',
              sortable: true,
              resizable: true,
              width: 100,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'center' },
            },
          ],
        },
      ],
    },
    {
      headerClass: 'header-custom-CV302-1',
      children: [
        {
          headerName: '',
          field: '',
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '입사일',
              field: '입사일',
              sortable: true,
              resizable: true,
              width: 100,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'center' },
            },
          ],
        },
      ],
    },
    {
      headerName: '지급내역',
      groupId: '지급내역',
      headerClass: 'header-custom-CV302-2',
      children: [
        {
          headerName: '과세',
          groupId: '과세',
          headerClass: 'header-custom-CV302-2',
          children: [
            {
              headerName: '기본급',
              field: '기본급',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },

            {
              headerName: '직책수당',
              field: '직책수당',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
            {
              headerName: '연차수당',
              field: '연차수당',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
            {
              headerName: '시간외/야간',
              field: '시간외/야간(과)',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
            {
              headerName: '시간외/연장',
              field: '시간외/연장(과)',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
            {
              headerName: '복지수당',
              field: '복지수당',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
            {
              headerName: '식대',
              field: '식대',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
            {
              headerName: '차량유지비',
              field: '차량유지비',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
            {
              headerName: '기타',
              field: '기타(과)',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
        {
          headerName: '',
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '과세총계',
              field: '과세총계', // ='소계1'
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
        {
          headerName: '비과세',
          groupId: '비과세',
          headerClass: 'header-custom-CV302-2',
          children: [
            {
              headerName: '시간외/야간',
              field: '시간외/야간(비)',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
            {
              headerName: '시간외/연장',
              field: '시간외/연장(비)',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
            {
              headerName: '식대/복지',
              field: '식대/복지',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
            {
              headerName: '기타',
              field: '기타(비)',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
        {
          headerName: '',
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '비과세총계',
              field: '비과세총계', // ='소계2'
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
      ],
    },
    {
      headerClass: 'header-custom-CV302-1',
      children: [
        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '급여총계',
              field: '급여총계',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
      ],
    },
    {
      headerName: '공제내역',
      groupId: '공제내역',
      headerClass: 'header-custom-CV302-2',
      children: [
        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '소득세',
              field: '소득세',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '지방세',
              field: '지방세',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '국민연금',
              field: '국민연금',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '건강보험',
              field: '건강보험',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '장기요양',
              field: '장기요양',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '고용보험',
              field: '고용보험',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },

        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '신원보증',
              field: '신원보증',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '선지급금',
              field: '선지급금',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '연말정산',
              field: '연말정산',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '기타공제',
              field: '기타공제',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
      ],
    },
    {
      headerClass: 'header-custom-CV302-1',
      children: [
        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '공제총계',
              field: '공제총계',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
      ],
    },
    {
      headerClass: 'header-custom-CV302-1',
      children: [
        {
          headerClass: 'header-custom-CV302-1',
          children: [
            {
              headerName: '총액',
              field: '총액',
              sortable: true,
              resizable: true,
              width: 80,
              headerClass: 'header-custom-CV302-1',
              cellStyle: { textAlign: 'end' },
            },
          ],
        },
      ],
    },

    // {
    //   field: 'Addr2',
    //   headerName: '호',
    //   resizable: true,
    //   filter: true,
    //   sortable: true,
    //   width: 80,
    //   cellClass: 'cell-class-contents',
    //   cellStyle: { textAlign: 'center' },
    //   headerClass: 'header-class-center', // 헤더에 클래스 적용
    // },
  ],
  // getRowHeight: function (params: { node: { rowSpan: any } }) {
  //   if (params.node.rowSpan) {
  //     return 60; // 행 높이 조절
  //   } else {
  //     return 30; // 기본 행 높이
  //   }
  // },
  // rowData: null,
  // defaultColDef: {
  //   filter: true,
  // },

  // custom loading template. the class ag-overlay-loading-center is part of the grid,
  // it gives a white background and rounded border
  overlayLoadingTemplate:
    // '<span class="ag-overlay-loading-center" style="padding: 10px; border: 1px solid #444; background: lightgoldenrodyellow;">데이터를 불러오는 중입니다</span>',
    '<span class="ag-overlay-loading-center" style="padding: 10px; border: 3px solid #ededed; border-radius: 0.5em; background-color: white; box-shadow: 5px 5px 15px -3px #ededed; color: #717171; font-weight: 600;">데이터를 불러오는 중입니다</span>',

  overlayNoRowsTemplate:
    '<span style="padding: 10px; border: 3px solid #ededed; border-radius: 0.5em; background-color: white; box-shadow: 5px 5px 15px -3px #ededed; color: #717171; font-weight: 600;">불러온 데이터가 없습니다</span>',
};
