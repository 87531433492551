import React from 'react';
import * as S from './Short.style';

interface InputProps {
  disabled?: boolean;
  type?: string;
  value?: string | number;
  defaultValue?: string | number;
  placeholder?: string;
  backgroundColor?: string;
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: any) => void;
}

export const Input = ({
  disabled = false,
  type = 'text',
  value,
  defaultValue,
  placeholder,
  size = 'small',
  backgroundColor,
  ...props
}: InputProps) => {
  return (
    <S.Input
      disabled={disabled}
      type={type}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      className={['input-short', `input-short--${size}`].join(' ')}
      style={{ backgroundColor }}
      {...props}
    ></S.Input>
  );
};
