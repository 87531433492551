// [MEMO] 임시 사용자 등록 현황
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface loadTemporaryEmployeeDataProps {
  setTemporaryEmployeeData: Dispatch<SetStateAction<never[]>>;
}

export const loadTemporaryEmployeeData = async ({
  setTemporaryEmployeeData,
}: loadTemporaryEmployeeDataProps) => {
  const token = sessionStorage.getItem('token');

  await axios
    .get(
      // https://apihr.mmigroup.co.kr:9020/dashboard/da10010/tempuserstatus
      `${process.env.COMMON_URL}/dashboard/da10010/tempuserstatus`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '메인화면 / 임시 사용자 등록 현황 API: ',
      //   `${process.env.COMMON_URL}/dashboard/da10010/tempuserstatus`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          // console.log(
          //   '메인화면 / 임시 사용자 등록 현황 res.data.Data: ',
          //   res.data.Data
          // );
          setTemporaryEmployeeData(res.data.Data);
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
