import React, { useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

// import { modifySingleServerData } from '../utils/modifySingleServerData';

interface ModalContentsProps {
  closeModal: () => void;
  selectedGridData: {};
  selectedDate: string;
  MMILogoImg: string;
  // apartmentIdx: number;
  // setDetectChangingData: Dispatch<SetStateAction<boolean>>; // 개별 데이터 삭제시, 데이터 불러오기 트리거
}

export const ModalContents = ({
  closeModal,
  selectedGridData,
  selectedDate,
  MMILogoImg,
}: // apartmentIdx,
// setDetectChangingData,
ModalContentsProps) => {
  console.log('🌞selectedGridData', selectedGridData);
  console.log('🌞🌞selectedDate', selectedDate);

  return (
    <Wrap>
      <Header>
        <TitleWrap>
          <MMILogo src={MMILogoImg} />
          <TitleText>
            <div className="title">급여명세서</div>
            <div className="date">
              ({selectedDate.slice(0, 4)}년{selectedDate.slice(4, 6)}월)
            </div>
          </TitleText>
        </TitleWrap>

        <InfoBox>
          <div>(주)앰앰아이</div>

          <ApartmentInfoList>
            <div className="apartment-name">{selectedGridData.단지}</div>
            <div className="employee-name">{selectedGridData.근무자}</div>
          </ApartmentInfoList>
        </InfoBox>
      </Header>

      <Section>
        <GridSection>
          <LeftSection>
            <CategoryListTitle>지급항목</CategoryListTitle>

            <CategoryListWrap>
              <LeftCategoryList>
                <CategoryItem className="title left-item">항목</CategoryItem>
                <CategoryItem className="left-item">
                  {/* 1 */}기본급
                </CategoryItem>
                <CategoryItem className="left-item">
                  {/* 2 */}직첵수당
                </CategoryItem>
                <CategoryItem className="left-item">
                  {/* 3 */}연차수당
                </CategoryItem>
                <CategoryItem className="left-item">
                  {/* 4 */}시간외/야간
                </CategoryItem>
                <CategoryItem className="left-item">
                  {/* 5 */}시간외/연장
                </CategoryItem>
                <CategoryItem className="left-item">
                  {/* 6 */}복지수당
                </CategoryItem>
                <CategoryItem className="left-item">{/* 7 */}식대</CategoryItem>
                <CategoryItem className="left-item">
                  {/* 8 */}차량유지비
                </CategoryItem>
                <CategoryItem className="left-item">{/* 9 */}기타</CategoryItem>
                <CategoryItem className="left-item">{/* 10 */}</CategoryItem>
                <CategoryItem className="left-item">{/* 11 */}</CategoryItem>
                <CategoryItem className="left-item">{/* 12 */}</CategoryItem>
                <CategoryItem className="left-item">
                  {/* 13 */}급여총계
                </CategoryItem>
              </LeftCategoryList>

              <RightCategoryList>
                <CategoryItem className="title">금액</CategoryItem>
                <CategoryItem className="right-item">
                  {/* 1. 기본급 */}
                  {Number(selectedGridData.기본급) !== 0 ? (
                    <>
                      <p>{selectedGridData.기본급}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 2. 직책수당 */}
                  {Number(selectedGridData.직책수당) !== 0 ? (
                    <>
                      <p>{selectedGridData.직책수당}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 3. 연차수당 */}
                  {Number(selectedGridData.연차수당) !== 0 ? (
                    <>
                      <p>{selectedGridData.연차수당}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 4. 시간외/야간 */}
                  {Number(selectedGridData['시간외/야간(과)']) !== 0 ? (
                    <>
                      <p>{selectedGridData['시간외/야간(과)']}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 5. 시간외/연장 */}
                  {Number(selectedGridData['시간외/연장(과)']) !== 0 ? (
                    <>
                      <p>{selectedGridData['시간외/연장(과)']}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 6. 복지수당 */}
                  {Number(selectedGridData.복지수당) !== 0 ? (
                    <>
                      <p>{selectedGridData.복지수당}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 7. 식대 */}
                  {Number(selectedGridData.식대) !== 0 ? (
                    <>
                      <p>{selectedGridData.식대}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 8. 차량유지비 */}
                  {Number(selectedGridData.차량유지비) !== 0 ? (
                    <>
                      <p>{selectedGridData.차량유지비}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 9. 기타 */}
                  {Number(selectedGridData.기타) !== 0 ? (
                    <>
                      <p>{selectedGridData.기타}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">{/* 10 */}</CategoryItem>
                <CategoryItem className="right-item">{/* 11 */}</CategoryItem>
                <CategoryItem className="right-item">{/* 12 */}</CategoryItem>
                <CategoryItem className="right-item">
                  {/* 13. 급여총계 */}
                  {Number(selectedGridData.급여총계) !== 0 ? (
                    <>
                      <p>{selectedGridData.급여총계}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
              </RightCategoryList>
            </CategoryListWrap>
          </LeftSection>

          <RightSection>
            <CategoryListTitle>공제항목</CategoryListTitle>

            <CategoryListWrap>
              <LeftCategoryList>
                <CategoryItem className="title">항목</CategoryItem>
                <CategoryItem className="left-item">
                  {/* 1 */}소득세
                </CategoryItem>
                <CategoryItem className="left-item">
                  {/* 2 */}지방세
                </CategoryItem>
                <CategoryItem className="left-item">
                  {/* 3 */}국민연금
                </CategoryItem>
                <CategoryItem className="left-item">
                  {/* 4 */}건강보험
                </CategoryItem>
                <CategoryItem className="left-item">
                  {/* 5 */}장기요양
                </CategoryItem>
                <CategoryItem className="left-item">
                  {/* 6 */}고용보험
                </CategoryItem>
                <CategoryItem className="left-item">
                  {/* 7 */}신원보증
                </CategoryItem>
                <CategoryItem className="left-item">
                  {/* 8 */}선지급금
                </CategoryItem>
                <CategoryItem className="left-item">
                  {/* 9 */}연말정산
                </CategoryItem>
                <CategoryItem className="left-item">
                  {/* 10 */}기타공제
                </CategoryItem>
                <CategoryItem className="left-item">{/* 11 */}</CategoryItem>
                <CategoryItem className="left-item">{/* 12 */}</CategoryItem>
                <CategoryItem className="left-item">
                  {/* 13 */}공제총계
                </CategoryItem>
              </LeftCategoryList>

              <RightCategoryList>
                <CategoryItem className="title">금액</CategoryItem>
                <CategoryItem className="right-item">
                  {/* 1. 소득세 */}
                  {Number(selectedGridData.소득세) !== 0 ? (
                    <>
                      <p>{selectedGridData.소득세}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 2. 지방세 */}
                  {Number(selectedGridData.지방세) !== 0 ? (
                    <>
                      <p>{selectedGridData.지방세}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 3. 국민연금 */}
                  {Number(selectedGridData.국민연금) !== 0 ? (
                    <>
                      <p>{selectedGridData.국민연금}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 4. 건강보험 */}
                  {Number(selectedGridData.건강보험) !== 0 ? (
                    <>
                      <p>{selectedGridData.건강보험}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 5. 장기요양 */}
                  {Number(selectedGridData.장기요양) !== 0 ? (
                    <>
                      <p>{selectedGridData.장기요양}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 6. 고용보험 */}
                  {Number(selectedGridData.고용보험) !== 0 ? (
                    <>
                      <p>{selectedGridData.고용보험}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 7. 신원보증 */}
                  {Number(selectedGridData.신원보증) !== 0 ? (
                    <>
                      <p>{selectedGridData.신원보증}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 8. 선지급금 */}
                  {Number(selectedGridData.선지급금) !== 0 ? (
                    <>
                      <p>{selectedGridData.선지급금}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 9. 연말정산 */}
                  {Number(selectedGridData.연말정산) !== 0 ? (
                    <>
                      <p>{selectedGridData.연말정산}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">
                  {/* 10. 기타공제 */}
                  {Number(selectedGridData.기타공제) !== 0 ? (
                    <>
                      <p>{selectedGridData.기타공제}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
                <CategoryItem className="right-item">{/* 11 */}</CategoryItem>
                <CategoryItem className="right-item">{/* 12 */}</CategoryItem>
                <CategoryItem className="right-item">
                  {/* 13. 공제총계 */}
                  {Number(selectedGridData.공제총계) !== 0 ? (
                    <>
                      <p>{selectedGridData.공제총계}</p>
                      <p className="unit">원</p>
                    </>
                  ) : (
                    <p className="none">-</p>
                  )}
                </CategoryItem>
              </RightCategoryList>
            </CategoryListWrap>
          </RightSection>
        </GridSection>

        <TotalCount>
          <p className="title">실지급액</p>
          <p className="count">
            {Number(selectedGridData.총액) !== 0
              ? `${selectedGridData.총액}원`
              : '-'}
          </p>
        </TotalCount>
        <TextBox>귀하의 노고에 감사드립니다.</TextBox>
      </Section>

      <ButtonList>
        {/* <ButtonStyle
          className="modify-button"
          // onClick={() =>
          //   modifySingleServerData({
          //     modifiedResultData,
          //     apartmentIdx,
          //     closeModal,
          //     setDetectChangingData,
          //   })
          // }
          onClick={() => window.print()}
        >
          인쇄하기
        </ButtonStyle> */}
        <ButtonStyle className="close-button" onClick={closeModal}>
          닫기
        </ButtonStyle>
      </ButtonList>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
`;

const ButtonList = styled.div`
  margin-top: 15px;
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.printBtnStyle}

  width: 80px;
  cursor: pointer;

  &.modify-button {
    border: solid 1px ${({ theme }) => theme.mainColor.dark};
    background-color: ${({ theme }) => theme.mainColor.main};
    color: white;

    :hover {
      background-color: ${({ theme }) => theme.mainColor.dark};
    }
  }

  &.close-button {
    margin-left: 10px;
  }
`;

const Header = styled.div`
  width: 400px;
`;

const TitleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`;

const MMILogo = styled.img`
  width: auto;
  height: 52px;
`;

const TitleText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: 600;

  div {
    &.title {
      margin-bottom: 5px;
    }

    &.date {
      font-size: 16px;
    }
  }
`;

const InfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 13px;
`;

const ApartmentInfoList = styled.div`
  div {
    &.apartment-name {
      margin-bottom: 5px;
    }

    &.employee-name {
      display: flex;
      justify-content: end;
    }
  }
`;

const Section = styled.div`
  width: 400px;
  border: solid 1px black;
  font-size: 13px;
`;

const GridSection = styled.div`
  display: flex;
`;

const LeftSection = styled.div`
  width: 50%;
  border-right: solid 1px black;
`;

const CategoryListTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-bottom: solid 1px black;
`;

const RightSection = styled.div`
  width: 50%;
`;

const CategoryListWrap = styled.div`
  display: flex;
`;

const LeftCategoryList = styled.div`
  width: 50%;
  border-right: solid 1px black;
`;

const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  border-bottom: solid 1px black;

  &.title {
    justify-content: center;
  }

  &.left-item {
    justify-content: center;

    p {
      &.unit {
        margin-right: 5px;
      }

      &.none {
        margin-right: 5px;
      }
    }
  }

  &.right-item {
    justify-content: end;

    p {
      &.unit {
        margin-right: 5px;
      }

      &.none {
        margin-right: 5px;
      }
    }
  }

  /*
  p {
    &.unit {
      margin-right: 5px;
    }

    &.none {
      margin-right: 5px;
    }
  } */
`;

const RightCategoryList = styled.div`
  width: 50%;
`;

const TotalCount = styled.div`
  display: flex;
  height: 30px;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px black;

    &.title {
      width: 25%;
      border-right: solid 1px black;
    }

    &.count {
      width: calc(75% + 2px);
    }
  }
`;

const TextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
`;
