import React, { useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { NoticeTextDataSection } from './NoticeTextDataSection';

import CancelIcon from '@mui/icons-material/Cancel';
interface ModalContentsProps {
  closeModal: () => void;
  detailedNoticeData: any;
}

export const ModalContents = ({
  closeModal,
  detailedNoticeData,
}: ModalContentsProps) => {
  // console.log(
  //   '[DA10-010]공지사항(모달)/ModalContents - detailedNoticeData: ',
  //   detailedNoticeData
  // );
  // console.log(
  //   '[DA10-010]공지사항(모달)/ModalContents - detailedNoticeData.Data[0]: ',
  //   detailedNoticeData.Data[0]
  // );

  return (
    <Wrap>
      <Header>
        <TitleWrap>
          <div className="title">제목</div>
          <div className="title-context">
            {detailedNoticeData.Data[0].PostTitle}
          </div>
        </TitleWrap>

        <HeaderRightSection>
          <ViewCountWrap>
            <div className="title">조회수</div>
            <div>{detailedNoticeData.Data[0].PostViewCount}</div>
          </ViewCountWrap>

          <CloseBtnWrap>
            <CancelIcon
              sx={{
                // color: 'black',
                fontSize: '20px',
              }}
              onClick={() => closeModal()}
            />
          </CloseBtnWrap>
        </HeaderRightSection>
      </Header>

      <NoticeTextDataSection detailedNoticeData={detailedNoticeData} />
    </Wrap>
  );
};

const Wrap = styled.div`
  ${({ theme }) => theme.scrollBarStyle};
  width: 820px;
  height: 80vh;
  overflow-y: auto;

  @media (max-width: 900px) {
    ${({ theme }) => theme.scrollBarStyle};
    width: auto;
    height: 80vh;
    overflow-y: auto;
  }
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.backgroundColor.gray};
  padding: 5px;
`;

const TitleWrap = styled.div`
  display: flex;
  margin-right: 10px;
  font-size: 14px;

  div {
    &.title {
      margin-right: 5px;
      font-weight: 600;
    }
  }
`;

const HeaderRightSection = styled.div`
  display: flex;
  align-items: center;
`;

const ViewCountWrap = styled.div`
  display: flex;
  font-size: 14px;
  margin-right: 5px;

  div {
    &.title {
      margin-right: 5px;
      font-weight: 600;
    }
  }
`;

const CloseBtnWrap = styled.div`
  cursor: pointer;
`;
