import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../../lib/utils/incorrectToken';

interface onAddDataProps {
  employeeNum: string;
  targetWorkStatusIdx: string | null;
  isNextDay: boolean;
  selectedStartHours: string;
  selectedStartMinutes: string;
  selectedEndHours: string;
  selectedEndMinutes: string;
  closeModal: () => void;
  setIsUpdateStatusBar: Dispatch<SetStateAction<boolean>>;
  calYear: string;
  calMonth: string;
  calDate: string;
  selectedStatusCode: string; // 🔰
  selectedStatusType: string; // 🔰
}

export const onAddData = async ({
  employeeNum,
  targetWorkStatusIdx,
  isNextDay,
  selectedStartHours,
  selectedStartMinutes,
  selectedEndHours,
  selectedEndMinutes,
  closeModal,
  setIsUpdateStatusBar,
  calYear,
  calMonth,
  calDate,

  selectedStatusCode, // 🔰
  selectedStatusType, // 🔰
}: onAddDataProps) => {
  function addString(str: string) {
    if (str.length === 1) {
      return '0' + str;
    } else return str;
  }

  const token = sessionStorage.getItem('token');

  console.log('🍡employeeNum: ', employeeNum);
  console.log('🍡targetWorkStatusIdx: ', targetWorkStatusIdx);
  console.log('🍡selectedStartHours: ', selectedStartHours);
  console.log('🍡selectedStartMinutes: ', selectedStartMinutes);
  console.log('🍡selectedEndHours: ', selectedEndHours);
  console.log('🍡selectedEndMinutes: ', selectedEndMinutes);

  await axios({
    url: `${process.env.COMMON_URL}/attendance/ac10030/attendancereport/${employeeNum}`,
    method: 'post',
    headers: { tokenId: token },
    data: {
      // idx: targetWorkStatusIdx,
      idx: 0,
      // AttendStatusName: '',
      AttendStatusCode: selectedStatusCode,
      AttendStatusName: '',
      // Working: 'D',
      Working: selectedStatusType,
      StatusTardy: '',
      StatusLeave: '',
      ChangedRecord: '',
      DeleteRecord: '',
      TriggerDate: `${calYear}-${calMonth}-${calDate}`,
      // [MEMO] TriggerTimeStart: '02:00' 형식,
      TriggerTimeStart: `${addString(selectedStartHours)}:${addString(
        selectedStartMinutes
      )}`,
      // [MEMO] TriggerTimeEnd: '12:48' 형식,
      TriggerTimeEnd: `${addString(selectedEndHours)}:${addString(
        selectedEndMinutes
      )}`,
      EndTimeDay: isNextDay,
      ModifyAvailableTimeStart: '',
      ModifyAvailableTimeEnd: '',
      ModifyEndTimeDay: '',
      AttedNote: '',
    },
    // data: {
    //   idx: targetWorkStatusIdx,
    //   AttendStatusCode: detailWorkData[0].AttendStatusCode,
    //   AttendStatusName: detailWorkData[0].AttendStatusName,
    //   Working: detailWorkData[0].Working,
    //   StatusTardy: detailWorkData[0].StatusTardy,
    //   StatusLeave: detailWorkData[0].StatusLeave,
    //   ChangedRecord: detailWorkData[0].ChangedRecord,
    //   DeleteRecord: detailWorkData[0].DeleteRecord,
    //   TriggerDate: detailWorkData[0].TriggerDate,
    //   // TriggerTimeStart: '02:00',
    //   TriggerTimeStart: `${addString(selectedStartHours)}:${addString(
    //     selectedStartMinutes
    //   )}`,
    //   // TriggerTimeEnd: '12:48',
    //   TriggerTimeEnd: !detailWorkData[0].TriggerTimeEnd
    //     ? ''
    //     : `${addString(selectedEndHours)}:${addString(selectedEndMinutes)}`,
    //   // EndTimeDay: detailWorkData[0].EndTimeDay,
    //   EndTimeDay: isNextDay,
    //   ModifyAvailableTimeStart: detailWorkData[0].ModifyAvailableTimeStart,
    //   ModifyAvailableTimeEnd: detailWorkData[0].ModifyAvailableTimeEnd,
    //   ModifyEndTimeDay: detailWorkData[0].ModifyEndTimeDay,
    //   AttedNote: detailWorkData[0].AttedNote,
    // },
  }).then(res => {
    console.log(
      '[AC10-030-REPORT] 근무 현황(일간) 추가 API: ',
      `${process.env.COMMON_URL}/attendance/ac10030/attendancereport/${employeeNum}`
    );

    try {
      if (res.data.ErrorCode === 0) {
        return (
          closeModal(),
          alert('변경이 완료되었습니다'),
          setIsUpdateStatusBar(true)
        );
      }

      incorrectToken(res);
    } catch (e) {
      console.error(e);
      console.log(
        `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
      );
    }
  });
};
