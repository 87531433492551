// src/PersonalInfoPage/PersonalInfoPage.tsx

import { createSlice } from '@reduxjs/toolkit';

export const targetApartmentInfoSlice = createSlice({
  name: 'Target Apartment Contract Detail Information',
  initialState: {
    apartmentIndexValue: 0, // 아파트 번호(idx)

    nameKRValue: '', // 아파트 이름 (Name)
    address1Value: '', // 주소 (Address1)
    areaNameValue: '', // 지역(이름) (AreaName)
    postCodeValue: '', // 우편번호 (POSTCode)

    buildingNumValue: 0, // 건물수 (NoBuilding)
    houseNumValue: 0, // 세대수 (NoHouse)
    parkingNumValue: 0, // 주차대수 (NoParking)
    manageAreaValue: 0, // 전체면적 (ManageArea)
    taxExemptionAreaValue: 0, // 면세면적 (TaxExemptionArea)
    taxationAreaValue: 0, // 과세면적 (TaxationArea)
  },
  reducers: {
    apartmentIndex: (state, action) => {
      // 아파트 사진
      state.apartmentIndexValue = action.payload;
    },

    nameKR: (state, action) => {
      // 아파트 이름
      state.nameKRValue = action.payload;
    },
    address1: (state, action) => {
      // 주소
      state.address1Value = action.payload;
    },
    areaName: (state, action) => {
      // 지역(이름)
      state.areaNameValue = action.payload;
    },
    postCode: (state, action) => {
      // 우편번호
      state.postCodeValue = action.payload;
    },

    buildingNum: (state, action) => {
      // 건물수
      state.buildingNumValue = action.payload;
    },
    houseNum: (state, action) => {
      // 세대수
      state.houseNumValue = action.payload;
    },
    parkingNum: (state, action) => {
      // 주차대수
      state.parkingNumValue = action.payload;
    },
    manageArea: (state, action) => {
      // 전체면적
      state.manageAreaValue = action.payload;
    },
    taxExemptionArea: (state, action) => {
      // 면세면적
      state.taxExemptionAreaValue = action.payload;
    },
    taxationArea: (state, action) => {
      // 과세면적
      state.taxationAreaValue = action.payload;
    },
  },
});

export const {
  apartmentIndex,
  nameKR,
  address1, // 주소
  areaName, // 지역(이름)
  postCode, // 우편번호
  buildingNum, // 건물수
  houseNum, // 세대수
  parkingNum, // 주차대수
  manageArea, // 전체면적
  taxExemptionArea, // 면세면적
  taxationArea, // 과세면적
} = targetApartmentInfoSlice.actions;
