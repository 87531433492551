import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { ModalContents } from './ModalContents';
import { CellClickedEvent } from 'ag-grid-community';

interface AttachedImageFileModalProps {
  // Type '(e: CellClickedEvent) => Promise<void>' is not assignable to type '() => void'.
  setOpenAttachedImageFileModal: Dispatch<SetStateAction<boolean>>;
  imageText: string;
  onDownloadFile: (
    downloadFileIdx: string,
    downloadFileName: string
  ) => Promise<void>;
  downloadFileIdx: string;
  downloadFileName: string;
}

export const AttachedImageFileModal = ({
  setOpenAttachedImageFileModal,
  imageText,
  onDownloadFile,
  downloadFileIdx,
  downloadFileName,
}: AttachedImageFileModalProps) => {
  const closeModal = () => {
    setOpenAttachedImageFileModal(false);
  };

  return (
    <Background onClick={closeModal}>
      <Container
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ModalContents
          closeModal={closeModal}
          imageText={imageText}
          onDownloadFile={() =>
            onDownloadFile(downloadFileIdx, downloadFileName)
          }
          downloadFileIdx={downloadFileIdx}
          downloadFileName={downloadFileName}
        />
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: white;
`;
