// [MEMO] 일간 스케줄러 컴포넌트
/* eslint-disable prefer-const */
import React, { useState, Dispatch, SetStateAction } from 'react';
import * as S from './Scheduler.style';

import { minutesToHours } from '../../../../lib/utils/minutesToHours';
import { onLoadWorkRecordInfo } from '../utils/onLoadWorkRecordInfo'; // 태그 기록 불러오기
import { onLoadDetailWorkInfo } from '../utils/onLoadDetailWorkInfo'; // 근무 상세 기록 불러오기

import { RightClickModalAtExistent } from '../RightClickModal/RightClickModalAtExistent';
import { RightClickModalAtEmpty } from '../RightClickModal/RightClickModalAtEmpty';
import { WorkRecordModal } from '../WorkRecordModal/WorkRecordModal';
import { ModifyModal } from '../ModifyModal/ModifyModal';
import { AddModal } from '../AddModal/AddModal';

interface SchedulerProps {
  employeeData: any[];
  apartmentContractIdx: string;
  calYear: string;
  calMonth: string;
  calDate: string;
  setIsUpdateStatusBar: Dispatch<SetStateAction<boolean>>;
}

export const Scheduler = ({
  employeeData,
  apartmentContractIdx,
  calYear,
  calMonth,
  calDate,
  setIsUpdateStatusBar,
}: SchedulerProps) => {
  console.log(
    '[AC10-030]일간 근무 현황/Scheduler-employeeData: ',
    employeeData
  );

  /**
   * [MEMO] headerHoursArray: 헤더(시), 하루를 시간 단위(1시간 단위)로 나누어 나타냄(0~23시).
   * - 필요한 칸의 개수: 총 24개(0~23시)
   * - 반복문을 이용해 0에서부터~23까지 숫자를 차례로 push하면, 총 24개가 들어감.
   */
  let headerHoursArray: {
    hour: number;
  }[] = [];

  for (let i = 0; i <= 23; i++) {
    headerHoursArray.push({
      hour: i,
    });
  }

  /**
   * [MEMO] headerMinutesArray: 헤더(분, 10분 단위). 하루를 10분 단위로 나누어 나타냄(시간당 6칸(총 144개)).
   * - 필요한 칸의 개수: 총 144개(하루 24시 x 시간당 6칸)
   * - 그래서 maxMinutesCount 값을 143개로 설정함.
   * - 반복문을 이용해 0에서부터~143까지 숫자를 차례로 push하면, 총 144개가 들어감.
   */
  let headerMinutesArray: {
    time: number;
  }[] = [];
  const maxMinutesCount = 143;

  for (let i = 0; i <= maxMinutesCount; i++) {
    headerMinutesArray.push({
      time: i,
    });
  }

  /**
   * 변수명 변경
   * dataArray -> processedEmployeeData
   * (dataArray에 속한) dayArray -> dayData
   */
  let processedEmployeeData: {
    dayData: {
      idx?: string;
      time?: number;
      triggerTimeStart?: string;
      triggerTimeEnd?: string;
      timeToWork?: string;
      timeToHome?: string;
      dateToWork?: number;
      dateToHome?: number;
      workingData?: string;
      timeStatus?: string;
      isWorking?: string;

      isNextDateTimeToHome?: boolean; // 퇴근 날이 다음 날짜인지 확인 여부 (true: 다음 날 퇴근)
      dataColorForTag?: string;
      dataNameForTag?: string;
      breakTime?: string; // 휴식 태그 시간대 중, 실제 휴식을 취한 시간
      startWorkTimeDayForTag?: string; // [MEMO] 근무중 색상바에 표시할 '출근~퇴근' 시간 중, 출근
      endWorkTimeDayForTag?: string; // [MEMO] 근무중 색상바에 표시할 '출근~퇴근' 시간 중, 퇴근
      startNotWorkingForTag?: string;
      endNotWorkingForTag?: string;
    }[];
    employeeName?: string;
    employeeNum?: string;
    totalWorkingTime?: any; // [MEMO] 총 근무시간
    expectedTimeToWork?: string; // [MEMO] 출근 예상 시간
    expectedTimeToHome?: string; // [MEMO] 퇴근 예상 시간
  }[] = [];
  /**
   * [MEMO] dayData.isWorking 속성:
   * 근무중인지를 체크해 색상바를 나타내기 위한 변수
   * false: 근무중이 아님. 색상바가 나타나지 않는 부분
   * true: 근무중(+출근, 퇴근, 휴식 등). 색상바가 나타나는 부분
   */

  const dataNameTagList = {
    working: '근무',
    startWork: '출근',
    endWork: '퇴근',
    breaking: '휴식',
    startBreak: '휴식(시작)', // [MEMO] 상태바 태그: 휴식(시작)
    endBreak: '휴식(종료)', // [MEMO] 상태바 태그: 휴식(종료)
    notWorking: '미근무',
    startNotWorking: '미근무(시작)',
    endNotWorking: '미근무(종료)',
  };

  const dataColorTagList = {
    working: '#57d49e',
    startWork: '#57d49e',
    endWork: '#57d49e',
    breaking: '#ffc936',
    startBreak: '#ffc936',
    endBreak: '#ffc936',
    notWorking: '#ff6038',
    startNotWorking: '#ff6038',
    endNotWorking: '#ff6038',
    dayOff: '#ededed',
  };

  // [TAG] 👇 현재 시간 체크를 위한 변수 -----start
  /**
   * [MEMO]
   * 1. 현재 시간을 체크하는 이유:
   * - 현재 시간 = 웹페이상에서 근태 시간을 확인하는 딱! 현재 시점의 시간을 의미
   * - 현재 시간 이후(=미래에 해당하는 시간대)에 데이터가 저장(기록)되는 것을 막기 위해, 현재 시간을 알아야 함.
   * 2. currentHoursToMinutes:
   * - 하루를 10분 단위로 나타내기로 정했기 때문에(총 144개), 현재 시간 역시 이에 맞추어서 나타내야 한다.
   * - 그래서 currentHours에
   */
  const currentDate = new Date();
  const currentHours = currentDate.getHours();

  // ----- 24.02.29 수정 전 : start -----
  // const currentHoursToMinutes = Math.floor(
  //   (Number(currentHours) * 60 + Number(currentHours)) / 10
  //   );
  // ----- 24.02.29 수정 전 : end -----

  // ----- 24.02.29 수정 후 : start -----
  const currentMinutes = currentDate.getMinutes();
  const currentHoursToMinutes = Math.ceil(
    (Number(currentHours) * 60 + Number(currentMinutes)) / 10
  );
  // ----- 24.02.29 수정 후 : end -----

  // [TAG] 👆 현재 시간 체크를 위한 변수 -----end

  function loadEmployeeData() {
    processedEmployeeData = [];

    employeeData.forEach(item => {
      let employeeDayArray: {
        // time?: number;
        // triggerTimeStart?: string;
        // triggerTimeEnd?: string;
        // timeToWork?: string;
        // timeToHome?: string;
        // dateToWork?: number;
        // dateToHome?: number;
        // workingData?: string;
        // timeStatus?: string;
        // isWorking?: string;
        // dataColorForTag?: string;
        // dataNameForTag?: string;
      }[] = [];

      let totalWorkingTime = 0; // [MEMO] 총 근무 시간
      let expectedTimeToWork = ''; // [MEMO] 예상 출근 시간
      let expectedTimeToHome = ''; // [MEMO] 예상 퇴근 시간

      // [MEMO] dataTimeTagList: 근무중 색상바에 표시할 '출근~퇴근' 시간 정보를 저장할을 객체
      // const dataTimeTacgList = {
      //   // startWorkTime: '', // 📗📗📗 색상바 관련 - 수정전
      //   // endWorkTime: '', // 📗📗📗 색상바 관련 - 수정전
      //   startWorkTime: 0, // 📕📕📕 색상바 관련 - 수정중
      //   endWorkTime: 0, // 📕📕📕 색상바 관련 - 수정중
      // };

      if (item.WorkingDate.length > 0) {
        // for (let processTime = 0; processTime < 24; processTime++) { // 📗📗📗 색상바 관련 - 수정전
        /**
         * [MEMO] processTime: 하루 총 시간
         * 1시간당 10분 단위로 나타내기로 했으므로 -> (하루(24시) x 시간당 6칸 = 총 144개)
         */
        for (
          let processTime = 0;
          processTime <= maxMinutesCount;
          processTime++
        ) {
          // 📕📕📕 색상바 관련 - 수정중
          let isTriggerTimeStartHourMatched = false;
          let isTriggerTimeEndHourMatched = false;
          let isWorking = false;
          /**
           * [MEMO] isWorking변수:
           * 근무중인지를 체크해 색상바를 나타내기 위한 변수
           * false: 근무중이 아님. 색상바가 나타나지 않는 부분
           * true: 근무중(+출근, 퇴근, 휴식 등). 색상바가 나타나는 부분
           */
          item.WorkingDate.forEach(
            (workingDate: {
              WorkingTime: number;
              WorkingData: any[];
              TimeToWork: string;
              TimeToHome: string;
              WorkingDayoff: boolean;
            }) => {
              /**
               * [MEMO] targetDayTimeToWork, targetDayTimeToHome:
               * 근무중 색상바에 표시할 '출근~퇴근' 태그에 담을 변수
               */

              // [MEMO] totalWorkingTime: 총 근무 시간
              totalWorkingTime = Number(workingDate.WorkingTime);
              expectedTimeToHome = workingDate.TimeToHome; // [MEMO] 예상 퇴근 시간
              expectedTimeToWork = workingDate.TimeToWork; // [MEMO] 예상 출근 시간

              let checkedDate;
              checkedDate = workingDate.WorkingData.map(workingData => {
                // [MEMO] targetDayTimeToWorkH: 시작 시간(시)이 undefined로 들어오는 경우를 대비해 분기 처리함(삼항연산자)
                const targetDayTimeToWorkH = workingData.TriggerTimeStart
                  ? workingData.TriggerTimeStart.slice(0, 2)
                  : ''; // 📕📕📕 색상바 관련 - 수정중

                // [MEMO] targetDayTimeToWorkM: 시작 시간(분)이 undefined로 들어오는 경우를 대비해 분기 처리함(삼항연산자)
                const targetDayTimeToWorkM = workingData.TriggerTimeStart
                  ? workingData.TriggerTimeStart.slice(3, 5)
                  : ''; // 📕📕📕 색상바 관련 - 수정중

                // [MEMO] targetDayTimeToWork:
                // let targetDayTimeToWork = Math.floor( // 🔪이전
                let targetDayTimeToWork = Math.ceil(
                  /**
                   * [MEMO] (Number(targetDayTimeToWorkH) * 60 + Number(targetDayTimeToWorkM)) / 10):
                   * 1-1. Number(targetDayTimeToWorkH) = 출근(시간 단위)
                   * 1-2. Number(targetDayTimeToWorkH) * 60: 시간 단위를 분 단위로 환산해야 하기 때문에, 여기(시간)에 60을 곱한다.
                   * 1-3. Number(targetDayTimeToWorkM) = 출근(분 단위)
                   * 1-4. (Number(targetDayTimeToWorkH) * 60 + Number(targetDayTimeToWorkM)):
                   * 분으로 환산한 시간과 + 분을 더해 총 시간(분 단위)를 구한다.
                   * 1-5. / 10: 합산한 결과를 10으로 나누는 이유는, 화면에 10분 단위로 나타내기로 결정했기 때문이다.
                   */
                  (Number(targetDayTimeToWorkH) * 60 +
                    Number(targetDayTimeToWorkM)) /
                    10
                );

                const targetDayTimeToHomeH = workingData.TriggerTimeEnd.slice(
                  0,
                  2
                ); // 📕📕📕 색상바 관련 - 수정중

                const targetDayTimeToHomeM = workingData.TriggerTimeEnd.slice(
                  3,
                  5
                ); // 📕📕📕 색상바 관련 - 수정중

                let targetDayTimeToHome = 0;
                if (workingData.TriggerTimeEnd !== '') {
                  // targetDayTimeToHome = Math.floor( // 🔪이전
                  targetDayTimeToHome = Math.ceil(
                    (Number(targetDayTimeToHomeH) * 60 +
                      Number(targetDayTimeToHomeM)) /
                      10
                  );
                } else {
                  targetDayTimeToHome = maxMinutesCount;
                }

                if (targetDayTimeToWork > targetDayTimeToHome) {
                  targetDayTimeToHome = maxMinutesCount;
                }

                // [TAG] 현재 시간 체크를 위한 조건 -----start
                if (
                  workingData.AttendStatusCode === 'W1000' &&
                  workingDate.TimeToHome === '' &&
                  // ----- 24.02.29 수정 전 : start -----
                  // processTime >= currentHoursToMinutes
                  // ----- 24.02.29 수정 전 : end -----
                  // ----- 24.02.29 수정 후 : start -----
                  processTime > currentHoursToMinutes
                  // ----- 24.02.29 수정 후 : end -----
                ) {
                  // [MEMO] W1000 = 근무
                  return (checkedDate = {
                    priority: 1,
                    idx: '',
                    time: processTime,
                    triggerTimeStart: '',
                    triggerTimeEnd: '',
                    workingData: '',
                    timeStatus: '퇴근 기록 없음',
                    dataColorForTag: '',
                    dataNameForTag: '',
                    isWorking: false,
                  });
                }
                // [TAG] 현재 시간 체크를 위한 조건 -----end

                if (
                  workingData.AttendStatusCode === 'R1000' &&
                  targetDayTimeToWork <= processTime &&
                  targetDayTimeToHome >= processTime
                ) {
                  // [MEMO] R1000 = 휴식
                  return (checkedDate = {
                    priority: 1,
                    idx: workingData.idx,
                    time: processTime,
                    triggerTimeStart: workingData.TriggerTimeStart,
                    triggerTimeEnd: workingData.TriggerTimeEnd,
                    workingData: workingData.AttendStatusName,
                    // timeStatus: '시작', // 기존
                    timeStatus: '휴식',
                    breakTime: workingData.TimeInfo,
                    dataColorForTag: dataColorTagList.startBreak,
                    dataNameForTag: dataNameTagList.breaking,
                    isWorking: true,
                  });
                } else if (
                  workingData.AttendStatusCode === 'R3000' &&
                  targetDayTimeToWork <= processTime &&
                  targetDayTimeToHome >= processTime
                ) {
                  return (checkedDate = {
                    priority: 2,
                    idx: workingData.idx,
                    time: processTime,
                    triggerTimeStart: workingData.TriggerTimeStart,
                    triggerTimeEnd: workingData.TriggerTimeEnd,
                    workingData: workingData.AttendStatusName,
                    timeStatus: '미근무(시작)',
                    dataColorForTag: dataColorTagList.startNotWorking,
                    dataNameForTag: dataNameTagList.notWorking,
                    isWorking: true,
                  });
                } else if (
                  workingData.AttendStatusCode === 'W1000' &&
                  workingData.EndTimeDay &&
                  targetDayTimeToWork <= processTime
                ) {
                  /**
                   * [MEMO]
                   * 1. workingData.EndTimeDay:
                   * 서버로 부터 받는 boolean값.
                   * 출근 날짜와 퇴근 날짜가 같으면 false(=당일 퇴근),
                   * 출근 날짜와 퇴근 날짜가 다르면 true(=다음날 퇴근) 값을 받는다.
                   * 2. workingData.EndTimeDay 대신 다음 코드 사용 가능(동일한 결과값):
                   * Number(workingDate.TimeToWork.slice(8, 10)) !== Number(workingDate.TimeToHome.slice(8, 10))
                   */

                  // [MEMO] 조건: 출퇴근 날짜 불일치 (오늘 출근, 내일 퇴근)
                  return (checkedDate = {
                    priority: 98,
                    idx: workingData.idx,
                    time: processTime,
                    triggerTimeStart: workingData.TriggerTimeStart,
                    triggerTimeEnd: workingData.TriggerTimeEnd,
                    workingData: workingData.AttendStatusName,
                    timeStatus: '출근',
                    dataColorForTag: dataColorTagList.startWork,
                    dataNameForTag: dataNameTagList.working,
                    isWorking: true,
                    dateToWork: Number(workingDate.TimeToWork.slice(8, 10)),
                    dateToHome: Number(workingDate.TimeToHome.slice(8, 10)),
                    isNextDateTimeToHome: true, // 퇴근 날이 다음 날짜인지 체크 (true: 다음날 퇴근)
                  });
                } else if (
                  workingData.AttendStatusCode === 'W1000' &&
                  targetDayTimeToWork <= processTime &&
                  targetDayTimeToHome >= processTime
                ) {
                  // [MEMO] 조건: 출퇴근 날짜 일치 (오늘 출근, 오늘 퇴근)
                  return (checkedDate = {
                    priority: 98,
                    idx: workingData.idx,
                    time: processTime,
                    triggerTimeStart: workingData.TriggerTimeStart,
                    triggerTimeEnd: workingData.TriggerTimeEnd,
                    workingData: workingData.AttendStatusName,
                    timeStatus: '출근',
                    dataColorForTag: dataColorTagList.startWork,
                    dataNameForTag: dataNameTagList.working,
                    isWorking: true,
                    dateToWork: Number(workingDate.TimeToWork.slice(8, 10)),
                    dateToHome: Number(workingDate.TimeToHome.slice(8, 10)),
                  });
                }
                // 24.03.05 -----start
                // else if (
                //   workingData.AttendStatusCode === 'H1000' &&
                //   targetDayTimeToWork <= processTime &&
                //   targetDayTimeToHome >= processTime
                // ) {
                //   // [MEMO] 조건: 출퇴근 날짜 일치 (오늘 출근, 오늘 퇴근)
                //   // [MEMO] H1000: 연차
                //   return (checkedDate = {
                //     priority: 98,
                //     idx: workingData.idx,
                //     time: processTime,
                //     triggerTimeStart: workingData.TriggerTimeStart,
                //     triggerTimeEnd: workingData.TriggerTimeEnd,
                //     workingData: workingData.AttendStatusName,
                //     timeStatus: '연차',
                //     dataColorForTag: dataColorTagList.dayOff,
                //     dataNameForTag: dataNameTagList.working,
                //     isWorking: true,
                //     dateToWork: Number(workingDate.TimeToWork.slice(8, 10)),
                //     dateToHome: Number(workingDate.TimeToHome.slice(8, 10)),
                //   });
                // }
                // 24.03.05 -----end
                else {
                  return (checkedDate = {
                    priority: 99,
                    idx: workingData.idx,
                    time: processTime,
                    workingData: '',
                    isWorking: false,
                    dataColorForTag: '',
                    dataNameForTag: '',
                    startWorkTimeDayForTag: '',
                    endWorkTimeDayForTag: '',
                  });
                }
                /*
                let triggerTimeStartHour = Number(
                  workingData.TriggerTimeStart.slice(0, 2)
                );

                let triggerTimeEndHour = Number(
                  workingData.TriggerTimeEnd.slice(0, 2)
                );

                // [TAG] 분기에 따른 isWorking 상태 체크 -----start
                if (triggerTimeStartHour < processTime) {
                  // 👆 조건: 근무(출근) 시간이 6시라면, 그것보다 큰 7, 8, 9, ... 시간들을 의미

                  // 👇 조건: [기본] 오늘 출근-퇴근 모두 있음
                  if (
                    Number(workingDate.TimeToHome.slice(8, 10)) ===
                    Number(calDate)
                  ) {
                    if (processTime < triggerTimeEndHour) {
                      isWorking = true;

                      dataTimeTagList.endWorkTime = targetDayTimeToHome;
                      dataTimeTagList.startWorkTime = targetDayTimeToWork;
                    }
                  }

                  // 👇 조건: 오늘 퇴근 아님
                  if (
                    Number(workingDate.TimeToHome.slice(8, 10)) !==
                    Number(calDate)
                  ) {
                    if (processTime <= 23) {
                      isWorking = true;

                      dataTimeTagList.endWorkTime = targetDayTimeToHome;
                      dataTimeTagList.startWorkTime = targetDayTimeToWork;
                    }
                  }
                }
                if (triggerTimeEndHour < triggerTimeStartHour) {
                  // 👆 조건: 퇴근 시간 앞에 출근 시간이 없을 때
                  if (processTime <= triggerTimeEndHour) {
                    isWorking = true;

                    dataTimeTagList.endWorkTime = targetDayTimeToHome;
                  }
                  // 👇 조건: 오늘 퇴근 아님 (별개로 오늘 퇴근인, 전혀 별개인 퇴근 기록 있음)
                  if (triggerTimeStartHour < processTime) {
                    // isWorking = true; // 📗📗📗 색상바 관련 - 수정전
                    isWorking = false; // 📕📕📕 색상바 관련 - 수정중

                    dataTimeTagList.startWorkTime = targetDayTimeToWork;
                    // dataTimeTagList.endWorkTime = ''; // 📗📗📗 색상바 관련 - 수정전
                    dataTimeTagList.endWorkTime = 0; // 📕📕📕 색상바 관련 - 수정중
                  }
                }
                // [TAG] 분기에 따른 isWorking 상태 체크 -----end

                //if (triggerTimeStartHour || triggerTimeEndHour) {
                // [TAG] 출근 -----start
                if (processTime === triggerTimeStartHour) {
                  isTriggerTimeStartHourMatched = true;

                  if (workingData.AttendStatusName === '근무') {
                    let resultDate = {
                      time: processTime,
                      triggerTimeStart: workingData.TriggerTimeStart,
                      dateToWork: Number(workingDate.TimeToWork.slice(8, 10)),
                      workingData: workingData.AttendStatusName,
                      timeStatus: '출근',
                      isWorking: '출근',
                      dataColorForTag: dataColorTagList.startWork,
                      dataNameForTag: dataNameTagList.startWork,
                    };

                    employeeDayArray.push(resultDate);
                  }

                  if (workingData.AttendStatusName === '휴식') {
                    let resultDate = {
                      time: processTime,
                      triggerTimeStart: workingData.TriggerTimeStart,
                      workingData: workingData.AttendStatusName,
                      timeStatus: '시작',
                      isWorking: isWorking,
                      dataColorForTag: dataColorTagList.startBreak,
                      dataNameForTag: dataNameTagList.startBreak,
                    };

                    employeeDayArray.push(resultDate);
                  }

                  // 👇 😈😈😈😈😈 미근무 관련 - 수정중 start 😈😈😈😈😈
                  if (workingData.AttendStatusName === '미근무') {
                    let resultDate = {
                      // time: processTime,
                      triggerTimeStart: workingData.TriggerTimeStart,
                      workingData: workingData.AttendStatusName,
                      timeStatus: '미근무(시작)',
                      isWorking: isWorking,
                      dataColorForTag: dataColorTagList.startNotWorking,
                      dataNameForTag: dataNameTagList.startNotWorking,
                    };

                    employeeDayArray.push(resultDate);
                  }
                  // 👆 😈😈😈😈😈 미근무 관련 - 수정중 end 😈😈😈😈😈
                }
                // [TAG] 출근 -----end

                // [TAG] 퇴근 -----start
                if (processTime === triggerTimeEndHour) {
                  isTriggerTimeEndHourMatched = true;

                  if (workingData.AttendStatusName === '근무') {
                    if (
                      Number(workingDate.TimeToHome.slice(8, 10)) !==
                      Number(calDate)
                    ) {
                      // [MEMO] Number(workingDate.TimeToHome.slice(8, 10)) !== Number(calDate) 조건
                      // 퇴근 날짜가 내일인 경우 (목데이터로 테스트할 경우 calDate 대신 1 넣기)
                      let resultDate = {
                        time: processTime,
                        workingData: '근무중',
                        isWorking: isWorking,
                        dataColorForTag: dataColorTagList.working,
                        dataNameForTag: dataNameTagList.working,
                        startWorkTimeDayForTag: dataTimeTagList.startWorkTime, // [MEMO] 근무중 색상바에 표시할 '출근~퇴근' 시간 중, 출근
                        endWorkTimeDayForTag: dataTimeTagList.endWorkTime, // [MEMO] 근무중 색상바에 표시할 '출근~퇴근' 시간 중, 퇴근
                      };

                      employeeDayArray.push(resultDate);
                    } else if (
                      Number(workingDate.TimeToHome.slice(8, 10)) ===
                      Number(calDate)
                    ) {
                       // [MEMO] Number(workingDate.TimeToHome.slice(8, 10)) === Number(calDate) 조건:
                       // 퇴근 날짜가 오늘인 경우 (목데이터로 테스트할 경우 calDate 대신 1 넣기)
                       // [MEMO] isWorking = true:
                       // 화면상에 나타날 퇴근 태그를 기록하기 위해 isWorking을 true로 설정함
                       // true일 때 화면에 색상바가 나타남
                      isWorking = true;

                      let resultDate = {
                        time: processTime,
                        triggerTimeEnd: workingData.TriggerTimeEnd,
                        dateToHome: Number(workingDate.TimeToHome.slice(8, 10)),
                        workingData: workingData.AttendStatusName,
                        timeStatus: '퇴근',
                        isWorking: isWorking,
                        dataColorForTag: dataColorTagList.endWork,
                        dataNameForTag: dataNameTagList.endWork,
                      };

                      employeeDayArray.push(resultDate);
                    }
                  }

                  if (workingData.AttendStatusName === '휴식') {
                    let resultDate = {
                      time: processTime,
                      triggerTimeEnd: workingData.TriggerTimeEnd,
                      workingData: workingData.AttendStatusName,
                      timeStatus: '종료',
                      isWorking: isWorking,
                      dataColorForTag: dataColorTagList.endBreak,
                      dataNameForTag: dataNameTagList.endBreak,
                    };

                    employeeDayArray.push(resultDate);
                  }

                  // 👇 😈😈😈😈😈 미근무 관련 - 수정중 start 😈😈😈😈😈
                  if (workingData.AttendStatusName === '미근무') {
                    let resultDate = {
                      // time: processTime,
                      triggerTimeEnd: workingData.TriggerTimeEnd,
                      workingData: workingData.AttendStatusName,
                      timeStatus: '미근무(종료)',
                      isWorking: isWorking,
                      dataColorForTag: dataColorTagList.endNotWorking,
                      dataNameForTag: dataNameTagList.endNotWorking,
                    };

                    employeeDayArray.push(resultDate);
                  }
                  // 👆 😈😈😈😈😈 미근무 관련 - 수정중 end 😈😈😈😈😈
                }
                // [TAG] 퇴근 -----end
                // }
                */
              });

              // console.log('일간 근무 현황/Scheduler - 우선순위 적용 전: ', processTime, checkedDate);
              /**
               * [MEMO] checkDatePriority: 저장 우선순위 후 정렬 🍛정리필요
               *
               */
              let checkDatePriority = checkedDate.sort(
                (
                  highLank: { priority: number },
                  rowLank: { priority: number }
                ) => {
                  if (highLank.priority > rowLank.priority) return 1;
                  if (highLank.priority < rowLank.priority) return -1;
                  return 0;
                }
              );
              // console.log('일간 근무 현황/Scheduler - 우선순위 적용 후: ', processTime, checkDatePriority);
              employeeDayArray.push(checkDatePriority[0]);
            }
          );
          /*
          if (!isTriggerTimeStartHourMatched && !isTriggerTimeEndHourMatched) {
            let resultDate = {
              time: processTime,
              workingData: isWorking ? '근무중' : '',
              isWorking: isWorking,
              dataColorForTag: isWorking ? dataColorTagList.working : '',
              dataNameForTag: isWorking ? dataNameTagList.working : '',
              startWorkTimeDayForTag: dataTimeTagList.startWorkTime, // [MEMO] 근무중 색상바에 표시할 '출근~퇴근' 시간 중, 출근
              endWorkTimeDayForTag: dataTimeTagList.endWorkTime, // [MEMO] 근무중 색상바에 표시할 '출근~퇴근' 시간 중, 퇴근
            };
            employeeDayArray.push(resultDate);
          }
          */
        }
      } else {
        /**
         * [MEMO] else에 해당하는 데이터:
         * - 0에서부터 23시까지 데이터가 아예 없는 사용자의 경우. 하루 내역이 아예 없는 사용자의 경우.
         */
        for (
          let processTime = 0;
          processTime <= maxMinutesCount;
          processTime++
        ) {
          let resultDate = {
            time: processTime,
            workingData: '근무없음',
          };

          employeeDayArray.push(resultDate);
        }
      }

      let result = {
        employeeName: item.UserNameKR,
        employeeNum: item.EmployeeNo,
        dayData: employeeDayArray,
        totalWorkingTime: totalWorkingTime, // [MEMO] 총 근무 시간
        expectedTimeToWork: expectedTimeToWork, // [MEMO] 예상 출근 시간
        expectedTimeToHome: expectedTimeToHome, // [MEMO] 예상 퇴근 시간
      };
      processedEmployeeData.push(result);
    });
  }

  loadEmployeeData();

  console.log('🧀processedEmployeeData: ', processedEmployeeData);

  // [TAG] 👇 근무 시간 추가 -----start
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [rightClickModalVisibleAtEmpty, setRightClickModalVisibleAtEmpty] =
    useState(false);

  const closeAddModal = () => {
    setAddModalVisible(false);
  };

  const onRightClickAtEmpty = (
    e: React.MouseEvent,
    statusIdx: string, // data.idx
    employeeNum: string, // row.employeeNum
    row: any, // row
    data: any // data 🔰
  ) => {
    setRowInfo(row);
    setCellData(data); // 🔰

    setTargetWorkStatusIdx(statusIdx);
    setTargetEmployeeNum(employeeNum);

    onLoadDetailWorkInfo({
      setDetailWorkData,
      statusIdx,
      employeeNum,
    });

    const position = { x: e.clientX, y: e.clientY };
    setRightClickModalPosition(position);
    setRightClickModalVisibleAtEmpty(true);
  };
  // [TAG] 👆 근무 시간 추가 -----end

  // [TAG] 👇 근무 시간 수정 -----start
  const [modifyModalVisible, setModifyModalVisible] = useState(false);
  const [
    rightClickModalVisibleAtExistent,
    setRightClickModalVisibleAtExistent,
  ] = useState(false);

  const closeModifyModal = () => {
    setModifyModalVisible(false);
  };

  const onRightClickAtExisting = (
    e: React.MouseEvent,
    statusIdx: string, // data.idx
    employeeNum: string, // row.employeeNum
    row: any, // row
    data: any // data 🔰
  ) => {
    setRowInfo(row);
    setCellData(data); // 🔰

    setTargetWorkStatusIdx(statusIdx);
    setTargetEmployeeNum(employeeNum);
    onLoadWorkRecordInfo({
      row,
      apartmentContractIdx,
      calYear,
      calMonth,
      calDate,
      workRecordData,
      setWorkRecordData,
      setOnWorkRecordModal,
    });
    onLoadDetailWorkInfo({
      setDetailWorkData,
      statusIdx,
      employeeNum,
    });

    const position = { x: e.clientX, y: e.clientY };
    setRightClickModalPosition(position);
    setRightClickModalVisibleAtExistent(true);
  };
  // [TAG] 👆 근무 시간 수정 -----end

  // [TAG] 👆 휴식 시간 추가 -----start
  // [TAG] 👆 휴식 시간 추가 -----end

  // [TAG] 👇 근태 기록 확인 -----start
  const [onWorkRecordModal, setOnWorkRecordModal] = useState(false);
  const [workRecordData, setWorkRecordData] = useState([]);

  const closeModal = () => {
    setOnWorkRecordModal(false);
  };
  // [TAG] 👆 근태 기록 확인-----end

  const [detailWorkData, setDetailWorkData] = useState([]);
  const [rowInfo, setRowInfo] = useState([]);
  const [cellData, setCellData] = useState({}); // 셀에 담긴 정보 🔰
  const [rightClickModalPosition, setRightClickModalPosition] = useState({
    x: 0,
    y: 0,
  });

  const [targetEmployeeNum, setTargetEmployeeNum] = useState('');
  const [targetWorkStatusIdx, setTargetWorkStatusIdx] = useState<string | null>(
    null
  );

  const handleRightClickModal = () => {
    setRightClickModalVisibleAtExistent(false);
    setRightClickModalVisibleAtEmpty(false);
  };

  const handleStatusBarClick = (statusIdx: string, employeeNum: string) => {
    setTargetWorkStatusIdx(statusIdx);
    setTargetEmployeeNum(employeeNum);

    onLoadDetailWorkInfo({
      setDetailWorkData,
      statusIdx,
      employeeNum,
    });
  };

  return (
    <>
      {rightClickModalVisibleAtExistent && (
        <RightClickModalAtExistent
          position={rightClickModalPosition}
          closeModal={handleRightClickModal}
          workRecordData={workRecordData}
          setOnWorkRecordModal={setOnWorkRecordModal}
          detailWorkData={detailWorkData}
          setModifyModalVisible={setModifyModalVisible}
          rowInfo={rowInfo}
        />
      )}

      {rightClickModalVisibleAtEmpty && (
        <RightClickModalAtEmpty
          position={rightClickModalPosition}
          closeModal={handleRightClickModal}
          detailWorkData={detailWorkData}
          setAddModalVisible={setAddModalVisible}
          rowInfo={rowInfo}
        />
      )}

      {modifyModalVisible && (
        <ModifyModal
          closeModal={closeModifyModal}
          detailWorkData={detailWorkData}
          employeeNum={targetEmployeeNum}
          targetWorkStatusIdx={targetWorkStatusIdx}
          setRightClickModalVisible={setRightClickModalVisibleAtExistent}
          setIsUpdateStatusBar={setIsUpdateStatusBar}
        />
      )}

      {addModalVisible && (
        <AddModal
          closeModal={closeAddModal}
          detailWorkData={detailWorkData}
          employeeNum={targetEmployeeNum}
          targetWorkStatusIdx={targetWorkStatusIdx}
          setRightClickModalVisible={setRightClickModalVisibleAtEmpty}
          setIsUpdateStatusBar={setIsUpdateStatusBar}
          calYear={calYear}
          calMonth={calMonth}
          calDate={calDate}
          cellData={cellData} // 🔰
        />
      )}

      {onWorkRecordModal &&
        (workRecordData.length > 0 ? (
          <WorkRecordModal
            closeModal={closeModal}
            workRecordData={workRecordData}
          />
        ) : (
          alert('태그 기록이 없습니다')
        ))}

      {/* 👇 [TAG] 본문 태그 색상 안내(어떤 상태인지) -----start */}
      {/* <S.Bar />
      <S.GuideSection>
        <S.GuideBox>
          <p style={{ backgroundColor: `${dataColorTagList.working}` }} />
          근무
        </S.GuideBox>
        <S.GuideBox>
          <p style={{ backgroundColor: `${dataColorTagList.breaking}` }} />
          휴식
        </S.GuideBox>
        <S.GuideBox>
          <p style={{ backgroundColor: `${dataColorTagList.notWorking}` }} />
          미근무
        </S.GuideBox>
      </S.GuideSection> */}
      {/* 👆 [TAG] 본문 태그 색상 안내(어떤 상태인지) -----end */}

      <S.Bar />
      <S.TableWrap>
        <S.Tables>
          <S.THead>
            <S.TrForHeader>
              <S.ThEmployeeNameHeader className="hours-array-section">
                {/* [MEMO] 근무자 */}
              </S.ThEmployeeNameHeader>

              <S.ThTotalWorkingTimeHeader className="hours-array-section">
                {/* [MEMO] 총 근무시간 */}
                <div />
              </S.ThTotalWorkingTimeHeader>

              {headerHoursArray.map((data, index) => (
                /**
                 * [MEMO] colSpan={6}:
                 * ThHourHeader는 시간을 나타내는 헤더.
                 * 한 시간을 6개로 쪼개어 나누기로 했기 때문에,
                 * colSpan으로 헤더 한칸당 6개로 나눈다.
                 */
                <S.ThHourHeader
                  key={data.hour}
                  className={`${index === 23 ? 'last-date' : ''}`}
                  colSpan={6}
                >
                  {data.hour}
                </S.ThHourHeader>
              ))}
            </S.TrForHeader>
          </S.THead>

          {/* [MEMO] 아래 두번째 thead:
            - '분' 단위 셀을 화면에 적용하고자 넣은 것. ThHourHeader 셀 하나당 = '10분' 단위.
            - ThHourHeader 하나당(=시간당) ThTimeHeader 6개의 셀이 들어간다. 이를 위해 ThHourHeader에 colSpan={6}을 적용한 것.
          */}
          <S.THead>
            <S.TrForHeader>
              <S.ThEmployeeNameHeader />
              <S.ThTotalWorkingTimeHeader />

              {headerMinutesArray.map(data => (
                <S.ThTimeHeader key={data.time} style={{ display: 'none' }} />
              ))}
            </S.TrForHeader>
          </S.THead>

          <tbody>
            {processedEmployeeData.map(row => (
              <S.TrForBody
                key={row.employeeName}
                onContextMenu={e => {
                  e.preventDefault();
                }}
                onDoubleClick={() =>
                  onLoadWorkRecordInfo({
                    row,
                    apartmentContractIdx,
                    calYear,
                    calMonth,
                    calDate,
                    workRecordData,
                    setWorkRecordData,
                    setOnWorkRecordModal,
                  })
                }
              >
                <S.TdEmployeeName>{row.employeeName}</S.TdEmployeeName>

                {/* 👇 총 근무시간 */}
                <S.TdTotalWorkingTime>
                  {row.totalWorkingTime > 0 ? (
                    <div>
                      <p>{minutesToHours(row.totalWorkingTime)} h</p>
                    </div>
                  ) : (
                    <div />
                  )}
                </S.TdTotalWorkingTime>
                {/* 👆 총 근무시간 */}

                {/* [MEMO] 분기 처리 이전(데이터 있고/없고 나누기 이전)으로 되돌리려면:
                 * 데이터 없는 영역에 해당되는 코드를 삭제 후,
                 * 데이터 있는 영역의 코드 중, 조건부 랜더링(data.isWorking &&)을 삭제하면 된다.
                 */}
                {/* 👇 데이터 있는(상태바 있는) 영역 -----start */}
                {row.dayData.map(data => (
                  <S.TdTimeInfo key={data.time}>
                    {data.isWorking && (
                      <S.StatusBarSection
                        onClick={() =>
                          handleStatusBarClick(data.idx, row.employeeNum)
                        }
                        onContextMenu={e => {
                          e.preventDefault(),
                            onRightClickAtExisting(
                              e,
                              data.idx,
                              row.employeeNum,
                              // 👇 [MEMO] row: onLoadWorkRecordInfo를 위한 (태그 정보 목록 모달을 위한)
                              row,
                              data // 🔰
                            );
                        }}
                      >
                        <S.StatusBarWrap>
                          <div
                            style={{
                              backgroundColor: `${data.dataColorForTag}`,
                            }}
                          >
                            <div
                              style={{
                                paddingLeft: '5px', // [MEMO] 상태바 텍스트 왼쪽 여백
                              }}
                            >
                              {data.dataNameForTag === '근무중' ? (
                                <div
                                  style={{
                                    color: `${data.dataColorForTag}`,
                                  }}
                                >
                                  <p>.</p>
                                </div>
                              ) : (
                                <div>
                                  <p />
                                  {data.dataNameForTag === '출근' &&
                                  data.triggerTimeStart
                                    ? `${data.dataNameForTag}` // 출근/시작 시간: ${data.triggerTimeStart}
                                    : ''}
                                  {data.dataNameForTag === '퇴근' &&
                                  data.triggerTimeEnd
                                    ? `${data.dataNameForTag}` // 퇴근/종료 시간: ${data.triggerTimeEnd}
                                    : ''}
                                </div>
                              )}
                            </div>
                          </div>

                          {/* <p className="status-bar-modal">
                            {data.triggerTimeStart
                              ? `${data.dataNameForTag} ${data.triggerTimeStart}`
                              : ''}
                            {data.triggerTimeEnd
                              ? `${data.dataNameForTag} ${data.triggerTimeEnd}`
                              : ''}
                            {data.dataNameForTag === '근무중'
                              ? `${data.startWorkTimeDayForTag} ~ ${data.endWorkTimeDayForTag}`
                              : ''}
                          </p> */}
                          <p className="status-bar-modal">
                            {data.triggerTimeStart
                              ? `${data.triggerTimeStart} ~`
                              : ''}
                            {data.triggerTimeEnd
                              ? ` ${data.triggerTimeEnd}`
                              : ''}
                            {data.breakTime && <br />}
                            {data.breakTime ? `(${data.breakTime})` : ''}
                            {data.isNextDateTimeToHome && '(+1)'}
                            {/* {data.dataNameForTag === '근무'
                              ? `${data.startWorkTimeDayForTag} ~ ${data.endWorkTimeDayForTag}`
                              : ''} */}
                          </p>
                        </S.StatusBarWrap>
                      </S.StatusBarSection>
                    )}
                    {/* 👆 데이터 있는(상태바 있는) 영역 -----start */}

                    {/* 👇 빈 데이터(상태바 없는) 영역 -----start */}
                    {!data.isWorking && row.totalWorkingTime === 0 && (
                      <S.StatusBarSection
                        onClick={() =>
                          handleStatusBarClick(data.idx, row.employeeNum)
                        }
                        onContextMenu={e => {
                          e.preventDefault(),
                            onRightClickAtEmpty(
                              e,
                              data.idx,
                              row.employeeNum,
                              // 👇 [MEMO] row: onLoadWorkRecordInfo를 위한 (태그 정보 목록 모달을 위한)
                              row,
                              data // 🔰
                            );
                        }}
                      ></S.StatusBarSection>
                    )}
                    {/* 👆 빈 데이터(상태바 없는) 영역 -----end */}
                  </S.TdTimeInfo>
                ))}
              </S.TrForBody>
            ))}
          </tbody>
        </S.Tables>
      </S.TableWrap>
    </>
  );
};
