// ref가 있는 인덱스 API (=단지 고유 번호(계약 아님))

import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { ModalContents } from './ModalContents';

interface ModalProps {
  closeModal: () => void;
  apartmentListData: any[];
  setApartmentListData: Dispatch<SetStateAction<any[]>>;
  apartmentIdx: string;
  setApartmentIdx: Dispatch<SetStateAction<string>>;
  apartmentName: string;
  setApartmentName: Dispatch<SetStateAction<string>>;
}

export const Modal = ({
  closeModal,
  apartmentListData,
  setApartmentListData,
  apartmentIdx,
  setApartmentIdx,
  apartmentName,
  setApartmentName,
}: ModalProps) => {
  return (
    <Background onClick={closeModal}>
      <Container
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ModalContents
          closeModal={closeModal}
          apartmentListData={apartmentListData}
          setApartmentListData={setApartmentListData}
          apartmentIdx={apartmentIdx}
          setApartmentIdx={setApartmentIdx}
          apartmentName={apartmentName}
          setApartmentName={setApartmentName}
        />
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: white;
`;
