import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { CreateApprovalTable } from '../CreateApprovalTable/CreateApprovalTable';

export const Contents = () => {
  const [createApprovalTableVisible, setCreateApprovalTableVisible] =
    useState(false);

  useEffect(() => {
    setCreateApprovalTableVisible(true); // [MEMO] 첫화면은 일간 근무 현황이 보이도록
  }, []);

  return (
    <>
      <Title>결재선 관리</Title>

      <SubTitle>
        <SubTitleCategory
          className={`${
            createApprovalTableVisible ? 'on-button' : 'off-button'
          }`}
          // onClick={() => onSwitchDailySection()}
        >
          기본 설정
        </SubTitleCategory>

        {/* <SubTitleCategory
          className={`${weeklySection ? 'on-button' : 'off-button'}`}
          onClick={() => onSwitchWeeklySection()}
        >
          주간
        </SubTitleCategory>
        <SubTitleCategory
          className={`${monthlySection ? 'on-button' : 'off-button'}`}
          onClick={() => onSwitchMonthlySection()}
        >
          월간
        </SubTitleCategory> */}
      </SubTitle>
      <Bar />

      <Section>
        <LoadInfoSection>
          {/* <SaveBtn style={{ backgroundColor: 'lightGray' }}>저장하기</SaveBtn> */}
        </LoadInfoSection>
        <Bar />

        <ContentsSection>
          {createApprovalTableVisible && <CreateApprovalTable />}
        </ContentsSection>
      </Section>
    </>
  );
};

const Bar = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

const Title = styled.div`
  ${({ theme }) => theme.contentsMainTitle};
  ${({ theme }) => theme.stopDrag};
`;

const SubTitle = styled.div`
  ${({ theme }) => theme.contentsSubTitle};
  ${({ theme }) => theme.stopDrag};
`;

const SubTitleCategory = styled.div`
  ${({ theme }) => theme.contentsSubTitleCategory};

  &.on-button {
    color: ${({ theme }) => theme.fontColor.black};
    position: relative;

    ::before {
      content: '';
      position: absolute;
      bottom: -20px;
      width: 80%;
      height: 6px;
      background-color: ${({ theme }) => theme.fontColor.black};
      left: 50%; /* MEMO: 가상 요소를 가운데로 이동시키기 */
      transform: translateX(-50%);
      // transform: translate(-50%, 50%);
    }
  }

  &.off-button {
    color: ${({ theme }) => theme.fontColor.lightGray};
  }

  &:active {
    color: #fc894d;
  }

  &:hover {
    color: ${({ theme }) => theme.mainColor.main};
  }
`;

const Section = styled.div`
  /* */
`;

const ContentsSection = styled.div`
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  padding: 15px 30px 0 30px;
  /* height: 40px; */
  font-size: 13px;
`;

const DatePickerSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: 40px;
  font-size: 13px;
`;

const DatePickerBoxWrap = styled.div`
  display: flex;
`;

const DatePickerBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;

  p {
    width: 50px;
  }
`;

const PrintBtnSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrintBtn = styled.button`
  ${({ theme }) => theme.printBtnStyle};
`;

const LoadInfoSection = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 30px;
  height: 40px;
  font-size: 13px;
`;

const LoadBtnSection = styled.div`
  display: flex;
  align-items: center;
`;

const ApartmentNameBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;

  p {
    color: ${({ theme }) => theme.fontColor.darkGray};
  }
`;

const ApartmentIdx = styled.div`
  color: ${({ theme }) => theme.mainColor.main};
`;

const ApartmentName = styled.div`
  /* font-weight: 600; */
`;

const LoadedDataSection = styled.div`
  /* padding: 20px; */
  /* background-color: gray; */
`;

const SaveBtn = styled.button`
  ${({ theme }) => theme.printBtnStyle};
`;
