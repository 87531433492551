export const gridOptions = {
  columnDefs: [
    {
      field: 'categoryName',
      headerName: '소속',
      resizable: true,
      filter: true,
      sortable: true,
      width: 100,
      cellClass: 'cell-class',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'employeeName',
      headerName: '근무자',
      resizable: true,
      filter: true,
      sortable: true,
      width: 100,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'employeeNum',
      headerName: '사번',
      resizable: true,
      filter: true,
      sortable: true,
      width: 100,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'date',
      headerName: '날짜',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'time',
      headerName: '시간',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'action',
      headerName: '행동',
      resizable: true,
      filter: true,
      sortable: true,
      width: 100,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'trackingGroup',
      headerName: '분류',
      resizable: true,
      filter: true,
      sortable: true,
      width: 100,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'trackingType',
      headerName: '기기유형',
      resizable: true,
      filter: true,
      sortable: true,
      width: 100,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'trackingLocation',
      headerName: '순찰구역',
      resizable: true,
      filter: true,
      sortable: true,
      width: 200,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'attachedReport',
      headerName: '보고서',
      resizable: true,
      filter: true,
      sortable: true,
      width: 80,
      cellStyle: { textAlign: 'start' },
      cellClass: 'cell-class-contents',
      headerClass: 'header-class-center', // 헤더에 클래스 적용
    },
    {
      field: 'attachedFile',
      headerName: '첨부파일',
      resizable: true,
      filter: true,
      sortable: true,
      width: 80,
      cellStyle: { textAlign: 'start' },
      cellClass: 'cell-class-contents',
      headerClass: 'header-class-center', // 헤더에 클래스 적용
    },
  ],

  // custom loading template. the class ag-overlay-loading-center is part of the grid,
  // it gives a white background and rounded border
  overlayLoadingTemplate:
    '<span class="ag-overlay-loading-center" style="padding: 10px; border: 1px solid #444; background: lightgoldenrodyellow;">데이터를 불러오는 중입니다</span>',

  overlayNoRowsTemplate:
    '<span style="padding: 10px; border: 3px solid #ededed; border-radius: 0.5em; background-color: white; box-shadow: 5px 5px 15px -3px #ededed; color: #717171; font-weight: 600;">불러온 데이터가 없습니다</span>',
};
