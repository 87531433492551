// src/PersonalInfoPage/PersonalInfoPage.tsx

import { createSlice } from '@reduxjs/toolkit';

export const targetEmployeeInfoSlice = createSlice({
  name: 'Target Employee Detail Information',
  initialState: {
    nameKRValue: '',
    employeeNumValue: '',
    userIDValue: '',
    permitCodeValue: '', // 권한 코드
    jobNameValue: '',

    registrationNumValue: '',
    phoneNumValue: '',

    address1Value: '', // 주소1
    address2Value: '', // 주소2

    enterDateValue: '', // 입사일
    exitDateValue: '', // 퇴사일
  },
  reducers: {
    nameKR: (state, action) => {
      // 이름(한글)
      state.nameKRValue = action.payload;
    },
    employeeNum: (state, action) => {
      // 사번
      state.employeeNumValue = action.payload;
    },
    userID: (state, action) => {
      // ID
      state.userIDValue = action.payload;
    },
    permitCode: (state, action) => {
      // 권한 코드
      state.permitCodeValue = action.payload;
    },
    jobName: (state, action) => {
      // 직무
      state.jobNameValue = action.payload;
    },

    registrationNum: (state, action) => {
      // 주민등록번호
      state.registrationNumValue = action.payload;
    },
    phoneNum: (state, action) => {
      // 전화번호
      state.phoneNumValue = action.payload;
    },
    address1: (state, action) => {
      // 주소1
      state.address1Value = action.payload;
    },
    address2: (state, action) => {
      // 주소2
      state.address2Value = action.payload;
    },
    enterDate: (state, action) => {
      // 입사일
      state.enterDateValue = action.payload;
    },
    exitDate: (state, action) => {
      // 퇴사일
      state.exitDateValue = action.payload;
    },
  },
});

export const {
  nameKR,
  employeeNum,
  userID,
  permitCode,
  jobName,
  registrationNum,
  phoneNum,
  address1,
  address2,
  enterDate,
  exitDate,
} = targetEmployeeInfoSlice.actions;
