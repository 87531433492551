import React from 'react';
import styled from 'styled-components';

import { ModalContents } from './ModalContents';

interface ModalProps {
  // (property) onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
  closeModal: React.MouseEventHandler<HTMLDivElement> | undefined;
  image: string;
  targetEmployeeData: any;
}

export const Modal = ({
  closeModal,
  image,
  targetEmployeeData,
}: ModalProps) => {
  const params = new URLSearchParams(location.search);
  console.log(params, 'params');
  console.log(params.get('employee'), 'params.get(id)');

  return (
    <Background onClick={closeModal}>
      <Container
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ModalContents image={image} targetEmployeeData={targetEmployeeData} />
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: white;
`;
