// CV10-030 차량 주차 관리 - 서버에 있는 데이터 가져오기
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

import parseISO from 'date-fns/parseISO';

interface loadExistingDataProps {
  setLoadedData: Dispatch<SetStateAction<never[]>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  apartmentIdx: number;
  selectedContractStart: string;
  selectedContractEnd: string;
  significantFlagSign: string;
  unregisteredCarSign: string;
  enteredCarNumber: string;
  selectedCheckPeriod: string;
  // onSavedViewCarNumberCV103: string;
  // onSavedEnteredCarNumberCV103: string; // 🙏
  // onSavedSelectedCheckPeriodCV103: string; // 🙏
  checkImage?: number;
}

export const loadExistingData = async ({
  setLoadedData,
  setLoading,
  apartmentIdx,
  selectedContractStart,
  selectedContractEnd,
  significantFlagSign, // 특이사항
  unregisteredCarSign,
  enteredCarNumber, // 차량번호
  selectedCheckPeriod, // 외부주차 및 위반 확인 기간
  checkImage,
}: // onSavedEnteredCarNumberCV103,
// onSavedSelectedCheckPeriodCV103,
loadExistingDataProps) => {
  let imagFlag = '';
  if (checkImage === 2) {
    imagFlag = `&ImgFlag=${2}`;
  } else {
    imagFlag = `&ImgFlag=${0}`;
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  }

  const validSelectedContractStart = formatDate(
    parseISO(selectedContractStart)
  );
  const validSelectedContractEnd = formatDate(parseISO(selectedContractEnd));

  console.log('🍓🍓🍓selectedContractStart: ', selectedContractStart);
  console.log('🍓🍓🍓selectedContractEnd: ', selectedContractEnd);
  console.log('🍓🧀🍓validSelectedContractStart: ', validSelectedContractStart);
  console.log('🍓🧀🍓validSelectedContractEnd: ', validSelectedContractEnd);

  const token = sessionStorage.getItem('token');
  const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx');

  if (!apartmentIdx && Number(apartmentRefIdx) === 0) {
    return alert('단지를 선택해주세요');
  }

  setLoading(true);

  let currentApartmentRefIdx = '';
  let flag = '';
  let unregisteredType = '';
  let carNumber = '';
  let checkPeriod = '';

  if (Number(apartmentRefIdx) !== 0) {
    currentApartmentRefIdx = `${apartmentRefIdx}`;
  } else {
    currentApartmentRefIdx = `${apartmentIdx}`;
  }

  if (significantFlagSign) {
    // &Flag=M
    flag = `&Flag=${significantFlagSign}`;
  }

  if (unregisteredCarSign) {
    // &Type=N
    unregisteredType = `&Type=${unregisteredCarSign}`;
  }

  if (enteredCarNumber) {
    // &CarNo=%EC%B0%A8%EB%9F%89%EB%B2%88%ED%98%B8
    carNumber = `&CarNo=${enteredCarNumber}`;
  }

  if (selectedCheckPeriod) {
    // &FlagCnt=30
    checkPeriod = `&FlagCnt=${selectedCheckPeriod}`;
  }

  console.log('불러오기의 checkPeriod: ', checkPeriod);

  console.log(
    '불러오기 API 확인 전전전+++: ',
    `${currentApartmentRefIdx}...(생략)...&StartDate=${validSelectedContractStart}&EndDate=${validSelectedContractEnd}${flag}${checkPeriod}${unregisteredType}${carNumber}`
  );

  await axios
    .get(
      // https://dev.mmigroup.co.kr:8020/vehicle/cv10030/checkcarmanage/234?ItemPerPage=0&ItemStartPage=0&StartDate=20240402&EndDate=20240415&ImgFlag=2
      // https://apihr.mmigroup.co.kr:9020/vehicle/cv10030/checkcarmanage/1?ItemPerPage=0&ItemStartPage=0&StartDate=20231101&EndDate=20231130&Flag=M&FlagCnt=30&Type=N&CarNo=%E3%84%B9%E3%84%B9%E3%84%B9%E3%84%B9
      // `${process.env.COMMON_URL}/vehicle/cv10030/checkcarmanage/1?ItemPerPage=0&ItemStartPage=0&StartDate=20231101&EndDate=20231130`,
      `${process.env.COMMON_URL}/vehicle/cv10030/checkcarmanage/${currentApartmentRefIdx}?ItemPerPage=0&ItemStartPage=0&StartDate=${validSelectedContractStart}&EndDate=${validSelectedContractEnd}${flag}${checkPeriod}${unregisteredType}${carNumber}${imagFlag}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      try {
        if (res.data.ErrorCode === 0) {
          console.log(
            '불러오기 API 확인 후후후---: ',
            `${currentApartmentRefIdx}...(생략)...&StartDate=${validSelectedContractStart}&EndDate=${validSelectedContractEnd}${flag}${checkPeriod}${unregisteredType}${carNumber}`
          );

          setLoading(false);
          setLoadedData(res.data.Data);
        }
        incorrectToken(res);
      } catch (e) {
        console.error(e);
      }
    });
};
