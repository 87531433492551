// [AC10-030] 근무 현황(월간) 삭제 API
import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface onDeleteDataProps {
  closeModal: () => void;
  setIsUpdateStatus: Dispatch<SetStateAction<boolean>>;
  employeeNum: string;
  selectedStatusIdx: string;
  cellData: any;
}

export const onDeleteData = async ({
  closeModal,
  setIsUpdateStatus,
  employeeNum,
  selectedStatusIdx,
  cellData,
}: onDeleteDataProps) => {
  const token = sessionStorage.getItem('token');

  console.log('🍰employeeNum: ', employeeNum);
  console.log('🍰selectedStatusIdx: ', selectedStatusIdx);
  console.log('🍰cellData: ', cellData);

  if (!employeeNum) {
    return;
  }

  if (!selectedStatusIdx) {
    return;
  }

  await axios({
    // https://dev.mmigroup.co.kr:8020/attendance/ac10030/attendancereport/000000?idx=99999'
    url: `${process.env.COMMON_URL}/attendance/ac10030/attendancereport/${employeeNum}?idx=${selectedStatusIdx}`,
    method: 'delete',
    headers: { tokenId: token },
  }).then(res => {
    if (res.data.ErrorCode === 0) {
      console.log(
        `${process.env.COMMON_URL}/attendance/ac10030/attendancereport/${employeeNum}?idx=${selectedStatusIdx}`
      );

      return (
        closeModal(), alert('삭제가 완료되었습니다'), setIsUpdateStatus(true)
      );
    }

    incorrectToken(res);
  });
};
