// CV10-020 차량 등록 관리 - 서버로 '신규' 데이터 보내기
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../../lib/utils/incorrectToken';

interface modifySingleServerDataProps {
  // resultData: any[];
  modifiedResultData: {
    RegEmployeeNo: string;
    Addr1: string;
    Addr2: string;
    OwnerName: string;
    OwnerPhone: string;
    CarNo: string;
    CarFlag: string;
    CarExpireDate: string;
  };
  apartmentIdx: number;
  closeModal: () => void;
  setDetectChangingData: Dispatch<SetStateAction<boolean>>; // 개별 데이터 삭제시, 데이터 불러오기 트리거
}

export const modifySingleServerData = async ({
  modifiedResultData,
  apartmentIdx,
  closeModal,
  setDetectChangingData,
}: modifySingleServerDataProps) => {
  console.log('🌞🌞🌞🔥🔥modifiedResultData', modifiedResultData);

  const token = sessionStorage.getItem('token');
  const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx');

  if (!apartmentIdx && Number(apartmentRefIdx) === 0) {
    return alert('단지를 선택해주세요');
  }

  if (modifiedResultData.CarFlag === '') {
    return alert('등록 유형을 선택해주세요');
  }

  let currentApartmentRefIdx = '';
  if (Number(apartmentRefIdx) !== 0) {
    currentApartmentRefIdx = `${apartmentRefIdx}`;
  } else {
    currentApartmentRefIdx = `${apartmentIdx}`;
  }

  console.log('개별수정-modifiedResultData: ', modifiedResultData);
  console.log('개별수정-currentApartmentRefIdx: ', currentApartmentRefIdx);

  await axios({
    // https://dev.mmigroup.co.kr:8020/vehicle/cv10020/regcarmanage/1
    url: `${process.env.COMMON_URL}/vehicle/cv10020/regcarmanage/${currentApartmentRefIdx}`,
    method: 'put',
    headers: { tokenId: token },
    data: {
      Data: [
        {
          idx: modifiedResultData.idx,
          RegEmployeeNo: modifiedResultData.RegEmployeeNo
            ? modifiedResultData.RegEmployeeNo.toString()
            : '',
          Addr1: modifiedResultData.Addr1
            ? modifiedResultData.Addr1.toString()
            : '',
          Addr2: modifiedResultData.Addr2
            ? modifiedResultData.Addr2.toString()
            : '',
          OwnerName: modifiedResultData.OwnerName
            ? modifiedResultData.OwnerName
            : '',
          OwnerPhone: modifiedResultData.OwnerPhone
            ? modifiedResultData.OwnerPhone.toString()
            : '',
          CarNo: modifiedResultData.CarNo,
          CarFlag: modifiedResultData.CarFlag ? modifiedResultData.CarFlag : '',
          CarExpireDate: modifiedResultData.CarExpireDate
            ? modifiedResultData.CarExpireDate
            : '',
        },
      ],
    },
  }).then(res => {
    console.log(
      `${process.env.COMMON_URL}/vehicle/cv10020/regcarmanage/${currentApartmentRefIdx}`
    );
    console.log(
      '👍modifySingleServerData.tsx-resultData: ',
      modifiedResultData
    );
    console.log('👍res.data: ', res.data);

    try {
      if (res.data.ErrorCode === 0) {
        alert('데이터가 성공적으로 수정되었습니다');
        closeModal();
        setDetectChangingData(true);
      }
      incorrectToken(res);
    } catch (e) {
      console.error(e);
    }
  });
};
