import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface onWorkStatusLoadBtnWeeklyProps {
  employeeData: any[];
  setEmployeeData: Dispatch<SetStateAction<any[]>>;
  apartmentIdx: string; // 아파트 리스트 인덱스
  validContract?: boolean;
  processingContact?: boolean;
  enteredEmployeeName?: string; // 근로자 이름
  // employeeName?: string; // 근로자 이름
  selectedContractStart?: string; // 범위 시작일
  selectedContractEnd?: string; // 범위 종료일
}

export const onEmployeeContractInfo = async ({
  employeeData,
  setEmployeeData,
  apartmentIdx, // 아파트 리스트 인덱스
  validContract,
  processingContact,
  enteredEmployeeName,
  // employeeName,
  selectedContractStart,
  selectedContractEnd,
}: onWorkStatusLoadBtnWeeklyProps) => {
  const token = sessionStorage.getItem('token');
  console.log(token, 'token');

  await axios
    .get(
      // /contractlist?ItemPerPage=0&ItemStartPage=0⭐&contractidx=24⭐&validcontract=true
      // ⭐&processingcontact=true⭐&employeename=%EC%9D%B4%EC%88%9C%EC%9E%90
      // ⭐&contractstart=2022-03-25⭐&contractend=2023-03-30
      // `/sample/CS10-040-sample.json`, // 목데이터
      `${process.env.COMMON_URL}/cost/cs10040/contractlist?ItemPerPage=0&ItemStartPage=0&contractidx=${apartmentIdx}&validcontract=${validContract}&processingcontact=${processingContact}${enteredEmployeeName}&contractstart=${selectedContractStart}&contractend=${selectedContractEnd}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   'onEmployeeContractInfo(직원 계약 정보 API확인)',
      //   `${process.env.COMMON_URL}/cost/cs10040/contractlist?ItemPerPage=0&ItemStartPage=0&contractidx=${apartmentIdx}&validcontract=${validContract}&processingcontact=${processingContact}${enteredEmployeeName}&contractstart=${selectedContractStart}&contractend=${selectedContractEnd}`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          setEmployeeData(res.data.Data);
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );

        alert('단지를 선택해주세요');
      }
    });
};
