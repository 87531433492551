import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

interface onLoadWorkRecordInfoProps {
  row: { employeeNum?: string };
  apartmentContractIdx: string;
  calYear: string;
  calMonth: string;
  calDate: string;
  workRecordData: any[];
  setWorkRecordData: Dispatch<SetStateAction<never[]>>;
  setOnWorkRecordModal: Dispatch<SetStateAction<boolean>>;
}

export const onLoadWorkRecordInfo = async ({
  row,
  apartmentContractIdx,
  calYear,
  calMonth,
  calDate,
  workRecordData,
  setWorkRecordData,
  setOnWorkRecordModal,
}: onLoadWorkRecordInfoProps) => {
  const token = sessionStorage.getItem('token');
  const employeeNum = row.employeeNum; // [MEMO] row.employeeNum: 사번

  await axios
    .get(
      // 'https://apihr.mmigroup.co.kr:9020/attendance/ac10030/
      // attendancerecord?ItemPerPage=0&ItemStartPage=0&ContractIdx=257&year=2023&month=5&day=23&employeeno=111111'
      // ✅ 목데이터 (단지 계약 정보)
      // https://apihr.mmigroup.co.kr:9020/attendance/ac10030/attendancerecord?ItemPerPage=0&ItemStartPage=0&ContractIdx=2&year=2023&month=10&day=26&employeeno=731265&group=A
      `${process.env.COMMON_URL}/attendance/ac10030/attendancerecord?ItemPerPage=0&ItemStartPage=0&ContractIdx=${apartmentContractIdx}&year=${calYear}&month=${calMonth}&day=${calDate}&employeeno=${employeeNum}&group=A`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '[AC10-030]월간 근무-근무 기록(태그 기록)(=그리드 클릭시 나타나는 모달) API: ',
      //   `${process.env.COMMON_URL}/attendance/ac10030/attendancerecord?ItemPerPage=0&ItemStartPage=0&ContractIdx=${apartmentContractIdx}&year=${calYear}&month=${calMonth}&day=${calDate}&employeeno=${employeeNum}`
      // );

      try {
        setWorkRecordData(res.data.Data);

        if (res.data.Data.length > 0) {
          // return setOnWorkRecordModal(true); // [MEMO] 주석 해제시, 좌클릭 더블클릭으로도 수정 모달 나타남
        }
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
