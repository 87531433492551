import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface onApartmentContractInfoProps {
  apartmentListData: any[];
  setApartmentListData: Dispatch<SetStateAction<any[]>>;
  setOnModal: Dispatch<SetStateAction<boolean>>;
  // apartmentName?: string;
  // managerNum?: string;
  // enteredNameValue?: string;
  // setApartmentIdx: Dispatch<SetStateAction<string>>;
}

export const onApartmentContractInfo = async ({
  apartmentListData,
  setApartmentListData,
  setOnModal,
}: onApartmentContractInfoProps) => {
  const token = sessionStorage.getItem('token');
  // const navigate = useNavigate();

  await axios
    .get(
      // 'https://apihr.mmigroup.co.kr:9020/common/contractinfo?ItemPerPage=10&ItemStartPage=0'
      // `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0${apartmentNameForm}&Valid=1${managerNumForm}`,
      `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0&Valid=1`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '#API확인',
      //   `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0&Valid=1`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          setApartmentListData(res.data.Data);
          // console.log('res.data.Data', res.data.Data);

          setOnModal(true);
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
