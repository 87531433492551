import React from 'react';
import { WorkLog } from '../../components/AR10-030-work-log/WorkLog/WorkLog';
import RouterTrackerForAnalytics from '../../lib/utils/RouterTrackerForAnalytics'; // [MEMO] 구글 애널리틱스 라우터 트래커

const WorkLogPage = () => {
  const $title = document.head.querySelector('title');
  $title!.innerText = '스마트 근무 일지';
  RouterTrackerForAnalytics();

  return (
    <>
      <WorkLog />
    </>
  );
};

export default WorkLogPage;
