// [MEMO] 공지사항
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface loadNoticeBoardDataProps {
  setNoticeData: Dispatch<SetStateAction<any[]>>;
}
export const loadNoticeBoardData = async ({
  setNoticeData,
}: loadNoticeBoardDataProps) => {
  const token = sessionStorage.getItem('token');
  const employeeNum = sessionStorage.getItem('employeeNum');

  await axios
    .get(
      // https://dev.mmigroup.co.kr:8020/dashboard/da10010/boardlist/1?ItemPerPage=0&ItemStartPage=0&employeeno=000001
      `${process.env.COMMON_URL}/dashboard/da10010/boardlist/1?ItemPerPage=0&ItemStartPage=0&employeeno=${employeeNum}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      try {
        if (res.data.ErrorCode === 0) {
          setNoticeData(res.data.Data);
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
