// CV10-020 차량 등록 관리 - Addr1(동) 리스트를 불러오는 API

import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface loadApartmentBlockListProps {
  apartmentIdx: number;
  setApartmentBlockListData: Dispatch<SetStateAction<never[]>>;
}
export const loadApartmentBlockList = async ({
  apartmentIdx,
  setApartmentBlockListData,
}: loadApartmentBlockListProps) => {
  const token = sessionStorage.getItem('token');
  const apartmentRefIdx = sessionStorage.getItem('apartmentRefIdx');

  let currentApartmentRefIdx = '';

  if (Number(apartmentRefIdx) !== 0) {
    currentApartmentRefIdx = `${apartmentRefIdx}`;
  } else {
    currentApartmentRefIdx = `${apartmentIdx}`;
  }

  await axios
    .get(
      // https://dev.mmigroup.co.kr:8020/vehicle/cv10010/getaddrlist/1?ItemPerPage=0&ItemStartPage=0
      `${process.env.COMMON_URL}/vehicle/cv10010/getaddrlist/${currentApartmentRefIdx}?ItemPerPage=0&ItemStartPage=0`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      try {
        if (res.data.ErrorCode === 0) {
          // console.log('loadApartmentBlockList/아파트 동 리스트-res.data.Data: ', res.data.Data);
          setApartmentBlockListData(res.data.Data);
        }
      } catch (e) {
        console.error(e);
      }
    });
};
