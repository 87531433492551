import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

interface onApartmentContractInfoProps {
  apartmentListData: any[];
  setApartmentListData: Dispatch<SetStateAction<any[]>>;
  setOnModal: Dispatch<SetStateAction<boolean>>;
}

export const onApartmentContractInfo = async ({
  apartmentListData,
  setApartmentListData,
  setOnModal,
}: onApartmentContractInfoProps) => {
  const token = sessionStorage.getItem('token');

  await axios
    .get(
      // 'https://apihr.mmigroup.co.kr:9020/common/contractinfo?ItemPerPage=10&ItemStartPage=0'
      `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0&Valid=1`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      console.log(
        '@API확인',
        `${process.env.COMMON_URL}/common/contractinfo?ItemPerPage=0&ItemStartPage=0&Valid=1`
      );
      try {
        setApartmentListData(res.data.Data);

        setOnModal(true);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
