import styled from 'styled-components';

export const Input = styled.input`
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    /* opacity: 0.5; */ /* Firefox */
    color: #a0a0a0;
  }

  &.input-short {
    display: inline-block;
    background-color: white;
    border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
    border-radius: 0.5em;
    color: ${({ theme }) => theme.fontColor.black};
  }

  &.input-short--small {
    padding: 10px;
    width: 150px;
    height: 35px;
    font-size: 13px;
  }

  &.input-short--medium {
    padding: 10px 15px;
    width: 320px;
    height: 44px;
    font-size: 14px;
  }

  &.input-short--large {
    padding: 10px 15px;
    width: 540px;
    height: 48px;
    font-size: 14px;
  }
`;
