import React, { Dispatch, SetStateAction } from 'react';
import axios from 'axios';

interface onChangePhoneNumberProps {
  userName: string;
  regNum: string;
  oldPhoneNumber?: string;
  currentPhoneNumber: string;
  verifiedCode: string;
  // recordListData: any[];
  // setRecordListData: Dispatch<SetStateAction<any[]>>;
  // setLoading: Dispatch<SetStateAction<boolean>>;
}

export const onChangePhoneNumber = async ({
  userName,
  regNum,
  oldPhoneNumber,
  currentPhoneNumber,
  verifiedCode,
}: onChangePhoneNumberProps) => {
  if (!userName) {
    return alert('이름을 입력해주세요');
  } else if (!currentPhoneNumber) {
    return alert('변경할 전화번호를 입력해주세요');
  }

  await axios
    .post(
      // https://apihr.mmigroup.co.kr:9020/common/logincheck/changenumber?lang=ko&corpId=MM
      `${process.env.COMMON_URL}/common/logincheck/changenumber?lang=ko&corpId=MM`,
      {
        UserName: `${userName}`,
        RegNumber: '0000000',
        OldNumber: `${oldPhoneNumber}`,
        Number: `${currentPhoneNumber}`,
        AuthMessage: `${verifiedCode}`,
      }
    )
    .then(res => {
      console.log(
        '전화번호 변경 확인 - API확인',
        `${process.env.COMMON_URL}/common/logincheck/changenumber?lang=ko&corpId=MM`
      );

      try {
        console.log(res.data, 'res.data');
        if (res.data.ErrorCode === 0) {
          alert('전화번호가 변경되었습니다');
          window.close();
        } else if (res.data.ErrorCode !== 0) {
          return alert('전화번호 변경에 실패했습니다');
        }
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
