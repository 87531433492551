// src/ApartmentInfoPage/ApartmentInfoPage.tsx

import { createSlice } from '@reduxjs/toolkit';

export const selectedCategoryAtApartmentSlice = createSlice({
  name: 'Selected Code Value at Apartment Information Page',
  initialState: {
    selectedAreaCodeValue: '', // 근무 지역
  },
  reducers: {
    selectedAreaCode: (state, action) => {
      state.selectedAreaCodeValue = action.payload;
    },
  },
});

export const {
  selectedAreaCode, // 근무 지역
} = selectedCategoryAtApartmentSlice.actions;
