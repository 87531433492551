import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

import { onEncodedString } from './onEncodedString'; // 문자열을 base64URL로 변환

interface onLoadBtnProps {
  setLogDataLoading: Dispatch<SetStateAction<boolean>>;
  setLogListData: Dispatch<SetStateAction<never[]>>;
  logListData: any[];
  apartmentContractIdx: string;
  selectedContractStart: string;
  selectedContractEnd: string;
}

export const onLoadBtn = async ({
  setLogDataLoading,
  setLogListData,
  logListData,
  apartmentContractIdx,
  selectedContractStart,
  selectedContractEnd,
}: onLoadBtnProps) => {
  const token = sessionStorage.getItem('token');
  const contractIdx = sessionStorage.getItem('contractIdx'); // 아파트 계약 인덱스

  // const startDate = new Date(`${selectedContractStart}T15:00:00.000Z`);
  // const endDate = new Date(`${selectedContractEnd}T00:00:00.000Z`);

  // 아래는 9시간을 의미하는 밀리세컨드. 왜 9시간이냐면 UTC와 한국 시간 차가 9시간임
  const koreaTimeDiff = 9 * 60 * 60 * 1000;

  // 👇
  // const startDate = new Date(`${selectedContractStart}T06:00:00.000Z`);
  // const koreaStartDate = new Date(startDate.getTime() + koreaTimeDiff);
  // const koreaEndDate = new Date(endDate.getTime() + koreaTimeDiff);
  // 그냥 딱.. 위 두줄로 끝났음 좋으련만... 이렇게 하면 문제가 생긴다.
  // 특정 날짜가 2023-05-10라 하면 이 날짜에 맞춰9시간이 더해진다
  // 9시간 더하는 것은 맞는데.. 문제는 내가 원하는 오프셋 결과는 한국시간 기준 2023년5월10일 T00:00:00이라는 거다.
  // T00:00:00에 맞추기 위해서 T06:00:00으로 넣은 거다. 근데 여기서 문제!!
  /*
  하루 빼는 이유는...
  한국 시간은 UTC로부터 +9시간 이므로,
  한국 시간 기준 T00:00:00에 맞추려면
  UTC는 여기서 -9시간이 되기에 이전 날짜가 될 수 밖에 없는 것이기 때문.
   */
  //근데 문제!!
  // const startDate = new Date(`${selectedContractStart}T15:00:00.000Z`); <-처럼 T15:00:00이어야할거같은데
  //왜 T06:00:00해야 T00:00:00으로 나오는 지 모르겠음...
  // 👆

  // 특정 날짜의 UTC 시간을 구하기.
  const startDate = new Date(`${selectedContractStart}T06:00:00.000Z`);
  const utcStartDate = startDate.getTime();

  const endDate = new Date(`${selectedContractEnd}T06:59:59.000Z`);
  const utcEndDate = endDate.getTime();

  // 선택한 특정 날짜의 어제 날짜의 UTC 시간을 구한다.
  const utcYesterdayForStartDate = new Date(utcStartDate - 24 * 60 * 60 * 1000);
  const utcYesterdayForEndDate = new Date(utcEndDate - 60 * 60 * 1000);
  // -24: 하루 전날로 해야하기에... 왜냐면 여기에 시간이 더해지게 때문에 UTC날짜+1일이 한국 시간(날짜)가 됨

  /**
   * 정리
   * const startDate = new Date(`${selectedContractStart}T06:00:00.000Z`);
   * const utcDate = startDate.getTime();
   * 요기까지하면... 그냥 UTC기준 날짜를 구하는 것임
   * 문제는 여기서 끝나면 안됨. 왜냐하면 한국 시간을 기준으로해서 오프셋 값을 불러오고 싶기 때문임.
   *
   * 그래서 utcDate에 한국 시간을 더해야 함.
   * const koreaUtcDate = new Date(utcStartDate * 60 * 60 * 1000);
   * 여기까지 하고 콘솔로 koreaUtcDate를 확인하면... utcDate에 한국 시간을 더한 날짜가 나올 것임.
   *
   * 그런데 또 문제가 발생함.
   * 예를 들어 selectedContractStart값이 2023-05-10이라고 하면,
   * utcDate 날짜 부분 역시 2023-05-10가 되기를 원함. 그런데 문제는
   * +9시간이 되어버려서 다음 날이 될 가능성(혹은 T:00:00:00이 되지 않을 가능성 <-startDate에서 시간을 T06:00:00로 설정하지 않았을 경우)이 생김
   * 결과로 2023-05-11날짜가 나오거나 T:00:00:00가 아닌 시간이 될 수 있는 것임.
   * 원하는 건 2023-05-10T:00:00:00인데!!
   *
   * 위 경우에는 2023-05-11으로 날짜가 넘어가 버렸음
   * 그래서 이를 맞추기 위해 하루를 뺀 후에 -> utc를 한국 시간대로 변환 해야함
   * 하루 빼는 이유는...
   * 한국 시간은 UTC로부터 +9시간 이므로, 한국 시간 기준 T00:00:00에 맞추려면
   * UTC는 여기서 -9시간이 되기에 이전 날짜가 될 수 밖에 없는 것이기 때문.
   * 그래서 아래처럼 -24를 넣은 변수가 필요함
   * const utcYesterday = new Date(utcDate - 24 * 60 * 60 * 1000);
   *
   * utcYesterday변수로 하루가 빠진 utc을 구한 후, 한국 시간이 반영된 오프셋 값을 구함
   * const koreaTimeDiff = 9 * 60 * 60 * 1000;
   * const koreaYesterday = new Date(utcYesterday.getTime() + koreaTimeDiff);
   *
   * 그러면 한국 시간 기준 2023-05-10T:00:00 <를 위한... 오프셋이 반환됨
   */

  // const koreaYesterdayForStartDate = new Date(utcDate + koreaTimeDiff); <이코드를 사용못하는 이유는...
  // 하루를 빼지 않은 것이기 때문에!!! 그래서
  // const utcDate = startDate.getTime();
  // const utcYesterday = new Date(utcDate - 24 * 60 * 60 * 1000); <요 변수를 하나 또 만든 후에야

  // 어제 날짜의 한국 시간 기준 T00:00:00를 얻기 위해 아래 코드를 사용함...
  const koreaYesterdayForStartDate = new Date(
    utcYesterdayForStartDate.getTime() + koreaTimeDiff
  );
  const koreaYesterdayForEndDate = new Date(
    utcYesterdayForEndDate.getTime() + koreaTimeDiff
  );
  // const koreaYesterdayForStartDate = new Date(utcDate + koreaTimeDiff);

  /**
   * Date.getTimezoneOffset():
   * getTimezoneOffset()은 UTC와 현재 국가 간의 시간 차이를 분으로 환산하는 메소드이다.
   * 예를 들어 한국에서 메소드를 사용할시 -540을 반환하는데, 이는 UTC와 현재 국가가 540분(=9시간) 차이인 것을 의미한다.
   * 즉 -540은 UTC+09:00에 대한 로컬 시간대 오프셋을 나타낸다.
   *
   * getTimezoneOffset() 메서드 사용시, 다음 두 가지를 주의해야 한다.
   *
   * 1. 국가 설정과 관련헤 주의 할 점((getTimezoneOffset()을 사용할 때 주의할 점):
   * getTimezoneOffset() 메서드는 사용자가 현재 위치한 국가에 따라 반환하는 값(분)이 달라진다.
   * 따라서 특정 국가의 시간대를 원한다면, 해당 국가의 시간대 정보를 설정해야 한다.
   * 예를 들어, 사용자가 한국에서 액세스하면 한국의 시간대 오프셋이 반환되며,
   * 미국에서 액세스하면 미국의 시간대 오프셋이 반환된다.
   * 그러므로 특정 국가의 시간대를 원한다면, 원하는 국가의 시간대로 설정해야 한다.
   *
   * 2. 시간 설정과 관련해 주의할 점(Date.getTimezoneOffset() 사용할 때 주의할 점):
   * Date.getTimezoneOffset() 사용시, Date 객체 안에 시간을 없이 날짜만 입력해도 오프셋을 반환한다.
   *
   * 예)
   * 1) const someDay = new Date(2023, 8, 18T00:00:00.000Z).getTimezoneOffset();
   * 2) const someDay = new Date(2023, 8, 18).getTimezoneOffset();
   * 1번 처럼 시간까지 넣지 않고, 2번 처럼 날짜만 넣어도 1, 2번은 동일한 결과(-540)를 반환한다.
   * 하지만 시간 없이 날짜만 넣어 호출하는 경우, 사용자가 호출하는 시간에 따라
   * getTimezoneOffset()도 그 시간에 맞추어 반환하는데, 이를 알고 주의해야 한다.
   * 그래서 특정 시간대를 명확하게 얻고 싶다면 날짜와 시간대 정보를 함께 입력해야 한다.
   *
   * 이렇게 두 가지 주의사항을 고려해 getTimezoneOffset() 메소드를 사용하면 더 정확하게 시간대를 설정할 수 있다.
   */

  function formatISOWithOffset(date: Date) {
    const offsetMinutes = date.getTimezoneOffset();
    /**
     * offsetMinutes:
     * getTimezoneOffset() 메서드가 반환한 오프셋 값(분 단위).
     * getTimezoneOffset() 메서드는 UTC와 사용자가 위치한 국가 간의 시간 차를 분 단위로 반환한다.
     */
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;

    const offsetSign = offsetMinutes < 0 ? '+' : '-';
    /**
     * offsetSign:
     * offsetMinutes값이 음수면 '-'부호를, 양수면 '+' 부호를 나타낸다.
     */
    const offsetHoursAbs = offsetHours.toString().padStart(2, '0');
    const offsetMinutesAbs = offsetMinutesRemainder.toString().padStart(2, '0');

    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date
      .getHours()
      .toString()
      .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}${offsetSign}${offsetHoursAbs}:${offsetMinutesAbs}`;
  }

  const formattedStartDate = formatISOWithOffset(koreaYesterdayForStartDate);
  const formattedEndDate = formatISOWithOffset(koreaYesterdayForEndDate);
  // console.log('[AR10-030]스마트 근무내역/onLoadBtn - 시작 날짜(UTC 한국 기준): ', formattedStartDate);
  // console.log('[AR10-030]스마트 근무내역/onLoadBtn - 종료 날짜(UTC 한국 기준): ', formattedEndDate);

  let encodedStarDate = '';
  let encodedEndDate = '';

  function onBase64StartDate() {
    const date = formattedStartDate;
    const { encodedString } = onEncodedString({ date });
    encodedStarDate = encodedString;
    // console.log(
    //   '[AR10-030]스마트 근무 일지/onLoadBtn - encodedStarDate: ',
    //   encodedStarDate
    // );
  }

  function onBase64EndDate() {
    const date = formattedEndDate;
    const { encodedString } = onEncodedString({ date });
    encodedEndDate = encodedString;
    // console.log(
    //   '[AR10-030]스마트 근무 일지/onLoadBtn - encodedEndDate: ',
    //   encodedEndDate
    // );
  }

  onBase64StartDate();
  onBase64EndDate();

  if (!apartmentContractIdx) {
    return alert('단지를 선택해주세요');
  }

  setLogDataLoading(true);

  await axios
    .get(
      // https://apihr.mmigroup.co.kr:9020/attendance/ar10030/workrecordtag/23?StartDate=2023-05-01&EndDate=2023-08-01
      `${process.env.COMMON_URL}/attendance/ar10030/workrecordtag/${apartmentContractIdx}?StartDate=${encodedStarDate}&EndDate=${encodedEndDate}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '[AR10-030]스마트 근무내역 기본 데이터 API확인/onLoadBtn: ',
      //   `${process.env.COMMON_URL}/attendance/ar10030/workrecordtag/${apartmentContractIdx}?StartDate=${selectedContractStart}&EndDate=${selectedContractEnd}`,
      // );
      try {
        if (res.data.ErrorCode === 0) {
          setLogDataLoading(false);
          setLogListData(res.data);
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
