// [MEMO] 스마트폰 상태 조회(설치)
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface loadUserDeviceCheckInstallDataProps {
  setUserDeviceInstallCheckData: Dispatch<SetStateAction<never[]>>;
  startCheckInstallYearPicker: string;
  startCheckInstallMonthPicker: string;
  startCheckInstallDatePicker: string;
  endCheckInstallYearPicker: string;
  endCheckInstallMonthPicker: string;
  endCheckInstallDatePicker: string;
}

export const loadUserDeviceCheckInstallData = async ({
  setUserDeviceInstallCheckData,
  startCheckInstallYearPicker,
  startCheckInstallMonthPicker,
  startCheckInstallDatePicker,
  endCheckInstallYearPicker,
  endCheckInstallMonthPicker,
  endCheckInstallDatePicker,
}: loadUserDeviceCheckInstallDataProps) => {
  const token = sessionStorage.getItem('token');

  const calStartYear = String(startCheckInstallYearPicker).padStart(2, '0');
  const calStartMonth = String(startCheckInstallMonthPicker).padStart(2, '0');
  const calStartDate = String(startCheckInstallDatePicker).padStart(2, '0');

  const calEndYear = String(endCheckInstallYearPicker).padStart(2, '0');
  const calEndMonth = String(endCheckInstallMonthPicker).padStart(2, '0');
  const calEndDate = String(endCheckInstallDatePicker).padStart(2, '0');

  const startEndDate = `StartDate=${calStartYear}${calStartMonth}${calStartDate}&EndDate=${calEndYear}${calEndMonth}${calEndDate}&`;

  await axios
    .get(
      // https://apihr.mmigroup.co.kr:9020/dashboard/da10010/userdevicecheck?StartDate=20230501&EndDate=20230530&Action=I
      `${process.env.COMMON_URL}/dashboard/da10010/userdevicecheck?${startEndDate}Action=I`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '메인화면 / 스마트폰 상태 조회(설치) API: ',
      //   `${process.env.COMMON_URL}/dashboard/da10010/userdevicecheck?${startEndDate}Action=I`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          setUserDeviceInstallCheckData(res.data.Data);
          // console.log(
          //   '메인화면 / 스마트폰 상태 조회(설치) res.data.Data: ',
          //   res.data.Data
          // );
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
