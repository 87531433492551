import styled from 'styled-components';

export const Bar = styled.div`
  border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

export const Title = styled.div`
  ${({ theme }) => theme.contentsMainTitle};
  ${({ theme }) => theme.stopDrag};
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  //justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 30px;
  padding-left: 30px;
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ApartmentInfoBox = styled.div`
  display: flex;
`;

export const CategorySection = styled.div`
  ${({ theme }) => theme.stopDrag};
  display: flex;
  flex-direction: column;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 15px;
`;

export const Wrap = styled.div`
  display: flex;
`;

export const CategoryWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0;
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-top: none;
  border-bottom-right-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  background-color: ${({ theme }) => theme.backgroundColor.gray};

  @media (max-width: 900px) {
    //
  }
`;

export const PeriodSettingWrap = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    //flex-direction: column;
  }
`;

export const EssentialCategorySection = styled.div`
  ${({ theme }) => theme.stopDrag};
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 15px;

  &.on-option {
    padding-bottom: 0;
  }

  &.on-sub-option {
    padding-bottom: 0;
  }
`;

export const EssentialCategoryWrap = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  padding-left: 10px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-radius: 0.5em;
  color: ${({ theme }) => theme.fontColor.black};
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;

  &.on-option {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.on-sub-option {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  :hover {
    color: ${({ theme }) => theme.mainColor.main};
  }
`;

export const DatePickerWrap = styled.ul`
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    //margin-right: 0;
  }
`;

export const DatePickerBox = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;

  p {
    width: 50px;
  }

  @media (max-width: 900px) {
    //flex-direction: column;
    //align-items: flex-start;

    p {
      // margin-bottom: 5px;
    }
  }
`;

export const PeriodBtnListSection = styled.ul`
  float: left;
  display: flex;
  align-items: center;
`;

export const PeriodBtn = styled.div`
  float: left;
  margin-right: 2px;
`;

export const AdditionalCategorySection = styled.ul`
  display: flex;
`;

export const GridSection = styled.div`
  /* */
  width: 100%;
`;

export const GridWrap = styled.div`
  width: 100%;
  // display: flex;
  padding: 0 35px;
  //overflow-x: auto;
  //overflow-y: auto;
`;

export const TableWrap = styled.div`
  width: 100%;

  padding-top: 15px;
  padding-right: 30px;
  padding-left: 30px;
`;

export const Tables = styled.table`
  background-color: white;
  width: 100%;
  // table-layout: fixed; // overflow(스크롤) 구현하고 싶으면 fixed 활성화 해야 함
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};

  border-collapse: separate;
  border-radius: 0.5em;
`;

export const THead = styled.thead`
  background-color: white;
  height: 60px;
  font-weight: 500;
  border-bottom: red;
`;

export const TrHeader = styled.tr`
  font-size: 13px;
`;

export const ThEmployeeName = styled.th`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: start;
  width: 150px;
  height: 60px;
  padding-left: 10px;
  border-top-left-radius: 1em; /* [MEMO] Tables에서 적용한 0.5em에 영향 미치지 않으려고 더 크게 잡음 */
  border-bottom-left-radius: 1em; /* [MEMO] Tables에서 적용한 0.5em에 영향 미치지 않으려고 더 크게 잡음 */
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};
`;

export const ThContractList = styled.th`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 600px;
  height: 60px;
  padding-left: 10px;
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};
`;

export const ThRequestDate = styled.th`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 100px;
  height: 60px;
  padding-left: 10px;
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};
`;

export const ThOpenDate = styled.th`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 100px;
  height: 60px;
  padding-left: 10px;
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};
`;

export const ThSignDate = styled.th`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 100px;
  height: 60px;
  padding-left: 10px;
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};
`;

export const ThContractStatus = styled.th`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 150px;
  height: 60px;
  padding-left: 10px;
  border-top-right-radius: 1em; /* [MEMO] Tables에서 적용한 0.5em에 영향 미치지 않으려고 더 크게 잡음 */
  border-bottom-right-radius: 1em; /* [MEMO] Tables에서 적용한 0.5em에 영향 미치지 않으려고 더 크게 잡음 */
  color: ${({ theme }) => theme.fontColor.darkGray};
`;

export const Tbody = styled.tbody`
  color: ${({ theme }) => theme.fontColor.darkGray};
  font-size: 13px;
  font-weight: 400;
`;

export const TrBody = styled.tr`
  &.first-data-row {
    border-top: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  }

  &.first-data-row td {
    border-top: inherit;
    border-bottom: inherit;
  }

  &.last-data-row {
    border-bottom: none;
  }

  &.last-data-row td {
    border-bottom: inherit;
  }
`;

export const TdEmployeeName = styled.td`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: start;
  width: 150px;
  height: 60px;
  padding-left: 10px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  color: ${({ theme }) => theme.fontColor.darkGray};
`;

export const TdContractList = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 600px;
  height: 60px;
  padding-left: 10px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

export const TdRequestDate = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 100px;
  height: 60px;
  padding-left: 10px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

export const TdOpenDate = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 100px;
  height: 60px;
  padding-left: 10px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

export const TdSignDate = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 100px;
  height: 60px;
  padding-left: 10px;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  border-right: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
`;

export const TdContractStatus = styled.td`
  display: table-cell;
  vertical-align: middle;
  text-align: start;
  width: 150px;
  height: 60px;
  padding-left: 10px;
`;

export const ApartmentNameBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  font-weight: 500;
  font-size: 13px;

  p {
    color: ${({ theme }) => theme.fontColor.darkGray};
  }
`;

export const ApartmentIdx = styled.div`
  /* display: flex; */
  color: ${({ theme }) => theme.mainColor.main};
`;

export const ApartmentName = styled.div`
  /* font-weight: 600; */
`;

export const LoadBtnSection = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 30px;
  margin: 5px 0;
`;

export const LoadInfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
`;
