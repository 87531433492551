import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';

import { deleteAllServerData } from '../utils/deleteAllServerData';

interface ModalContentsProps {
  closeModal: () => void;
  setOpenBeforeInitialAlertModal: Dispatch<SetStateAction<boolean>>;
  apartmentIdx: number;
  setDetectChangingData: Dispatch<SetStateAction<boolean>>;
}

export const ModalContents = ({
  closeModal,
  setOpenBeforeInitialAlertModal,
  apartmentIdx,
  setDetectChangingData,
}: ModalContentsProps) => {
  return (
    <Wrap>
      <SignTextSection>
        <SignText>
          기존 데이터는 전부 삭제됩니다. 계속 진행하시겠습니까?
        </SignText>
      </SignTextSection>

      <ButtonList>
        <ButtonStyle
          className="yes-button"
          onClick={() =>
            deleteAllServerData({
              setOpenBeforeInitialAlertModal,
              apartmentIdx,
              setDetectChangingData,
            })
          }
        >
          예
        </ButtonStyle>
        <ButtonStyle className="close-button" onClick={closeModal}>
          아니오
        </ButtonStyle>
      </ButtonList>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
`;

const SignTextSection = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 30px;
  width: 100%;
`;

const ButtonList = styled.div`
  /* margin-top: 15px; */
`;

const SignText = styled.div`
  font-size: 14px;
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.printBtnStyle}

  width: 80px;
  cursor: pointer;

  &.yes-button {
    border: solid 1px ${({ theme }) => theme.mainColor.dark};
    background-color: ${({ theme }) => theme.mainColor.main};
    color: white;

    :hover {
      background-color: ${({ theme }) => theme.mainColor.dark};
    }
  }

  &.close-button {
    margin-left: 10px;
  }
`;
