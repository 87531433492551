import React from 'react';
import styled from 'styled-components';

import { CarParkingManagement } from '../../components/CV10-030-car-parking-management/CarParkingManagement/CarParkingManagement';

import RouterTrackerForAnalytics from '../../lib/utils/RouterTrackerForAnalytics'; // [MEMO] 구글 애널리틱스 라우터 트래커

const CarParkingManagementPage = () => {
  const $title = document.head.querySelector('title');
  $title!.innerText = '차량 등록 관리';
  RouterTrackerForAnalytics();

  return (
    <>
      <CarParkingManagement />
    </>
  );
};

export default CarParkingManagementPage;
