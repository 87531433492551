import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { AddContents } from './AddContents';

interface AddModalProps {
  setRightClickModalVisible: Dispatch<SetStateAction<boolean>>;
  closeModal: () => void;
  setIsUpdateStatus: Dispatch<SetStateAction<boolean>>;
  detailWorkData: any;
  cellData: any;
  employeeNum: string;
  targetWorkStatusIdx: string | null;
  calYear: string;
  calMonth: string;
}

export const AddModal = ({
  setRightClickModalVisible,
  closeModal,
  setIsUpdateStatus,
  detailWorkData,
  cellData,
  employeeNum,
  targetWorkStatusIdx,
  calYear,
  calMonth,
}: AddModalProps) => {
  setRightClickModalVisible(false);

  return (
    <Background onClick={closeModal}>
      <Container
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <AddContents
          closeModal={closeModal}
          setIsUpdateStatus={setIsUpdateStatus}
          detailWorkData={detailWorkData}
          cellData={cellData}
          employeeNum={employeeNum}
          targetWorkStatusIdx={targetWorkStatusIdx}
          calYear={calYear}
          calMonth={calMonth}
        />
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* rgba(0, 0, 0, 0.3) */
  z-index: 999;
`;

const Container = styled.div`
  padding: 10px;
  border-radius: 8px;
  background: white;
`;
