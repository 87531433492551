import React, { useState } from 'react';
import styled from 'styled-components';
import { MapModal } from './MapModal/MapModal';
import PlaceIcon from '@mui/icons-material/Place';

interface ModalContentsProps {
  workRecordData: any[];
}

export const ModalContents = ({ workRecordData }: ModalContentsProps) => {
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [cellData, setCellData] = useState(false);

  const controlMapModal = data => {
    setCellData(data);
    setMapModalVisible(true);
  };

  const closeMapModal = () => {
    setMapModalVisible(false);
  };

  const dataArray: {
    idx: string; // 기기? 인덱스
    date: string;
    employeeName: string; // 근무자
    employeeNum: string; // 사번
    time: string; // 시간
    action: string;
    trackingGroup: string;
    trackingType: string;
    trackingName: string; // 기기 이름
    trackingLocation: string; // 기기 설치 장소

    GPSAltitude: string; // GPSAltitude GPS고도
    GPSLatitude: string; // GPSLatitude GPS위도
    GPSLongitude: string; // GPSLongitude GPS경도

    tagId: string;
    IPAddressV4: string;
  }[] = [];

  function loadRecordListData() {
    workRecordData.forEach(data => {
      let trackingGroupName = ''; // 분류
      let trackingTypeName = ''; // 기기
      let actionName = ''; // 행동

      if (data.TrackingGroup === 'W') {
        trackingGroupName = '근무';
      } else if (data.TrackingGroup === 'A') {
        // 근태 = 출퇴근
        trackingGroupName = '근태';
      }

      if (data.TrackingType === 'N') {
        trackingTypeName = 'NFC';
      } else if (data.TrackingType === 'B') {
        trackingTypeName = 'Beacon';
      } else if (data.TrackingType === 'G') {
        trackingTypeName = 'GPS';
      } else if (data.TrackingType === 'W') {
        trackingTypeName = 'WIFI';
      }

      if (data.Action === 'I') {
        // [MEMO] I = 구역 내 위치함
        actionName = 'IN';
      } else if (data.Action === 'L') {
        // [MEMO] L = 특정 구역 벗어남(전체 범위 내에는 있음)
        actionName = 'LEAVE';
      } else if (data.Action === 'O') {
        // [MEMO] O = 전체 구역 벗어남
        actionName = 'OUT';
      } else if (data.Action === 'A') {
        actionName = '태그';
      }

      const resultData = {
        idx: data.idx,
        trackingName: data.TrackingName, // 기기 이름
        trackingLocation: data.TrackingLocation, // 기기 설치 장소

        employeeName: data.EmployeeName, // 근무자
        employeeNum: data.EmployeeNo, // 사번
        date: data.TrigerTime.slice(0, 10), // 날짜
        time: data.TrigerTime.slice(11, 19), // 시간
        action: actionName,
        trackingGroup: trackingGroupName,
        trackingType: trackingTypeName,

        GPSAltitude: data.GPSAltitude, // GPSAltitude GPS고도
        GPSLatitude: data.GPSLatitude, // GPSLatitude GPS위도
        GPSLongitude: data.GPSLongitude, // GPSLongitude GPS경도

        tagId: data.TagID,
        IPAddressV4: data.IPAddressV4,
      };

      dataArray.push(resultData);
    });
  }
  loadRecordListData();

  return (
    <>
      {mapModalVisible && (
        <MapModal closeMapModal={closeMapModal} cellData={cellData} />
      )}

      <Wrap>
        <Section>
          <Tables>
            <Thead>
              <Tr className="header">
                <Th>사번</Th>
                {/* <Th>인덱스</Th> */}

                <Th className="tag-id">태그ID</Th>
                <Th className="device">기기</Th>
                <Th className="installed-location">설치장소</Th>
                <Th>행동</Th>
                <Th>분류</Th>

                <Th className="date">날짜</Th>
                <Th className="time">시간</Th>
                <Th className="device-type">기기유형</Th>
                <Th className="ip-address">IP주소</Th>

                <Th className="last map-location">위치정보</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dataArray.map((data, idx) => {
                if (data) {
                  return (
                    <Tr className="body" key={data.idx}>
                      <Td>{data.employeeNum}</Td>
                      {/* [MEMO] 👇 인덱스 */}
                      {/* <Td>{data.idx}</Td> */}

                      <Td>{data.tagId}</Td>
                      <Td>{data.trackingName}</Td>
                      <Td>{data.trackingLocation}</Td>
                      <Td>{data.action}</Td>
                      <Td>{data.trackingGroup}</Td>

                      <Td>{data.date}</Td>
                      <Td>{data.time}</Td>
                      <Td>{data.trackingType}</Td>
                      <Td>{data.IPAddressV4}</Td>

                      <Td className="last">
                        {Number(data.GPSLatitude) <= 0 &&
                        Number(data.GPSLongitude) <= 0 ? (
                          ''
                        ) : (
                          <MapButton onClick={() => controlMapModal(data)}>
                            <PlaceIcon sx={{ fontSize: '18px' }} />
                          </MapButton>
                        )}
                      </Td>
                    </Tr>
                  );
                }
              })}
            </Tbody>
          </Tables>
        </Section>
      </Wrap>
    </>
  );
};

const Wrap = styled.div`
  padding: 15px;
`;

const Section = styled.div`
  ${({ theme }) => theme.scrollBarStyle};
  font-size: 13px;
  height: 600px;
  overflow-y: auto;
`;

const Tables = styled.table`
  position: relative;
`;

const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 10;
`;

const Th = styled.th`
  padding: 3px 8px;
  border-right: solid 1px white;

  &.last {
    border-right: none;
  }
`;

const Tbody = styled.tbody`
  max-height: 300px;
`;

const Tr = styled.tr`
  &.header {
    background-color: ${({ theme }) => theme.backgroundColor.gray};
  }

  &.body {
    /*  */
  }
`;

const Td = styled.td`
  display: table-cell; /* 테이블 셀 중앙정렬 */
  vertical-align: middle; /* 테이블 셀 중앙정렬 */
  text-align: center; /* 테이블 셀 중앙정렬 */
  padding: 5px;

  &.last {
    border-right: none;
  }
`;

const MapButton = styled.button`
  cursor: pointer;
`;
