import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface onLoadBtnProps {
  setLoading: Dispatch<SetStateAction<boolean>>;
  setRecordListData: Dispatch<SetStateAction<never[]>>;
  apartmentContractIdx: string;
  selectedContractStart: string;
  selectedContractEnd: string;
}

export const onLoadBtn = async ({
  setLoading,
  setRecordListData,
  apartmentContractIdx,
  selectedContractStart,
  selectedContractEnd,
}: onLoadBtnProps) => {
  const token = sessionStorage.getItem('token');
  const contractIdx = sessionStorage.getItem('contractIdx'); // 아파트 계약 인덱스

  if (!apartmentContractIdx) {
    return alert('단지를 선택해주세요');
  }

  setLoading(true);

  await axios
    .get(
      // https://apihr.mmigroup.co.kr:9020/attendance/ar10020/workrecordstatus/256?ItemPerPage=0&ItemStartPage=0&StartDate=20230523&EndDate=20230524
      `${process.env.COMMON_URL}/attendance/ar10020/workrecordstatus/${apartmentContractIdx}?ItemPerPage=0&ItemStartPage=0&StartDate=${selectedContractStart}&EndDate=${selectedContractEnd}`,
      {
        headers: { tokenId: token },
      }
    )
    .then(res => {
      // console.log(
      //   '[AR10-020] 스마트 근무내역 그리드 API확인: ',
      //   `${process.env.COMMON_URL}/attendance/ar10020/workrecordstatus/${apartmentContractIdx}?ItemPerPage=0&ItemStartPage=0&StartDate=${selectedContractStart}&EndDate=${selectedContractEnd}`
      // );
      try {
        if (res.data.ErrorCode === 0) {
          setLoading(false);
          setRecordListData(res.data.Data);
        }

        incorrectToken(res);
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });

  // setLoading(false);
};
