import React, { useState } from 'react';
import styled from 'styled-components';

// import { Mainbar } from '../../components/Mainbar/Mainbar';
import { Navbar } from '../../components/Navbar/Navbar';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { WorkStatusEmployee } from '../../components/AC10-030-work-status-employee/WorkStatusEmployee/WorkStatusEmployee';

import RouterTrackerForAnalytics from '../../lib/utils/RouterTrackerForAnalytics';

const WorkStatusEmployeePage = () => {
  const $title = document.head.querySelector('title');
  $title!.innerText = '조직원 근무';
  RouterTrackerForAnalytics();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  function handleSidebar() {
    setIsSidebarOpen(!isSidebarOpen);
  }

  return (
    <>
      {/* <Mainbar /> */}

      {/* 👇 1. 네브바 */}
      {/* <Navbar isSidebarOpen={isSidebarOpen} handleSidebar={handleSidebar} /> */}
      {/* 👆 1. 네브바 */}

      {/* <Body> */}
      {/* 👇 2. 사이드바 */}
      {/* <Sidebar isSidebarOpen={isSidebarOpen} /> */}
      {/* 👆 2. 사이드바 */}
      {/* <Contents> */}
      {/* <Wrap> */}
      {/* 👇 3. 페이지 */}
      <WorkStatusEmployee />
      {/* 👆 3. 페이지 */}
      {/* </Wrap>
        </Contents>
      </Body> */}
    </>
  );
};

export default WorkStatusEmployeePage;

const Body = styled.div`
  display: flex;
  margin-top: 55px; /* 네브바의 height 감안 */
`;

const Contents = styled.div`
  margin-left: 200px; /* 사이드바의 width 감안 */
  width: 100vw;
  height: calc(100vh - 55px); /* 네브바의 height 감안 */
  background-color: orange;
  /* background-color: ${({ theme }) => theme.backgroundColor.gray}; */
  /* flex: 1; */

  min-width: 0; /* 🔥 */
  /* max-height: 0; */
  transition: 0.2s ease-out;

  @media (max-width: 900px) {
    margin-left: 0; /* [MEMO] 사라진 사이드바 자리 채우기 */
    transition: 0.2s ease-out;
  }
`;

const Wrap = styled.div`
  padding: 15px;
  background-color: pink;
  /* background-color: ${({ theme }) => theme.backgroundColor.gray}; */
`;
