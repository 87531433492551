// ref가 있는 인덱스 API (=단지 고유 번호(계약 아님))

import React, { useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { searchApartmentName } from './searchApartmentName';
import { onEncodedString } from './onEncodedString'; // 문자열을 base64URL로 변환

import { Input } from '../UI/Input/Short';
import { Button } from '../UI/Button/Short';

interface ModalContentsProps {
  closeModal: () => void | undefined;
  apartmentListData: any[];
  setApartmentListData: Dispatch<SetStateAction<any[]>>;
  apartmentIdx: string; // 아파트 '계약' 인덱스(6자리 아님)
  setApartmentIdx: Dispatch<SetStateAction<string>>;
  apartmentName: string;
  setApartmentName: Dispatch<SetStateAction<string>>;
}

export const ModalContents = ({
  closeModal,
  apartmentListData,
  setApartmentListData,
  apartmentIdx,
  setApartmentIdx,
  apartmentName,
  setApartmentName,
}: ModalContentsProps) => {
  const permitCode = sessionStorage.getItem('permitCode');

  const [enteredName, setEnteredName] = useState('');
  const [openResult, setOpenResult] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  // [MEMO] onEnteredName: 일반 문자열을 BASE64로 인코딩
  const onEnteredName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { encodedString } = onEncodedString({ e });
    setEnteredName(encodedString);
  };

  // [MEMO] enteredNameValue: 단지 이름으로 검색시
  // const enteredNameValue = enteredName ? `&Name=${enteredName}` : '';
  const enteredNameValue = `&Name=${enteredName}`;

  const pressEnter = (e: { key: string }) => {
    if (e.key === 'Enter') {
      return searchApartmentName({
        apartmentListData,
        setApartmentListData,
        enteredNameValue,
        setOpenResult,
        setSearchResult,
      });
    }
  };

  return (
    <Section>
      <SelectedApartmentTitle>선택한 단지</SelectedApartmentTitle>
      <SelectedApartmentBox>
        <SelectedApartmentContents>
          <SelectedApartmentIdx>{apartmentIdx}</SelectedApartmentIdx>
          <SelectedApartmentName>{apartmentName}</SelectedApartmentName>
        </SelectedApartmentContents>
        <Button label="닫기" onClick={closeModal} />
      </SelectedApartmentBox>

      <ApartmentListTitle
        className={
          permitCode === process.env.HEAD_OFFICE_MANAGER_CODE
            ? 'head-office-manager'
            : 'apartment-manager'
        }
      >
        검색 단지 리스트
      </ApartmentListTitle>
      <SearchWrap
        className={
          permitCode === process.env.HEAD_OFFICE_MANAGER_CODE
            ? 'head-office-manager'
            : 'apartment-manager'
        }
      >
        {/* [MEMO] 👇 계약 단지 입력 */}
        <InputWrap>
          <Input
            placeholder="단지 이름"
            onChange={onEnteredName}
            onKeyPress={pressEnter}
          />
        </InputWrap>

        <Button
          label="검색"
          onClick={() =>
            searchApartmentName({
              apartmentListData,
              setApartmentListData,
              enteredNameValue,
              setOpenResult,
              setSearchResult,
            })
          }
        />
      </SearchWrap>

      <SearchResultWrap
        className={
          permitCode === process.env.HEAD_OFFICE_MANAGER_CODE
            ? 'head-office-manager'
            : 'apartment-manager'
        }
      >
        {openResult ? (
          <>
            {searchResult.map(data => {
              return (
                <ApartmentListBox
                  key={data.idx}
                  onClick={() => {
                    setApartmentIdx(data.idx);
                    setApartmentName(data.Name);
                    closeModal();
                  }}
                  //onDoubleClick={closeModal}
                >
                  <ApartmentIdx>{data.idx}</ApartmentIdx>
                  <ApartmentName>
                    {data.Name}
                  </ApartmentName>
                </ApartmentListBox>
              );
            })}
          </>
        ) : (
          <DefaultSearchBox>
            <div>검색 결과</div>
          </DefaultSearchBox>
        )}
      </SearchResultWrap>

      <DefaultListWrap
        className={
          permitCode === process.env.HEAD_OFFICE_MANAGER_CODE
            ? 'head-office-manager'
            : 'apartment-manager'
        }
      >
        {apartmentListData.map(data => {
          return (
            <ApartmentListBox
              key={data.idx}
              onClick={() => {
                setApartmentIdx(data.idx);
                setApartmentName(data.Name);
                closeModal();
              }}
              //onDoubleClick={closeModal}
            >
              <ApartmentIdx>{data.idx}</ApartmentIdx>
              <ApartmentName>
                {data.Name}
              </ApartmentName>
            </ApartmentListBox>
          );
        })}
      </DefaultListWrap>
    </Section>
  );
};

const Section = styled.div`
  font-size: 13px;
  font-weight: 500;
`;

const SelectedApartmentTitle = styled.div`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.mainColor.main};
`;

const SelectedApartmentBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const SelectedApartmentContents = styled.div`
  display: flex;
`;

const SelectedApartmentIdx = styled.div`
  margin-right: 5px;
  color: ${({ theme }) => theme.mainColor.main};
`;

const SelectedApartmentName = styled.div`
  /* padding: 20px; */
`;

const ApartmentListTitle = styled.div`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.mainColor.main};

  &.head-office-manager {
    display: block;
  }

  &.apartment-manager {
    display: none;
  }
`;

const SearchWrap = styled.div`
  justify-content: space-between;
  margin-bottom: 5px;

  &.head-office-manager {
    display: flex;
  }

  &.apartment-manager {
    display: none;
  }
`;

const InputWrap = styled.div`
  margin-right: 5px;
`;

const DefaultListWrap = styled.div`
  ${({ theme }) => theme.scrollBarStyle};
  overflow: auto;

  &.head-office-manager {
    height: 300px;
  }

  &.apartment-manager {
    width: 350px;
    height: 100px;
    border-radius: 0.5em;
    border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  }
`;

const ApartmentListBox = styled.div`
  display: flex;
  padding: 10px 0 10px 5px;

  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.mainColor.main};
    color: white;
  }
`;

const ApartmentIdx = styled.div`
  margin-right: 5px;
`;

const ApartmentName = styled.div``;

const SearchResultWrap = styled.div`
  ${({ theme }) => theme.scrollBarStyle};
  margin-bottom: 5px;
  padding: 10px;
  width: 400px;
  height: 100px;
  border-radius: 0.5em;
  border: solid 1px ${({ theme }) => theme.backgroundColor.shadowGray};
  overflow: auto;

  &.apartment-manager {
    display: none;
  }
`;

const DefaultSearchBox = styled.div`
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  color: ${({ theme }) => theme.fontColor.lightGray};
`;
