import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { incorrectToken } from '../../../lib/utils/incorrectToken';

interface ModalContentsProps {
  closeModal: () => void;
  apartmentIdx: number;
}

interface PrivateSet {
  MaskingName: boolean;
  MaskingPhoneNo: boolean;
  MaskingDong: boolean;
  MaskingHO: boolean;
}

export const ModalContents = ({
  closeModal,
  apartmentIdx,
}:
ModalContentsProps) => {
  const token = sessionStorage.getItem('token');

  const [validPrivateData, setValidPrivateData] = useState(false);
  const [listData, setListData] = useState([] );
  const [privateData, setPrivateData] = useState<PrivateSet>(
    {  MaskingName: true, MaskingPhoneNo: true, MaskingDong: true, MaskingHO: true }
  );

  useEffect(() => {
    axios
      .get(
        `${process.env.COMMON_URL}/vehicle/cv10020/privateset/${apartmentIdx}`,
        {
          headers: { tokenID: token },
        }
      )
      .then(res => {
        try {
          if (res.data.ErrorCode === 0) {
            //console.log('res.data.Data: ', res.data.Data);
            setListData(res.data.Data[0]);
          }
          incorrectToken(res);
        } catch (e) {
          console.error(e);
          console.log(
            `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
          );
        }
      });
  }, []);

  useEffect(() => {
    setPrivateData(listData);
  }, [listData]); // listData가 변경될 때만 실행

  useEffect(() => {
    //console.log('validPrivateData: ', validPrivateData );
  }, [validPrivateData]);

  const toggleBoxActivity = (idx: number) => {
    switch (idx) {
      case 1:
          privateData.MaskingName = !privateData.MaskingName;
          break;
      case 2:
          privateData.MaskingPhoneNo = !privateData.MaskingPhoneNo;
          break;
      case 3:
          privateData.MaskingDong = !privateData.MaskingDong;
          break;
      case 4:
          privateData.MaskingHO = !privateData.MaskingHO;
          break;
    }
    setPrivateData(privateData);
    setValidPrivateData(!validPrivateData);
    //console.log('PrivateData: ', privateData );
  };


  const handleSaveData = async () => {
    //console.log('PrivateData: ', privateData );
    await axios({
      url: `${process.env.COMMON_URL}/vehicle/cv10020/privateset/${apartmentIdx}`,
      method: 'put',
      headers: { tokenId: token },
      data: {
        MaskingName : privateData.MaskingName,
        MaskingPhoneNo : privateData.MaskingPhoneNo,
        MaskingDong : privateData.MaskingDong,
        MaskingHO : privateData.MaskingHO
      },
    }).then(res => {
      if (res.data.ErrorCode === 0) {
        //console.log('🍓res.data.Data: ', res.data.Data);
        alert('개인정보 마스킹 설정이 저장되었습니다');
        incorrectToken(res);
      }

      if (res.data.ErrorCode !== 0) {
        alert(`ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`);
      }
      // console.error(e);
      console.log(
        `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
      );
    });
    //await onSaveData({ privateData:PrivateTempData, setListData });
    closeModal();
  };

  return (
    <Wrap>
      <NoticeBox>
        · 근무자에게 공개할 등록 차량 개인 정보를 설정해주세요.
        <br />
        · '마스킹 처리'/'개인정보 표시' 버튼을 눌러 해당 항목의 표시 여부를 선택 할 수 있습니다.
        <br />
        <div>
          &nbsp;
          <p className="alert">
            '마스킹 처리'로 선택된 경우, 해당 정보는 근무자에게 **** 로 표시됩니다.
            <br />
            '개인정보 표시'로 선택된 경우, 등록된 정보가 근무자에게 표시됩니다. (단, 정보를 등록하지 않으면 표시되지 않습니다.)
          </p>
        </div>
      </NoticeBox>

      <CategoryListSection>
      <CategoryBox>
          <CategoryTitle>
            차량 소유자 이름 표기
          </CategoryTitle>
          <ApprovalBoxWrap >
            <ApprovalBoxActiveBtn className={`${privateData.MaskingName ? 'on' : 'off'}`} onClick={() => toggleBoxActivity(1)}>
              {privateData.MaskingName ? '개인정보 마스킹 처리' : '개인정보 표시'}
            </ApprovalBoxActiveBtn>
          </ApprovalBoxWrap>
        </CategoryBox>
        <CategoryBox>
          <CategoryTitle>
            차량 소유자 연락처 표기
          </CategoryTitle>
          <ApprovalBoxWrap >
            <ApprovalBoxActiveBtn className={`${privateData.MaskingPhoneNo ? 'on' : 'off'}`} onClick={() => toggleBoxActivity(2)}>
              {privateData.MaskingPhoneNo ? '개인정보 마스킹 처리' : '개인정보 표시'}
            </ApprovalBoxActiveBtn>
          </ApprovalBoxWrap>
        </CategoryBox>
        <CategoryBox>
          <CategoryTitle>
            차량 소유자 동 표기
          </CategoryTitle>
          <ApprovalBoxWrap >
            <ApprovalBoxActiveBtn className={`${privateData.MaskingDong ? 'on' : 'off'}`} onClick={() => toggleBoxActivity(3)}>
              {privateData.MaskingDong ? '개인정보 마스킹 처리' : '개인정보 표시'}
            </ApprovalBoxActiveBtn>
          </ApprovalBoxWrap>
        </CategoryBox>
        <CategoryBox>
          <CategoryTitle>
            차량 소유자 호수 표기
          </CategoryTitle>
          <ApprovalBoxWrap >
            <ApprovalBoxActiveBtn className={`${privateData.MaskingHO ? 'on' : 'off'}`} onClick={() => toggleBoxActivity(4)}>
              {privateData.MaskingHO ? '개인정보 마스킹 처리' : '개인정보 표시'}
            </ApprovalBoxActiveBtn>
          </ApprovalBoxWrap>
        </CategoryBox>
      </CategoryListSection>
      <ButtonList>
        <ButtonStyle
          className="yes-button"
          onClick={handleSaveData}
        >
          저장
        </ButtonStyle>
        <ButtonStyle className="close-button" onClick={closeModal}>
          닫기
        </ButtonStyle>
      </ButtonList>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
`;

const NoticeBox = styled.div`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.backgroundColor.gray};
  border-radius:0.5em;
  line-height:20px;

  p {
    display: flex;

    &.bold {
      font-weight: 500;
    }

    &.alert {
      color: red;
    }
  }
`;

const ApprovalBoxWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ApprovalBoxActiveBtn = styled.button`
  ${({ theme }) => theme.printBtnStyle};
  margin-bottom: 5px;
  width: 200px;
  cursor: pointer;

  &.on {
    background-color:  #ededed;
  }

  &.off {
    background-color: rgba(168, 242, 167, 0.8);
    /* background-color: ${({ theme }) => theme.statusColor.working}; */
  }
`;

const CategoryListSection = styled.div`

  padding: 14px 10px;
  border: solid 1px ${({ theme }) => theme.backgroundColor.gray};
  border-radius: 5px;
`;

const ButtonList = styled.div`
  margin-top: 15px;
`;

const ButtonStyle = styled.button`
  ${({ theme }) => theme.printBtnStyle}

  width: 80px;
  cursor: pointer;

  &.yes-button {
    border: solid 1px ${({ theme }) => theme.mainColor.dark};
    background-color: ${({ theme }) => theme.mainColor.main};
    color: white;

    :hover {
      background-color: ${({ theme }) => theme.mainColor.dark};
    }
  }

  &.close-button {
    margin-left: 10px;
  }
`;

const CategoryBox = styled.div`
  display: flex;
  margin-bottom: 5px;

  &.last-category {
    margin-bottom: 0;
  }
`;

const CategoryTitle = styled.label`
  display: flex;
  align-items: center;
  width: 200px;
  height: 30px;
  font-size: 14px;

  p {
    margin-left: 2px;
    color: red;
    font-size: 11px;
  }
`;
