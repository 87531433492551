export const gridOptions = {
  columnDefs: [
    {
      field: 'nameKR',
      headerName: '이름',
      resizable: true,
      filter: true,
      sortable: true,
      width: 150,
      cellClass: 'cell-class',
    },
    {
      field: 'employeeNum',
      headerName: '사번',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
    },
    {
      field: 'userId',
      headerName: '아이디',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
    },
    {
      field: 'permitCode',
      headerName: '직위 코드',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
    },
    {
      field: 'areaName',
      headerName: '지역',
      resizable: true,
      filter: true,
      sortable: true,
      width: 150,
      cellClass: 'cell-class-contents',
    },
    {
      field: 'jobName',
      headerName: '직무',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
    },
    {
      field: 'userStatus',
      headerName: '근무 상태',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
    },
    {
      field: 'enterDate',
      headerName: '입사일',
      resizable: true,
      filter: true,
      sortable: true,
      width: 150,
      cellClass: 'cell-class-contents',
    },
    {
      field: 'birthDay',
      headerName: '생년월일',
      resizable: true,
      filter: true,
      sortable: true,
      width: 150,
      cellClass: 'cell-class-contents',
    },
  ],

  // custom loading template. the class ag-overlay-loading-center is part of the grid,
  // it gives a white background and rounded border
  overlayLoadingTemplate:
    '<span class="ag-overlay-loading-center" style="padding: 10px; border: 1px solid #444; background: lightgoldenrodyellow;">데이터를 불러오는 중입니다</span>',

  overlayNoRowsTemplate:
    '<span style="padding: 10px; border: 3px solid #ededed; border-radius: 0.5em; background-color: white; box-shadow: 5px 5px 15px -3px #ededed; color: #717171; font-weight: 600;">불러온 데이터가 없습니다</span>',
};
