export const gridOptions = {
  columnDefs: [
    {
      field: 'idx',
      headerName: '계약번호',
      resizable: true,
      filter: true,
      sortable: true,
      width: 80,
      // cellClass: 'cell-class',
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'name',
      headerName: '단지명',
      resizable: true,
      filter: true,
      sortable: true,
      width: 300,
      cellClass: 'cell-class',
      // cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'areaName',
      headerName: '지역',
      resizable: true,
      filter: true,
      sortable: true,
      width: 140,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'jobGroupEtc',
      headerName: '계약직군',
      resizable: true,
      filter: true,
      sortable: true,
      width: 140,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'noEmployee',
      headerName: '근무인원',
      resizable: true,
      filter: true,
      sortable: true,
      width: 80,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },

    {
      field: 'noHouse',
      headerName: '세대수',
      resizable: true,
      filter: true,
      sortable: true,
      width: 80,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'manageArea',
      headerName: '관리면적',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'startDate',
      headerName: '계약 시작일',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'endDate',
      headerName: '계약 종료일',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'employeeNameAccount',
      headerName: '회계사',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
    {
      field: 'employeeNameManager',
      headerName: '관리자',
      resizable: true,
      filter: true,
      sortable: true,
      width: 120,
      cellClass: 'cell-class-contents',
      cellStyle: { textAlign: 'start' },
    },
  ],

  // custom loading template. the class ag-overlay-loading-center is part of the grid,
  // it gives a white background and rounded border
  overlayLoadingTemplate:
    '<span class="ag-overlay-loading-center" style="padding: 10px; border: 1px solid #444; background: lightgoldenrodyellow;">데이터를 불러오는 중입니다</span>',

  overlayNoRowsTemplate:
    '<span style="padding: 10px; border: 3px solid #ededed; border-radius: 0.5em; background-color: white; box-shadow: 5px 5px 15px -3px #ededed; color: #717171; font-weight: 600;">불러온 데이터가 없습니다</span>',
};
