import React, { Dispatch, SetStateAction } from 'react';
import axios from 'axios';

interface onChangePasswordProps {
  userName: string;
  regNum: string;
  currentPhoneNumber: string;
  verifiedCode: string;
  newPassword: string;
  checkPassword: string;
  // recordListData: any[];
  // setRecordListData: Dispatch<SetStateAction<any[]>>;
  // setLoading: Dispatch<SetStateAction<boolean>>;
}

export const onChangePassword = async ({
  userName,
  regNum,
  currentPhoneNumber,
  verifiedCode,
  newPassword,
  checkPassword,
}: onChangePasswordProps) => {
  /**
   * [MEMO] 정규표현식 (조건: 최소 6자, 최대 15자 하나의 문자 및 하나의 숫자 포함)
   * 정규 표현식 문제 있는 듯... 조건에 맞지 않음 (수정할 필요 있음)
   * https://mmigroup.atlassian.net/wiki/spaces/MMI/pages/61702231?focusedCommentId=144637953
   */
  const isValidPw = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,15}$/.test(
    newPassword
  );

  if (!userName) {
    return alert('이름을 입력해주세요');
  } else if (!newPassword) {
    return alert('변경할 비밀번호를 입력해주세요');
  } else if (newPassword !== checkPassword) {
    return alert('비밀번호가 일치하지 않습니다');
  }

  await axios
    .post(
      // https://apihr.mmigroup.co.kr:9020/common/logincheck/resetpassword?lang=ko&corpId=MM
      `${process.env.COMMON_URL}/common/logincheck/resetpassword?lang=ko&corpId=MM`,
      {
        username: `${userName}`,
        regnumber: '0000000',
        number: `${currentPhoneNumber}`,
        AuthMessage: `${verifiedCode}`,
        resetPassword: `${newPassword}`,
      }
    )
    .then(res => {
      console.log(
        '비밀번호 변경 확인 - API확인',
        `${process.env.COMMON_URL}/common/logincheck/resetpassword?lang=ko&corpId=MM`
      );

      try {
        console.log(res.data, 'res.data');
        if (res.data.ErrorCode === 0) {
          // alert('비밀번호가 변경되었습니다');
          alert(`${res.data.Data[0].ResultMessage}`);
          console.log('👏1', res.data.ErrorCode);
          // window.close();
          if (res.data.Data[0].ResultMessage === '') {
            alert('비밀번호가 변경되었습니다');
          }
        } else if (res.data.ErrorCode !== 0) {
          console.log('👏2', res.data.ErrorCode);
          // return alert('이름 혹은 인증번호가 적합하지 않습니다');
          return alert(`${res.data.Data[0].ResultMessage}`);
        }
      } catch (e) {
        console.error(e);
        console.log(
          `ErrorCode: ${res.data.ErrorCode}, ErrorMsg: ${res.data.ErrorMsg}`
        );
      }
    });
};
