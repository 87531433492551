/* 현재 파일은 configureStore을 위한 파일 */

import { combineReducers, configureStore } from '@reduxjs/toolkit';
/* configureStore:
작은 스토어(=slice)를 모아서 큰 스토어를 만들 때 사용한다.
그러니까 configureStore가 큰 스토어 역할을 한다. 여기(configureStore)에 slice들을 모을 것이기 때문에. */

import { persistReducer } from 'redux-persist'; // redux-persist

// import storage from 'redux-persist/lib/storage'; // redux-persist
import storage from 'redux-persist/lib/storage/session';

import { loggedInMenuDataSlice } from '../slice/loggedInMenuDataSlice';
import { loggedInUserBasicSlice } from '../slice/loggedInUserBasicSlice';
import { loggedInUserDetailSlice } from '../slice/loggedInUserDetailSlice';
import { selectedCategoryAtEmployeeSlice } from '../slice/selectedCategoryAtEmployeeSlice';
import { selectedCategoryAtApartmentSlice } from '../slice/selectedCategoryAtApartmentSlice';
import { targetEmployeeInfoSlice } from '../slice/targetEmployeeInfoSlice';

const reducers = combineReducers({
  loggedInMenuData: loggedInMenuDataSlice.reducer,
  loggedInUserBasic: loggedInUserBasicSlice.reducer,
  loggedInUserDetail: loggedInUserDetailSlice.reducer,
  selectedCategoryAtEmployee: selectedCategoryAtEmployeeSlice.reducer,
  selectedCategoryAtApartment: selectedCategoryAtApartmentSlice.reducer,
  targetEmployeeInfo: targetEmployeeInfoSlice.reducer, // 근로자 상세 정보
});

// 아래 persistConfig: redux-persist
const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers); //  redux-persist

export const store = configureStore({
  reducer: persistedReducer,
  // 아래 middleware 추가 부분: (https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using)
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

/**
 * 이하: redux-persist 적용 전 코드 (Redux Toolkit만 적용했을 때)
 */
/**
 * 아래 export const store = configureStore({...});:
 * redux-persist 적용 전 (Redux Toolkit만 적용했을 때)
 *
 * configureStore에는 reducer(주의! s가 붙지 않음)가 반드시 있어야 한다.
 * configureStore의 reducer에는,
 * 각각의 slice에 있는 reducer들(slice의 reducers안에 있는 것들)이 들어가야 한다.
 *
 * 주의! counterSlice에 reducers(주의! s가 붙음)을 썼지만,
 * 그걸 reducer안에 넣을 때는 reducer(주의! s가 붙지 않음)라고 써야 한다.
 * 왜 여기서 단수형을 쓰냐면,
 * slice의 reducers에 여러 개의 reducer를 넣었어도
 * (비록 (counterSlice파일에 있는) counterSlice에서는 1개만 썼지만, 2개 이상 올 수 있다),
 * configureStore로 가져올 때는 그 여러 개를 하나로 합쳐서 가져오기 때문이다.
 */
/*
export const store = configureStore({
  reducer: {
    count: countSlice.reducer,
    user: loginSlice.reducer,
  },
});
*/

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
